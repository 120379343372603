import {ReactComponent as ArrowRightIcon} from "../../../../core/ui/icon/arrow-right.svg";

import {NavLink} from "react-router-dom";

import "./_employee-detail-page-header.scss";
import ROUTES from "../../../../core/route/routes";

interface EmployeeDetailPageHeaderProps {
  employeeName: string;
}

function EmployeeDetailPageHeader({employeeName}: EmployeeDetailPageHeaderProps) {
  return (
    <div className={"has-space-between"}>
      <div className={"employee-detail-page-header__breadcrumb"}>
        <NavLink to={ROUTES.EMPLOYEE} className={"typography--h5 text-color--secondary"}>
          {"Mitarbeiter"}
        </NavLink>

        <ArrowRightIcon />

        <p className={"typography--h5 text-color--primary"}>{employeeName}</p>
      </div>

      <div className={"has-space-between employee-detail-page-header__button-group"}>
        {/* Vacation usage management */}
        <NavLink
          className={"button button--primary"}
          to={ROUTES.VACATION_USAGE_MANAGEMENT}>
          {"Urlaubskonto"}
        </NavLink>

        {/* Vacation Planning */}
        <NavLink className={"button button--primary"} to={ROUTES.VACATION_PLANNING}>
          {"Urlaubsplanung"}
        </NavLink>
      </div>
    </div>
  );
}

export default EmployeeDetailPageHeader;
