import "./_tooltip.scss";

import classNames from "classnames";
import ReactTooltip from "react-tooltip";

interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  dataFor: string;
  customClassName?: string;
}

function Tooltip({children, content, dataFor, customClassName}: TooltipProps) {
  return (
    <div className={classNames("tooltip-container", customClassName)}>
      <div className={"tooltip-trigger"} data-for={dataFor} data-tip={true}>
        {children}
      </div>

      <ReactTooltip id={dataFor} effect={"solid"} className={"tooltip"} place={"top"}>
        <div className={"typography--secondary-body tooltip-content"}>{content}</div>
      </ReactTooltip>
    </div>
  );
}

export default Tooltip;
