import {ReactComponent as PlusIcon} from "../../core/ui/icon/plus.svg";
import {ReactComponent as SettingsIcon} from "../../core/ui/icon/settings.svg";
import {ReactComponent as TrashIcon} from "../../core/ui/icon/24px/trash_24x24.svg";
import {ReactComponent as DocumentIcon} from "../../core/ui/icon/36px/document_36x36.svg";

import "./_bill-page.scss";

import {Button} from "@hipo/react-ui-toolkit";
import {format} from "date-fns";
import {Fragment, useCallback, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import axios from "axios";

import PageContent from "../../component/page/content/PageContent";
import Page from "../../component/page/Page";
import Table from "../../component/table/Table";
import {generateBillTableColumns} from "../util/billPageUtils";
import {DATE_FORMAT} from "../../core/util/time/timeConstants";
import useAsyncProcess from "../../core/network/async-process/useAsyncProcess";
import {Bill} from "../api/billApiModel";
import billApi from "../api/billApi";
import {filterTruthyObjectValues} from "../../core/util/object/objectUtils";
import {useAppContext} from "../../core/app/AppContext";
import AsyncContent, {
  AsyncContentStatus
} from "../../component/async-content/AsyncContent";
import Loading from "../../component/loading/Loading";
import ROUTES from "../../core/route/routes";
import {BASE_URL} from "../../core/network/util/networkConstants";
import Modal from "../../component/modal/Modal";
import DeleteModal from "../../component/delete-modal/DeleteModal";
import BillFilterForm from "../form/filter/BillFilterForm";
import {Ordering} from "../../core/util/ordering/orderingTypes";

type TableOrdering =
  | "bill_nr"
  | "firm"
  | "creation_date"
  | "has_to_be_paid_date"
  | "sum"
  | "end_sum";

export type BillTableOrdering = Ordering<TableOrdering>;

function BillPage() {
  const {state, runAsyncProcess} = useAsyncProcess<Bill[]>();
  const [isFilterFormOpen, setFilterFormVisibility] = useState(false);
  const {
    state: {ownFirm, user}
  } = useAppContext();
  const [deleteModalState, setDeleteModalState] = useState({
    visibility: false,
    clickedBill: "",
    bill_nr: ""
  });
  const [filterState, setFilterState] = useState<{
    firm: string;
    nettoStart: string;
    nettoEnd: string;
    brutto_start: string;
    brutto_end: string;
    created_date_start: null | Date;
    created_date_end: null | Date;
  }>();
  const [ordering, setOrdering] = useState<BillTableOrdering["state"]>();
  const [tableColumns, setColumns] = useState(generateBillTableColumns());

  const handleOrdering = useCallback(
    (order_by: TableOrdering) => {
      setOrdering({order_by, direction: ordering?.direction === "asc" ? "des" : "asc"});
    },
    [ordering?.direction]
  );

  useEffect(() => {
    setColumns(generateBillTableColumns({state: ordering, onChange: handleOrdering}));
  }, [ordering, handleOrdering]);

  useEffect(() => {
    runAsyncProcess(
      billApi.getBills(
        filterTruthyObjectValues({
          own_firm: ownFirm,
          firm: filterState?.firm,
          netto_start: filterState?.nettoStart,
          netto_end: filterState?.nettoEnd,
          brutto_start: filterState?.brutto_start,
          brutto_end: filterState?.brutto_end,
          created_date_start: filterState?.created_date_start
            ? String(format(new Date(filterState.created_date_start), DATE_FORMAT.API))
            : undefined,
          created_date_end: filterState?.created_date_end
            ? String(format(new Date(filterState.created_date_end), DATE_FORMAT.API))
            : undefined,
          order_by: ordering?.order_by,
          direction: ordering?.direction
        })
      )
    );
  }, [runAsyncProcess, ownFirm, filterState, ordering?.direction, ordering?.order_by]);

  return (
    <Page title={"Rechnungen"}>
      <PageContent>
        <AsyncContent requestStates={[state]} content={renderAsyncContent} />
      </PageContent>
    </Page>
  );

  function renderAsyncContent(status: AsyncContentStatus) {
    let node = <Fragment />;

    switch (status) {
      case "pending":
        node = <Loading />;
        break;

      case "error":
        node = <p>{"An error ocurred on server."}</p>;
        break;

      case "success":
        node = (
          <>
            <div className={"has-space-between"}>
              <NavLink to={ROUTES.BILL_CREATE} className={"button button--blue"}>
                {"Rechnung erstellen"}

                <PlusIcon />
              </NavLink>

              <h2 className={"typography--h2"}>{"Rechnungen"}</h2>

              <Button
                customClassName={"button--primary text-color--light typography--h8"}
                onClick={() => setFilterFormVisibility(true)}>
                {"Filter"}

                <SettingsIcon />
              </Button>

              <Modal
                customClassName={"filter-modal"}
                isOpen={isFilterFormOpen}
                onClose={() => setFilterFormVisibility(false)}>
                <BillFilterForm
                  onSubmit={handleFilterSubmit}
                  onClose={() => setFilterFormVisibility(false)}
                />
              </Modal>
            </div>

            <div className={"bill-page__table-container"}>
              <Table
                customClassName={"bill-page__table"}
                data={state.data || []}
                columns={tableColumns}
                customRow={renderRow}
              />

              <Modal
                isOpen={deleteModalState.visibility}
                onClose={() =>
                  setDeleteModalState({...deleteModalState, visibility: false})
                }>
                <DeleteModal
                  text={deleteModalState.bill_nr}
                  onSubmit={() => handleDelete(deleteModalState.clickedBill)}
                  onClose={() =>
                    setDeleteModalState({clickedBill: "", bill_nr: "", visibility: false})
                  }
                />
              </Modal>
            </div>
          </>
        );
        break;

      default:
        break;
    }

    return node;
  }

  function renderRow(rowData: Bill) {
    return (
      <div className={"bill-page__table__row"}>
        <p className={"typography--h5"}>{rowData.bill_nr}</p>

        <p className={"typography--h5"}>{rowData.firm}</p>

        <p className={"typography--h5"}>
          {format(new Date(rowData.creation_date), DATE_FORMAT.DEFAULT)}
        </p>

        <p className={"typography--h5"}>
          {rowData.has_to_be_paid_date_start || rowData.has_to_be_paid_date_end ? (
            <>
              <span>
                {rowData.has_to_be_paid_date_start
                  ? format(
                      new Date(rowData.has_to_be_paid_date_start),
                      DATE_FORMAT.DEFAULT
                    )
                  : "X"}
              </span>

              <span style={{margin: "0 8px"}}>{"-"}</span>

              <span>
                {rowData.has_to_be_paid_date_end
                  ? format(new Date(rowData.has_to_be_paid_date_end), DATE_FORMAT.DEFAULT)
                  : "X"}
              </span>
            </>
          ) : (
            "-"
          )}
        </p>

        <p className={"typography--h5"}>{rowData.end_sum || "-"}</p>

        <p className={"typography--h5"}>{rowData.sum || "-"}</p>

        {rowData.file ? (
          <a
            href={rowData.file}
            download={rowData.bill_nr}
            target={"_blank"}
            rel={"noreferrer"}>
            <DocumentIcon />
          </a>
        ) : (
          "-"
        )}

        <Button
          customClassName={"bill-page__table__row__delete-button"}
          onClick={() =>
            setDeleteModalState({
              visibility: true,
              clickedBill: rowData.id,
              bill_nr: rowData.bill_nr
            })
          }>
          <TrashIcon />
        </Button>
      </div>
    );
  }

  function handleFilterSubmit(filter: {
    firm: string;
    nettoStart: string;
    nettoEnd: string;
    brutto_start: string;
    brutto_end: string;
    created_date_start: null | Date;
    created_date_end: null | Date;
  }) {
    setFilterState(filter);
    setFilterFormVisibility(false);
  }

  async function handleDelete(billId: string) {
    const formData = new FormData();

    formData.append("admin", user!.hash);
    formData.append("bill_id", billId!);
    await axios({
      method: "POST",
      url: `${BASE_URL}delete-bill`,
      data: formData
    });

    location.reload();
  }
}

export default BillPage;
