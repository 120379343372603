/* eslint-disable complexity */
/* eslint-disable max-lines */
import {ReactComponent as TrashIcon} from "../../core/ui/icon/24px/trash_24x24.svg";
import {ReactComponent as PlusIcon} from "../../core/ui/icon/plus.svg";

import "./_create-company.scss";

import {Button, FormField, Input, useToaster} from "@hipo/react-ui-toolkit";
import {useState} from "react";
import {Link, useLocation, Location, useNavigate} from "react-router-dom";
import axios, {AxiosError} from "axios";

import Form from "../../component/form/Form";
import FileInput from "../../component/input/file/FileInput";
import ROUTES from "../../core/route/routes";
import {BASE_URL} from "../../core/network/util/networkConstants";
import {OwnFirm} from "../api/companyApiModel";
import ErrorToast from "../../component/toast/error/ErrorToast";
import Modal from "../../component/modal/Modal";
import DeleteModal from "../../component/delete-modal/DeleteModal";

type LocationWithState = Location & {
  state?: {
    company: Partial<
      Omit<OwnFirm, "logo"> & {
        logo?: File;
      }
    >;
  };
};

function CreateCompany() {
  const {state} = useLocation() as LocationWithState;
  const [formState, setFormState] = useState(state?.company);
  const navigate = useNavigate();
  const toaster = useToaster();
  const [isDeleteModalOpen, setDeleteModalState] = useState(false);

  return (
    <div className={"create-company"}>
      <h3 className={"typography--h3"} style={{marginBottom: "24px"}}>
        {"Neues Unternehmen"}
      </h3>

      <Form>
        <div className={"create-company__form"}>
          <div style={{width: "100%"}}>
            <FormField label={"Firmenname"} labelFor={"firm-name"}>
              <Input
                id={"firm-name"}
                name={"firm-name"}
                value={formState?.name}
                onChange={(event) =>
                  setFormState({...formState, name: event.currentTarget.value})
                }
              />
            </FormField>

            <FormField label={"Adresse"} labelFor={"address"}>
              <Input
                id={"address"}
                name={"address"}
                value={formState?.address}
                onChange={(event) =>
                  setFormState({...formState, address: event.currentTarget.value})
                }
              />
            </FormField>

            <h3 className={"typography--h3"}>{"Info Ansprechpartner"}</h3>

            <FormField label={"Name"} labelFor={"name"}>
              <Input
                id={"name"}
                name={"name"}
                value={formState?.contact_name}
                onChange={(event) =>
                  setFormState({...formState, contact_name: event.currentTarget.value})
                }
              />
            </FormField>

            <FormField label={"Tel"} labelFor={"tel"}>
              <Input
                id={"tel"}
                name={"tel"}
                value={formState?.contact_phone}
                onChange={(event) =>
                  setFormState({...formState, contact_phone: event.currentTarget.value})
                }
              />
            </FormField>

            <FormField label={"Fax"} labelFor={"fax"}>
              <Input
                id={"fax"}
                name={"fax"}
                value={formState?.contact_fax}
                onChange={(event) =>
                  setFormState({...formState, contact_fax: event.currentTarget.value})
                }
              />
            </FormField>
          </div>

          <div style={{width: "100%"}}>
            <FormField label={"Mobil"} labelFor={"mobil"}>
              <Input
                id={"mobil"}
                name={"mobil"}
                value={formState?.contact_phone}
                onChange={(event) =>
                  setFormState({...formState, contact_phone: event.currentTarget.value})
                }
              />
            </FormField>

            <FormField label={"E-mail"} labelFor={"mail"}>
              <Input
                id={"mail"}
                name={"mail"}
                value={formState?.mail}
                onChange={(event) =>
                  setFormState({...formState, mail: event.currentTarget.value})
                }
              />
            </FormField>

            <h3 className={"typography--h3"}>{"Info Bankverbindung"}</h3>

            <FormField label={"Bank"} labelFor={"bank"}>
              <Input
                id={"bank"}
                name={"bank"}
                value={formState?.bank_name}
                onChange={(event) =>
                  setFormState({...formState, bank_name: event.currentTarget.value})
                }
              />
            </FormField>

            <FormField label={"IBAN"} labelFor={"iban"}>
              <Input
                id={"iban"}
                name={"iban"}
                value={formState?.bank_iban}
                onChange={(event) =>
                  setFormState({...formState, bank_iban: event.currentTarget.value})
                }
              />
            </FormField>

            <FormField label={"BIC"} labelFor={"bic"}>
              <Input
                id={"bic"}
                name={"bic"}
                value={formState?.bank_bic}
                onChange={(event) =>
                  setFormState({...formState, bank_bic: event.currentTarget.value})
                }
              />
            </FormField>
          </div>

          <div style={{width: "100%"}}>
            <FormField label={"Geschäftsführung"} labelFor={"director"}>
              <Input
                id={"director"}
                name={"director"}
                value={formState?.company_place}
                onChange={(event) =>
                  setFormState({...formState, company_place: event.currentTarget.value})
                }
              />
            </FormField>

            <FormField label={"Sitz"} labelFor={"seat"}>
              <Input
                id={"seat"}
                name={"seat"}
                value={formState?.chairman}
                onChange={(event) =>
                  setFormState({...formState, chairman: event.currentTarget.value})
                }
              />
            </FormField>

            <FormField label={"Registergericht"} labelFor={"registry"}>
              <Input
                id={"registry"}
                name={"registry"}
                value={formState?.register_court}
                onChange={(event) =>
                  setFormState({...formState, register_court: event.currentTarget.value})
                }
              />
            </FormField>

            <FormField label={"Steuernummer"} labelFor={"tax"}>
              <Input
                id={"tax"}
                name={"tax"}
                value={formState?.tax_nr}
                onChange={(event) =>
                  setFormState({...formState, tax_nr: event.currentTarget.value})
                }
              />
            </FormField>

            <FormField label={"Ust.-ID"} labelFor={"ust"}>
              <Input
                id={"ust"}
                name={"ust"}
                value={formState?.ustid}
                onChange={(event) =>
                  setFormState({...formState, ustid: event.currentTarget.value})
                }
              />
            </FormField>

            <FormField label={"Logo"} labelFor={"logo"}>
              <FileInput
                name={"company-logo"}
                onChange={(event) =>
                  setFormState({
                    ...formState,
                    logo: event.currentTarget.files
                      ? event.currentTarget.files[0]
                      : undefined
                  })
                }
                customClassName={"button--primary create-company__add-logo-cta"}
                buttonContent={
                  <>
                    <PlusIcon />

                    {"Dokument hochladen"}
                  </>
                }
              />

              {formState?.logo && (
                <p className={"typography--b4"}>{formState.logo.name as File["name"]}</p>
              )}
            </FormField>
          </div>
        </div>

        <div className={"create-company__button-container"}>
          {state?.company && (
            <Button
              onClick={() => setDeleteModalState(true)}
              customClassName={"button--transparent"}
              style={{marginTop: "auto"}}>
              <TrashIcon />
            </Button>
          )}

          <Modal isOpen={isDeleteModalOpen} onClose={() => setDeleteModalState(false)}>
            <DeleteModal
              onSubmit={handleDelete}
              onClose={() => setDeleteModalState(false)}
            />
          </Modal>

          <div
            className={"button-container create-company__button-group"}
            style={{marginTop: "50px", marginLeft: "auto"}}>
            <Link
              className={"button--transparent button--large create-company--back-button"}
              to={ROUTES.COMPANY_SELECT}>
              {"Abbrechen"}
            </Link>

            <Button
              customClassName={"button--primary button--large"}
              type={"submit"}
              onClick={handleSubmit}
              isDisabled={!formState?.name}>
              {state?.company ? "Speichern" : "Hinzufügen"}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );

  async function handleSubmit() {
    const formData = new FormData();

    formData.append("name", formState!.name!);
    if (formState?.address) formData.append("address", formState.address);
    if (formState?.contact_phone) formData.append("phone", formState.contact_phone);
    if (formState?.mail) formData.append("mail", formState.mail);
    if (formState?.chairman) formData.append("chairman", formState.chairman);
    if (formState?.company_place)
      formData.append("company_place", formState.company_place);
    if (formState?.register_court)
      formData.append("register_court", formState.register_court);
    if (formState?.tax_nr) formData.append("tax_nr", formState.tax_nr);
    if (formState?.ustid) formData.append("ustid", formState.ustid);
    if (formState?.contact_name) formData.append("contact_name", formState.contact_name);
    if (formState?.contact_phone)
      formData.append("contact_phone", formState.contact_phone);
    if (formState?.contact_fax) formData.append("contact_fax", formState.contact_fax);
    if (formState?.bank_name) formData.append("bank_name", formState.bank_name);
    if (formState?.bank_iban) formData.append("bank_iban", formState.bank_iban);
    if (formState?.bank_bic) formData.append("bank_bic", formState.bank_bic);

    if (formState?.id) {
      formData.append("id", formState.id);
      if (formState.name === state?.company.name) {
        formData.delete("name");
      }

      try {
        await axios({
          method: "POST",
          url: `${BASE_URL}update-own-firm`,
          data: formData
        });

        navigate(ROUTES.COMPANY_SELECT);
      } catch (e) {
        const err = e as AxiosError;

        toaster.display({
          render() {
            return <ErrorToast message={err.response?.data as string} />;
          }
        });
      }

      if (formState.logo && formState.logo.size > 0) {
        const logoData = new FormData();

        if (formState?.logo) logoData.append("file", formState.logo);
        logoData.append("own_firm_id", formState.id);
        await axios({
          method: "POST",
          url: `${BASE_URL}own-firm-add-logo`,
          data: logoData
        });
      }
    } else {
      try {
        const id = await axios({
          method: "POST",
          url: `${BASE_URL}create-own-firm`,
          data: formData
        });

        if (formState?.logo) {
          const logoDataCreate = new FormData();

          logoDataCreate.append("file", formState.logo);
          logoDataCreate.append("own_firm_id", id.data);
          await axios({
            method: "POST",
            url: `${BASE_URL}own-firm-add-logo`,
            data: logoDataCreate
          });
        }

        navigate(ROUTES.COMPANY_SELECT);
      } catch (e) {
        const err = e as AxiosError;

        toaster.display({
          render() {
            return <ErrorToast message={err.response?.data as string} />;
          }
        });
      }
    }
  }

  function handleDelete() {
    const formData = new FormData();

    formData.append("own_firm_id", state!.company.id!);
    axios({
      method: "POST",
      url: `${BASE_URL}delete-own-firm`,
      data: formData
    });

    navigate(ROUTES.COMPANY_SELECT);
  }
}

export default CreateCompany;
