import "./_create-tour-form.scss";

import {useState} from "react";
import {
  Button,
  DropdownOption,
  FormField,
  Input,
  Textarea,
  TypeaheadSelect,
  useToaster
} from "@hipo/react-ui-toolkit";
import axios, {AxiosError} from "axios";

import Form from "../../../component/form/Form";
import {useAppContext} from "../../../core/app/AppContext";
import {BASE_URL} from "../../../core/network/util/networkConstants";
import ErrorToast from "../../../component/toast/error/ErrorToast";

interface CreateTourFormProps {
  onCancel: VoidFunction;
}

function CreateTourForm({onCancel}: CreateTourFormProps) {
  const {
    state: {ownFirm, globalFirms, vehicleList, driverList, user}
  } = useAppContext();
  const [formState, setFormState] = useState<{
    id: string;
    firmList: DropdownOption[];
    driverList: DropdownOption[];
    truckList: DropdownOption[];
    note: string;
  }>({
    id: "",
    firmList: [],
    driverList: [],
    truckList: [],
    note: ""
  });
  const toaster = useToaster();

  return (
    <div className={"create-tour-form-container"}>
      <p className={"typography--h3 text-color--primary"}>{"Neue Tour hinzufügen"}</p>

      <Form customClassName={"create-tour-form"}>
        <div>
          <FormField label={"Roller Nummer:"} labelFor={"tour-id"}>
            <Input
              id={"tour-id"}
              name={"tour-id"}
              value={formState?.id}
              onChange={handleTourNameChange}
            />
          </FormField>

          <FormField label={"Firmenname:"} labelFor={"firm-name"}>
            <TypeaheadSelect
              dropdownOptions={
                globalFirms?.tour_firms
                  ? globalFirms?.tour_firms.map((item) => ({
                      id: item.name,
                      title: item.name
                    }))
                  : []
              }
              selectedOptions={formState.firmList}
              selectedOptionLimit={1}
              onSelect={handleSelectFirm}
              typeaheadProps={{
                placeholder: "Suche",
                name: "firm-name"
              }}
              onTagRemove={handleRemoveFirm}
            />
          </FormField>

          <FormField label={"Fahrer:"} labelFor={"driver-list"}>
            <TypeaheadSelect
              dropdownOptions={
                driverList && driverList.length > 0
                  ? driverList?.map((item) => ({
                      id: String(item.id),
                      title: `${item.name}`
                    }))
                  : []
              }
              selectedOptions={formState.driverList}
              onSelect={handleSelectDriver}
              selectedOptionLimit={1}
              typeaheadProps={{
                placeholder: "Suche",
                name: "driver-list"
              }}
              onTagRemove={handleRemoveDriver}
            />
          </FormField>

          <FormField label={"LKW-Kennzeichen:"} labelFor={"truck"}>
            <TypeaheadSelect
              dropdownOptions={
                vehicleList && vehicleList.length > 0
                  ? vehicleList.map((item) => ({id: String(item.id), title: item.plate}))
                  : []
              }
              selectedOptions={formState.truckList}
              onSelect={handleSelectTruck}
              selectedOptionLimit={1}
              typeaheadProps={{
                placeholder: "Suche",
                name: "truck"
              }}
              onTagRemove={handleRemoveTruck}
            />
          </FormField>
        </div>

        <div>
          <FormField
            label={"Tournotizen:"}
            labelFor={"tour-note"}
            customClassName={"create-tour-form__form-field--note"}>
            <Textarea
              name={"tour-note"}
              onChange={handleNoteChange}
              customClassNames={{textarea: "create-tour-form__note-textarea"}}
            />
          </FormField>

          <div className={"create-tour-form__button-group"}>
            <Button
              onClick={handleCancelButton}
              customClassName={"button--transparent button--large"}>
              {"Abbrechen"}
            </Button>

            <Button
              onClick={handleSubmit}
              isDisabled={
                !(
                  formState.id &&
                  formState.firmList[0] &&
                  formState.driverList[0] &&
                  formState.truckList[0]
                )
              }
              customClassName={"button--primary button--large"}
              type={"submit"}>
              {"Hinzufügen"}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );

  function handleTourNameChange(event: React.SyntheticEvent<HTMLInputElement>) {
    setFormState({
      ...formState,
      id: event.currentTarget.value
    });
  }

  function handleSelectFirm(option: DropdownOption) {
    setFormState({
      ...formState,
      firmList: [...formState.firmList, option]
    });
  }

  function handleRemoveFirm(option: DropdownOption) {
    setFormState({
      ...formState,
      firmList: formState.firmList.filter((firm) => firm !== option)
    });
  }

  function handleSelectDriver(option: DropdownOption) {
    setFormState({
      ...formState,
      driverList: [...formState.driverList, option]
    });
  }

  function handleRemoveDriver(option: DropdownOption) {
    setFormState({
      ...formState,
      driverList: formState.driverList.filter((driver) => driver !== option)
    });
  }

  function handleSelectTruck(option: DropdownOption) {
    setFormState({
      ...formState,
      truckList: [...formState.truckList, option]
    });
  }

  function handleRemoveTruck(option: DropdownOption) {
    setFormState({
      ...formState,
      truckList: formState.truckList.filter((truck) => truck !== option)
    });
  }

  function handleNoteChange(event: React.SyntheticEvent<HTMLTextAreaElement>) {
    setFormState({
      ...formState,
      note: event.currentTarget.value
    });
  }

  function handleCancelButton() {
    onCancel();
  }

  async function handleSubmit() {
    const formData = new FormData();

    formData.append("own_firm", ownFirm);
    formData.append("admin", user!.hash);
    formData.append("roller_nr", formState.id);
    formData.append("firm_name", formState.firmList[0].title);
    if (formState.note) formData.append("general_note", formState.note);
    if (formState.driverList[0])
      formData.append("default_driver", formState.driverList[0].id);
    formData.append("default_truck", formState.truckList[0].title);

    try {
      await axios({
        method: "POST",
        url: `${BASE_URL}create-tour`,
        data: formData
      });
    } catch (e) {
      const err = e as AxiosError;

      toaster.display({
        render() {
          return <ErrorToast message={err.response?.data as string} />;
        }
      });
    }

    location.reload();
  }
}

export default CreateTourForm;
