import {ReactComponent as PlusIcon} from "../../../../core/ui/icon/plus.svg";

import "./_employee-detail-page-debt.scss";

import {useState} from "react";
import {format} from "date-fns";
import {Button, ListItem} from "@hipo/react-ui-toolkit";
import axios from "axios";

import Table from "../../../../component/table/Table";
import {generateEmployeeDebtTableColumns} from "./util/employeeDetailPageDebtUtils";
import {DATE_FORMAT} from "../../../../core/util/time/timeConstants";
import Modal from "../../../../component/modal/Modal";
import EmployeeDebtForm from "./form/EmployeeDebtForm";
import {useAppContext} from "../../../../core/app/AppContext";
import {BASE_URL} from "../../../../core/network/util/networkConstants";
import {EmployeeDebt} from "../../../api/employeeApiModel";
import {formatNumber} from "../../../../core/util/number/numberUtils";

interface EmployeeDetailPageDebtProps {
  workerId: string;
  debtPayments?: EmployeeDebt[];
}

const numberFormatter = formatNumber({
  maximumFractionDigits: 2,
  minimumFractionDigits: 0
});

function EmployeeDetailPageDebt({debtPayments, workerId}: EmployeeDetailPageDebtProps) {
  const [modalState, setModalState] = useState<{
    isOpen: boolean;
    id: string;
    mode: "debt" | "paid";
    amount: string;
    date: null | Date;
    note?: string;
  }>({
    isOpen: false,
    id: "",
    mode: "debt",
    amount: "",
    date: null
  });
  const {
    state: {user}
  } = useAppContext();
  const [debtPaymentState, setDebtPayment] = useState(debtPayments);
  const totalAmount = debtPaymentState?.map((item) => Number(item.amount));
  const totalDebt = totalAmount?.reduce((acc, value) => {
    if (value >= 0) return acc + value;

    return acc;
  }, 0);
  const totalPaid = totalAmount?.reduce((acc, value) => {
    if (value < 0) return acc + value;

    return acc;
  }, 0);

  return (
    <div className={"employee-detail-page-debt"}>
      <div className={"has-space-between employee-detail-page-debt__header"}>
        <h2 className={"typography--h1 text-color--blue"}>{"Schulden"}</h2>

        <div>
          <div className={"employee-detail-page-debt__field"}>
            <label htmlFor={"total-amount"} className={"typography--h7"}>
              {"Total Menge:"}
            </label>
            <p className={"typography--h5"} style={{marginLeft: "auto"}}>
              {totalDebt ? `${numberFormatter(totalDebt)}€` : "0€"}
            </p>

            <Button
              customClassName={"button--transparent"}
              onClick={() =>
                setModalState({
                  id: "",
                  amount: "",
                  date: null,
                  isOpen: true,
                  mode: "debt"
                })
              }>
              <PlusIcon />
            </Button>
          </div>

          <div className={"employee-detail-page-debt__field"}>
            <label htmlFor={"total-paid"} className={"typography--h7"}>
              {"Total Bezahlt:"}
            </label>
            <p className={"typography--h5"} style={{marginLeft: "auto"}}>
              {totalPaid ? `${numberFormatter(Math.abs(totalPaid))}€` : "0€"}
            </p>

            <Button
              customClassName={"button--transparent"}
              onClick={() =>
                setModalState({
                  id: "",
                  amount: "",
                  date: null,
                  isOpen: true,
                  mode: "paid"
                })
              }>
              <PlusIcon />
            </Button>
          </div>

          <Modal
            isOpen={modalState.isOpen}
            onClose={() => setModalState({...modalState, isOpen: false})}>
            <EmployeeDebtForm
              mode={modalState.mode}
              onClose={() => setModalState({...modalState, isOpen: false})}
              onSubmit={handleSubmit}
              data={{
                id: modalState.id,
                name: modalState.amount,
                mode: modalState.mode,
                date: modalState.date,
                note: modalState.note
              }}
            />
          </Modal>
        </div>
      </div>

      <Table
        data={debtPaymentState || []}
        columns={generateEmployeeDebtTableColumns()}
        customRow={renderRow}
        customClassName={"employee-detail-page-debt__table"}
      />
    </div>
  );

  async function handleSubmit({
    name,
    date,
    mode,
    note
  }: {
    name: string;
    date: Date;
    mode: string;
    note?: string;
  }) {
    const formData = new FormData();

    formData.append("worker_id", workerId);
    formData.append("admin", user!.hash);
    formData.append("amount", `${mode === "debt" ? "+" : "-"}${name}`);
    formData.append("date", format(new Date(date), DATE_FORMAT.API));
    if (note) formData.append("notes", note);

    if (modalState.amount.length > 0) {
      const updateData = new FormData();

      updateData.append("debt_id", modalState.id);
      updateData.append("admin", user!.hash);
      updateData.append("amount", `${mode === "debt" ? "+" : "-"}${name}`);
      updateData.append("date", format(new Date(date), DATE_FORMAT.API));
      if (note) updateData.append("notes", note);
      await axios({
        method: "POST",
        url: `${BASE_URL}update-debt`,
        data: updateData
      });

      location.reload();
    } else {
      await axios({
        method: "POST",
        url: `${BASE_URL}add-debt`,
        data: formData
      });

      location.reload();

      if (debtPaymentState) {
        setDebtPayment([
          ...debtPaymentState,
          {
            id: "0",
            amount: `${mode === "debt" ? "+" : "-"}${name}`,
            date: format(date, DATE_FORMAT.API)
          }
        ]);
      } else {
        setDebtPayment([
          {
            id: "0",
            amount: `${mode === "debt" ? "+" : "-"}${name}`,
            date: format(date, DATE_FORMAT.API)
          }
        ]);
      }
    }

    setModalState({...modalState, isOpen: false});
  }

  function renderRow(rowData: {
    id: string;
    amount: string;
    date: string;
    notes?: string;
  }) {
    return (
      <ListItem
        customClassName={"employee-detail-page-debt__table__row"}
        clickableListItemProps={{
          onClick: () =>
            setModalState({
              isOpen: true,
              id: rowData.id,
              amount: rowData.amount,
              date: new Date(rowData.date),
              note: rowData.notes,
              mode: rowData.amount.includes("-") ? "paid" : "debt"
            })
        }}>
        <p className={"typography--h5"}>{`${
          rowData.amount.includes("-") ? "- " : "+ "
        }${formatNumber({maximumFractionDigits: 0})(
          Number(rowData.amount.replace("-", ""))
        )}€`}</p>

        <p className={"typography--h5"}>
          {format(new Date(rowData.date), DATE_FORMAT.DEFAULT)}
        </p>
      </ListItem>
    );
  }
}

export default EmployeeDetailPageDebt;
