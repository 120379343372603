/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
import {ReactComponent as TrashIcon} from "../../../../core/ui/icon/24px/trash_24x24.svg";
import {ReactComponent as PlusButtonIcon} from "../../../../core/ui/icon/plus-button.svg";
import {ReactComponent as SaveIcon} from "../../../../core/ui/icon/save.svg";
import {ReactComponent as EditIcon} from "../../../../core/ui/icon/24px/edit.svg";

import "./_employee-detail-page-working.scss";

import {Button, Input} from "@hipo/react-ui-toolkit";
import {SyntheticEvent, useState} from "react";
import axios from "axios";
import {format} from "date-fns";

import {generateEmployeeWorkingColumns} from "./util/employeeDetailPageWorkingUtils";
import TableHeader from "../../../../component/table/header/TableHeader";
import DateInput from "../../../../component/input/date/DateInput";
import {BASE_URL} from "../../../../core/network/util/networkConstants";
import {DATE_FORMAT} from "../../../../core/util/time/timeConstants";
import {useAppContext} from "../../../../core/app/AppContext";
import {EmployeeWorkTime} from "../../../api/employeeApiModel";
import {
  customDifferenceInHours,
  generateFullDateForToday
} from "../../../../core/util/time/timeUtils";
import {formatNumber} from "../../../../core/util/number/numberUtils";
import DeleteModal from "../../../../component/delete-modal/DeleteModal";
import Modal from "../../../../component/modal/Modal";

interface EmployeeDetailPageWorkingProps {
  workerId: string;
  data: EmployeeWorkTime[];
}

const numberFormatter = formatNumber({
  maximumFractionDigits: 2,
  minimumFractionDigits: 0
});

function EmployeeDetailPageWorking({
  workerId,
  data: availableData
}: EmployeeDetailPageWorkingProps) {
  const [isEditable, setEditable] = useState(false);
  const {
    state: {user}
  } = useAppContext();
  const [formState, setFormState] = useState<
    Omit<EmployeeWorkTime, "pause"> & {pause: {hour: string; min: string}}
  >({
    id: "0",
    cost: "",
    start: "",
    pause: {hour: "", min: ""},
    end: "",
    duration: "",
    date: ""
  });
  const [data, setData] = useState(availableData);
  const fullStartDate = generateFullDateForToday(formState.start);
  const fullEndDate = generateFullDateForToday(formState.end);
  const duration = customDifferenceInHours(fullStartDate, formState.pause, fullEndDate);
  const [isUpdate, setUpdateState] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState({id: "", isOpen: false});

  return (
    <div>
      <div>
        <h3 className={"typography--h1 text-color--blue"}>{"Arbeitszeit & Spesen"}</h3>
      </div>

      <div className={"employee-detail-page-working__table-container"}>
        <div className={"employee-detail-page-working__table"}>
          <TableHeader items={generateEmployeeWorkingColumns()} />

          {renderTableData()}

          <Modal
            isOpen={deleteModalState.isOpen}
            onClose={() => setDeleteModalState({isOpen: false, id: ""})}>
            <DeleteModal
              onSubmit={() => handleDelete(deleteModalState.id)}
              onClose={() => setDeleteModalState({isOpen: false, id: ""})}
            />
          </Modal>
        </div>
      </div>
    </div>
  );

  function renderTableData() {
    return (
      <>
        {data.map((item, _index) => {
          if (isUpdate && item.id === formState.id) {
            return (
              <div
                key={item.date}
                className={"table-row employee-detail-page-working__table__row"}>
                <Input
                  name={"expenses"}
                  leftIcon={"€"}
                  value={formState.cost}
                  customClassName={"employee-detail-page-working__table__row__expenses"}
                  onChange={(event) =>
                    setFormState({...formState, cost: event.currentTarget.value})
                  }
                />

                <Input
                  name={"start"}
                  type={"time"}
                  value={formState.start}
                  onChange={(value) =>
                    setFormState({...formState, start: value.currentTarget.value})
                  }
                />

                <div className={"duration-input-group"}>
                  <Input
                    name={"pause-hour"}
                    value={formState.pause.hour}
                    onChange={handlePauseHour}
                  />

                  <p>{":"}</p>

                  <Input
                    id={"pause-min-input"}
                    name={"pause-min"}
                    value={formState.pause.min}
                    onChange={handlePauseMin}
                  />
                </div>

                <Input
                  type={"time"}
                  value={formState.end}
                  name={"end"}
                  onChange={(value) =>
                    setFormState({...formState, end: value.currentTarget.value})
                  }
                />

                <p>{duration}</p>

                <DateInput
                  name={"date"}
                  selected={formState.date.length > 0 ? new Date(formState.date) : null}
                  onChange={(date) => setFormState({...formState, date: String(date)})}
                />

                <Button
                  customClassName={"employee-detail-page-working__table__row__submit"}
                  onClick={handleSubmit}
                  isDisabled={
                    !(
                      formState.date &&
                      formState.end &&
                      formState.start &&
                      formState.pause
                    )
                  }>
                  <SaveIcon />
                </Button>
              </div>
            );
          }

          return (
            <div
              key={item.id}
              className={
                "typography--h5 table-row employee-detail-page-working__table__row"
              }>
              <p>{item.cost ? `${numberFormatter(Number(item.cost))}€` : "-"}</p>

              <p>{item.start.substring(0, 5)}</p>

              <p>{item.pause.substring(0, 5)}</p>

              <p>{item.end.substring(0, 5)}</p>

              <p>{item.duration ? item.duration.substring(0, 5) : "-"}</p>

              <p>{item.date ? format(new Date(item.date), DATE_FORMAT.DEFAULT) : "-"}</p>

              <div className={"is-centered"}>
                <Button
                  customClassName={"employee-detail-page-working__edit-button"}
                  onClick={() => setDeleteModalState({id: item.id, isOpen: true})}>
                  <TrashIcon />
                </Button>

                <Button
                  customClassName={"employee-detail-page-working__edit-button"}
                  onClick={() => {
                    setUpdateState(true);
                    setEditable(false);

                    setFormState({
                      id: item.id,
                      cost: item.cost,
                      start: item.start.substring(0, 5),
                      pause: {
                        hour: item.pause.substring(0, 2),
                        min: item.pause.substring(3, 5)
                      },
                      end: item.end.substring(0, 5),
                      duration: item.duration ? item.duration.substring(0, 5) : "-",
                      date: item.date
                    });
                  }}>
                  <EditIcon />
                </Button>
              </div>
            </div>
          );
        })}

        {isEditable ? (
          <div className={"table-row employee-detail-page-working__table__row"}>
            <Input
              name={"expenses"}
              value={formState.cost}
              leftIcon={"€"}
              customClassName={"employee-detail-page-working__table__row__expenses"}
              onChange={(event) =>
                setFormState({...formState, cost: event.currentTarget.value})
              }
            />

            <Input
              name={"start"}
              type={"time"}
              value={formState.start}
              onChange={(value) =>
                setFormState({...formState, start: value.currentTarget.value})
              }
            />

            <div className={"duration-input-group"}>
              <Input
                name={"pause-hour"}
                value={formState.pause.hour}
                onChange={handlePauseHour}
              />

              <p>{":"}</p>

              <Input
                id={"pause-min-input"}
                name={"pause-min"}
                value={formState.pause.min}
                onChange={handlePauseMin}
              />
            </div>

            <Input
              type={"time"}
              value={formState.end}
              name={"end"}
              onChange={(value) =>
                setFormState({...formState, end: value.currentTarget.value})
              }
            />

            <p>{duration}</p>

            <DateInput
              name={"date"}
              selected={formState.date.length > 0 ? new Date(formState.date) : null}
              onChange={(date) => setFormState({...formState, date: String(date)})}
            />

            <Button
              customClassName={"employee-detail-page-working__table__row__submit"}
              onClick={handleSubmit}
              isDisabled={
                !(formState.date && formState.end && formState.start && formState.pause)
              }>
              <SaveIcon />
            </Button>
          </div>
        ) : (
          <div className={"table-row"}>
            <Button
              onClick={() => {
                setEditable(true);
                setUpdateState(false);
                setFormState({
                  id: "0",
                  cost: "",
                  start: "",
                  pause: {hour: "", min: ""},
                  end: "",
                  duration: "",
                  date: ""
                });
              }}
              customClassName={"employee-detail-page-working__table__row__edit-button"}>
              <PlusButtonIcon />
            </Button>
          </div>
        )}
      </>
    );

    function handlePauseHour(event: SyntheticEvent<HTMLInputElement>) {
      const {value} = event.currentTarget;

      if (Number(value) <= 25 && value.length <= 2) {
        setFormState({...formState, pause: {...formState.pause, hour: value}});
      }

      if (value.length >= 2) {
        const pauseMinInput = document.querySelector<HTMLDivElement>("#pause-min-input");

        if (pauseMinInput) {
          pauseMinInput.focus();
        }
      }
    }
    function handlePauseMin(event: SyntheticEvent<HTMLInputElement>) {
      const {value} = event.currentTarget;

      if (Number(value) <= 60 && value.length <= 2) {
        setFormState({...formState, pause: {...formState.pause, min: value}});
      }
    }

    async function handleSubmit() {
      const formData = new FormData();

      formData.append("worker_id", workerId);
      formData.append("admin", user!.hash);
      formData.append("date", format(new Date(formState.date!), DATE_FORMAT.API));
      formData.append("start", formState.start);
      formData.append("pause", `${formState.pause.hour}:${formState.pause.min}`);
      formData.append("end", formState.end);
      formData.append("duration", String(duration));
      if (formState.cost) formData.append("daily_expenses", formState.cost);
      await axios({
        method: "POST",
        url: `${BASE_URL}add-worktime`,
        data: formData
      });

      if (isUpdate) {
        setUpdateState(false);
      } else {
        setData([
          ...data,
          {
            ...formState,
            pause: `${formState.pause.hour}:${formState.pause.min}`,
            duration
          }
        ]);

        setEditable(false);
      }

      setFormState({
        id: "0",
        cost: "",
        start: "",
        pause: {hour: "", min: ""},
        end: "",
        duration: "",
        date: ""
      });

      location.reload();
    }
  }

  async function handleDelete(id: string) {
    const formData = new FormData();

    formData.append("worktime_id", id);

    await axios({
      method: "POST",
      url: `${BASE_URL}delete-worktime`,
      data: formData
    });

    location.reload();
  }
}

export default EmployeeDetailPageWorking;
