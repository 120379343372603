import {ReactComponent as TrashIcon} from "../../../core/ui/icon/24px/trash_24x24.svg";

import "./_create-meeting-form.scss";

import {Button, FormField, Textarea} from "@hipo/react-ui-toolkit";
import {useState} from "react";
import {format} from "date-fns";
import axios from "axios";

import DateInput from "../../../component/input/date/DateInput";
import {DATE_FORMAT} from "../../../core/util/time/timeConstants";
import {BASE_URL} from "../../../core/network/util/networkConstants";
import {useAppContext} from "../../../core/app/AppContext";
import Modal from "../../../component/modal/Modal";
import DeleteModal from "../../../component/delete-modal/DeleteModal";

interface CreateMeetingFormProps {
  contact: string;
  onClose: VoidFunction;
  date?: Date;
  meetingId?: string;
  note?: string;
}

function CreateMeetingForm({
  contact,
  date,
  note = "",
  meetingId,
  onClose
}: CreateMeetingFormProps) {
  const [formState, setFormState] = useState({date, note});
  const {
    state: {user}
  } = useAppContext();
  const [isDeleteModalOpen, setDeleteModalState] = useState(false);

  return (
    <div>
      <h3 className={"typography--h3"}>{date ? "Meeting" : "Neues Meeting"}</h3>

      <form
        onSubmit={handleSubmit}
        className={"form-field--vertical create-meeting-form"}>
        <FormField label={"Datum"} labelFor={"date"}>
          <DateInput
            name={"date"}
            onChange={(selectedDate) =>
              setFormState({...formState, date: selectedDate || undefined})
            }
            selected={formState.date}
          />
        </FormField>

        <FormField label={"Notizen"} labelFor={"note"}>
          <Textarea
            name={"note"}
            onChange={(event) =>
              setFormState({...formState, note: event.currentTarget.value})
            }
            value={formState.note}
          />
        </FormField>

        <div className={"form__button-group"}>
          {meetingId && (
            <>
              <Button
                onClick={() => setDeleteModalState(true)}
                customClassName={"button--transparent"}
                style={{marginTop: "4px", marginRight: "auto"}}>
                <TrashIcon />
              </Button>

              <Modal
                isOpen={isDeleteModalOpen}
                onClose={() => setDeleteModalState(false)}>
                <DeleteModal
                  onSubmit={handleDelete}
                  onClose={() => setDeleteModalState(false)}
                />
              </Modal>
            </>
          )}

          <Button customClassName={"button--transparent button--large"} onClick={onClose}>
            {"Abbrechen"}
          </Button>

          <Button
            customClassName={"button--primary button--large"}
            type={"submit"}
            isDisabled={!formState.date}>
            {date ? "Speichern" : "Hinzufügen"}
          </Button>
        </div>
      </form>
    </div>
  );

  async function handleDelete() {
    const formData = new FormData();

    formData.append("admin", user!.hash);
    formData.append("meeting_id", meetingId!);

    await axios({
      method: "POST",
      url: `${BASE_URL}delete-meeting`,
      data: formData
    });

    location.reload();
  }

  async function handleSubmit(event: any) {
    event.preventDefault();

    const formData = new FormData();

    formData.append("contact", contact);
    formData.append("admin", user!.hash);
    formData.append("meetings_date", format(formState.date!, DATE_FORMAT.API));
    if (formState.note) formData.append("meetings_notes", formState.note);

    if (date) {
      const updateForm = new FormData();

      updateForm.append("admin", user!.hash);
      updateForm.append("meeting_id", meetingId!);
      if (formState.date)
        updateForm.append("meetings_date", format(formState.date!, DATE_FORMAT.API));
      if (formState.note) updateForm.append("meetings_notes", formState.note);
      await axios({
        method: "POST",
        url: `${BASE_URL}update-meeting`,
        data: updateForm
      });
    } else {
      await axios({
        method: "POST",
        url: `${BASE_URL}create-meeting`,
        data: formData
      });
    }

    location.reload();
  }
}

export default CreateMeetingForm;
