import "./_create-driver-form.scss";

import {
  FormField,
  TypeaheadSelect,
  DropdownOption,
  Button,
  Input
} from "@hipo/react-ui-toolkit";
import {useEffect, useState} from "react";
import axios from "axios";
import {format} from "date-fns";

import DateInput from "../../../component/input/date/DateInput";
import employeeApi from "../../../employee/api/employeeApi";
import {BASE_URL} from "../../../core/network/util/networkConstants";
import {DATE_FORMAT} from "../../../core/util/time/timeConstants";
import {useAppContext} from "../../../core/app/AppContext";

function CreateDriverForm({cardId, onClose}: {cardId: string; onClose: VoidFunction}) {
  const [state, setState] = useState<{
    name: DropdownOption[];
    gotDate: undefined | Date;
    getBackDate: undefined | Date;
    hasTakenDate: boolean;
  }>({name: [], gotDate: undefined, getBackDate: undefined, hasTakenDate: true});
  const [driverOptions, setDriverOptions] = useState<DropdownOption[]>();
  const {
    state: {ownFirm, user}
  } = useAppContext();

  useEffect(() => {
    employeeApi.getDrivers({own_firm: ownFirm}).then((response) => {
      setDriverOptions(
        response.map((option) => ({
          id: String(option.id),
          title: option.name
        }))
      );
    });
  }, [ownFirm]);

  return (
    <form className={"create-driver-form"}>
      <h3 className={"typography--h3"} style={{marginBottom: "16px"}}>
        {"Neuer Besitzer"}
      </h3>

      <FormField label={"Name"} labelFor={"name"}>
        <TypeaheadSelect
          dropdownOptions={driverOptions || []}
          selectedOptions={state.name}
          onSelect={handleSelectDriver}
          selectedOptionLimit={1}
          typeaheadProps={{
            placeholder: "Suche",
            name: "driver-list"
          }}
          onTagRemove={handleRemoveDriver}
        />
      </FormField>

      <FormField label={"Ausgabedatum"} labelFor={"date"}>
        <DateInput
          name={"date"}
          placeholderText={"Auswählen"}
          onChange={(selectedDate) =>
            setState({...state, gotDate: selectedDate || new Date()})
          }
          selected={state.gotDate}
        />
      </FormField>

      {state.hasTakenDate && (
        <FormField label={"Rückgabedatum"} labelFor={"date"}>
          <DateInput
            name={"date"}
            placeholderText={"Auswählen"}
            onChange={(selectedDate) =>
              setState({...state, getBackDate: selectedDate || new Date()})
            }
            selected={state.getBackDate}
          />
        </FormField>
      )}

      <FormField
        label={"Kein Rückgabedatum"}
        labelFor={"has-taken"}
        customClassName={"create-driver-form__checkbox-label"}>
        <Input
          name={"has-taken"}
          type={"checkbox"}
          onChange={() =>
            setState({
              ...state,
              hasTakenDate: !state?.hasTakenDate
            })
          }
          checked={!state?.hasTakenDate}
          customClassName={"create-driver-form__checkbox"}
        />
      </FormField>

      <div className={"create-tour-form__button-group"} style={{marginTop: "20px"}}>
        <Button onClick={onClose} customClassName={"button--transparent button--large"}>
          {"Abbrechen"}
        </Button>

        <Button
          isDisabled={!(state.name[0] && state.gotDate)}
          onClick={handleSubmit}
          customClassName={"button--primary button--large"}
          type={"submit"}>
          {"Hinzufügen"}
        </Button>
      </div>
    </form>
  );

  async function handleSubmit() {
    const formData = new FormData();

    formData.append("card_id", cardId);
    formData.append("admin", user!.hash);
    formData.append("worker_id", state.name[0].id);
    if (state.gotDate)
      formData.append(
        "got_date",
        String(format(new Date(state.gotDate), DATE_FORMAT.API))
      );
    if (state.getBackDate)
      formData.append(
        "gave_back_date",
        String(format(new Date(state.getBackDate), DATE_FORMAT.API))
      );

    await axios({
      method: "POST",
      url: `${BASE_URL}change-fuelcard-driver`,
      data: formData
    });

    location.reload();
  }

  function handleSelectDriver(option: DropdownOption) {
    setState({
      ...state,
      name: [option]
    });
  }

  function handleRemoveDriver() {
    setState({
      ...state,
      name: []
    });
  }
}

export default CreateDriverForm;
