import {ReactComponent as EditIcon} from "../../../../core/ui/icon/24px/edit.svg";

import "./_employee-detail-page-info.scss";

import {format} from "date-fns";
import {useState} from "react";
import {Button} from "@hipo/react-ui-toolkit";

import {DATE_FORMAT} from "../../../../core/util/time/timeConstants";
import Modal from "../../../../component/modal/Modal";
import EmployeeForm from "../form/EmployeeForm";
import {formatNumber} from "../../../../core/util/number/numberUtils";

interface EmployeeDetailPageInfoProps {
  apiWorkerId: string;
  id: string;
  name: string;
  job: string;
  vacation: number;
  startDate: string;
  expense: string;
  salary?: null | number;
  endDate?: null | string;
  note?: string;
  remaining_holidays?: string;
}

const numberFormatter = formatNumber({
  maximumFractionDigits: 2,
  minimumFractionDigits: 0
});

function EmployeeDetailPageInfo({
  id,
  apiWorkerId,
  name,
  salary,
  job,
  vacation,
  startDate,
  expense,
  endDate,
  note,
  remaining_holidays
}: EmployeeDetailPageInfoProps) {
  const [isModalOpen, setModalVisibility] = useState(false);

  return (
    <div style={{marginTop: "72px"}}>
      <div style={{display: "flex"}}>
        <h2 className={"typography--h1 text-color--blue"}>
          {"Arbeitnehmerinformationen"}
        </h2>

        <Button
          onClick={() => setModalVisibility(true)}
          style={{backgroundColor: "transparent"}}>
          <EditIcon />
        </Button>

        <Modal isOpen={isModalOpen} onClose={() => setModalVisibility(false)}>
          <EmployeeForm
            onClose={() => setModalVisibility(false)}
            data={{
              name,
              workerId: id,
              apiWorkerId,
              jobDescription: job,
              salary: String(salary),
              expense,
              offDays: String(vacation),
              startDate: new Date(startDate),
              endDate: endDate ? new Date(endDate) : undefined,
              note,
              remaining_holidays
            }}
          />
        </Modal>
      </div>

      <div className={"employee-detail-page-info-container"}>
        <div>
          <div className={"employee-detail-page-info__field"}>
            <label htmlFor={"name"} className={"typography--h7 text-color--primary"}>
              {"Name:"}
            </label>
            <p className={"typography--h5 text-color--primary"}>{name}</p>
          </div>

          <div className={"employee-detail-page-info__field"}>
            <label htmlFor={"id"} className={"typography--h7 text-color--primary"}>
              {"Personelnummer:"}
            </label>
            <p className={"typography--h5 text-color--primary"}>{id}</p>
          </div>

          <div className={"employee-detail-page-info__field"}>
            <label htmlFor={"job"} className={"typography--h7 text-color--primary"}>
              {"Jobbeschreibung:"}
            </label>
            <p className={"typography--h5 text-color--primary"}>{job}</p>
          </div>

          <div className={"employee-detail-page-info__field"}>
            <label htmlFor={"salary"} className={"typography--h7 text-color--primary"}>
              {"Lohn:"}
            </label>
            <p className={"typography--h5 text-color--primary"}>
              {salary ? `${numberFormatter(Number(salary))}€` : "-"}
            </p>
          </div>
        </div>

        <div>
          <div className={"employee-detail-page-info__field"}>
            <label htmlFor={"vacation"} className={"typography--h7 text-color--primary"}>
              {"Jährliche Urlaubstage:"}
            </label>
            <p className={"typography--h5 text-color--primary"}>{vacation}</p>
          </div>

          <div className={"employee-detail-page-info__field"}>
            <label htmlFor={"expense"} className={"typography--h7 text-color--primary"}>
              {"Spesen:"}
            </label>
            <p className={"typography--h5 text-color--primary"}>{`${numberFormatter(
              Number(expense)
            )}€`}</p>
          </div>

          <div className={"employee-detail-page-info__field"}>
            <label
              htmlFor={"start-date"}
              className={"typography--h7 text-color--primary"}>
              {"Arbeitsbeginn:"}
            </label>
            <p className={"typography--h5 text-color--primary"}>
              {format(new Date(startDate), DATE_FORMAT.DEFAULT)}
            </p>
          </div>

          <div className={"employee-detail-page-info__field"}>
            <label htmlFor={"end-date"} className={"typography--h7 text-color--primary"}>
              {"Kündigungsdatum:"}
            </label>
            <p className={"typography--h5 text-color--primary"}>{endDate || "-"}</p>
          </div>
        </div>

        <div>
          {note && (
            <>
              <label htmlFor={"note"} className={"typography--h7 text-color--primary"}>
                {"Notizen:"}
              </label>
              <p
                className={
                  "employee-detail-page-info__field--note typography--b3 text-color--primary"
                }>
                {note}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmployeeDetailPageInfo;
