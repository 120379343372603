import {ReactComponent as ArrowLeftIcon} from "../../../core/ui/icon/arrow-left.svg";
import {ReactComponent as LogoutIcon} from "../../../core/ui/icon/logout.svg";

import "./_page-navigation.scss";

import {Button} from "@hipo/react-ui-toolkit";
import classNames from "classnames";
import {Link} from "react-router-dom";

import ROUTES from "../../../core/route/routes";
import PageNavigationItem from "./item/PageNavigationItem";
import {useAppContext} from "../../../core/app/AppContext";
import webStorage, {STORED_KEYS} from "../../../core/util/storage/web/webStorage";

function PageNavigation() {
  const {state, dispatch} = useAppContext();

  return (
    <nav
      className={classNames(
        "page-navigation",
        `page-navigation--${state.isNavigationOpen ? "open" : "close"}`
      )}>
      <Button
        customClassName={"page-navigation__collapse-button"}
        onClick={handleCollapseClick}>
        <ArrowLeftIcon />
      </Button>

      <div className={"page-navigation__elbcargo"}>
        <p className={"text-color--white typography--h3"}>{state.ownFirm}</p>

        <Link
          className={"text-color--light page-navigation__elbcargo__change-button"}
          to={ROUTES.COMPANY_SELECT}>
          {"ändern"}
        </Link>
      </div>

      <ul className={"page-navigation__nav-link-list"}>
        <li className={"page-navigation__nav-link-list__item"}>
          <PageNavigationItem route={ROUTES.HOME} name={"Home"} />
        </li>

        <li className={"page-navigation__nav-link-list__item"}>
          <PageNavigationItem route={ROUTES.TOUR} name={"Tourenbesetzung"} />
        </li>

        <li className={"page-navigation__nav-link-list__item"}>
          <PageNavigationItem route={ROUTES.EMPLOYEE} name={"Mitarbeiter"} />
        </li>

        <li className={"page-navigation__nav-link-list__item"}>
          <PageNavigationItem route={ROUTES.VACATION_PLANNING} name={"Urlaubsplanung"} />
        </li>

        <li className={"page-navigation__nav-link-list__item"}>
          <PageNavigationItem
            route={ROUTES.VACATION_USAGE_MANAGEMENT}
            name={"Urlaubskonto"}
          />
        </li>

        <li className={"page-navigation__nav-link-list__item"}>
          <PageNavigationItem route={ROUTES.BILLS} name={"Rechnungen"} />
        </li>

        <li className={"page-navigation__nav-link-list__item"}>
          <PageNavigationItem route={ROUTES.CREDITS} name={"Gutschriften"} />
        </li>

        <li className={"page-navigation__nav-link-list__item"}>
          <PageNavigationItem route={ROUTES.TRUCK} name={"LKW-Datenbank"} />
        </li>

        <li className={"page-navigation__nav-link-list__item"}>
          <PageNavigationItem
            route={ROUTES.TANK_PAYMENT}
            name={"Tank-Zahlungsverfolgung"}
          />
        </li>

        <li className={"page-navigation__nav-link-list__item"}>
          <PageNavigationItem route={ROUTES.CONTACTS} name={"Kontaktendatenbank"} />
        </li>

        {state.user?.role === "superuser" && (
          <li className={"page-navigation__nav-link-list__item"}>
            <PageNavigationItem route={ROUTES.SETTINGS_TAG} name={"Einstellungen"} />
          </li>
        )}
      </ul>

      <div className={"page-navigation__user"}>
        <div style={{margin: "auto 0"}}>
          <p className={"typography--h8 text-color--light"}>{state.user?.name}</p>

          <p className={"typography--b4 text-color--secondary"}>
            {state.user?.role === "superuser" ? "Super Admin" : "Admin"}
          </p>
        </div>

        <Button customClassName={"button--transparent"} onClick={handleLogout}>
          <LogoutIcon />
        </Button>
      </div>
    </nav>
  );

  function handleLogout() {
    dispatch({type: "LOGOUT"});

    webStorage.local.removeItem(STORED_KEYS.USER);
    webStorage.local.removeItem(STORED_KEYS.SELECTED_COMPANY);
  }

  function handleCollapseClick() {
    dispatch({
      type: "TOGGLE_NAV_VISIBILITY"
    });
  }
}
export default PageNavigation;
