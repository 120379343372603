import "./_schedule-header.scss";

// eslint-disable-next-line import/no-duplicates
import {format} from "date-fns";
// eslint-disable-next-line import/no-duplicates
import {de} from "date-fns/locale";

interface ScheduleHeaderProps {
  dateRange: Date[];
}

function ScheduleHeader({dateRange}: ScheduleHeaderProps) {
  return (
    <div className={"schedule-header"}>
      {dateRange.map((date, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className={"schedule-header__date"}>
          <p className={"typography--h4 text-color--secondary"}>
            {format(date, "EEEE", {locale: de})}
          </p>

          <p className={"typography--h6 text-color--blue"}>{date.getDate()}</p>
        </div>
      ))}
    </div>
  );
}

export default ScheduleHeader;
