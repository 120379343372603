import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useEffect,
  useReducer
} from "react";

import companyApi from "../../company/api/companyApi";
import {OwnFirm} from "../../company/api/companyApiModel";
import employeeApi from "../../employee/api/employeeApi";
import {DriverOption} from "../../employee/api/employeeApiModel";
import settingsApi from "../../settings/api/settingsApi";
import {GlobalFirms, SettingsTag} from "../../settings/api/settingsApiModel";
import truckApi from "../../truck/api/truckApi";
import {Truck} from "../../truck/api/truckApiModel";
import useAsyncProcess from "../network/async-process/useAsyncProcess";
import webStorage, {STORED_KEYS} from "../util/storage/web/webStorage";
import {appStateReducer, AppStateReducerAction, initialAppState} from "./appStateReducer";

const initialAppContextValue = {
  state: initialAppState,
  dispatch: (() => undefined) as Dispatch<AppStateReducerAction>
};

const AppContext = createContext(initialAppContextValue);

AppContext.displayName = "AppContext";

function AppContextProvider({children}: {children: ReactNode}) {
  const [state, dispatch] = useReducer(appStateReducer, initialAppState);
  const {runAsyncProcess: runGlobalFirmAsyncProcess} = useAsyncProcess<GlobalFirms>();
  const {runAsyncProcess: runGlobalStatusAsyncProcess} = useAsyncProcess<SettingsTag>();
  const {runAsyncProcess: runGetDriversAsyncProcess} = useAsyncProcess<DriverOption[]>();
  const {runAsyncProcess: runGetTruckListAsyncProcess} = useAsyncProcess<Truck[]>();
  const {runAsyncProcess: runGetOwnFirmProcess} = useAsyncProcess<OwnFirm[]>();

  useEffect(() => {
    (async () => {
      const res = await runGetOwnFirmProcess(companyApi.getOwnFirms());

      dispatch({
        type: "SET_OWN_FIRM_LOGO",
        logo: (res.find((item) => item.name === state.ownFirm)?.logo as string) || ""
      });
    })();
  }, [runGetOwnFirmProcess, state.ownFirm]);

  useEffect(() => {
    (async () => {
      if (state.ownFirm && typeof state.ownFirm === "string") {
        try {
          const res = await runGlobalFirmAsyncProcess(
            settingsApi.getFirms({own_firm: state.ownFirm})
          );

          dispatch({
            type: "SET_GLOBAL_FIRMS",
            firms: res
          });
        } catch (e) {
          webStorage.local.removeItem(STORED_KEYS.SELECTED_COMPANY);
          webStorage.local.removeItem(STORED_KEYS.USER);

          location.reload();
        }
      }
    })();
  }, [runGlobalFirmAsyncProcess, state.ownFirm]);

  useEffect(() => {
    (async () => {
      const res = await runGlobalStatusAsyncProcess(settingsApi.getTags());

      dispatch({
        type: "SET_GLOBAL_STATUS",
        status: res
      });
    })();
  }, [runGlobalStatusAsyncProcess]);

  useEffect(() => {
    (async () => {
      const res = await runGetDriversAsyncProcess(
        employeeApi.getDrivers({own_firm: state.ownFirm})
      );

      dispatch({
        type: "SET_DRIVER_LIST",
        driverList: res
      });
    })();
  }, [runGetDriversAsyncProcess, state.ownFirm]);

  useEffect(() => {
    (async () => {
      const res = await runGetTruckListAsyncProcess(
        truckApi.getTrucks({own_firm: state.ownFirm})
      );

      dispatch({
        type: "SET_VEHICLE_LIST",
        vehicleList: res
      });
    })();
  }, [state.ownFirm, runGetTruckListAsyncProcess]);

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch
      }}>
      {children}
    </AppContext.Provider>
  );
}

function useAppContext() {
  return useContext(AppContext);
}

export {AppContext, AppContextProvider, useAppContext};
