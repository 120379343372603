import {TableColumn} from "../../../../../component/table/Table";

function generateEmployeeDebtTableColumns(): TableColumn<any>[] {
  return [
    {
      id: "EmployeeDebtTable.payment",
      header: "Zahlungsinfo"
    },
    {
      id: "EmployeeDebtTable.date",
      header: "Datum"
    }
  ];
}

export {generateEmployeeDebtTableColumns};
