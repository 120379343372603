import "./_loading.scss";

function Loading() {
  return (
    <div className={"loading"}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

export default Loading;
