/* eslint-disable no-nested-ternary */
import {ReactComponent as TrashIcon} from "../../../../../core/ui/icon/24px/trash_24x24.svg";

import "./_employee-debt-form.scss";

import {Button, FormField, Input, Textarea} from "@hipo/react-ui-toolkit";
import axios from "axios";
import {useState} from "react";

import Form from "../../../../../component/form/Form";
import DateInput from "../../../../../component/input/date/DateInput";
import {BASE_URL} from "../../../../../core/network/util/networkConstants";
import DeleteModal from "../../../../../component/delete-modal/DeleteModal";
import Modal from "../../../../../component/modal/Modal";

interface EmployeeDebtFormProps {
  mode: "debt" | "paid";
  onSubmit: ({
    name,
    date,
    mode,
    note
  }: {
    name: string;
    date: Date;
    mode: string;
    note?: string;
  }) => void;
  onClose: VoidFunction;
  data?: {id: string; name: string; date: null | Date; mode: string; note?: string};
}

function EmployeeDebtForm({mode, onSubmit, onClose, data}: EmployeeDebtFormProps) {
  const [formState, setFormState] = useState<{
    name?: string;
    date?: null | Date;
    note?: string;
  }>(data || {});
  const [isDeleteModalOpen, setDeleteModalState] = useState(false);

  return (
    <div className={"employee-debt-form"}>
      <h3 className={"typography--h3"}>
        {data?.name
          ? "Schulden"
          : mode === "debt"
          ? "Schulden hinzufügen"
          : "Bezahlt hinzufügen"}
      </h3>

      <Form>
        <FormField label={"Menge"}>
          <Input
            leftIcon={"€"}
            name={"debt"}
            value={formState?.name}
            onChange={(event) =>
              setFormState({...formState, name: event.currentTarget.value})
            }
          />
        </FormField>

        <FormField label={"Datum"} labelFor={"date"}>
          <DateInput
            name={"date"}
            selected={formState.date}
            placeholderText={"Auswählen"}
            onChange={(date) => setFormState({...formState, date})}
          />
        </FormField>

        <FormField label={"Notizen"} labelFor={"note"}>
          <Textarea
            style={{height: "100px"}}
            name={"note"}
            value={formState?.note}
            onChange={(event) =>
              setFormState({...formState, note: event.currentTarget.value})
            }
          />
        </FormField>

        <div className={"create-tour-form__button-group"} style={{marginTop: "36px"}}>
          <Button
            onClick={() => setDeleteModalState(true)}
            customClassName={"button--transparent"}
            style={{marginTop: "4px", marginRight: "auto"}}>
            <TrashIcon />
          </Button>

          <Modal isOpen={isDeleteModalOpen} onClose={() => setDeleteModalState(false)}>
            <DeleteModal
              onSubmit={handleDelete}
              onClose={() => setDeleteModalState(false)}
            />
          </Modal>

          <Button onClick={onClose} customClassName={"button--transparent button--large"}>
            {"Abbrechen"}
          </Button>

          <Button
            onClick={handleSubmit}
            customClassName={"button--primary button--large"}
            isDisabled={!(formState.name && formState.date)}
            type={"submit"}>
            {data?.name ? "Speichern" : "Hinzufügen"}
          </Button>
        </div>
      </Form>
    </div>
  );

  async function handleDelete() {
    const formData = new FormData();

    if (data) formData.append("debt_id", data?.id);
    await axios({
      method: "POST",
      url: `${BASE_URL}delete-debt`,
      data: formData
    });

    location.reload();
  }

  function handleSubmit() {
    onSubmit({
      name: formState.name!,
      date: formState.date!,
      mode,
      note: formState.note
    });
  }
}

export default EmployeeDebtForm;
