/* eslint-disable no-negated-condition */
/* eslint-disable no-nested-ternary */
import {ReactComponent as TrashIcon} from "../../../core/ui/icon/24px/trash_24x24.svg";

import "./_create-truck-document-form.scss";

import {Button, FormField, Input} from "@hipo/react-ui-toolkit";
import {FileUploader} from "react-drag-drop-files";
import {SyntheticEvent, useState} from "react";
import axios from "axios";
import {format} from "date-fns";

import DateInput from "../../../component/input/date/DateInput";
import {useAppContext} from "../../../core/app/AppContext";
import {BASE_URL} from "../../../core/network/util/networkConstants";
import {DATE_FORMAT} from "../../../core/util/time/timeConstants";
import Modal from "../../../component/modal/Modal";
import DeleteModal from "../../../component/delete-modal/DeleteModal";

function CreateTruckDocumentForm({
  onClose,
  data,
  plate
}: {
  onClose: VoidFunction;
  data?: {
    id: string;
    name: string;
    date: null | Date;
    file: null | File | string;
    done: boolean;
  };
  plate?: string;
}) {
  const [formState, setFormState] = useState<{
    id?: string;
    name: string;
    date: Date | null;
    file: File | null | string;
    done: boolean;
  }>(data || {name: "", date: null, file: null, done: false});
  const {
    state: {ownFirm, user}
  } = useAppContext();
  const [documentId, setDocumentId] = useState("");
  const [isDeletedFile, setDeletedFile] = useState(false);
  const [isDeleteModalOpen, setDeleteModalState] = useState(false);

  return (
    <div className={"create-truck-document-form"}>
      <h3 className={"typography--h3"}>{data ? "Dokument" : "Neues Dokument"}</h3>

      <form onSubmit={handleSubmit} className={"edit-truck-payment-form"}>
        <FormField label={"Name des Dokumentes"} labelFor={"document_type"}>
          <Input
            name={"document_type"}
            onChange={(event) =>
              setFormState({...formState, name: event.currentTarget.value})
            }
            value={formState?.name}
          />
        </FormField>

        <FormField label={"Verfallsdatum"} labelFor={"document_type"}>
          <DateInput
            name={"date"}
            selected={formState?.date}
            onChange={(date) => setFormState({...formState, date})}
          />
        </FormField>

        <div className={"edit-truck-payment-form__radio-button-group"}>
          <label
            className={"edit-truck-payment-form__radio-button-container"}
            htmlFor={"done"}>
            <input
              id={"done"}
              name={"payment-method"}
              type={"radio"}
              value={"done"}
              checked={formState.done}
              className={"edit-truck-payment-form__radio-button__input"}
              onChange={() => setFormState({...formState, done: true})}
            />

            <p>{"Erledigt"}</p>
          </label>

          <label
            className={"edit-truck-payment-form__radio-button-container"}
            htmlFor={"note-done"}>
            <input
              id={"note-done"}
              name={"payment-method"}
              type={"radio"}
              checked={!formState.done}
              value={"installment-payment"}
              className={"edit-truck-payment-form__radio-button__input"}
              onChange={() => setFormState({...formState, done: false})}
            />

            <p>{"nicht Erledigt"}</p>
          </label>
        </div>

        <FormField label={"Dokument hochladen"} labelFor={"document"}>
          {typeof formState.file === "string" ? (
            <div style={{display: "flex", alignItems: "center"}}>
              <a
                className={"typography--b3"}
                href={formState.file}
                target={"_blank"}
                rel={"noopener noreferrer"}>
                {"Dokument"}
              </a>

              <Button
                onClick={() => {
                  setDeletedFile(true);
                  setFormState({...formState, file: null});
                }}>
                {"x"}
              </Button>
            </div>
          ) : !isDeletedFile ? (
            <>
              <FileUploader
                name={"document"}
                multiple={false}
                file={formState.file}
                isDisabled={isDeletedFile}
                handleChange={handleChange}
                classes={"typography--h2 create-truck-document-form__drag-drop"}
              />

              <p className={"typography--h8 create-truck-document-form__drag-drop-files"}>
                {formState?.file
                  ? `File name: ${(formState.file as File).name}`
                  : "No files uploaded yet"}
              </p>
            </>
          ) : (
            <div>{"Will be deleted after submission"}</div>
          )}
        </FormField>

        <div className={"edit-truck-payment-form__button-group"}>
          <Button
            onClick={() => setDeleteModalState(true)}
            customClassName={"button--transparent"}
            style={{margin: "auto auto 8px 0"}}>
            <TrashIcon />
          </Button>

          <Modal isOpen={isDeleteModalOpen} onClose={() => setDeleteModalState(false)}>
            <DeleteModal
              onSubmit={handleDelete}
              onClose={() => setDeleteModalState(false)}
            />
          </Modal>

          <Button customClassName={"button--transparent button--large"} onClick={onClose}>
            {"Abbrechen"}
          </Button>

          <Button
            customClassName={"button--primary button--large"}
            type={"submit"}
            isDisabled={!(formState.name && Boolean(formState.date || formState.file))}>
            {data ? "Speichern" : "Hinzufügen"}
          </Button>
        </div>
      </form>
    </div>
  );

  async function handleDelete() {
    const formData = new FormData();

    formData.append("document_id", data!.id);
    await axios({
      method: "POST",
      url: `${BASE_URL}delete-truck-document`,
      data: formData
    });

    location.reload();
  }

  async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    if (data?.id) {
      const formData = new FormData();

      formData.append("own_firm", ownFirm);
      formData.append("admin", user!.hash);
      formData.append("document_id", data.id);
      formData.append("name", formState.name);
      formData.append("done", String(formState.done));
      if (formState.date)
        formData.append("expiry_date", format(new Date(formState.date), DATE_FORMAT.API));
      formData.append("delete_file", String(isDeletedFile));

      if (typeof formState.file !== "string" && formState.file !== null) {
        formData.append("file", formState.file);

        await axios({
          method: "POST",
          url: `${BASE_URL}add-truck-document-file`,
          params: {document_id: data?.id ?? documentId},
          data: formData
        });
      }

      await axios({
        method: "POST",
        url: `${BASE_URL}update-truck-document`,
        data: formData
      });

      location.reload();
    } else {
      const formData = new FormData();

      formData.append("admin", user!.hash);
      if (typeof formState.file !== "string" && formState.file !== null) {
        formData.append("file", formState.file);

        await axios({
          method: "POST",
          url: `${BASE_URL}add-truck-document-file`,
          params: {document_id: documentId},
          data: formData
        });
        location.reload();
      } else {
        const formDataWithoutFile = new FormData();

        formDataWithoutFile.append("admin", user!.hash);
        formDataWithoutFile.append("own_firm", ownFirm);
        formDataWithoutFile.append("name", formState.name);
        if (plate) {
          formDataWithoutFile.append("plate", plate);
        }
        if (formState.date) {
          formDataWithoutFile.append(
            "expiry_date",
            format(formState.date, DATE_FORMAT.API)
          );
        }

        try {
          await axios({
            method: "POST",
            url: `${BASE_URL}add-truck-document`,
            data: formDataWithoutFile
          });
          location.reload();
        } catch (e) {
          console.error(e);
        }
      }
    }
  }

  async function handleChange(selectedFile: File) {
    setFormState({...formState, file: selectedFile});

    const formData = new FormData();

    formData.append("admin", user!.hash);
    formData.append("own_firm", ownFirm);
    formData.append("name", formState.name);
    if (plate) {
      formData.append("plate", plate);
    }
    if (formState.date) {
      formData.append("expiry_date", format(formState.date, DATE_FORMAT.API));
    }

    try {
      if (!data?.id) {
        const id = await axios({
          method: "POST",
          url: `${BASE_URL}add-truck-document`,
          data: formData
        });

        setDocumentId(id.data);
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export default CreateTruckDocumentForm;
