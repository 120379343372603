import elbcargoApi from "../../core/network/elbcargo/elbcargoApi";
import {CreditResponse, SingleCreditResponse} from "./creditApiModel";

const creditApi = {
  getCredits(params: {
    own_firm: string;
    firm?: string;
    avis_nr?: string;
    document_nr?: string;
    paid_start?: string;
    paid_end?: string;
    open_start?: string;
    open_end?: string;
    order_by?: string;
    direction?: string;
  }) {
    return elbcargoApi.run<CreditResponse[]>(
      {
        method: "GET",
        params
      },
      "get-gutschrifts"
    );
  },
  getCredit(params: {gutschrift_id: string}) {
    return elbcargoApi.run<SingleCreditResponse>(
      {
        method: "GET",
        params
      },
      "get-gutschrift"
    );
  }
};

export default creditApi;
