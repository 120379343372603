/* eslint-disable react/jsx-no-bind */
import {Fragment, useState} from "react";
import {Button} from "@hipo/react-ui-toolkit";

import {Tours} from "../../../tour/api/tourApiModel";
import CreateEditTourForm from "../../../tour/form/create/CreateEditTourForm";
import Modal from "../../modal/Modal";
import "./_schedule-column.scss";

interface ScheduleColumnProps {
  columnName: string;
  rowName: string;
  data: Tours;
}

function ScheduleColumn({columnName, rowName, data}: ScheduleColumnProps) {
  const [modalState, setModalState] = useState({visibility: false, id: ""});

  return (
    <div className={"schedule-column"}>
      <div className={"schedule-column__index"}>
        <p
          className={"typography--h4 text-color--secondary schedule-column__index__tage"}>
          {rowName}
        </p>

        <div
          className={
            "typography--h4 text-color--secondary schedule-column__index__diagonal-line"
          }
        />

        <p
          className={"typography--h4 text-color--secondary schedule-column__index__name"}>
          {columnName}
        </p>
      </div>

      <ul>
        {data.map((item, index) => (
          <>
            <p className={"typography--b3 schedule-column__firm"}>{Object.keys(item)}</p>

            {Object.values(item).map((tours) =>
              tours.map((tour) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={index}>
                  <li
                    className={"typography--h5 text-color--primary schedule-column__row"}>
                    <Button
                      onClick={() => handleEditTourClick(tour.roller_nr)}
                      customClassName={"schedule-column__row-button"}>
                      {tour.roller_nr}
                    </Button>
                  </li>
                </Fragment>
              ))
            )}
          </>
        ))}
      </ul>

      <Modal
        isOpen={modalState.visibility}
        onClose={() => setModalState({...modalState, visibility: false})}>
        <CreateEditTourForm
          onCancel={() => setModalState({...modalState, visibility: false})}
          id={modalState.id}
        />
      </Modal>
    </div>
  );

  // function handleEditSubmit(id: string) {
  //   const newDataState = dataState.map((item) => {
  //     Object.values(item).map((tours) => (
  //       tours.map
  //     ))
  //     if (item.roller_nr === modalState.id) {
  //       return {
  //         ...item,
  //         roller_nr: id
  //       };
  //     }

  //     return item;
  //   });

  //   setDataState(newDataState);
  // }

  function handleEditTourClick(id: string) {
    setModalState({
      visibility: true,
      id
    });
  }
}

export default ScheduleColumn;
