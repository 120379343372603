/* eslint-disable arrow-body-style */
import "./_tour-page.scss";

import {Fragment, useEffect, useState} from "react";
import {getDaysInMonth, isSaturday, isSunday, isWeekend} from "date-fns";
import {addMonths} from "date-fns/esm";
import {DropdownOption} from "@hipo/react-ui-toolkit";
import classNames from "classnames";

import Page from "../../component/page/Page";
import PageContent from "../../component/page/content/PageContent";
import Schedule from "../../component/schedule/Schedule";
import ScheduleHeader from "../../component/schedule/header/ScheduleHeader";
import TourSchedule from "../schedule/TourSchedule";
import TourPageHeader from "./header/TourPageHeader";
import ScheduleColumn from "../../component/schedule/column/ScheduleColumn";
import useAsyncProcess from "../../core/network/async-process/useAsyncProcess";
import tourApi from "../api/tourApi";
import {Tours} from "../api/tourApiModel";
import AsyncContent, {
  AsyncContentStatus
} from "../../component/async-content/AsyncContent";
import Loading from "../../component/loading/Loading";
import {useAppContext} from "../../core/app/AppContext";
import {filterTruthyObjectValues} from "../../core/util/object/objectUtils";

function TourPage() {
  const currentDate = new Date();
  const {state, runAsyncProcess} = useAsyncProcess<Tours>();
  const {
    state: {ownFirm}
  } = useAppContext();
  const [formState, setFormState] = useState<{
    selectedDate: Date;
    firmList: DropdownOption[] | undefined;
    driverList: DropdownOption[] | undefined;
    label: string | undefined;
  }>({
    selectedDate: currentDate,
    firmList: undefined,
    driverList: undefined,
    label: undefined
  });
  const scheduleDays = Array.from(
    {length: getDaysInMonth(formState.selectedDate)},
    (_, index) => index
  ).map(
    (day) =>
      new Date(
        formState.selectedDate.getFullYear(),
        formState.selectedDate.getMonth(),
        day + 1
      )
  );

  useEffect(() => {
    (async () => {
      await runAsyncProcess(
        tourApi.getTourList(
          filterTruthyObjectValues({
            own_firm: ownFirm,
            year: String(formState.selectedDate.getFullYear()),
            status: formState.label,
            drivers:
              formState.driverList && formState.driverList.length > 0
                ? formState.driverList.map((driver) => driver.id)
                : undefined,
            firm:
              formState.firmList && formState.firmList[0]
                ? formState.firmList[0].title
                : undefined,
            month: String(formState.selectedDate.getMonth() + 1)
          })
        )
      );
    })();
  }, [runAsyncProcess, formState, ownFirm]);

  return (
    <Page title={"Tour"}>
      <PageContent>
        <AsyncContent requestStates={[state]} content={renderAsyncContent} />
      </PageContent>
    </Page>
  );

  function renderAsyncContent(status: AsyncContentStatus) {
    let node = <Fragment />;

    switch (status) {
      case "pending":
        node = <Loading />;
        break;

      case "error":
        node = <p>{"An error ocurred on server."}</p>;
        break;

      case "success":
        node = (
          <>
            <TourPageHeader
              onNextMonthClick={handleNextClick}
              onPrevMonthClick={handlePrevClick}
              onFilterSubmit={handleFilterSubmit}
              currentDate={formState.selectedDate}
            />

            {state.data && (
              <Schedule customClassName={"tour-page__schedule"}>
                <ScheduleColumn
                  rowName={"Tage"}
                  columnName={"Roller Nr."}
                  data={state.data}
                />

                <div style={{overflow: "auto"}}>
                  <ScheduleHeader dateRange={scheduleDays} />

                  {state.data?.map((tours, index) => (
                    <>
                      <div className={"tour-page__firm-row-container"}>
                        {scheduleDays.map((day) => (
                          <div
                            key={"mock"}
                            className={classNames("tour-page__firm-row", {
                              "selection-area__cell__weekend-day": isWeekend(day),
                              "selection-area__cell__weekend-day--is-saturday":
                                isSaturday(new Date(day)),
                              "selection-area__cell__weekend-day--is-sunday":
                                isSunday(day)
                            })}
                            style={{
                              paddingLeft: day.getDate() === 1 ? "4px" : undefined
                            }}
                          />
                        ))}
                      </div>

                      {Object.values(tours).map((tour) =>
                        tour.map((item) => (
                          <TourSchedule
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            tour={item}
                            scheduleDays={scheduleDays}
                          />
                        ))
                      )}
                    </>
                  ))}
                </div>
              </Schedule>
            )}
          </>
        );
        break;

      default:
        break;
    }

    return node;
  }

  function handleNextClick() {
    setFormState({
      ...formState,
      selectedDate: addMonths(formState.selectedDate, 1)
    });
  }

  function handlePrevClick() {
    setFormState({
      ...formState,
      selectedDate: addMonths(formState.selectedDate, -1)
    });
  }

  function handleFilterSubmit({
    firmList,
    driverList,
    label
  }: {
    firmList: DropdownOption[];
    driverList: DropdownOption[];
    label: string;
  }) {
    setFormState({
      ...formState,
      firmList,
      driverList,
      label
    });
  }
}

export default TourPage;
