import "./_create-truck-form.scss";

import {Button, FormField, Input, useToaster} from "@hipo/react-ui-toolkit";
import {useState} from "react";
import axios, {AxiosError} from "axios";

import {useAppContext} from "../../../core/app/AppContext";
import {BASE_URL} from "../../../core/network/util/networkConstants";
import {Truck} from "../../api/truckApiModel";
import ErrorToast from "../../../component/toast/error/ErrorToast";

function CreateTruckFrom({
  onClose,
  onSubmit
}: {
  onClose: VoidFunction;
  onSubmit: (truck: Truck) => void;
}) {
  const [formState, setFormState] = useState({plate: "", brand: "", model: ""});
  const {
    state: {ownFirm, user}
  } = useAppContext();
  const toaster = useToaster();

  return (
    <div className={"create-truck-form"}>
      <h3 className={"typography--h3 create-truck-from__title"}>{"Neues LKW"}</h3>

      <form>
        <FormField label={"Nummernschild"} labelFor={"plate"}>
          <Input name={"plate"} onChange={handlePlateChange} />
        </FormField>

        <FormField label={"Marke"} labelFor={"brand"}>
          <Input name={"brand"} onChange={handleBrandChange} />
        </FormField>

        <FormField label={"Model"} labelFor={"model"}>
          <Input name={"mode"} onChange={handleModalChange} />
        </FormField>

        <div className={"create-truck-form__button-group"}>
          <Button
            customClassName={"button--transparent button--large"}
            onClick={handleClose}>
            {"Abbrechen"}
          </Button>

          <Button
            customClassName={"button--primary button--large"}
            type={"submit"}
            onClick={handleSubmit}>
            {"Hinzufügen"}
          </Button>
        </div>
      </form>
    </div>
  );

  async function handleSubmit() {
    const formData = new FormData();

    formData.append("own_firm", ownFirm);
    formData.append("plate", formState.plate);
    formData.append("manufacturer", formState.brand);
    formData.append("model", formState.model);
    formData.append("admin", user!.hash);

    try {
      await axios({
        method: "POST",
        url: `${BASE_URL}create-truck`,
        data: formData
      });

      onSubmit({
        id: formState.plate,
        plate: formState.plate,
        manufacturer: formState.brand,
        model: formState.model
      });

      onClose();
    } catch (e) {
      const err = e as AxiosError;

      toaster.display({
        render() {
          return <ErrorToast message={err.response?.data as string} />;
        }
      });
    }
  }

  function handlePlateChange(event: React.SyntheticEvent<HTMLInputElement>) {
    setFormState({
      ...formState,
      plate: event.currentTarget.value
    });
  }

  function handleBrandChange(event: React.SyntheticEvent<HTMLInputElement>) {
    setFormState({
      ...formState,
      brand: event.currentTarget.value
    });
  }

  function handleModalChange(event: React.SyntheticEvent<HTMLInputElement>) {
    setFormState({
      ...formState,
      model: event.currentTarget.value
    });
  }

  function handleClose() {
    onClose();
  }
}

export default CreateTruckFrom;
