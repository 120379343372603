import elbcargoApi from "../../core/network/elbcargo/elbcargoApi";
import {
  GlobalFirms,
  GlobalStatus,
  SettingsAdminDetail,
  SettingsCustomer,
  SettingsLog,
  SettingsTag
} from "./settingsApiModel";

const settingsApi = {
  getFirms(params: {own_firm: string}) {
    return elbcargoApi.run<GlobalFirms>(
      {
        method: "GET",
        params
      },
      "get-firms"
    );
  },
  getStatus() {
    return elbcargoApi.run<GlobalStatus>(
      {
        method: "GET"
      },
      "get-status"
    );
  },
  getTags() {
    return elbcargoApi.run<SettingsTag>(
      {
        method: "GET"
      },
      "get-tags"
    );
  },
  createTag(body: {
    section: "tour" | "contacts" | "offday";
    name: string;
    colour: string;
  }) {
    return elbcargoApi.run(
      {
        method: "POST",
        body
      },
      "create-tag"
    );
  },
  getAvailableColors() {
    return elbcargoApi.run<{id: string; hex_code: string}[]>(
      {
        method: "GET"
      },
      "get-available-colours"
    );
  },
  getCustomers(params: {own_firm: string; order_by?: string; direction?: string}) {
    return elbcargoApi.run<SettingsCustomer[]>(
      {
        method: "GET",
        params
      },
      "get-customers"
    );
  },
  getAdmins(params?: {order_by?: string; direction?: string}) {
    return elbcargoApi.run<{id: string; name: string; username: string}[]>(
      {
        method: "GET",
        params
      },
      "get-admins"
    );
  },
  getAdminDetails(params: {admin_id: string}) {
    return elbcargoApi.run<SettingsAdminDetail>(
      {
        method: "GET",
        params
      },
      "get-admin-details"
    );
  },
  getLogs(params: {
    own_firm: string;
    admin?: string;
    start_date?: string;
    end_date?: string;
    order_by?: string;
    direction?: string;
  }) {
    return elbcargoApi.run<SettingsLog[]>(
      {
        method: "GET",
        params
      },
      "get-logs"
    );
  }
};

export default settingsApi;
