import "./_tank-payment-filter-form.scss";

import {
  Button,
  FormField,
  Input,
  TypeaheadSelect,
  DropdownOption
} from "@hipo/react-ui-toolkit";
import {useState} from "react";

import {useAppContext} from "../../../core/app/AppContext";

interface TankPaymentFilterFormProps {
  onSubmit: ({
    firm,
    driver,
    active
  }: {
    firm?: string;
    driver?: string;
    active?: boolean;
  }) => void;
  onClose: VoidFunction;
}

function TankPaymentFilterForm({onSubmit, onClose}: TankPaymentFilterFormProps) {
  const [state, setState] = useState<{
    firm: null | DropdownOption;
    driver: null | DropdownOption;
    active: boolean;
  }>({
    firm: null,
    driver: null,
    active: false
  });
  const {
    state: {driverList, globalFirms}
  } = useAppContext();

  return (
    <div>
      <form className={"form-field--vertical tank-payment-filter-form"}>
        <FormField label={"Firmenname"} labelFor={"firm"}>
          <TypeaheadSelect
            dropdownOptions={
              globalFirms && globalFirms.fuelcard_firms.length > 0
                ? globalFirms.fuelcard_firms?.map((item) => ({
                    id: String(item.id),
                    title: `${item.name}`
                  }))
                : []
            }
            selectedOptions={state.firm ? [state.firm] : []}
            onSelect={(option) => setState({...state, firm: option})}
            selectedOptionLimit={1}
            typeaheadProps={{
              placeholder: "Suche",
              name: "firm-list"
            }}
            onTagRemove={() => setState({...state, firm: null})}
          />
        </FormField>

        <FormField label={"Fahrer"} labelFor={"driver"}>
          <TypeaheadSelect
            dropdownOptions={
              driverList && driverList.length > 0
                ? driverList?.map((item) => ({
                    id: String(item.id),
                    title: `${item.name}`
                  }))
                : []
            }
            selectedOptions={state.driver ? [state.driver] : []}
            onSelect={(option) => setState({...state, driver: option})}
            selectedOptionLimit={1}
            typeaheadProps={{
              placeholder: "Suche",
              name: "driver-list"
            }}
            onTagRemove={() => setState({...state, driver: null})}
          />
        </FormField>

        <FormField
          label={"Nur aktive Karten anzeigen."}
          labelFor={"active"}
          customClassName={"tank-payment-filter-form__checkbox-label"}>
          <Input
            name={"active"}
            type={"checkbox"}
            onChange={() => setState({...state, active: !state.active})}
            checked={state?.active}
            customClassName={"tank-payment-filter-form__checkbox"}
          />
        </FormField>

        <div className={"form__button-group"}>
          <Button customClassName={"button--transparent button--large"} onClick={onClose}>
            {"Abbrechen"}
          </Button>

          <Button
            customClassName={"button--primary button--large"}
            type={"submit"}
            onClick={handleSubmit}>
            {"Filtern"}
          </Button>
        </div>
      </form>
    </div>
  );

  function handleSubmit() {
    onSubmit({
      firm: state.firm?.id,
      driver: state.driver?.id,
      active: state.active
    });
  }
}

export default TankPaymentFilterForm;
