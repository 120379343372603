import {ReactComponent as ArrowLeftIcon} from "../../../core/ui/icon/arrow-left.svg";
import {ReactComponent as ArrowRightIcon} from "../../../core/ui/icon/arrow-right.svg";
import {ReactComponent as SettingsIcon} from "../../../core/ui/icon/settings.svg";

import "./_vacation-planning-page.scss";

import {Fragment, useEffect, useState} from "react";
import {Button} from "@hipo/react-ui-toolkit";
// eslint-disable-next-line import/no-duplicates
import {addMonths, format, getDaysInMonth} from "date-fns";
// eslint-disable-next-line import/no-duplicates
import {de} from "date-fns/locale";

import PageContent from "../../../component/page/content/PageContent";
import Page from "../../../component/page/Page";
import {MONTH_NAMES} from "../../../core/util/time/timeConstants";
import VacationSchedule from "../schedule/VacationSchedule";
import {useAppContext} from "../../../core/app/AppContext";
import useAsyncProcess from "../../../core/network/async-process/useAsyncProcess";
import vacationPlanningApi from "../api/vacationPlanningApi";
import {VacationPlanningWorker} from "../api/vacationPlanningApiModel";
import Loading from "../../../component/loading/Loading";
import AsyncContent, {
  AsyncContentStatus
} from "../../../component/async-content/AsyncContent";
import Modal from "../../../component/modal/Modal";
import VacationPlanningFilter from "../form/VacationPlanningFilter";
import {filterTruthyObjectValues} from "../../../core/util/object/objectUtils";

function VacationPlanningPage() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const scheduleDays = Array.from(
    {length: getDaysInMonth(selectedDate)},
    (_, index) => index
  ).map((day) => new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day + 1));
  const {runAsyncProcess, state: offdayState} =
    useAsyncProcess<VacationPlanningWorker[]>();
  const {
    state: {ownFirm}
  } = useAppContext();
  const [filterModal, setFilterModal] = useState(false);
  const [filterState, setFilterState] = useState<{
    driver?: string;
    label?: string;
    start?: string;
    end?: string;
  }>({});

  useEffect(() => {
    runAsyncProcess(
      vacationPlanningApi.getOffdays(
        filterTruthyObjectValues({
          own_firm: ownFirm,
          year: String(selectedDate.getFullYear()),
          month: String(selectedDate.getMonth() + 1),
          worker: filterState.driver,
          holidays_remaining_end: filterState.end,
          holidays_remaining_start: filterState.start,
          tag: filterState.label
        })
      )
    );
  }, [runAsyncProcess, ownFirm, selectedDate, filterState]);

  return (
    <Page title={"Urlaubsplanung"}>
      <PageContent>
        <AsyncContent requestStates={[offdayState]} content={renderAsyncContent} />
      </PageContent>
    </Page>
  );

  function renderAsyncContent(status: AsyncContentStatus) {
    let node = <Fragment />;

    switch (status) {
      case "pending":
        node = <Loading />;
        break;

      case "error":
        node = <p>{"An error ocurred on server."}</p>;
        break;

      case "success":
        node = (
          <>
            <div className={"vacation-planning-page__header"}>
              <div style={{width: "80px"}} />

              <div className={"vacation-planning-page__header__month"}>
                <Button onClick={() => setSelectedDate(addMonths(selectedDate, -1))}>
                  <ArrowLeftIcon />
                </Button>

                <p>{`${
                  MONTH_NAMES[selectedDate.getMonth()]
                }, ${selectedDate.getFullYear()}`}</p>

                <Button onClick={() => setSelectedDate(addMonths(selectedDate, +1))}>
                  <ArrowRightIcon />
                </Button>
              </div>

              <Button
                customClassName={"button--primary text-color--light typography--h8"}
                onClick={() => setFilterModal(true)}>
                <SettingsIcon />

                {"Filter"}
              </Button>

              <Modal
                isOpen={filterModal}
                onClose={() => setFilterModal(false)}
                customClassName={"filter-modal"}>
                <VacationPlanningFilter
                  onClose={() => setFilterModal(false)}
                  onSubmit={handleSubmit}
                />
              </Modal>
            </div>

            <div className={"vacation-planning-page__schedule"}>
              <div className={"vacation-planning-page__schedule__left-side"}>
                <div>
                  <div className={"schedule-column__index"}>
                    <p
                      className={
                        "typography--h4 text-color--secondary schedule-column__index__tage"
                      }>
                      {"Tage"}
                    </p>

                    <div
                      className={
                        "typography--h4 text-color--secondary schedule-column__index__diagonal-line"
                      }
                    />

                    <p
                      className={
                        "typography--h4 text-color--secondary schedule-column__index__name"
                      }>
                      {"Arbeiter"}
                    </p>
                  </div>

                  <div>
                    {offdayState.data!.map((worker) => (
                      <div
                        key={worker.worker_id}
                        className={"vacation-schedule__worker-cell"}>
                        {worker.name}
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  className={"vacation-planning-page__schedule-container"}
                  style={{overflow: "auto"}}>
                  <div style={{display: "flex"}}>
                    {scheduleDays.map((date, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={index} className={"schedule-header__date"}>
                        <p className={"typography--h4 text-color--secondary"}>
                          {format(date, "EEEE", {locale: de})}
                        </p>

                        <p className={"typography--h6 text-color--blue"}>
                          {date.getDate()}
                        </p>
                      </div>
                    ))}
                  </div>

                  <div>
                    {offdayState.data!.map((workerOffdays) => (
                      <VacationSchedule
                        key={workerOffdays.worker_id}
                        scheduleDays={scheduleDays}
                        offdayRow={workerOffdays}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
        break;

      default:
        break;
    }

    return node;
  }

  function handleSubmit(filter: {
    driver?: string;
    label?: string;
    start?: string;
    end?: string;
  }) {
    setFilterState(filter);
    setFilterModal(false);
  }
}

export default VacationPlanningPage;
