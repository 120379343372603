import "./_chart-tooltip.scss";

import {NameType} from "recharts/types/component/DefaultTooltipContent";

import {MONTH_NAMES} from "../../../core/util/time/timeConstants";
import {formatNumber} from "../../../core/util/number/numberUtils";

interface ChartTooltipProps {
  active?: boolean;
  payload?: {
    color?: string;
    name?: NameType;
    payload?: {
      bills?: string;
      gutschrifts?: string;
      month?: number;
      year?: number;
      total?: string;
    };
  }[];
}

const numberFormatter = formatNumber({
  maximumFractionDigits: 2,
  minimumFractionDigits: 0
});

function ChartTooltip({active, payload}: ChartTooltipProps) {
  if (active && payload && payload.length) {
    return (
      <div className={"chart-tooltip"}>
        <p className={"typography--b6 text-color--light"}>{`${
          MONTH_NAMES[payload[0].payload!.month! - 1]
        } ${payload[0].payload?.year}`}</p>

        <div className={"chart-tooltip-item"}>
          <span className={"chart-tooltip-dot"} style={{background: payload[0].color}} />

          <p className={"typography--b5"}>{`${numberFormatter(
            Number(payload[0].payload?.bills) || 0
          )}€`}</p>

          <p className={"typography--b5"}>{"von Rechnungen"}</p>
        </div>

        <div className={"chart-tooltip-item"}>
          <span className={"chart-tooltip-dot"} style={{background: payload[1].color}} />

          <p className={"typography--b5"}>{`${
            numberFormatter(Number(payload[1].payload?.gutschrifts)) || 0
          }€`}</p>

          <p className={"typography--b5"}>{" on Gutschriften"}</p>
        </div>
      </div>
    );
  }

  return null;
}

export default ChartTooltip;
