import "./_modal.scss";

import ReactModal from "react-modal";
import classNames from "classnames";

import {SECOND_IN_MS} from "../../core/util/time/timeConstants";

interface ModalProps {
  isOpen: boolean;
  onClose: ReactModal.Props["onRequestClose"];
  children: React.ReactNode;
  customClassName?: string;
}

ReactModal.defaultStyles = {};
ReactModal.setAppElement("#root");

const MODAL_CLOSE_TIMEOUT =
  parseFloat(
    getComputedStyle(document.documentElement)
      .getPropertyValue("--modal-transition-duration")
      .trim() || "0.2"
  ) * SECOND_IN_MS;

function getParent() {
  return document.querySelector("#modal-root") as HTMLElement;
}

function Modal({isOpen, onClose, children, customClassName}: ModalProps) {
  return (
    <ReactModal
      isOpen={isOpen}
      parentSelector={getParent}
      onRequestClose={onClose}
      className={classNames("modal", customClassName)}
      closeTimeoutMS={MODAL_CLOSE_TIMEOUT}
      contentLabel={"Example Modal"}>
      {children}
    </ReactModal>
  );
}

export default Modal;
