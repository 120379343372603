import {ReactComponent as TrashIcon} from "../../../core/ui/icon/24px/trash_24x24.svg";

import {Button, FormField, Input} from "@hipo/react-ui-toolkit";
import {SyntheticEvent, useState} from "react";
import axios from "axios";

import {BASE_URL} from "../../../core/network/util/networkConstants";
import DeleteModal from "../../../component/delete-modal/DeleteModal";
import Modal from "../../../component/modal/Modal";

interface CreateCompanyFormProps {
  onSubmit: (data: {
    firmId?: string;
    firmName: string;
    vat?: null | string;
    address?: null | string;
  }) => void;
  onClose: VoidFunction;
  firmId?: string;
  firmName?: string;
  vat?: null | string;
  address?: null | string;
}

function CreateCompanyForm({
  onSubmit,
  onClose,
  firmName = "",
  firmId,
  vat,
  address
}: CreateCompanyFormProps) {
  const [formState, setFormState] = useState({
    firmName,
    vat,
    address,
    firmId
  });
  const [isDeleteModalOpen, setDeleteModalState] = useState(false);

  return (
    <form onSubmit={handleSubmit} className={"create-company-form"}>
      <h3 className={"typography--h3"} style={{marginBottom: "24px"}}>
        {firmId ? "Kunde" : "Neuer Kunde"}
      </h3>

      <FormField label={"Firmenname"} labelFor={"firm_name"}>
        <Input
          name={"firm_name"}
          onChange={(event) =>
            setFormState({...formState, firmName: event.currentTarget.value})
          }
          value={formState.firmName}
        />
      </FormField>

      <FormField label={"VAX"} labelFor={"vat"}>
        <Input
          name={"vat"}
          onChange={(event) =>
            setFormState({...formState, vat: event.currentTarget.value})
          }
          value={formState.vat || ""}
        />
      </FormField>

      <FormField label={"Address"} labelFor={"address"}>
        <Input
          name={"address"}
          onChange={(event) =>
            setFormState({...formState, address: event.currentTarget.value})
          }
          value={formState.address || ""}
        />
      </FormField>

      <div className={"form__button-group"} style={{justifyContent: "space-between"}}>
        {firmId && (
          <>
            <Button
              onClick={() => setDeleteModalState(true)}
              customClassName={"button--transparent"}
              style={{marginTop: "4px", marginRight: "auto"}}>
              <TrashIcon />
            </Button>

            <Modal isOpen={isDeleteModalOpen} onClose={() => setDeleteModalState(false)}>
              <DeleteModal
                onSubmit={handleDelete}
                onClose={() => setDeleteModalState(false)}
              />
            </Modal>
          </>
        )}

        <Button customClassName={"button--transparent button--large"} onClick={onClose}>
          {"Abbrechen"}
        </Button>

        <Button
          customClassName={"button--primary button--large"}
          isDisabled={!formState.firmName}
          type={"submit"}>
          {firmId ? "Speichern" : "Hinzufügen"}
        </Button>
      </div>
    </form>
  );

  async function handleDelete() {
    if (firmId) {
      const formData = new FormData();

      formData.append("firm_id", firmId);
      await axios({
        method: "POST",
        url: `${BASE_URL}delete-customer`,
        data: formData
      });

      location.reload();
    }
  }

  function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    onSubmit(formState);
  }
}

export default CreateCompanyForm;
