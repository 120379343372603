import {ReactComponent as TrashIcon} from "../../../core/ui/icon/24px/trash_24x24.svg";
import {ReactComponent as PlusButtonIcon} from "../../../core/ui/icon/plus-button.svg";

import {Button, FormField, Textarea} from "@hipo/react-ui-toolkit";
import {useState} from "react";

import Form from "../../../component/form/Form";
import DeleteModal from "../../../component/delete-modal/DeleteModal";
import Modal from "../../../component/modal/Modal";
import DateInput from "../../../component/input/date/DateInput";
import LabelToggle from "../../../component/label/toggle/LabelToggle";
import {useAppContext} from "../../../core/app/AppContext";

interface CreateOffdayFormProps {
  selectionRange: {
    workerId: string;
    date: Date;
    tag?: string;
    note?: string;
  }[];
  onSubmit: (tag: string, note: string | null | undefined, end: null | Date) => void;
  onClose: VoidFunction;
  onDelete: VoidFunction;
}

function CreateOffdayForm({
  selectionRange,
  onDelete,
  onSubmit,
  onClose
}: CreateOffdayFormProps) {
  const [isSingleDay, setSingleDay] = useState(selectionRange.length === 1);
  const [formState, setFormState] = useState<{
    start: Date | null;
    end: Date | null;
    tag?: string | null;
    note?: string | null;
  }>({
    start: selectionRange.length ? selectionRange[0].date : null,
    end:
      selectionRange.length > 1 ? selectionRange[selectionRange.length - 1].date : null,
    tag: isSingleDay ? selectionRange[0].tag : null,
    note: isSingleDay ? selectionRange[0].note : null
  });
  const [isDeleteModalOpen, setDeleteModalVisibility] = useState(false);
  const {
    state: {globalStatus}
  } = useAppContext();

  return (
    <Form>
      {selectionRange.every((item) => item.tag) && (
        <>
          <Button
            onClick={() => setDeleteModalVisibility(true)}
            style={{marginBottom: "8px", paddingLeft: "0"}}>
            <TrashIcon />
          </Button>

          <Modal
            isOpen={isDeleteModalOpen}
            onClose={() => setDeleteModalVisibility(false)}>
            <DeleteModal
              onSubmit={onDelete}
              onClose={() => setDeleteModalVisibility(false)}
            />
          </Modal>
        </>
      )}

      <FormField
        label={"Datum:"}
        labelFor={"start-date"}
        customClassName={"edit-tour-form__date-range"}>
        <DateInput
          name={"start-date"}
          selected={formState.start}
          onChange={(date) => setFormState({...formState, start: date})}
          isIconed={false}
          focusSelectedMonth={false}
          customClassName={"edit-tour-form__date"}
        />

        {"-"}

        {isSingleDay ? (
          <Button
            customClassName={"edit-tour-form__plus-button"}
            onClick={() => setSingleDay(false)}>
            <PlusButtonIcon />
          </Button>
        ) : (
          <DateInput
            name={"end-date"}
            selected={formState.end}
            onChange={(date) => setFormState({...formState, end: date})}
            isIconed={false}
            customClassName={"edit-tour-form__date"}
          />
        )}
      </FormField>

      {globalStatus && (
        <FormField
          label={"Etikett:"}
          labelFor={"label"}
          customClassName={"edit-tour-form__form-field--label"}>
          <LabelToggle
            onToggle={(label) => setFormState({...formState, tag: label})}
            items={globalStatus.offday_tags}
            selectedItem={formState.tag || ""}
          />
        </FormField>
      )}

      <FormField
        label={"Notizen:"}
        labelFor={"day-note"}
        customClassName={
          "edit-tour-form__form-field--label edit-tour-form__form-field--label-note"
        }>
        <Textarea
          name={"day-note"}
          onChange={(event) =>
            setFormState({...formState, note: event.currentTarget.value})
          }
          value={formState.note || ""}
          style={{height: "80px"}}
          customClassNames={{textarea: "create-tour-form__note-textarea"}}
        />
      </FormField>

      <div className={"create-tour-form__button-group"} style={{marginTop: "24px"}}>
        <Button onClick={onClose} customClassName={"button--transparent button--large"}>
          {"Abbrechen"}
        </Button>

        <Button
          onClick={handleSubmit}
          customClassName={"button--primary button--large"}
          isDisabled={!(formState.start && formState.tag)}
          type={"submit"}>
          {"Hinzufügen"}
        </Button>
      </div>
    </Form>
  );

  function handleSubmit() {
    if (formState.tag) onSubmit(formState.tag, formState.note, formState.end);
  }
}

export default CreateOffdayForm;
