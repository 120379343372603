/* eslint-disable max-lines */
import {ReactComponent as TrashIcon} from "../../../core/ui/icon/24px/trash_24x24.svg";

import React, {SyntheticEvent, useState} from "react";
import {Button, FormField, Input} from "@hipo/react-ui-toolkit";
import {format} from "date-fns";
import axios from "axios";
import {useNavigate} from "react-router-dom";

import "./_edit-truck-payment-form.scss";

import DateInput from "../../../component/input/date/DateInput";
import {useAppContext} from "../../../core/app/AppContext";
import {DATE_FORMAT} from "../../../core/util/time/timeConstants";
import {BASE_URL} from "../../../core/network/util/networkConstants";
import ROUTES from "../../../core/route/routes";
import DeleteModal from "../../../component/delete-modal/DeleteModal";
import Modal from "../../../component/modal/Modal";

interface EditTruckPaymentFormProps {
  paymentMethod: "cash" | "installment-payment";
  paymentStatus: boolean;
  onClose: VoidFunction;
  period: number;
  price: string;
  installmentAmount: string;
  startDate: string;
  endDate: string;
  plate: string;
}

function EditTruckPaymentForm({
  paymentMethod,
  price,
  paymentStatus,
  period,
  installmentAmount,
  startDate,
  endDate,
  onClose,
  plate
}: EditTruckPaymentFormProps) {
  const [formState, setFormState] = useState({
    paymentMethod,
    paymentStatus,
    period,
    price,
    installmentAmount,
    startDate: startDate ? new Date(startDate) : new Date(),
    endDate: endDate ? new Date(endDate) : new Date()
  });
  const {
    state: {ownFirm, user}
  } = useAppContext();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setDeleteModalState] = useState(false);

  return (
    <div>
      <h3 className={"typography--h3"}>{"Zahlungsinformationen"}</h3>

      <form onSubmit={handleSubmit} className={"edit-truck-payment-form"}>
        <p className={"typography--h7"}>{"Zahlungsmethode"}</p>

        <div className={"edit-truck-payment-form__radio-button-group"}>
          <label
            className={"edit-truck-payment-form__radio-button-container"}
            htmlFor={"cash"}>
            <input
              id={"cash"}
              name={"payment-method"}
              type={"radio"}
              value={"cash"}
              checked={formState.paymentMethod === "cash"}
              className={"edit-truck-payment-form__radio-button__input"}
              onChange={handlePaymentMethodChange}
            />

            <p>{"Barzahlung"}</p>
          </label>

          <label
            className={"edit-truck-payment-form__radio-button-container"}
            htmlFor={"installment-payment"}>
            <input
              id={"installment-payment"}
              name={"payment-method"}
              type={"radio"}
              checked={formState.paymentMethod === "installment-payment"}
              value={"installment-payment"}
              className={"edit-truck-payment-form__radio-button__input"}
              onChange={handlePaymentMethodChange}
            />

            <p>{"Ratenzahlung"}</p>
          </label>
        </div>

        <FormField label={"Preis"} labelFor={"price"} customClassName={""}>
          <div className={"edit-truck-payment-form__price-field"}>
            <Input name={"price"} value={formState.price} onChange={handlePriceChange} />

            <span className={"typography--b2"}>{"€"}</span>
          </div>
        </FormField>

        {formState.paymentMethod !== "cash" && (
          <>
            <p className={"typography--h7"}>{"Zahlungsstatus"}</p>

            <div className={"edit-truck-payment-form__radio-button-group"}>
              <label
                className={"edit-truck-payment-form__radio-button-container"}
                htmlFor={"unpaid"}>
                <input
                  id={"unpaid"}
                  name={"payment-status"}
                  value={"unpaid"}
                  type={"radio"}
                  checked={!formState.paymentStatus}
                  className={"edit-truck-payment-form__radio-button__input"}
                  onChange={handlePaymentStatus}
                />

                <p>{"Zahlung läuft"}</p>
              </label>

              <label
                className={"edit-truck-payment-form__radio-button-container"}
                htmlFor={"paid"}>
                <input
                  id={"paid"}
                  name={"payment-status"}
                  value={"paid"}
                  type={"radio"}
                  checked={formState.paymentStatus}
                  className={"edit-truck-payment-form__radio-button__input"}
                  onChange={handlePaymentStatus}
                />

                <p>{"Bezahlt"}</p>
              </label>
            </div>

            <FormField label={"Zeitraum"} labelFor={"period"}>
              <div className={"edit-truck-payment-form__period-field"}>
                <Input
                  name={"period"}
                  value={formState.period}
                  onChange={handlePeriodChange}
                />

                <span className={"typography--b2"}>{"Monaten"}</span>
              </div>
            </FormField>

            <FormField
              label={"Ratenbetrag"}
              labelFor={"installment-amount"}
              customClassName={"edit-truck-payment-form__amount-field"}>
              <Input
                name={"installment-amount"}
                onChange={handleInstallmentAmountChange}
                value={formState.installmentAmount}
                rightIcon={<span className={"typography--b2"}>{"€"}</span>}
              />
            </FormField>
          </>
        )}

        <FormField
          label={formState.paymentMethod === "cash" ? "Bezahltes Datum" : "Anfangsdatum"}
          labelFor={"start-date"}
          customClassName={"edit-truck-payment-form__date-field"}>
          <DateInput
            name={"start-date"}
            selected={formState.startDate}
            onChange={handleStartDateChange}
          />
        </FormField>

        {formState.paymentMethod !== "cash" && formState.paymentStatus && (
          <FormField
            label={"Enddatum"}
            labelFor={"end-date"}
            customClassName={"edit-truck-payment-form__date-field"}>
            <DateInput
              name={"end-date"}
              selected={formState.endDate}
              onChange={handleEndDateChange}
            />
          </FormField>
        )}

        <div className={"edit-truck-payment-form__button-group"}>
          <Button
            customClassName={"edit-truck-payment-form__delete-button"}
            onClick={() => setDeleteModalState(true)}>
            <TrashIcon />
          </Button>

          <Modal isOpen={isDeleteModalOpen} onClose={() => setDeleteModalState(false)}>
            <DeleteModal
              onSubmit={handleDelete}
              onClose={() => setDeleteModalState(false)}
            />
          </Modal>

          <Button
            customClassName={"button--transparent button--large"}
            onClick={handleClose}>
            {"Abbrechen"}
          </Button>

          <Button
            isDisabled={!formState.price}
            customClassName={"button--primary button--large"}
            type={"submit"}>
            {"Speichern"}
          </Button>
        </div>
      </form>
    </div>
  );

  async function handleDelete() {
    const formData = new FormData();

    formData.append("own_firm", ownFirm);
    formData.append("admin", user!.hash);
    formData.append("plate", plate);

    await axios({
      method: "POST",
      url: `${BASE_URL}delete-truck`,
      data: formData
    });

    navigate(ROUTES.TRUCK);
  }

  async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData();

    formData.append("own_firm", ownFirm);
    formData.append("plate", plate);
    formData.append("admin", user!.hash);
    formData.append(
      "payment_method",
      formState.paymentMethod === "cash" ? "Barzahlung" : "Ratenzahlung"
    );
    formData.append("price", formState.price);
    formData.append("total_installment_months", String(formState.period));
    formData.append(
      "installment_start_date",
      String(format(new Date(formState.startDate), DATE_FORMAT.API))
    );
    formData.append(
      "installment_end_date",
      String(format(new Date(formState.endDate), DATE_FORMAT.API))
    );

    if (formState.installmentAmount) {
      formData.append("installment_monthly_payment_amount", formState.installmentAmount);
    }

    if (formState.paymentMethod === "cash") {
      formData.append(
        "paid_day",
        String(format(new Date(formState.startDate), DATE_FORMAT.API))
      );
    }

    if (formState.paymentStatus) {
      formData.append("paid_status", String(formState.paymentStatus));
    }

    try {
      await axios({
        method: "POST",
        url: `${BASE_URL}change-truck-payment`,
        data: formData
      });

      location.reload();
    } catch (e) {
      console.error(e);
    }
  }

  function handleClose() {
    onClose();
  }

  function handleStartDateChange(date: Date) {
    setFormState({
      ...formState,
      startDate: date
    });
  }

  function handleEndDateChange(date: Date) {
    setFormState({
      ...formState,
      endDate: date
    });
  }

  function handleInstallmentAmountChange(event: React.SyntheticEvent<HTMLInputElement>) {
    setFormState({
      ...formState,
      installmentAmount: event.currentTarget.value
    });
  }

  function handlePeriodChange(event: React.SyntheticEvent<HTMLInputElement>) {
    setFormState({
      ...formState,
      period: parseInt(event.currentTarget.value)
    });
  }

  function handlePriceChange(event: React.SyntheticEvent<HTMLInputElement>) {
    setFormState({
      ...formState,
      price: event.currentTarget.value
    });
  }

  function handlePaymentMethodChange(event: React.SyntheticEvent<HTMLInputElement>) {
    setFormState({
      ...formState,
      paymentMethod: event.currentTarget.value as "cash" | "installment-payment"
    });
  }

  function handlePaymentStatus(event: React.SyntheticEvent<HTMLInputElement>) {
    setFormState({
      ...formState,
      paymentStatus: event.currentTarget.value === "paid"
    });
  }
}

export default EditTruckPaymentForm;
