import {ReactComponent as ShowPasswordIcon} from "../../core/ui/icon/show-password.svg";
import {ReactComponent as HidePasswordIcon} from "../../core/ui/icon/hide-password.svg";
import loginImage from "../../core/ui/image/login.png";

import "./_login-page.scss";

import {Button, FormField, Input, PasswordInput} from "@hipo/react-ui-toolkit";
import {SyntheticEvent, useState} from "react";
import axios, {AxiosResponse} from "axios";
import {Navigate, useLocation, useNavigate} from "react-router-dom";

import Form from "../../component/form/Form";
import {BASE_URL} from "../../core/network/util/networkConstants";
import webStorage, {STORED_KEYS} from "../../core/util/storage/web/webStorage";
import {useAppContext} from "../../core/app/AppContext";
import {User} from "../../core/app/appStateReducer";
import ROUTES from "../../core/route/routes";

function LoginPage() {
  const [formState, setFormState] = useState({username: "", password: ""});
  const {
    state: {user, ownFirm},
    dispatch
  } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();

  if (user && ownFirm) {
    return <Navigate to={ROUTES.HOME} replace={true} />;
  } else if (user) {
    return <Navigate to={ROUTES.COMPANY_SELECT} replace={true} />;
  }

  return (
    <div className={"login-page page"}>
      <img src={loginImage} alt={"login cover"} className={"login-cover"} />

      <div className={"login-page__form-container"}>
        <Form customClassName={"login-page__form"}>
          <h1 className={"typography--h1 login-page__form__title"}>{"Anmeldung"}</h1>

          <FormField
            label={"Username"}
            labelFor={"username"}
            customClassName={"login-page__form-field"}>
            <Input
              name={"username"}
              value={formState.username}
              placeholder={"Enter your username"}
              onChange={handleUsernameChange}
            />
          </FormField>

          <FormField
            label={"Passwort"}
            labelFor={"password"}
            customClassName={"login-page__form-field"}>
            <PasswordInput
              revealIcon={<ShowPasswordIcon />}
              hideIcon={<HidePasswordIcon />}
              customClassName={"login-page__password-input"}
              name={"password"}
              value={formState.password}
              placeholder={"Enter your password"}
              onChange={handlePasswordChange}
            />
          </FormField>

          <Button
            customClassName={"button--primary button--large"}
            type={"submit"}
            onClick={handleClick}>
            {"Anmeldung"}
          </Button>
        </Form>
      </div>
    </div>
  );

  async function handleClick() {
    const formData = new FormData();

    formData.append("username", formState.username);
    formData.append("password", formState.password);
    const authenticatedUser: AxiosResponse<User> = await axios({
      method: "POST",
      url: `${BASE_URL}login`,
      data: formData
    });

    webStorage.local.setItem(STORED_KEYS.USER, authenticatedUser.data);

    dispatch({
      type: "LOGIN",
      user: authenticatedUser.data
    });

    navigate(ROUTES.COMPANY_SELECT, {state: {from: location}});
  }

  function handleUsernameChange(event: SyntheticEvent<HTMLInputElement>) {
    setFormState({
      ...formState,
      username: event.currentTarget.value
    });
  }

  function handlePasswordChange(event: SyntheticEvent<HTMLInputElement>) {
    setFormState({
      ...formState,
      password: event.currentTarget.value
    });
  }
}

export default LoginPage;
