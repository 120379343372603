import {Fragment} from "react";
import {Navigate, RouteProps} from "react-router";
import {To} from "history";
import {useLocation, Location} from "react-router-dom";

import ROUTES from "./routes";
import {useAppContext} from "../app/AppContext";

type RequireAccountProps = RouteProps & {
  children: React.ReactNode;
  redirectTo?: To;
};

function RequireAccount({redirectTo, children}: RequireAccountProps) {
  const {state: appState} = useAppContext();
  const {state} = useLocation() as Location & {state?: {isNavigated: boolean}};

  if ((appState.user && appState.ownFirm) || state?.isNavigated) {
    return <Fragment>{children}</Fragment>;
  } else if (appState.user && !appState.ownFirm) {
    return <Navigate to={ROUTES.COMPANY_SELECT} />;
  }

  return <Navigate to={redirectTo || ROUTES.LOGIN} replace={true} />;
}

export default RequireAccount;
