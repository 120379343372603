import "./_vacation-schedule.scss";

import {SelectionEvent} from "@viselect/react";
import classNames from "classnames";
import {addDays, format, isSaturday, isSunday, isWeekend} from "date-fns";
import axios from "axios";
import {useState} from "react";

import ScheduleSelectionArea from "../../../component/schedule/selection-area/ScheduleSelectionArea";
import {DATE_FORMAT} from "../../../core/util/time/timeConstants";
import {VacationPlanningWorker} from "../api/vacationPlanningApiModel";
import Modal from "../../../component/modal/Modal";
import CreateOffdayForm from "../form/CreateOffdayForm";
import {BASE_URL} from "../../../core/network/util/networkConstants";
import {useAppContext} from "../../../core/app/AppContext";

interface VacationScheduleProps {
  scheduleDays: Date[];
  offdayRow: VacationPlanningWorker;
}

function VacationSchedule({scheduleDays, offdayRow}: VacationScheduleProps) {
  const extractIds = (els: Element[]): number[] =>
    els
      .map((v) => v.getAttribute("data-key"))
      .filter(Boolean)
      .map(Number);
  const [selectionState, setSelectionState] = useState<{
    self: Set<number>;
    range: {
      workerId: string;
      date: Date;
      tag?: string;
      note?: string;
    }[];
    isModalOpen: boolean;
  }>(() => ({
    self: new Set(),
    range: [],
    isModalOpen: false
  }));
  const [offdayRowState, setOffdayRowState] = useState(offdayRow);
  const {
    state: {globalStatus, user}
  } = useAppContext();

  return (
    <div className={"vacation-schedule"}>
      <ScheduleSelectionArea
        onStart={handleStart}
        onMove={handleMove}
        onStop={handleStop}
        selectableClassName={"vacation-schedule__cell"}>
        {scheduleDays.map((day, dayIndex) => {
          const currentDay = offdayRowState.offdays.find(
            (offday) =>
              format(new Date(offday.date), DATE_FORMAT.DEFAULT) ===
              format(day, DATE_FORMAT.DEFAULT)
          );
          const nextDay = new Date(day);
          const prevDay = new Date(day);

          nextDay.setDate(day.getDate() + 1);
          prevDay.setDate(day.getDate() - 1);

          const nextDayStatus = offdayRowState.offdays.find(
            (offday) =>
              format(new Date(offday.date), DATE_FORMAT.DEFAULT) ===
              format(nextDay, DATE_FORMAT.DEFAULT)
          )?.tag;
          const prevDayStatus = offdayRowState.offdays.find(
            (offday) =>
              format(new Date(offday.date), DATE_FORMAT.DEFAULT) ===
              format(prevDay, DATE_FORMAT.DEFAULT)
          )?.tag;
          const hasNextDaySameStatus = currentDay?.tag === nextDayStatus;
          const hasPrevDaySameStatus = currentDay?.tag === prevDayStatus;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={dayIndex} className={"vacation-schedule__cell-container"}>
              <div
                className={classNames("vacation-schedule__cell", {
                  "vacation-schedule__cell__has-status": currentDay?.tag,
                  "vacation-schedule__cell__selected": selectionState.self.has(dayIndex),
                  "selection-area__cell__weekend-day": isWeekend(day),
                  "selection-area__cell__weekend-day--is-saturday": isSaturday(
                    new Date(day)
                  ),
                  "selection-area__cell__weekend-day--is-sunday": isSunday(day)
                })}
                style={{
                  backgroundColor: currentDay?.tag_colour,
                  paddingLeft: hasPrevDaySameStatus ? undefined : "4px",
                  paddingRight: hasNextDaySameStatus ? undefined : "4px",
                  borderRight: hasNextDaySameStatus
                    ? undefined
                    : "1px solid var(--stroke-grey)"
                }}
                data-offday-worker-id={currentDay?.id}
                data-offday-date={day}
                data-offday-tag={currentDay?.tag}
                data-offday-note={currentDay?.notes}
                data-key={dayIndex}
              />

              {currentDay?.tag && !hasPrevDaySameStatus && (
                <p className={"typography--b2 text-color--primary tour-schedule__driver"}>
                  {currentDay.tag}
                </p>
              )}
            </div>
          );
        })}
      </ScheduleSelectionArea>

      {selectionState.range.length > 0 && (
        <Modal
          isOpen={selectionState.isModalOpen}
          onClose={handleModalClose}
          customClassName={"filter-modal"}>
          <CreateOffdayForm
            selectionRange={selectionState.range}
            onClose={handleModalClose}
            onDelete={handleDelete}
            onSubmit={handleSubmit}
          />
        </Modal>
      )}
    </div>
  );

  async function handleSubmit(
    tag: string,
    notes: string | null | undefined,
    end: null | Date
  ) {
    let endDate = selectionState.range
      .map((item) => format(item.date, DATE_FORMAT.API))
      .join(",");

    if (end) {
      const dateArr = [selectionState.range[0].date];

      while (new Date(dateArr[dateArr.length - 1]).getTime() !== end.getTime()) {
        dateArr.push(addDays(dateArr[dateArr.length - 1], 1));
      }

      endDate = dateArr.map((item) => format(item, DATE_FORMAT.API)).join(",");
    }
    const formData = new FormData();

    formData.append("worker_id", offdayRow.worker_id);
    formData.append("tag", tag);
    formData.append("admin", user!.hash);
    formData.append("dates", endDate);
    if (notes) formData.append("notes", notes);

    await axios({
      method: "POST",
      url: `${BASE_URL}create-offdays`,
      data: formData
    });

    if (end) location.reload();

    const newSelectedOffdays = selectionState.range.map((item) => ({
      id: "0",
      notes: item.note || null,
      date: format(item.date, DATE_FORMAT.API),
      tag,
      tag_colour:
        globalStatus?.offday_tags.find((statusItem) => statusItem.name === tag)?.colour ||
        "var(--tag-green-2)"
    }));
    const sortedOffdays = [...offdayRowState.offdays, ...newSelectedOffdays].sort(
      (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf()
    );

    setOffdayRowState({
      ...offdayRowState,
      offdays: sortedOffdays
    });

    // I know dude, looks horible
    location.reload();

    handleModalClose();
  }

  async function handleDelete() {
    const formData = new FormData();

    formData.append("admin", user!.hash);
    formData.append("data", selectionState.range.map((item) => item.workerId).join(","));

    await axios({
      method: "POST",
      url: `${BASE_URL}delete-offdays`,
      data: formData
    });

    setOffdayRowState({
      ...offdayRowState,
      offdays: offdayRowState.offdays.filter(
        (stateItem) =>
          !selectionState.range.some(
            (selectionItem) => String(stateItem.id) === selectionItem.workerId
          )
      )
    });

    handleModalClose();
  }

  function handleStart({event, selection}: SelectionEvent) {
    if (!event?.ctrlKey && !event?.metaKey) {
      selection.clearSelection();
      setSelectionState(() => ({
        ...selectionState,
        range: [],
        self: new Set()
      }));
    }
  }

  function handleMove({
    store: {
      changed: {added, removed}
    }
  }: SelectionEvent) {
    setSelectionState((prev) => {
      const next = new Set(prev.self);

      extractIds(added).forEach((id) => next.add(id));
      extractIds(removed).forEach((id) => next.delete(id));

      return {
        ...selectionState,
        self: next
      };
    });
  }

  function handleStop({store}: SelectionEvent) {
    const selectedList: Element[] = Array.from(store.selected);
    const newSelectionRange = selectedList.map((day) => ({
      workerId: day.getAttribute("data-offday-worker-id") || "",
      date: new Date(String(day.getAttribute("data-offday-date"))) || "",
      tag: day.getAttribute("data-offday-tag") || undefined,
      note: day.getAttribute("data-offday-note") || undefined
    }));

    setSelectionState({
      ...selectionState,
      range: newSelectionRange,
      isModalOpen: true
    });
  }

  function handleModalClose() {
    setSelectionState(() => ({
      range: [],
      self: new Set(),
      isModalOpen: false
    }));
  }
}

export default VacationSchedule;
