/* eslint-disable max-lines */
import {ReactComponent as ArrowRightIcon} from "../../../core/ui/icon/arrow-right.svg";
import {ReactComponent as TrashIcon} from "../../../core/ui/icon/24px/trash_24x24.svg";
import {ReactComponent as PlusIcon} from "../../../core/ui/icon/plus.svg";
import {ReactComponent as EditIcon} from "../../../core/ui/icon/24px/edit.svg";

import "./_contact-detail-page.scss";

import {Fragment, useCallback, useEffect, useState} from "react";
import {Button} from "@hipo/react-ui-toolkit";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {format} from "date-fns";
import axios from "axios";

import PageContent from "../../../component/page/content/PageContent";
import Page from "../../../component/page/Page";
import Table from "../../../component/table/Table";
import ROUTES from "../../../core/route/routes";
import {DATE_FORMAT} from "../../../core/util/time/timeConstants";
import {generateContactDetailTableColumns} from "./util/contactDateilPageUtils";
import Modal from "../../../component/modal/Modal";
import CreateMeetingForm from "../../form/create-meeting/CreateMeetingForm";
import useAsyncProcess from "../../../core/network/async-process/useAsyncProcess";
import contactApi from "../../api/contactApi";
import AsyncContent, {
  AsyncContentStatus
} from "../../../component/async-content/AsyncContent";
import Loading from "../../../component/loading/Loading";
import {
  SingleContactMeetingResponse,
  SingleContactResponse
} from "../../api/contactApiModels";
import DeleteModal from "../../../component/delete-modal/DeleteModal";
import CreateContactForm from "../../form/create-contact/CreateContactForm";
import {useAppContext} from "../../../core/app/AppContext";
import Label from "../../../component/label/Label";
import {BASE_URL} from "../../../core/network/util/networkConstants";
import {Ordering} from "../../../core/util/ordering/orderingTypes";
import {filterTruthyObjectValues} from "../../../core/util/object/objectUtils";

export type MeetingTableOrdering = Ordering<"date">;

function ContactDetailPage() {
  const {contactId} = useParams<{contactId: string}>();
  const [meetingState, setMeetingState] = useState({
    date: null as null | Date,
    id: "",
    note: "",
    visibility: false
  });
  const {runAsyncProcess, state} = useAsyncProcess<SingleContactResponse>();
  const [isDeleteModalOpen, setDeleteModalVisibility] = useState(false);
  const navigate = useNavigate();
  const [isEditContactFormOpen, setEditContactFormVisibility] = useState(false);
  const {
    state: {globalStatus, user}
  } = useAppContext();
  const [ordering, setOrdering] = useState<MeetingTableOrdering["state"]>();
  const [tableColumns, setColumns] = useState(generateContactDetailTableColumns());

  const handleOrdering = useCallback(
    (order_by: "date") => {
      setOrdering({order_by, direction: ordering?.direction === "asc" ? "des" : "asc"});
    },
    [ordering?.direction]
  );

  useEffect(() => {
    setColumns(
      generateContactDetailTableColumns({state: ordering, onChange: handleOrdering})
    );
  }, [ordering, handleOrdering]);

  useEffect(() => {
    if (contactId)
      runAsyncProcess(
        contactApi.getContact(
          filterTruthyObjectValues({
            contact_id: contactId,
            order_by: ordering?.order_by,
            direction: ordering?.direction
          })
        )
      );
  }, [runAsyncProcess, contactId, ordering?.direction, ordering?.order_by]);

  return (
    <Page title={"Kontaktendatenbank"}>
      <PageContent>
        <div className={"has-space-between"}>
          <div className={"contact-detail-page__header__breadcrumb"}>
            <NavLink
              to={ROUTES.CONTACTS}
              className={"typography--h5 text-color--secondary"}>
              {"Kontaktendatenbank"}
            </NavLink>

            <ArrowRightIcon />

            <p className={"typography--h5 text-color--primary"}>
              {state.data?.details.name}
            </p>
          </div>

          <Button
            onClick={() => setDeleteModalVisibility(true)}
            customClassName={
              "button--transparent contact-detail-page__header__delete-button"
            }>
            <TrashIcon />

            {"Löschen"}
          </Button>

          <Modal
            isOpen={isDeleteModalOpen}
            onClose={() => setDeleteModalVisibility(false)}>
            <DeleteModal
              text={state.data?.details.name}
              onSubmit={handleDelete}
              onClose={() => setDeleteModalVisibility(false)}
            />
          </Modal>
        </div>

        <AsyncContent requestStates={[state]} content={renderAsyncContent} />
      </PageContent>
    </Page>
  );

  function renderAsyncContent(status: AsyncContentStatus) {
    let node = <Fragment />;

    switch (status) {
      case "pending":
        node = <Loading />;
        break;

      case "error":
        node = <p>{"An error ocurred on server."}</p>;
        break;

      case "success":
        node = (
          <div>
            <div style={{marginTop: "50px", display: "flex"}}>
              <h2 className={"typography--h1 text-color--blue"}>{"Informationen"}</h2>

              <Button
                onClick={() => setEditContactFormVisibility(true)}
                style={{backgroundColor: "transparent"}}>
                <EditIcon />
              </Button>
            </div>

            <Modal
              isOpen={isEditContactFormOpen}
              onClose={() => setEditContactFormVisibility(false)}>
              <CreateContactForm
                name={state.data?.details.name}
                firm={state.data?.details.firm}
                label={state.data?.details.tag}
                phone={state.data?.details.phone}
                mail={state.data?.details.mail}
                fax={state.data?.details.fax}
                address={state.data?.details.address}
                note={state.data?.details.notes}
                contactId={state.data?.details.id}
                onClose={() => setEditContactFormVisibility(false)}
              />
            </Modal>

            <div className={"contact-detail-page__info-container"}>
              <div>
                <div className={"contact-detail-page__info__field"}>
                  <label
                    htmlFor={"name"}
                    className={"typography--h7 text-color--primary"}>
                    {"Name:"}
                  </label>
                  <p className={"typography--h5 text-color--primary"}>
                    {state.data?.details.name}
                  </p>
                </div>

                <div className={"contact-detail-page__info__field"}>
                  <label
                    htmlFor={"firm-name"}
                    className={"typography--h7 text-color--primary"}>
                    {"Firmenname:"}
                  </label>
                  <p className={"typography--h5 text-color--primary"}>
                    {state.data?.details.firm || "-"}
                  </p>
                </div>

                <div className={"contact-detail-page__info__field"}>
                  <label
                    htmlFor={"phone-name"}
                    className={"typography--h7 text-color--primary"}>
                    {"Telefonnummer:"}
                  </label>
                  <p className={"typography--h5 text-color--primary"}>
                    {state.data?.details.phone || "-"}
                  </p>
                </div>

                <div className={"contact-detail-page__info__field"}>
                  <label
                    htmlFor={"mail"}
                    className={"typography--h7 text-color--primary"}>
                    {"Mail:"}
                  </label>
                  <p className={"typography--h5 text-color--primary"}>
                    {state.data?.details.mail || "-"}
                  </p>
                </div>
              </div>

              <div>
                <div className={"contact-detail-page__info__field"}>
                  <label
                    htmlFor={"label"}
                    className={"typography--h7 text-color--primary"}>
                    {"Etikett:"}
                  </label>

                  <Label
                    name={state.data?.details.tag || "-"}
                    color={
                      globalStatus?.contacts_tags.find(
                        (item) => item.name === state.data?.details.tag
                      )?.colour || "-"
                    }
                  />
                </div>

                <div className={"contact-detail-page__info__field"}>
                  <label htmlFor={"fax"} className={"typography--h7 text-color--primary"}>
                    {"Fax:"}
                  </label>
                  <p className={"typography--h5 text-color--primary"}>
                    {state.data?.details.fax || "-"}
                  </p>
                </div>

                <div className={"contact-detail-page__info__field"}>
                  <label
                    htmlFor={"address"}
                    className={"typography--h7 text-color--primary"}>
                    {"Adress:"}
                  </label>
                  <p className={"typography--h5 text-color--primary"}>
                    {state.data?.details.address || "-"}
                  </p>
                </div>
              </div>

              {state.data?.details.notes && (
                <div>
                  <label
                    htmlFor={"note"}
                    className={"typography--h7 text-color--primary"}>
                    {"Notizen:"}
                  </label>
                  <p
                    className={
                      "contact-detail-page-info__field--note typography--b3 text-color--primary"
                    }>
                    {state.data?.details.notes}
                  </p>
                </div>
              )}
            </div>

            <div className={"contact-detail-page__notes"}>
              <div className={"has-space-between"}>
                <h2 className={"typography--h1 text-color--blue"}>{"Meetings"}</h2>

                <Button customClassName={"button--blue"} onClick={openCreateMeeting}>
                  {"Neue Meeting hinzufügen"}

                  <PlusIcon />
                </Button>

                <Modal isOpen={meetingState.visibility} onClose={closeCreateMeeting}>
                  <CreateMeetingForm
                    contact={contactId!}
                    date={meetingState.date ? new Date(meetingState.date) : undefined}
                    meetingId={meetingState.id}
                    note={meetingState.note}
                    onClose={closeCreateMeeting}
                  />
                </Modal>
              </div>

              <Table
                customClassName={"contact-detail-page__table"}
                data={state.data?.meetings || []}
                columns={tableColumns}
                customRow={renderRow}
              />
            </div>
          </div>
        );
        break;

      default:
        break;
    }

    return node;
  }

  async function handleDelete() {
    if (contactId) {
      const formData = new FormData();

      formData.append("admin", user!.hash);
      formData.append("contact_id", contactId);

      await axios({
        method: "POST",
        url: `${BASE_URL}delete-contact`,
        data: formData
      });

      navigate(ROUTES.CONTACTS);
    }
  }

  function renderRow(rowData: SingleContactMeetingResponse) {
    return (
      <div
        className={
          "table-row tank-payment-detail-page__table__row contact-detail-page__table__row"
        }>
        <p className={"typography--h5"}>
          {format(new Date(rowData.meetings_date), DATE_FORMAT.DEFAULT)}
        </p>

        <p className={"typography--h5"}>{rowData.meetings_notes}</p>

        <Button
          onClick={() =>
            handleEditMeetingClick(
              rowData.meetings_date,
              rowData.meetings_notes,
              rowData.id
            )
          }
          customClassName={"contact-detail-page__edit-button"}>
          <EditIcon />
        </Button>
      </div>
    );
  }

  function handleEditMeetingClick(date: string, note: string, id: string) {
    setMeetingState({
      date: new Date(date),
      note,
      visibility: true,
      id
    });
  }

  function openCreateMeeting() {
    setMeetingState({...meetingState, visibility: true});
  }

  function closeCreateMeeting() {
    setMeetingState({...meetingState, visibility: false, date: null});
  }
}

export default ContactDetailPage;
