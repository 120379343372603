import elbcargoApi from "../../core/network/elbcargo/elbcargoApi";
import {SingleTruckResponse, Truck} from "./truckApiModel";

const truckApi = {
  getTrucks(params: {
    own_firm: string;
    plate?: string;
    manufacturer?: string;
    model?: string;
  }) {
    return elbcargoApi.run<Truck[]>(
      {
        method: "GET",
        params
      },
      "get-trucks"
    );
  },
  getTruck(params: {own_firm: string; plate: string}) {
    return elbcargoApi.run<SingleTruckResponse>(
      {
        method: "GET",
        params
      },
      "get-truck"
    );
  },
  createTruck(payload: FormData) {
    return elbcargoApi.run(
      {
        method: "POST",
        body: payload
      },
      "create-truck"
    );
  },
  deleteTruck(params: {own_firm: string; plate: string}) {
    return elbcargoApi.run(
      {
        method: "POST"
      },
      `delete-truck/${params.own_firm}/${params.plate}`
    );
  },
  createTruckDocument(payload: {
    own_firm: string;
    plate: string;
    name: string;
    expiry_date?: string;
  }) {
    return elbcargoApi.run(
      {
        method: "POST",
        body: payload
      },
      "add-truck-document"
    );
  }
};

export default truckApi;
