import "./_page-content.scss";

import React from "react";
import classNames from "classnames";

import {useAppContext} from "../../../core/app/AppContext";

interface PageContentProps {
  children: React.ReactNode;
  isLoading?: boolean;
  customClassName?: string;
}

function PageContent({children, isLoading, customClassName}: PageContentProps) {
  const {
    state: {isNavigationOpen}
  } = useAppContext();

  return (
    <main
      className={classNames("page-content", customClassName, {
        "page-content--spinner": isLoading,
        "page-content--wide": !isNavigationOpen
      })}>
      {children}
    </main>
  );
}

export default PageContent;
