import {TableColumn} from "../../../../../component/table/Table";

function generateEmployeeDocumentTableColumns(): TableColumn<any>[] {
  return [
    {
      id: "EmployeeDocumentTable.name",
      header: "Dokumentname"
    },
    {
      id: "EmployeeDocumentTable.date",
      header: "Verfallsdatum"
    },
    {
      id: "EmployeeDocumentTable.file",
      header: ""
    }
  ];
}

export {generateEmployeeDocumentTableColumns};
