import {ReactComponent as TrashIcon} from "../../../core/ui/icon/24px/trash_24x24.svg";

import "./_edit-truck-form.scss";

import {Input, FormField, Button} from "@hipo/react-ui-toolkit";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";

import {BASE_URL} from "../../../core/network/util/networkConstants";
import {useAppContext} from "../../../core/app/AppContext";
import ROUTES from "../../../core/route/routes";
import Modal from "../../../component/modal/Modal";
import DeleteModal from "../../../component/delete-modal/DeleteModal";

interface EditTruckFormProps {
  truckId: string;
  data: {
    plate?: string;
    brand?: string;
    model?: string;
  };
  onClose: VoidFunction;
}

function EditTruckForm({truckId, data, onClose}: EditTruckFormProps) {
  const [formState, setFormState] = useState(data);
  const {
    state: {ownFirm, user}
  } = useAppContext();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setDeleteModalState] = useState(false);

  return (
    <div>
      <h3 className={"typography--h3"} style={{marginBottom: "24px"}}>
        {"Edit LKW"}
      </h3>

      <form className={"edit-truck-form"}>
        <FormField label={"Nummernschild"} labelFor={"plate"}>
          <Input
            name={"plate"}
            onChange={(event) =>
              setFormState({...formState, plate: event.currentTarget.value})
            }
            value={formState?.plate}
          />
        </FormField>

        <FormField label={"Marke"} labelFor={"brand"}>
          <Input
            name={"brand"}
            onChange={(event) =>
              setFormState({...formState, brand: event.currentTarget.value})
            }
            value={formState?.brand}
          />
        </FormField>

        <FormField label={"Modell"} labelFor={"model"}>
          <Input
            name={"model"}
            onChange={(event) =>
              setFormState({...formState, model: event.currentTarget.value})
            }
            value={formState?.model}
          />
        </FormField>

        <div className={"form__button-group"}>
          <Button
            onClick={() => setDeleteModalState(true)}
            customClassName={"button--transparent"}
            style={{marginTop: "auto", marginBottom: "10px"}}>
            <TrashIcon />
          </Button>

          <Modal isOpen={isDeleteModalOpen} onClose={() => setDeleteModalState(false)}>
            <DeleteModal
              onSubmit={handleDelete}
              onClose={() => setDeleteModalState(false)}
            />
          </Modal>

          <Button customClassName={"button--transparent button--large"} onClick={onClose}>
            {"Abbrechen"}
          </Button>

          <Button
            onClick={handleSubmit}
            customClassName={"button--primary button--large"}
            type={"submit"}>
            {"Speichern"}
          </Button>
        </div>
      </form>
    </div>
  );

  async function handleSubmit() {
    const formData = new FormData();

    formData.append("own_firm", ownFirm);
    formData.append("admin", user!.hash);
    formData.append("truck_id", truckId);

    if (formState.brand) formData.append("manufacturer", formState.brand);
    if (formState.plate) formData.append("plate", formState.plate);
    if (formState.model) formData.append("model", formState.model);
    await axios({
      method: "POST",
      url: `${BASE_URL}update-truck`,
      data: formData
    });

    if (data.plate === formState.plate) {
      location.reload();
    } else {
      navigate(ROUTES.TRUCK);
    }
  }

  async function handleDelete() {
    const formData = new FormData();

    formData.append("own_firm", ownFirm);
    formData.append("plate", data.plate!);
    formData.append("admin", user!.hash);
    await axios({
      method: "POST",
      url: `${BASE_URL}delete-truck`,
      data: formData
    });

    navigate(ROUTES.TRUCK);
  }
}

export default EditTruckForm;
