/* eslint-disable react/no-array-index-key */
/* eslint-disable no-magic-numbers */
import {Dropdown, DropdownOption, Input, List, ListItem} from "@hipo/react-ui-toolkit";
import {Fragment, useEffect, useState} from "react";
import {format} from "date-fns";

import "./_vacation-management-page.scss";

import PageContent from "../../../component/page/content/PageContent";
import Page from "../../../component/page/Page";
import Schedule from "../../../component/schedule/Schedule";
import useAsyncProcess from "../../../core/network/async-process/useAsyncProcess";
import {VacationInfo} from "../api/vacationManagementApiModel";
import {vacationManagementApi} from "../api/vacationManagementApi";
import {useAppContext} from "../../../core/app/AppContext";
import Tooltip from "../../../component/tooltip/Tooltip";
import AsyncContent, {
  AsyncContentStatus
} from "../../../component/async-content/AsyncContent";
import Loading from "../../../component/loading/Loading";
import {DATE_FORMAT} from "../../../core/util/time/timeConstants";

const monthOfYear = [
  "JAN",
  "FEB",
  "MÄR",
  "APR",
  "MAI",
  "JUNI",
  "JULI",
  "AUG",
  "SEP",
  "OKT",
  "NOV",
  "DEZ"
];

function VacationManagementPage() {
  const {runAsyncProcess, state} = useAsyncProcess<VacationInfo>();
  const currentYear =
    state.data?.available_years.find(
      (item) => item === String(new Date().getFullYear())
    ) || String(new Date().getFullYear());
  const [selectedOption, setSelectedOption] = useState<DropdownOption>({
    id: currentYear,
    title: currentYear
  });
  const {
    state: {ownFirm, globalStatus}
  } = useAppContext();
  const [filter, setFilter] = useState("");

  useEffect(() => {
    runAsyncProcess(
      vacationManagementApi.getVacationInfo({own_firm: ownFirm, year: selectedOption.id})
    );
  }, [runAsyncProcess, ownFirm, selectedOption]);

  return (
    <Page title={"Urlaubskonto"}>
      <PageContent>
        <AsyncContent requestStates={[state]} content={renderAsyncContent} />
      </PageContent>
    </Page>
  );

  function handleSelect(option: DropdownOption | null) {
    if (option) {
      setSelectedOption(option);
    }
  }

  function renderAsyncContent(status: AsyncContentStatus) {
    let node = <Fragment />;

    switch (status) {
      case "pending":
        node = <Loading />;
        break;

      case "error":
        node = <p>{"An error ocurred on server."}</p>;
        break;

      case "success":
        node = (
          <>
            <div className={"vacation-management-header"}>
              <Input
                name={"employee"}
                value={filter}
                onChange={(event) => setFilter(event.currentTarget.value)}
                placeholder={"Suche Arbeiter"}
                customClassName={"vacation-management-header__search"}
              />

              <h2 className={"typography--h2"}>{"Urlaubskonto"}</h2>

              <Dropdown
                role={"listbox"}
                options={
                  state.data?.available_years.map((item) => ({id: item, title: item})) ||
                  []
                }
                selectedOption={selectedOption}
                onSelect={handleSelect}
              />
            </div>

            <div className={"vacation-management-schedule-container"}>
              <Schedule customClassName={"vacation-management-schedule"}>
                <div className={"vacation-management-schedule__index"}>
                  <p
                    className={
                      "typography--h4 text-color--secondary vacation-management-schedule__index--row"
                    }>
                    {"Monate"}
                  </p>

                  <div
                    className={
                      "typography--h4 text-color--secondary schedule-column__index__diagonal-line"
                    }
                  />

                  <p
                    className={
                      "typography--h4 text-color--secondary vacation-management-schedule__index--column"
                    }>
                    {"Arbeiter"}
                  </p>
                </div>

                <div className={"vacation-management-schedule__header"}>
                  {["Verbl.", "Ben."].map((item) => (
                    <div
                      key={item}
                      className={
                        "typography--h4 text-color--secondary vacation-management-schedule__header-row text-color--blue"
                      }>
                      {item}
                    </div>
                  ))}
                  {monthOfYear.map((month) => (
                    <div
                      key={month}
                      className={
                        "typography--h4 text-color--secondary vacation-management-schedule__header-row"
                      }>
                      {month}
                    </div>
                  ))}
                </div>
              </Schedule>

              <ul className={"vacation-management-schedule__list"}>
                {state.data?.details
                  ?.filter((item) =>
                    item.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
                  )
                  .map((item) => (
                    <div
                      key={item.name}
                      className={"vacation-management-schedule__list-item-container"}>
                      <li
                        className={
                          "vacation-management-schedule__list-item vacation-management-schedule__list-item--first"
                        }>
                        {item.name}
                      </li>

                      <ListItem
                        customClassName={
                          "vacation-management-schedule__list-item vacation-management-schedule__list-item--additional"
                        }>
                        <p
                          key={item.remaining_holiday_days}
                          className={"typography--h3 text-color--main"}>
                          {item.remaining_holiday_days}
                        </p>
                      </ListItem>

                      <ListItem
                        customClassName={
                          "vacation-management-schedule__list-item vacation-management-schedule__list-item--additional"
                        }>
                        <p
                          key={item.used_holiday_days}
                          className={"typography--h3 text-color--main"}>
                          {item.used_holiday_days}
                        </p>
                      </ListItem>

                      <List
                        items={Object.values(item.days)}
                        customClassName={
                          "vacation-management-schedule__list-item__month-list"
                        }>
                        {(month, _, index) => (
                          <ListItem
                            customClassName={"vacation-management-schedule__list-item"}>
                            {Object.values(month).some((label) => label.length > 0) ? (
                              <Tooltip
                                customClassName={
                                  "vacation-management-page__tooltip-container"
                                }
                                content={
                                  <div>
                                    {month.Urlaub.length > 0 && (
                                      <div style={{marginBottom: "8px"}}>
                                        <span>{"Urlaub: "}</span>

                                        {month.Urlaub.map((x) => (
                                          <p key={x}>
                                            {format(new Date(x), DATE_FORMAT.DEFAULT)}
                                          </p>
                                        ))}
                                      </div>
                                    )}

                                    {month.Krank.length > 0 && (
                                      <div style={{marginBottom: "8px"}}>
                                        <span>{"Krank: "}</span>

                                        {month.Krank.map((x) => (
                                          <p key={x}>
                                            {format(new Date(x), DATE_FORMAT.DEFAULT)}
                                          </p>
                                        ))}
                                      </div>
                                    )}

                                    {month["Unbezahlter Urlaub"].length > 0 && (
                                      <div style={{marginBottom: "8px"}}>
                                        <span>{"Unbezahlter Urlaub: "}</span>

                                        {month["Unbezahlter Urlaub"].map((x) => (
                                          <p key={x}>
                                            {format(new Date(x), DATE_FORMAT.DEFAULT)}
                                          </p>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                }
                                dataFor={`${String(new Date().getTime())}-${index}`}>
                                <div>
                                  <p className={"typography--h3 text-color--main"}>
                                    {month.Krank.length +
                                      month["Unbezahlter Urlaub"].length +
                                      month.Urlaub.length}
                                  </p>

                                  <div className={"vacation-month-group"}>
                                    <div
                                      className={"vacation-month-group-item"}
                                      style={{
                                        backgroundColor: globalStatus?.offday_tags.find(
                                          (tag) => tag.name === "Urlaub"
                                        )?.colour
                                      }}>
                                      <p
                                        className={
                                          "typography--b5 text-color--main vacation-month-group-item-label"
                                        }>
                                        {"U"}
                                      </p>

                                      <p className={"typography--b5 text-color--main"}>
                                        {month.Urlaub.length}
                                      </p>
                                    </div>

                                    <div
                                      className={"vacation-month-group-item"}
                                      style={{
                                        backgroundColor: globalStatus?.offday_tags.find(
                                          (tag) => tag.name === "Krank"
                                        )?.colour
                                      }}>
                                      <p
                                        className={
                                          "typography--b5 text-color--main vacation-month-group-item-label"
                                        }>
                                        {"K"}
                                      </p>

                                      <p className={"typography--b5 text-color--main"}>
                                        {" "}
                                        {month.Krank.length}
                                      </p>
                                    </div>

                                    <div
                                      className={"vacation-month-group-item"}
                                      style={{
                                        backgroundColor: globalStatus?.offday_tags.find(
                                          (tag) => tag.name === "Unbezahlter Urlaub"
                                        )?.colour
                                      }}>
                                      <p
                                        className={
                                          "typography--b5 text-color--main vacation-month-group-item-label"
                                        }>
                                        {"UU"}
                                      </p>

                                      <p className={"typography--b5 text-color--main"}>
                                        {month["Unbezahlter Urlaub"].length}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Tooltip>
                            ) : (
                              <p className={"typography--h3 text-color--primary"}>
                                {"-"}
                              </p>
                            )}
                          </ListItem>
                        )}
                      </List>
                    </div>
                  ))}
              </ul>
            </div>
          </>
        );
        break;

      default:
        break;
    }

    return node;
  }
}

export default VacationManagementPage;
