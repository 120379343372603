import {CreditTableOrdering} from "../CreditPage";

function generateCreditTableColumns(ordering?: CreditTableOrdering) {
  return [
    {
      id: "CreditTable.id",
      header: "Avis Nr.",
      orderingProps: {
        isActive: ordering?.state?.order_by === "avis",
        onChange: () => ordering?.onChange("avis"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "CreditTable.doc-no",
      header: "Doc. Nr.",
      orderingProps: {
        isActive: ordering?.state?.order_by === "document_nr",
        onChange: () => ordering?.onChange("document_nr"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "CreditTable.firm-name",
      header: "Firmenname",
      orderingProps: {
        isActive: ordering?.state?.order_by === "firm",
        onChange: () => ordering?.onChange("firm"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "CreditTable.paid",
      header: "Ausgezahlt"
    },
    {
      id: "CreditTable.debt",
      header: "Offen",
      orderingProps: {
        isActive: ordering?.state?.order_by === "open_amount",
        onChange: () => ordering?.onChange("open_amount"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "CreditTable.gross",
      header: "Brutto"
    },
    {
      id: "CreditTable.document",
      header: "Dokument"
    }
  ];
}

export {generateCreditTableColumns};
