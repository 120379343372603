import "./_create-credit-form.scss";

import {
  FormField,
  Input,
  TypeaheadSelect,
  DropdownOption,
  Button,
  useToaster
} from "@hipo/react-ui-toolkit";
import {useState} from "react";
import {FileUploader} from "react-drag-drop-files";
import {format} from "date-fns";
import axios, {AxiosError} from "axios";

import DateInput from "../../../component/input/date/DateInput";
import {useAppContext} from "../../../core/app/AppContext";
import {BASE_URL} from "../../../core/network/util/networkConstants";
import {DATE_FORMAT} from "../../../core/util/time/timeConstants";
import ErrorToast from "../../../component/toast/error/ErrorToast";

interface CreateCreditFormProps {
  onClose: VoidFunction;
  data?: {
    id?: string;
    firmList?: DropdownOption[];
    billDate?: null | Date;
    start?: null | Date;
    end?: null | Date;
    document_nr?: string;
    gross_amount?: string;
    taxes?: string;
    avis?: string;
    isTaxesIncluded?: boolean;
    file?: null | File;
    file_url?: null | string;
  };
}

function CreateCreditForm({onClose, data}: CreateCreditFormProps) {
  const [formState, setFormState] = useState(data);
  const {
    state: {globalFirms, ownFirm, user}
  } = useAppContext();
  const firmDropdownOptions: DropdownOption[] = globalFirms
    ? globalFirms?.tour_firms.map(
        (firm) => ({id: firm.id, title: firm.name} as DropdownOption)
      )
    : [];
  const toaster = useToaster();

  return (
    <div>
      <h3 className={"typography--h3"}>{"Gutschrift erstellen"}</h3>

      <form>
        <div className={"create-credit-form__form-field-group"}>
          <div>
            <FormField label={"Dokumentnummer"} labelFor={"doc-no"}>
              <Input
                name={"doc-no"}
                value={formState?.document_nr}
                onChange={(event) =>
                  setFormState({...formState, document_nr: event.currentTarget.value})
                }
              />
            </FormField>

            <FormField label={"Firmenname"} labelFor={"firm-list"}>
              <TypeaheadSelect
                dropdownOptions={firmDropdownOptions}
                selectedOptions={formState?.firmList || []}
                customClassName={""}
                selectedOptionLimit={1}
                onSelect={(option) => setFormState({...formState, firmList: [option]})}
                typeaheadProps={{
                  placeholder: "Suche",
                  name: "firm-list"
                }}
                onTagRemove={() => setFormState({...formState, firmList: []})}
              />
            </FormField>

            <FormField label={"Avis Nummer"} labelFor={"avis"}>
              <Input
                name={"avis"}
                placeholder={"Suche"}
                value={formState?.avis}
                onChange={(event) =>
                  setFormState({...formState, avis: event.currentTarget.value})
                }
              />
            </FormField>

            <FormField label={"Eingangsdatum"} labelFor={"bill-date"}>
              <DateInput
                name={"bill-date"}
                onChange={(selectedDate) =>
                  setFormState({...formState, billDate: selectedDate || new Date()})
                }
                selected={formState?.billDate}
                placeholderText={"Auswählen"}
              />
            </FormField>
          </div>

          <div>
            <FormField label={"Zeitraum"} labelFor={"date"}>
              <DateInput
                name={"start-date"}
                placeholderText={"von"}
                onChange={(selectedDate) =>
                  setFormState({...formState, start: selectedDate})
                }
                selected={formState?.start}
              />

              <DateInput
                name={"end-date"}
                placeholderText={"bis"}
                onChange={(selectedDate) =>
                  setFormState({...formState, end: selectedDate})
                }
                selected={formState?.end}
              />
            </FormField>

            <FormField label={"Betrag"} labelFor={"amount"}>
              <Input
                name={"amount"}
                leftIcon={"€"}
                value={formState?.gross_amount}
                onChange={(event) =>
                  setFormState({...formState, gross_amount: event.currentTarget.value})
                }
              />
            </FormField>

            <FormField
              label={"Inklusive Mwst"}
              labelFor={"tax-included"}
              customClassName={"create-credit-form__checkbox-label"}>
              <Input
                name={"tax-included"}
                type={"checkbox"}
                onChange={() =>
                  setFormState({
                    ...formState,
                    isTaxesIncluded: !formState?.isTaxesIncluded
                  })
                }
                checked={formState?.isTaxesIncluded}
                customClassName={"create-credit-form__checkbox"}
              />
            </FormField>

            {formState?.isTaxesIncluded && (
              <FormField label={"Steuerprozent"} labelFor={"taxes"}>
                <div className={"create-credit-form__tax-input"}>
                  <span className={"typography--h5"}>{"%"}</span>

                  <Input
                    name={"taxes"}
                    onChange={(event) =>
                      setFormState({...formState, taxes: event.currentTarget.value})
                    }
                    value={formState.taxes}
                  />
                </div>
              </FormField>
            )}
          </div>

          <div>
            <FormField label={"Dokument hochladen"} labelFor={"document"}>
              <FileUploader
                name={"document"}
                multiple={false}
                file={formState?.file}
                handleChange={(file: File) => setFormState({...formState, file})}
                classes={"typography--h2 create-truck-document-form__drag-drop"}
              />

              <p className={"typography--h8 create-truck-document-form__drag-drop-files"}>
                {formState?.file
                  ? `File name: ${formState.file.name}`
                  : "No files uploaded yet"}
              </p>

              {formState?.file_url && (
                <a href={formState.file_url} target={"_blank"} rel={"noreferrer"}>
                  {"Avaiable file"}
                </a>
              )}
            </FormField>
          </div>
        </div>

        <div className={"form__button-group"}>
          <Button customClassName={"button--transparent button--large"} onClick={onClose}>
            {"Abbrechen"}
          </Button>

          <Button
            isDisabled={
              !(
                formState?.document_nr &&
                formState?.gross_amount &&
                formState?.billDate &&
                formState?.end &&
                formState?.start &&
                formState?.firmList &&
                formState.firmList[0]
              )
            }
            customClassName={"button--primary button--large"}
            type={"submit"}
            onClick={handleSubmit}>
            {"Speichern"}
          </Button>
        </div>
      </form>
    </div>
  );

  async function handleSubmit() {
    const formData = new FormData();

    formData.append("own_firm", ownFirm);
    if (formState?.firmList && formState.firmList[0])
      formData.append("firm", formState.firmList[0].id);

    formData.append("admin", user!.hash);

    formData.append(
      "creation_date",
      format(new Date(formState!.billDate!), DATE_FORMAT.API)
    );
    formData.append("gross_amount", formState!.gross_amount!);
    formData.append("taxes", formState?.taxes || "19");
    formData.append("avis_nr", formState!.avis!);
    formData.append("admin", user!.hash);
    if (formState?.start)
      formData.append("start", format(new Date(formState.start), DATE_FORMAT.API));
    if (formState?.end)
      formData.append("end", format(new Date(formState.end), DATE_FORMAT.API));

    if (data) {
      formData.append("gutschrift_id", formState!.id!);

      if (data.document_nr !== formState?.document_nr) {
        formData.append("document_nr", formState!.document_nr!);
      }

      try {
        await axios({
          method: "POST",
          url: `${BASE_URL}update-gutschrift`,
          data: formData
        });
      } catch (e) {
        const err = e as AxiosError;

        toaster.display({
          render() {
            return <ErrorToast message={err.response?.data as string} />;
          }
        });
      }

      const fileData = new FormData();

      if (formState?.file) {
        fileData.append("admin", user!.hash);
        fileData.append("gutschrift_id", data.id!);
        fileData.append("file", formState.file);

        await axios({
          method: "POST",
          url: `${BASE_URL}update-gutschrift-file`,
          data: fileData
        });

        location.reload();
      }
    } else {
      formData.append("document_nr", formState!.document_nr!);

      try {
        const id = await axios({
          method: "POST",
          url: `${BASE_URL}create-gutschrift`,
          data: formData
        });

        if (formState?.file) {
          const docData = new FormData();

          docData.append("gutschrift_id", id.data);
          docData.append("file", formState.file);

          await axios({
            method: "POST",
            url: `${BASE_URL}add-gutschrift-document`,
            data: docData
          });
        }

        location.reload();
      } catch (e) {
        const err = e as AxiosError;

        toaster.display({
          render() {
            return <ErrorToast message={err.response?.data as string} />;
          }
        });
      }
    }
  }
}

export default CreateCreditForm;
