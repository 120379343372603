import {Toggle} from "@hipo/react-ui-toolkit";

import {Status} from "../../../settings/api/settingsApiModel";
import Label from "../Label";

interface LabelToggleProps {
  onToggle: (label: string) => void;
  items: Status[];
  selectedItem?: string;
}

function LabelToggle({onToggle, items, selectedItem}: LabelToggleProps) {
  return (
    <Toggle onToggle={handleToggle} selectedItems={selectedItem ? [selectedItem] : []}>
      {items.map((item) => (
        <Toggle.Item key={item.name} dataId={item.name}>
          <Label name={item.name} color={item.colour} />
        </Toggle.Item>
      ))}
    </Toggle>
  );

  function handleToggle(selectedLabel: string[]) {
    onToggle(selectedLabel[0]);
  }
}

export default LabelToggle;
