import elbcargoApi from "../../../core/network/elbcargo/elbcargoApi";
import {VacationInfo} from "./vacationManagementApiModel";

const vacationManagementApi = {
  getVacationInfo(params: {own_firm: string; year: string}) {
    return elbcargoApi.run<VacationInfo>(
      {
        method: "GET",
        params
      },
      "get-urlaubskonto"
    );
  }
};

export {vacationManagementApi};
