import "./_vacation-planning-filter.scss";

import {
  Button,
  Dropdown,
  DropdownOption,
  FormField,
  TypeaheadSelect
} from "@hipo/react-ui-toolkit";
import {useState} from "react";

import LabelToggle from "../../../component/label/toggle/LabelToggle";
import {useAppContext} from "../../../core/app/AppContext";

interface VacationPlanningFilterProps {
  onClose: VoidFunction;
  onSubmit: (filter: {
    driver?: string;
    label?: string;
    start?: string;
    end?: string;
  }) => void;
}

const DROPDOWN_OPTIONS = [
  {id: "0-4", title: "0-4 Tage"},
  {id: "5-10", title: "5-10 Tage"},
  {id: "11-15", title: "11-15 Tage"},
  {id: "16-20", title: "16-20 Tage"},
  {id: "20", title: "20+ Tage"}
];

function VacationPlanningFilter({onClose, onSubmit}: VacationPlanningFilterProps) {
  const [formState, setFormState] = useState<{
    driver: null | DropdownOption;
    label: string;
    remainingOffday: null | DropdownOption;
  }>({
    driver: null,
    label: "",
    remainingOffday: null
  });
  const {
    state: {globalStatus, driverList}
  } = useAppContext();

  return (
    <form className={"vacation-planning-filter"}>
      <FormField label={"Fahrer:"} labelFor={"driver-list"}>
        <TypeaheadSelect
          dropdownOptions={
            driverList && driverList.length > 0
              ? driverList?.map((item) => ({
                  id: String(item.id),
                  title: `${item.name}`
                }))
              : []
          }
          selectedOptions={formState.driver ? [formState.driver] : []}
          onSelect={(option) => setFormState({...formState, driver: option})}
          selectedOptionLimit={1}
          typeaheadProps={{
            placeholder: "Suche",
            name: "driver-list"
          }}
          onTagRemove={() => setFormState({...formState, driver: null})}
        />
      </FormField>

      <FormField
        label={"Etikett:"}
        labelFor={"label"}
        customClassName={"edit-tour-form__form-field--label"}>
        <Dropdown
          role={"listbox"}
          options={DROPDOWN_OPTIONS}
          placeholder={"Suche"}
          selectedOption={formState.remainingOffday}
          onSelect={(option) => setFormState({...formState, remainingOffday: option})}
        />
      </FormField>

      {globalStatus && (
        <FormField
          label={"Etikett:"}
          labelFor={"label"}
          customClassName={"edit-tour-form__form-field--label"}>
          <LabelToggle
            onToggle={(label) => setFormState({...formState, label})}
            items={globalStatus.offday_tags}
            selectedItem={formState.label || ""}
          />
        </FormField>
      )}

      <div className={"form__button-group"}>
        <Button customClassName={"button--transparent button--large"} onClick={onClose}>
          {"Abbrechen"}
        </Button>

        <Button
          onClick={handleSubmit}
          customClassName={"button--primary button--large"}
          type={"submit"}>
          {"Filtern"}
        </Button>
      </div>
    </form>
  );

  function handleSubmit() {
    const selectedLabelId = globalStatus?.offday_tags.find(
      (item) => item.name === formState.label
    )?.id;

    if (formState.remainingOffday) {
      const [a, b] = formState.remainingOffday?.id.split("-");

      onSubmit({driver: formState.driver?.id, label: selectedLabelId, start: a, end: b});
    } else {
      onSubmit({driver: formState.driver?.id, label: selectedLabelId});
    }
  }
}

export default VacationPlanningFilter;
