/* eslint-disable max-lines */
import {ReactComponent as ArrowRightIcon} from "../../../core/ui/icon/arrow-right.svg";
import {ReactComponent as TrashIcon} from "../../../core/ui/icon/24px/trash_24x24.svg";
import {ReactComponent as PlusIcon} from "../../../core/ui/icon/plus.svg";
import {ReactComponent as EditIcon} from "../../../core/ui/icon/24px/edit.svg";
import {ReactComponent as DocumentIcon} from "../../../core/ui/icon/36px/document_36x36.svg";

import "./_truck-detail-page.scss";

import {NavLink, useNavigate, useParams} from "react-router-dom";
import {Button, ListItem} from "@hipo/react-ui-toolkit";
import {format} from "date-fns";
import {Fragment, useEffect, useState} from "react";
import axios from "axios";

import PageContent from "../../../component/page/content/PageContent";
import Page from "../../../component/page/Page";
import ROUTES from "../../../core/route/routes";
import Table from "../../../component/table/Table";
import {generateTruckDetailTableColumns} from "./util/truckDetailPageUtils";
import {DATE_FORMAT} from "../../../core/util/time/timeConstants";
import Modal from "../../../component/modal/Modal";
import EditTruckPaymentForm from "../../form/edit-truck-payment/EditTruckPaymentForm";
import CreateTruckDocumentForm from "../../form/create-truck-document/CreateTruckDocumentForm";
import useAsyncProcess from "../../../core/network/async-process/useAsyncProcess";
import {SingleTruckDocumentResponse, SingleTruckResponse} from "../../api/truckApiModel";
import truckApi from "../../api/truckApi";
import {useAppContext} from "../../../core/app/AppContext";
import DeleteModal from "../../../component/delete-modal/DeleteModal";
import AsyncContent, {
  AsyncContentError,
  AsyncContentStatus
} from "../../../component/async-content/AsyncContent";
import Loading from "../../../component/loading/Loading";
import EditTruckForm from "../../form/edit-truck/EditTruckForm";
import {BASE_URL} from "../../../core/network/util/networkConstants";
import {formatNumber} from "../../../core/util/number/numberUtils";

const numberFormatter = formatNumber({
  maximumFractionDigits: 2,
  minimumFractionDigits: 0
});

function TruckDetailPage() {
  const {truckId} = useParams<{truckId: string}>();
  const [isEditPaymentModalOpen, setEditPaymentModalVisibility] = useState(false);
  const [documentModalState, setDocumentModalState] = useState<{
    isOpen: boolean;
    id: string;
    name: string;
    date: null | Date;
    file: null | string;
    done: boolean;
  }>({
    isOpen: false,
    id: "",
    name: "",
    date: null,
    file: null,
    done: false
  });
  const [isEditModalOpen, setEditModalVisibility] = useState(false);
  const {state, runAsyncProcess} = useAsyncProcess<SingleTruckResponse>();
  const {
    state: {ownFirm, user}
  } = useAppContext();
  const [isDeleteModalOpen, setDeleteModalVisibility] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (truckId) {
      runAsyncProcess(
        truckApi.getTruck({
          own_firm: ownFirm,
          plate: truckId
        })
      );
    }
  }, [truckId, runAsyncProcess, ownFirm]);

  return (
    <Page title={"LKW-Datenbank"}>
      <PageContent>
        <div className={"has-space-between"}>
          <div className={"truck-detail-page__header__breadcrumb"}>
            <NavLink to={ROUTES.TRUCK} className={"typography--h5 text-color--secondary"}>
              {"LKW-Datenbank"}
            </NavLink>

            <ArrowRightIcon />

            <p className={"typography--h5 text-color--primary"}>{truckId}</p>
          </div>

          <Button
            onClick={() => setDeleteModalVisibility(true)}
            customClassName={
              "button--transparent truck-detail-page__header__delete-button"
            }>
            <TrashIcon />

            {"Löschen"}
          </Button>

          <Modal
            isOpen={isDeleteModalOpen}
            onClose={() => setDeleteModalVisibility(false)}>
            <DeleteModal
              text={truckId}
              onClose={() => setDeleteModalVisibility(false)}
              onSubmit={handleDeleteTruck}
            />
          </Modal>

          {state.data && (
            <Modal isOpen={isEditPaymentModalOpen} onClose={handleditPaymentModalClose}>
              <EditTruckPaymentForm
                plate={state.data.details.plate}
                paymentMethod={
                  state.data.details.payment_method === "Barzahlung"
                    ? "cash"
                    : "installment-payment"
                }
                paymentStatus={state.data.details.paid_status}
                price={state.data.details.price}
                period={state.data.details.total_installment_months}
                installmentAmount={state.data.details.installment_monthly_payment_amount}
                startDate={state.data.details.installment_start_date}
                endDate={state.data.details.installment_end_date}
                onClose={handleditPaymentModalClose}
              />
            </Modal>
          )}
        </div>

        <AsyncContent requestStates={[state]} content={renderAsyncContent} />
      </PageContent>
    </Page>
  );

  function renderAsyncContent(status: AsyncContentStatus, error: AsyncContentError) {
    let node = <Fragment />;

    switch (status) {
      case "pending":
        node = (
          <div className={"table__loading-view-container"}>
            <Loading />
          </div>
        );
        break;

      case "error":
        node = (
          <Fragment>
            <div className={"is-centered table__error-view-container"}>
              <div className={"is-centered"}>{error?.message}</div>
            </div>
          </Fragment>
        );
        break;

      case "success":
        node = (
          <>
            <div className={"truck-detail-page__meta"}>
              <div className={"truck-detail-page__info__title"}>
                <h2
                  className={"typography--h1 text-color--blue"}
                  style={{marginBottom: "16px"}}>
                  {"Informationen"}
                </h2>

                <Button
                  onClick={handleEditInfoClick}
                  customClassName={"truck-detail-page__info__edit-button"}>
                  <EditIcon />
                </Button>
              </div>

              <Modal
                isOpen={isEditModalOpen}
                onClose={() => setEditModalVisibility(false)}>
                <EditTruckForm
                  data={{
                    plate: state.data?.details.plate,
                    brand: state.data?.details.manufacturer,
                    model: state.data?.details.model
                  }}
                  truckId={state.data!.details.id}
                  onClose={() => setEditModalVisibility(false)}
                />
              </Modal>

              <div className={"truck-detail-page__info__field"}>
                <div>
                  <div
                    className={"is-vertically-centered"}
                    style={{gap: "16px", marginBottom: "8px"}}>
                    <label
                      htmlFor={"payment-status"}
                      className={"typography--h7 text-color--primary"}>
                      {"Nummernschild:"}
                    </label>
                    <p className={"typography--h5 text-color--primary"}>
                      {state.data?.details.plate}
                    </p>
                  </div>
                  <div className={"is-vertically-centered"} style={{gap: "16px"}}>
                    <label
                      htmlFor={"payment-status"}
                      className={"typography--h7 text-color--primary"}>
                      {"Modell:"}
                    </label>
                    <p
                      className={
                        "typography--h5 text-color--primary"
                      }>{`${state.data?.details.manufacturer} / ${state.data?.details.model}`}</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className={"truck-detail-page__info__title"}>
                <h2 className={"typography--h1 text-color--blue"}>
                  {"Zahlungsinformationen"}
                </h2>

                <Button
                  onClick={handleOpenPaymentModalClick}
                  customClassName={"truck-detail-page__info__edit-button"}>
                  <EditIcon />
                </Button>
              </div>

              <div className={"truck-detail-page__info-container"}>
                <div>
                  <div className={"truck-detail-page__info__field"}>
                    <label
                      htmlFor={"payment-method"}
                      className={"typography--h7 text-color--primary"}>
                      {"Zahlungsmethode:"}
                    </label>
                    <p className={"typography--h5 text-color--primary"}>
                      {state.data?.details.payment_method || "-"}
                    </p>
                  </div>

                  <div className={"truck-detail-page__info__field"}>
                    <label
                      htmlFor={"payment-method"}
                      className={"typography--h7 text-color--primary"}>
                      {"Zahlungsstatus:"}
                    </label>
                    <p className={"typography--h5 text-color--primary"}>
                      {state.data?.details.paid_status ? "Zahlung läuft" : "Bezahlt"}
                    </p>
                  </div>

                  <div className={"truck-detail-page__info__field"}>
                    <label
                      htmlFor={"payment-status"}
                      className={"typography--h7 text-color--primary"}>
                      {"Preis:"}
                    </label>
                    <p className={"typography--h5 text-color--primary"}>
                      {state.data?.details.price
                        ? `${numberFormatter(Number(state.data?.details.price))}€`
                        : "-"}
                    </p>
                  </div>
                </div>

                <div>
                  <div className={"truck-detail-page__info__field"}>
                    <label
                      htmlFor={"period"}
                      className={"typography--h7 text-color--primary"}>
                      {"Zeitraum:"}
                    </label>
                    <p className={"typography--h5 text-color--primary"}>
                      {state.data?.details.total_installment_months}
                    </p>
                  </div>

                  <div className={"truck-detail-page__info__field"}>
                    <label
                      htmlFor={"installment-amount"}
                      className={"typography--h7 text-color--primary"}>
                      {"Ratenbetrag:"}
                    </label>
                    <p className={"typography--h5 text-color--primary"}>
                      {state.data?.details.installment_monthly_payment_amount
                        ? `${state.data?.details.installment_monthly_payment_amount}€`
                        : "-"}
                    </p>
                  </div>
                </div>

                <div>
                  <div className={"truck-detail-page__info__field"}>
                    <label
                      htmlFor={"start-date"}
                      className={"typography--h7 text-color--primary"}>
                      {"Anfangsdatum:"}
                    </label>
                    <p className={"typography--h5 text-color--primary"}>
                      {state.data?.details.installment_start_date
                        ? format(
                            new Date(state.data.details.installment_start_date),
                            DATE_FORMAT.DEFAULT
                          )
                        : "-"}
                    </p>
                  </div>

                  <div className={"truck-detail-page__info__field"}>
                    <label
                      htmlFor={"end-date"}
                      className={"typography--h7 text-color--primary"}>
                      {"Enddatum:"}
                    </label>
                    <p className={"typography--h5 text-color--primary"}>
                      {state.data?.details.installment_end_date
                        ? format(
                            new Date(state.data.details.installment_end_date),
                            DATE_FORMAT.DEFAULT
                          )
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>

              <div className={"credit-detail-page__payment-history"}>
                <div className={"has-space-between"}>
                  <h2 className={"typography--h1 text-color--blue"}>{"Dokumente"}</h2>

                  <Button
                    customClassName={"button--blue"}
                    onClick={handleCreateDocumentClick}>
                    {"Neue hinzufügen"}

                    <PlusIcon />
                  </Button>

                  <Modal
                    isOpen={documentModalState.isOpen}
                    onClose={closeCreateDocumentModal}>
                    <CreateTruckDocumentForm
                      plate={truckId}
                      onClose={closeCreateDocumentModal}
                      data={{
                        id: documentModalState.id,
                        name: documentModalState.name,
                        file: documentModalState.file,
                        date: documentModalState.date,
                        done: documentModalState.done
                      }}
                    />
                  </Modal>
                </div>

                <Table
                  customClassName={"truck-detail-page__table"}
                  data={state.data?.documents || []}
                  columns={generateTruckDetailTableColumns()}
                  customRow={renderRow}
                />
              </div>
            </div>
          </>
        );
        break;

      default:
        break;
    }
    return node;
  }

  function renderRow(rowData: SingleTruckDocumentResponse) {
    return (
      <ListItem
        customClassName={"truck-detail-page__table__row truck-table-row"}
        clickableListItemProps={{
          onClick: () => {
            setDocumentModalState({
              id: rowData.id,
              name: rowData.name,
              date: rowData.expiry_date ? new Date(rowData.expiry_date) : null,
              file: rowData.file_url,
              isOpen: true,
              done: rowData.done
            });
          }
        }}>
        <p className={"typography--h5"}>{rowData.name}</p>

        <p className={"typography--h5"}>
          {rowData.expiry_date
            ? format(new Date(rowData.expiry_date), DATE_FORMAT.DEFAULT)
            : "-"}
        </p>

        {rowData.file_url ? (
          <a
            href={rowData.file_url}
            download={true}
            target={"_blank"}
            rel={"noopener noreferrer"}>
            <DocumentIcon />
          </a>
        ) : (
          <div />
        )}
      </ListItem>
    );
  }

  function handleEditInfoClick() {
    setEditModalVisibility(true);
  }

  async function handleDeleteTruck() {
    const formData = new FormData();

    formData.append("own_firm", ownFirm);
    formData.append("plate", truckId!);
    formData.append("admin", user!.hash);
    await axios({
      method: "POST",
      url: `${BASE_URL}delete-truck`,
      data: formData
    });

    navigate(ROUTES.TRUCK);
  }

  function handleCreateDocumentClick() {
    setDocumentModalState({
      id: "",
      name: "",
      date: null,
      file: null,
      done: false,
      isOpen: true
    });
  }

  function closeCreateDocumentModal() {
    setDocumentModalState({
      id: "",
      name: "",
      date: null,
      file: null,
      done: false,
      isOpen: false
    });
  }

  function handleOpenPaymentModalClick() {
    setEditPaymentModalVisibility(true);
  }

  function handleditPaymentModalClose() {
    setEditPaymentModalVisibility(false);
  }
}

export default TruckDetailPage;
