import elbcargoApi from "../../core/network/elbcargo/elbcargoApi";
import {DailyExpense, DriverOption, Employee, EmployeeListItem} from "./employeeApiModel";

const employeeApi = {
  getDrivers(params: {own_firm: string}) {
    return elbcargoApi.run<DriverOption[]>(
      {
        method: "GET",
        params
      },
      "get-drivers"
    );
  },
  getWorkers(params: {
    own_firm: string;
    show_quit_workers?: boolean;
    order_by?: string;
    direction?: string;
  }) {
    return elbcargoApi.run<EmployeeListItem[]>(
      {
        method: "GET",
        params
      },
      "get-workers"
    );
  },
  getWorker(params: {worker_id: number}) {
    return elbcargoApi.run<Employee>(
      {
        method: "GET",
        params
      },
      "get-worker"
    );
  },
  getDailyExpenses(params: {own_firm: string}) {
    return elbcargoApi.run<DailyExpense[]>(
      {
        method: "GET",
        params
      },
      "get-daily-expenses"
    );
  }
};

export default employeeApi;
