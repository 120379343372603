import classNames from "classnames";
import React, {CSSProperties} from "react";

import {TableColumn} from "../Table";

interface TableRowProps<TableData extends {id: string}> {
  columns: TableColumn<TableData>[];
  rowData: TableData;
  style?: CSSProperties;
}

function TableRow<TableData extends {id: string; [x: string]: any}>({
  columns,
  rowData,
  style
}: TableRowProps<TableData>) {
  const rowID = `table-row--${rowData.id}`;

  return (
    <div className={"table-row"} style={style}>
      {columns.map((column) => {
        const cellID = `${rowID}--${column.id}`;
        let cellContent: React.ReactNode = "";

        if (column.cellContent) {
          cellContent = column.cellContent({
            data: rowData,
            testid: cellID
          });
        } else if (column.cellFieldName) {
          cellContent = rowData[column.cellFieldName];
        }

        return (
          <div
            key={cellID}
            className={classNames("table-row__cell", {
              "is-aligned-right": column.alignment === "right"
            })}>
            {cellContent}
          </div>
        );
      })}
    </div>
  );
}

export default TableRow;
