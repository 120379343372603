function generateLabelColumns() {
  return [
    {
      id: "LabelTable.label",
      header: "Etikett"
    },
    {
      id: "LabelTable.color",
      header: "Farbe"
    }
  ];
}

export {generateLabelColumns};
