// 3rd party CSS imports should come here
import "@hipo/react-ui-toolkit/dist/main.css";
import "react-datepicker/dist/react-datepicker.css";

import "./core/ui/style/override/_override.scss";
import "./core/ui/style/_align.scss";
import "./core/ui/style/_measure.scss";
import "./core/ui/style/_common.scss";
import "./core/ui/typography/_typography.scss";
import "./core/ui/style/color/_global-colors.scss";
import "./core/ui/style/color/_theme.scss";

// 3rd party CSS override imports should come here
import "./core/ui/style/override/hipo-ui-toolkit/_button.scss";
import "./core/ui/style/override/hipo-ui-toolkit/_input.scss";
import "./core/ui/style/override/hipo-ui-toolkit/_dropdown.scss";
import "./core/ui/style/override/hipo-ui-toolkit/_form-field.scss";
import "./core/ui/style/override/hipo-ui-toolkit/_typeahead-select.scss";
import "./core/ui/style/override/hipo-ui-toolkit/_tab.scss";
import "./core/ui/style/override/hipo-ui-toolkit/_toggle.scss";
import "./core/ui/style/override/hipo-ui-toolkit/_toast.scss";

import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {ToastContextProvider} from "@hipo/react-ui-toolkit";

import App from "./core/app/App";
import reportWebVitals from "./core/reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ToastContextProvider autoCloseToasts={true} defaultAutoCloseTimeout={3000}>
        <App />
      </ToastContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
