import "./_contact-filter-form.scss";

import {Button, FormField, Input} from "@hipo/react-ui-toolkit";
import {useState} from "react";

import LabelToggle from "../../../component/label/toggle/LabelToggle";
import {useAppContext} from "../../../core/app/AppContext";

interface ContactFilterFormProps {
  onSubmit: (name: string, firm: string, label: string) => void;
  onClose: VoidFunction;
}

function ContactFilterForm({onSubmit, onClose}: ContactFilterFormProps) {
  const [formState, setFormState] = useState({
    name: "",
    label: "",
    firm: ""
  });
  const {
    state: {globalStatus}
  } = useAppContext();

  return (
    <form className={"contact-filter-form"}>
      <FormField label={"Name"} labelFor={"name"}>
        <Input
          name={"name"}
          onChange={(event) =>
            setFormState({...formState, name: event.currentTarget.value})
          }
          value={formState.name}
        />
      </FormField>

      <FormField label={"Firmenname"} labelFor={"firm"}>
        <Input
          name={"firm"}
          onChange={(event) =>
            setFormState({...formState, firm: event.currentTarget.value})
          }
          value={formState.firm}
        />
      </FormField>

      {globalStatus?.contacts_tags && (
        <FormField
          label={"Etikett:"}
          labelFor={"label"}
          customClassName={"edit-tour-form__form-field--label"}>
          <LabelToggle
            onToggle={handleLabelToggle}
            items={globalStatus.contacts_tags}
            selectedItem={formState.label}
          />
        </FormField>
      )}

      <div className={"form__button-group"} style={{justifyContent: "space-between"}}>
        <Button customClassName={"button--transparent button--large"} onClick={onClose}>
          {"Abbrechen"}
        </Button>

        <Button
          customClassName={"button--primary button--large"}
          type={"submit"}
          onClick={handleSubmit}>
          {"Filtern"}
        </Button>
      </div>
    </form>
  );

  function handleLabelToggle(selected: string) {
    setFormState({...formState, label: selected});
  }

  function handleSubmit() {
    onSubmit(formState.name, formState.firm, formState.label);

    onClose();
  }
}

export default ContactFilterForm;
