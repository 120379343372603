import "./_truck-filter-form.scss";

import {FormField, Button, Input} from "@hipo/react-ui-toolkit";
import {SyntheticEvent} from "react";

import {NO_OP} from "../../../core/util/function/functionUtils";

function TruckFilterForm({
  onClose,
  onSubmit
}: {
  onClose: VoidFunction;
  onSubmit: ({
    plate,
    manufacturer,
    model
  }: {
    plate?: string;
    manufacturer?: string;
    model?: string;
  }) => void;
}) {
  return (
    <form onSubmit={handleSubmit} className={"truck-filter-form"}>
      <FormField label={"Nummernschild"} labelFor={"plate"}>
        <Input name={"plate"} onChange={NO_OP} placeholder={"Suche"} />
      </FormField>

      <FormField label={"Marke"} labelFor={"brand"}>
        <Input name={"brand"} onChange={NO_OP} placeholder={"Suche"} />
      </FormField>

      <FormField label={"Modell"} labelFor={"model"}>
        <Input name={"model"} onChange={NO_OP} placeholder={"Suche"} />
      </FormField>

      <div className={"create-tour-form__button-group"} style={{marginTop: "24px"}}>
        <Button onClick={onClose} customClassName={"button--transparent button--large"}>
          {"Abbrechen"}
        </Button>

        <Button customClassName={"button--primary button--large"} type={"submit"}>
          {"Filtern"}
        </Button>
      </div>
    </form>
  );

  function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    onSubmit({
      plate: formData.get("plate")?.toString(),
      manufacturer: formData.get("brand")?.toString(),
      model: formData.get("model")?.toString()
    });
  }
}

export default TruckFilterForm;
