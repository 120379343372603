import {ReactComponent as PaymentIcon} from "../core/ui/icon/payment-finance.svg";
import {ReactComponent as InvoiceIcon} from "../core/ui/icon/invoice.svg";

import "./_home-page.scss";

import {Bar, BarChart, Tooltip, XAxis} from "recharts";
import {Fragment, useEffect} from "react";
import {Tab} from "@hipo/react-ui-toolkit";
import {Link} from "react-router-dom";

import Page from "../component/page/Page";
import PageContent from "../component/page/content/PageContent";
import useAsyncProcess from "../core/network/async-process/useAsyncProcess";
import homeApi, {HomeAPIResponse} from "./api/homeApi";
import {useAppContext} from "../core/app/AppContext";
import AsyncContent, {AsyncContentStatus} from "../component/async-content/AsyncContent";
import Loading from "../component/loading/Loading";
import {MONTH_NAMES} from "../core/util/time/timeConstants";
import ChartTooltip from "../component/chart/tooltip/ChartTooltip";
import Table from "../component/table/Table";
import {formatNumber} from "../core/util/number/numberUtils";
import ROUTES from "../core/route/routes";

const numberFormatter = formatNumber({
  maximumFractionDigits: 2,
  minimumFractionDigits: 0
});

function HomePage() {
  const {runAsyncProcess, state} = useAsyncProcess<HomeAPIResponse>();
  const {
    state: {ownFirm}
  } = useAppContext();
  const reversedData = state.data ? [...state.data.sales.months].reverse() : [];

  useEffect(() => {
    runAsyncProcess(homeApi.getHomePage({own_firm: ownFirm}));
  }, [runAsyncProcess, ownFirm]);

  return (
    <Page title={"Home"}>
      <PageContent>
        <AsyncContent requestStates={[state]} content={renderAsyncContent} />
      </PageContent>
    </Page>
  );

  function renderAsyncContent(status: AsyncContentStatus) {
    let node = <Fragment />;

    switch (status) {
      case "pending":
        node = <Loading />;
        break;

      case "error":
        node = <p>{"An error ocurred on server."}</p>;
        break;

      case "success":
        node = (
          <div className={"home-page"}>
            <div className={"home-page-left-side"}>
              <div className={"home-page__chart-section"}>
                <div className={"has-space-between"} style={{width: "550px"}}>
                  <div className={"is-vertically-centered"} style={{gap: "8px"}}>
                    <h3 className={"typography--h3"}>{"Umsätze"}</h3>

                    <p className={"typography--b4"} style={{color: "#9B9B9B"}}>
                      {"von den letzten 12 Monaten"}
                    </p>
                  </div>

                  <p className={"typography--h1 text-color--blue"}>{`${numberFormatter(
                    Number(state.data?.sales.total || 0)
                  )}€`}</p>
                </div>
                <BarChart
                  width={580}
                  height={300}
                  data={reversedData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                  }}>
                  <XAxis
                    dataKey={"month"}
                    tickFormatter={(value) => MONTH_NAMES[value - 1].substring(0, 1)}
                    tickSize={0}
                    tickMargin={10}
                    axisLine={false}
                  />
                  <Tooltip
                    content={(props) => <ChartTooltip {...props} />}
                    position={{x: 0, y: 0}}
                  />
                  <Bar dataKey={"bills"} fill={"#0241C9"} />
                  <Bar dataKey={"gutschrifts"} fill={"#02C98A"} />
                </BarChart>
              </div>

              <div className={"home-page-tour-table-container"}>
                <Link
                  to={ROUTES.TOUR}
                  className={"typography--h3"}
                  style={{
                    marginBottom: "16px",
                    marginLeft: "6px",
                    display: "block",
                    color: "var(--text-primary)"
                  }}>
                  {"heutige Tourenbesetzung "}
                </Link>

                <Table
                  customClassName={"home-page-tour-table"}
                  data={state.data?.tours || []}
                  columns={generateTourColumns()}
                  customRow={renderTourRow}
                />
              </div>
            </div>

            <div className={"home-page-right-side"}>
              <div className={"home-page-right-table-section"}>
                <div>
                  <h4
                    className={
                      "typography--h4 text-color--secondary home-page-right-title"
                    }>
                    {"Termine"}
                  </h4>

                  <Tab
                    items={[
                      {id: "LKW", content: "LKW"},
                      {id: "Fahrer", content: "Fahrer"}
                    ]}
                    customClassName={"home-page-right-tab"}>
                    {[
                      <div key={1} className={"home-page-right-table-container"}>
                        <Table
                          data={state.data?.appointments.vehicles || []}
                          customRow={renderVehicleRow}
                          customClassName={"home-page-right-tables"}
                          columns={[
                            {id: "id", header: "Kennzeichen", link: ROUTES.TRUCK},
                            {id: "doc", header: "Dokument"},
                            {id: "date", header: "Gültig bis"}
                          ]}
                        />
                      </div>,
                      <div key={2} className={"home-page-right-table-container"}>
                        <Table
                          data={state.data?.appointments.drivers || []}
                          customRow={renderDriverRow}
                          customClassName={"home-page-right-tables"}
                          columns={[
                            {id: "id", header: "Fahrer", link: ROUTES.EMPLOYEE},
                            {id: "doc", header: "Dokument"},
                            {id: "date", header: "Gültig bis"}
                          ]}
                        />
                      </div>
                    ]}
                  </Tab>
                </div>
              </div>

              <div className={"payment-section"}>
                <h3 className={"typography--h3 text-color--main"}>
                  {"Zahlungen für April"}
                </h3>
                <div className={"payment-container"}>
                  <Link to={ROUTES.CREDITS} className={"payment-box payment-box--credit"}>
                    <PaymentIcon />

                    <p className={"typography--h4 text-color--white"}>{"Gutschriften"}</p>

                    <p className={"typography--h1 text-color--white"}>{`${formatNumber({
                      maximumFractionDigits: 0
                    })(Number(state.data?.payments.gutschrift_total) || 0)}€`}</p>
                  </Link>

                  <Link to={ROUTES.BILLS} className={"payment-box payment-box--bill"}>
                    <InvoiceIcon />

                    <p className={"typography--h4 text-color--white"}>{"Rechnungen"}</p>

                    <p className={"typography--h1 text-color--white"}>{`${formatNumber({
                      maximumFractionDigits: 0
                    })(Number(state.data?.payments.bills_total))}€`}</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
        break;

      default:
        break;
    }

    return node;
  }

  function renderDriverRow(data: {
    id: string;
    worker_id: string;
    driver: string;
    name: string;
    expiry_date: string;
  }) {
    return (
      <Link to={`/employee/detail/${data.worker_id}`} className={"table-row"}>
        <p>{data.driver}</p>

        <p>{data.name}</p>

        {data.expiry_date ? <p>{data.expiry_date}</p> : "-"}
      </Link>
    );
  }

  function renderVehicleRow(data: {
    id: string;
    plate: string;
    name: string;
    expiry_date: string;
  }) {
    return (
      <Link to={`/truck/${data.plate}`} className={"table-row"}>
        <p>{data.plate}</p>

        <p>{data.name}</p>

        {data.expiry_date ? <p>{data.expiry_date}</p> : "-"}
      </Link>
    );
  }

  function generateTourColumns() {
    return [
      {
        id: "TourTable.id",
        header: "Roller Nummer",
        link: ROUTES.TOUR
      },
      {
        id: "TourTable.plate",
        header: "Nummernschild",
        link: ROUTES.TRUCK
      },
      {
        id: "TourTable.driver",
        header: "Fahrer",
        link: ROUTES.EMPLOYEE
      },
      {
        id: "TourTable.firm",
        header: "Firma",
        link: ROUTES.SETTINGS_FIRM
      }
    ];
  }

  function renderTourRow(data: {
    id: string;
    roller_nr: string;
    plate: string;
    drivers: {id: string; name: string}[];
    firm: string;
  }) {
    return (
      <div className={"table-row"}>
        <p>{data.roller_nr}</p>

        <Link to={`/truck/${data.plate}`} style={{color: "var(--text-primary)"}}>
          {data.plate}
        </Link>

        <p>
          {data.drivers.map((item) => (
            <Link
              key={item.id}
              to={`employee/detail/${item.id}`}
              style={{color: "var(--text-primary)"}}>
              {item.name}
            </Link>
          ))}
        </p>

        <p>{data.firm}</p>
      </div>
    );
  }
}

export default HomePage;
