import "./_employee-detail-page.scss";

import {useParams} from "react-router-dom";
import {useEffect} from "react";

import PageContent from "../../../component/page/content/PageContent";
import Page from "../../../component/page/Page";
import EmployeeDetailPageDebt from "./debt/EmployeeDetailPageDebt";
import EmployeeDetailPageDocument from "./document/EmployeeDetailPageDocument";
import EmployeeDetailPageHeader from "./header/EmployeeDetailPageHeader";
import EmployeeDetailPageInfo from "./info/EmployeeDetailPageInfo";
import useAsyncProcess from "../../../core/network/async-process/useAsyncProcess";
import {Employee} from "../../api/employeeApiModel";
import employeeApi from "../../api/employeeApi";
import Loading from "../../../component/loading/Loading";
import EmployeeDetailPageWorking from "./working/EmployeeDetailPageWorking";

function EmployeeDetailPage() {
  const {employeeId} = useParams<{employeeId: string}>();
  const {runAsyncProcess, state} = useAsyncProcess<Employee>();

  useEffect(() => {
    runAsyncProcess(employeeApi.getWorker({worker_id: Number(employeeId!)}));
  }, [runAsyncProcess, employeeId]);

  return (
    <Page title={"Mitarbeiter"}>
      <PageContent>
        {state.isRequestFetched ? (
          <>
            <EmployeeDetailPageHeader employeeName={state.data!.name} />

            <EmployeeDetailPageInfo
              apiWorkerId={employeeId!}
              id={state.data!.worker_id}
              name={state.data!.name}
              salary={state.data?.salary}
              job={state.data!.position}
              vacation={state.data!.holidays}
              startDate={state.data!.start_date}
              expense={state.data!.daily_expense}
              endDate={state.data?.quit_date}
              note={state.data?.note || undefined}
              remaining_holidays={state.data?.remaining_holidays}
            />

            <div className={"has-space-between employee-detail-page__table-group"}>
              <EmployeeDetailPageDebt
                workerId={employeeId!}
                debtPayments={state.data!.debt_payments}
              />

              <EmployeeDetailPageDocument
                workerId={employeeId!}
                documents={state.data!.worker_documents}
              />
            </div>

            {state.data?.worktimes && (
              <EmployeeDetailPageWorking
                workerId={employeeId!}
                data={state.data.worktimes}
              />
            )}
          </>
        ) : (
          <Loading />
        )}
      </PageContent>
    </Page>
  );
}

export default EmployeeDetailPage;
