import {ReactComponent as PlusIcon} from "../../core/ui/icon/plus.svg";
import {ReactComponent as SettingsIcon} from "../../core/ui/icon/settings.svg";

import "./_tank-payment-page.scss";

import {Button} from "@hipo/react-ui-toolkit";
import {Fragment, useCallback, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";

import PageContent from "../../component/page/content/PageContent";
import Page from "../../component/page/Page";
import Table from "../../component/table/Table";
import {generateTankPaymentTableColumns} from "./util/tankPaymentPageUtils";
import Modal from "../../component/modal/Modal";
import CreatePaymentForm from "../form/create-payment/CreatePaymentForm";
import TankPaymentFilterForm from "../form/filter/TankPaymentFilterForm";
import useAsyncProcess from "../../core/network/async-process/useAsyncProcess";
import {TankPaymentFuelCard} from "../api/tankPaymentApiModel";
import tankPaymentApi from "../api/tankPaymentApi";
import {useAppContext} from "../../core/app/AppContext";
import AsyncContent, {
  AsyncContentStatus
} from "../../component/async-content/AsyncContent";
import Loading from "../../component/loading/Loading";
import Label from "../../component/label/Label";
import {filterTruthyObjectValues} from "../../core/util/object/objectUtils";
import {Ordering} from "../../core/util/ordering/orderingTypes";

type TableOrdering = "card_nr" | "firm" | "driver";

export type PaymentTableOrdering = Ordering<TableOrdering>;

function TankPaymentPage() {
  const [isCreateFormOpen, setCreateFormVisibility] = useState(false);
  const [isFilterOpen, setFilterVisibility] = useState(false);
  const {state, runAsyncProcess} = useAsyncProcess<TankPaymentFuelCard[]>();
  const [filterState, setFilterState] = useState<{
    firm?: string;
    driver?: string;
    active?: boolean;
  }>({});
  const {
    state: {ownFirm}
  } = useAppContext();
  const [ordering, setOrdering] = useState<PaymentTableOrdering["state"]>();
  const [tableColumns, setColumns] = useState(generateTankPaymentTableColumns());

  const handleOrdering = useCallback(
    (order_by: TableOrdering) => {
      setOrdering({order_by, direction: ordering?.direction === "asc" ? "des" : "asc"});
    },
    [ordering?.direction]
  );

  useEffect(() => {
    setColumns(
      generateTankPaymentTableColumns({state: ordering, onChange: handleOrdering})
    );
  }, [ordering, handleOrdering]);

  useEffect(() => {
    runAsyncProcess(
      tankPaymentApi.getFuelCards(
        filterTruthyObjectValues({
          own_firm: ownFirm,
          ...filterState,
          order_by: ordering?.order_by,
          direction: ordering?.direction
        })
      )
    );
  }, [runAsyncProcess, ownFirm, filterState, ordering?.direction, ordering?.order_by]);

  return (
    <Page title={"Tank-Zahlungsverfolgung"}>
      <PageContent>
        <AsyncContent requestStates={[state]} content={renderAsyncContent} />
      </PageContent>
    </Page>
  );

  function handleFilterSubmit({
    firm,
    driver,
    active
  }: {
    firm?: string;
    driver?: string;
    active?: boolean;
  }) {
    setFilterState({firm, driver, active});

    setFilterVisibility(false);
  }

  function renderAsyncContent(status: AsyncContentStatus) {
    let node = <Fragment />;

    switch (status) {
      case "pending":
        node = <Loading />;
        break;

      case "error":
        node = <p>{"An error ocurred on server."}</p>;
        break;

      case "success":
        node = (
          <>
            <div className={"has-space-between"}>
              <Button customClassName={"button--blue"} onClick={handleCreatePaymentClick}>
                {"Neue Tank-Karte hinzufügen"}

                <PlusIcon />
              </Button>

              <Modal isOpen={isCreateFormOpen} onClose={closeCreateForm}>
                <CreatePaymentForm onClose={closeCreateForm} />
              </Modal>

              <h2 className={"typography--h2"}>{"Tank-Zahlungsverfolgung"}</h2>

              <Button
                customClassName={"button--primary text-color--light typography--h8"}
                onClick={openFilter}>
                {"Filter"}

                <SettingsIcon />
              </Button>

              <Modal
                isOpen={isFilterOpen}
                onClose={closeFilter}
                customClassName={"filter-modal"}>
                <TankPaymentFilterForm
                  onSubmit={handleFilterSubmit}
                  onClose={closeFilter}
                />
              </Modal>
            </div>

            <div className={"tank-payment-page__table-container"}>
              <Table
                customClassName={"tank-payment-page__table"}
                data={state.data || []}
                columns={tableColumns}
                customRow={renderRow}
              />
            </div>
          </>
        );
        break;

      default:
        break;
    }

    return node;
  }

  function renderRow(rowData: TankPaymentFuelCard) {
    return (
      <NavLink
        className={"table-row tank-payment-page__table__row"}
        to={`/tank-payment/${rowData.id}`}>
        <p className={"typography--h5"}>{rowData.card_nr}</p>

        <p className={"typography--h5"}>{rowData.firm}</p>

        <p className={"typography--h5"}>
          {rowData.card_is_at === "None" ? "" : rowData.card_is_at}
        </p>

        <Label
          name={rowData.status}
          color={rowData.status === "Aktiv" ? "var(--tag-green-2)" : "var(--tag-pink-3)"}
        />
      </NavLink>
    );
  }

  function openFilter() {
    setFilterVisibility(true);
  }

  function closeFilter() {
    setFilterVisibility(false);
  }

  function handleCreatePaymentClick() {
    setCreateFormVisibility(true);
  }

  function closeCreateForm() {
    setCreateFormVisibility(false);
  }
}

export default TankPaymentPage;
