import {ReactComponent as PlusIcon} from "../../core/ui/icon/plus.svg";
import {ReactComponent as SettingsIcon} from "../../core/ui/icon/settings.svg";

import {Fragment, useCallback, useEffect, useState} from "react";
import {Button} from "@hipo/react-ui-toolkit";
import {NavLink} from "react-router-dom";

import "./_contact-page.scss";

import PageContent from "../../component/page/content/PageContent";
import Page from "../../component/page/Page";
import Table from "../../component/table/Table";
import {generateContactTableColumns} from "./util/contactPageUtils";
import Modal from "../../component/modal/Modal";
import CreateContactFrom from "../form/create-contact/CreateContactForm";
import ContactFilterForm from "../form/filter/ContactFilterForm";
import useAsyncProcess from "../../core/network/async-process/useAsyncProcess";
import contactApi from "../api/contactApi";
import {useAppContext} from "../../core/app/AppContext";
import {ContactResponse} from "../api/contactApiModels";
import AsyncContent, {
  AsyncContentStatus
} from "../../component/async-content/AsyncContent";
import Loading from "../../component/loading/Loading";
import Label from "../../component/label/Label";
import {filterTruthyObjectValues} from "../../core/util/object/objectUtils";
import {Ordering} from "../../core/util/ordering/orderingTypes";

type TableOrdering = "name" | "firm" | "phone" | "mail";

export type ContactTableOrdering = Ordering<TableOrdering>;

function ContactPage() {
  const [isCreateContactOpen, setCreateContactVisibility] = useState(false);
  const [isFilterOpen, setFilterVisibility] = useState(false);
  const {runAsyncProcess, state} = useAsyncProcess<ContactResponse[]>();
  const {
    state: {ownFirm, globalStatus}
  } = useAppContext();
  const [filterState, setFilterState] = useState({
    name: "",
    firm: "",
    label: ""
  });
  const [ordering, setOrdering] = useState<ContactTableOrdering["state"]>();
  const [tableColumns, setColumns] = useState(generateContactTableColumns());

  const handleOrdering = useCallback(
    (order_by: TableOrdering) => {
      setOrdering({order_by, direction: ordering?.direction === "asc" ? "des" : "asc"});
    },
    [ordering?.direction]
  );

  useEffect(() => {
    setColumns(generateContactTableColumns({state: ordering, onChange: handleOrdering}));
  }, [ordering, handleOrdering]);

  useEffect(() => {
    runAsyncProcess(
      contactApi.getContacts(
        filterTruthyObjectValues({
          own_firm: ownFirm,
          name: filterState.name,
          firm: filterState.firm,
          tag: filterState.label,
          order_by: ordering?.order_by,
          direction: ordering?.direction
        })
      )
    );
  }, [ownFirm, runAsyncProcess, filterState, ordering?.direction, ordering?.order_by]);

  return (
    <Page title={"Kontaktendatenbank"}>
      <PageContent>
        <AsyncContent requestStates={[state]} content={renderAsyncContent} />
      </PageContent>
    </Page>
  );

  function renderAsyncContent(status: AsyncContentStatus) {
    let node = <Fragment />;

    switch (status) {
      case "pending":
        node = <Loading />;
        break;

      case "error":
        node = <p>{"An error ocurred on server."}</p>;
        break;

      case "success":
        node = (
          <>
            <div className={"has-space-between"}>
              <Button customClassName={"button--blue"} onClick={handleCreateContactClick}>
                {"Neue Kontakt hinzufügen"}

                <PlusIcon />
              </Button>

              <Modal isOpen={isCreateContactOpen} onClose={closeCreateContact}>
                <CreateContactFrom onClose={closeCreateContact} />
              </Modal>

              <h2 className={"typography--h2"}>{"Kontaktendatenbank"}</h2>

              <Button
                onClick={openFilterForm}
                customClassName={"button--primary text-color--light typography--h8"}>
                {"Filter"}

                <SettingsIcon />
              </Button>

              <Modal
                isOpen={isFilterOpen}
                onClose={closeFilterForm}
                customClassName={"filter-modal"}>
                <ContactFilterForm
                  onSubmit={handleFilterSubmit}
                  onClose={closeFilterForm}
                />
              </Modal>
            </div>

            <div className={"contact-page__table-container"}>
              <Table
                customClassName={"contact-page__table"}
                data={state.data || []}
                columns={tableColumns}
                customRow={renderRow}
              />
            </div>
          </>
        );
        break;

      default:
        break;
    }

    return node;
  }

  function renderRow(rowData: ContactResponse) {
    return (
      <NavLink
        className={"table-row contact-page__table__row"}
        to={`/contacts/${rowData.id}`}>
        <p className={"typography--h5"}>{rowData.name}</p>

        <p className={"typography--h5"}>{rowData.firm}</p>

        <p className={"typography--h5"}>{rowData.phone}</p>

        <p className={"typography--h5"}>{rowData.mail}</p>

        {globalStatus &&
        globalStatus.contacts_tags.find((item) => item.name === rowData.tag)?.colour ? (
          <Label
            name={rowData.tag}
            color={
              globalStatus.contacts_tags.find((item) => item.name === rowData.tag)!.colour
            }
          />
        ) : (
          "-"
        )}
      </NavLink>
    );
  }

  function handleFilterSubmit(name: string, firm: string, label: string) {
    setFilterState({name, firm, label});
  }

  function openFilterForm() {
    setFilterVisibility(true);
  }

  function closeFilterForm() {
    setFilterVisibility(false);
  }

  function handleCreateContactClick() {
    setCreateContactVisibility(true);
  }

  function closeCreateContact() {
    setCreateContactVisibility(false);
  }
}

export default ContactPage;
