import {BillTableOrdering} from "../page/BillPage";

function generateBillTableColumns(ordering?: BillTableOrdering) {
  return [
    {
      id: "BillTable.id",
      header: "Rechnungsnummer",
      orderingProps: {
        isActive: ordering?.state?.order_by === "bill_nr",
        onChange: () => ordering?.onChange("bill_nr"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "BillTable.firmName",
      header: "Firmenname",
      orderingProps: {
        isActive: ordering?.state?.order_by === "firm",
        onChange: () => ordering?.onChange("firm"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "BillTable.creationDate",
      header: "Erstellungsdatum",
      orderingProps: {
        isActive: ordering?.state?.order_by === "creation_date",
        onChange: () => ordering?.onChange("creation_date"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "BillTable.expireDate",
      header: "Leistungszeitraum",
      orderingProps: {
        isActive: ordering?.state?.order_by === "has_to_be_paid_date",
        onChange: () => ordering?.onChange("has_to_be_paid_date"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "BillTable.net",
      header: "Netto",
      orderingProps: {
        isActive: ordering?.state?.order_by === "sum",
        onChange: () => ordering?.onChange("sum"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "BillTable.gross",
      header: "Brutto",
      orderingProps: {
        isActive: ordering?.state?.order_by === "end_sum",
        onChange: () => ordering?.onChange("end_sum"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "BillTable.document",
      header: "Dokument"
    }
  ];
}

export {generateBillTableColumns};
