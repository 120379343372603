import elbcargoApi from "../../core/network/elbcargo/elbcargoApi";

export type HomeAPIResponse = {
  sales: {
    total: string;
    months: {
      month: string;
      year: string;
      bills: string;
      gutschrifts: string;
      total: string;
    }[];
  };
  tours: {
    id: string;
    roller_nr: string;
    plate: string;
    drivers: {id: string; name: string}[];
    firm: string;
  }[];
  appointments: {
    vehicles: {id: string; plate: string; name: string; expiry_date: string}[];
    drivers: {
      id: string;
      driver: string;
      name: string;
      expiry_date: string;
      worker_id: string;
    }[];
  };
  payments: {
    gutschrift_total: string;
    bills_total: string;
  };
};

const homeApi = {
  getHomePage(params: {own_firm: string}) {
    return elbcargoApi.run<HomeAPIResponse>(
      {
        method: "GET",
        params
      },
      "get-homepage"
    );
  }
};

export default homeApi;
