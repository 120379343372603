import {ReactComponent as SettingsIcon} from "../../../../core/ui/icon/settings.svg";

import "./_log-tab.scss";

import {Button} from "@hipo/react-ui-toolkit";
import {Fragment, useCallback, useEffect, useState} from "react";
import {format} from "date-fns";

import Modal from "../../../../component/modal/Modal";
import Table from "../../../../component/table/Table";
import LogFilterForm from "../../../form/log-filter/LogFilterForm";
import useInterval from "../../../../core/util/hook/useInterval";
import {DATE_FORMAT, MINUTE_IN_MS} from "../../../../core/util/time/timeConstants";
import useAsyncProcess from "../../../../core/network/async-process/useAsyncProcess";
import {SettingsLog} from "../../../api/settingsApiModel";
import settingsApi from "../../../api/settingsApi";
import {useAppContext} from "../../../../core/app/AppContext";
import AsyncContent, {
  AsyncContentStatus
} from "../../../../component/async-content/AsyncContent";
import Loading from "../../../../component/loading/Loading";
import {filterTruthyObjectValues} from "../../../../core/util/object/objectUtils";
import {Ordering} from "../../../../core/util/ordering/orderingTypes";

type TableOrdering = "admin" | "date" | "firm";

export type LogTableOrdering = Ordering<TableOrdering>;

function LogTab() {
  const [isModalOpen, setModalVisibility] = useState(false);
  const {runAsyncProcess, state} = useAsyncProcess<SettingsLog[]>({
    shouldResetDataWhenPending: false
  });
  const {
    state: {ownFirm}
  } = useAppContext();
  const [ordering, setOrdering] = useState<LogTableOrdering["state"]>();
  const [tableColumns, setColumns] = useState(generateLogTableColumns());

  const fetchLogs = useCallback(() => {
    runAsyncProcess(
      settingsApi.getLogs(
        filterTruthyObjectValues({
          own_firm: ownFirm,
          order_by: ordering?.order_by,
          direction: ordering?.direction
        })
      )
    );
  }, [runAsyncProcess, ownFirm, ordering?.direction, ordering?.order_by]);

  const handleOrdering = useCallback(
    (order_by: TableOrdering) => {
      fetchLogs();
      setOrdering({order_by, direction: ordering?.direction === "asc" ? "des" : "asc"});
    },
    [ordering?.direction, fetchLogs]
  );

  useEffect(() => {
    setColumns(generateLogTableColumns({state: ordering, onChange: handleOrdering}));
  }, [ordering, handleOrdering]);

  useInterval(fetchLogs, MINUTE_IN_MS);

  return (
    <div className={"company-tab"}>
      <AsyncContent requestStates={[state]} content={renderAsyncContent} />
    </div>
  );

  function renderAsyncContent(status: AsyncContentStatus) {
    let node = <Fragment />;

    switch (status) {
      case "pending":
        node = <Loading />;
        break;

      case "error":
        node = <p>{"An error ocurred on server."}</p>;
        break;

      case "success":
        node = (
          <>
            <Button
              customClassName={"button--primary company-tab__cta"}
              onClick={handleClick}
              style={{marginLeft: "auto", marginRight: "40px"}}>
              <SettingsIcon />

              {"Filter"}
            </Button>

            <Modal
              isOpen={isModalOpen}
              onClose={closeModal}
              customClassName={"filter-modal"}>
              <LogFilterForm onClose={closeModal} onSubmit={handleFilterSubmit} />
            </Modal>

            <Table
              data={state.data || []}
              columns={tableColumns}
              customRow={renderRow}
              customClassName={"log-tab__table"}
            />
          </>
        );
        break;

      default:
        break;
    }

    return node;
  }

  function handleFilterSubmit(filter: {
    name?: string;
    start: Date | null;
    end: Date | null;
  }) {
    runAsyncProcess(
      settingsApi.getLogs(
        filterTruthyObjectValues({
          own_firm: ownFirm,
          admin: filter.name,
          start_date: filter.start ? format(filter.start, DATE_FORMAT.API) : undefined,
          end_date: filter.end ? format(filter.end, DATE_FORMAT.API) : undefined
        })
      )
    );

    closeModal();
  }

  function closeModal() {
    setModalVisibility(false);
  }

  function handleClick() {
    setModalVisibility(true);
  }

  function renderRow(rowData: SettingsLog) {
    return (
      <div className={"table-row"}>
        <p className={"typography--h5"}>{rowData.admin}</p>

        <p className={"typography--h5"}>{rowData.date}</p>

        <p className={"typography--h5"}>{rowData.event}</p>
      </div>
    );
  }
}

function generateLogTableColumns(ordering?: LogTableOrdering) {
  return [
    {
      id: "LogTable.name",
      header: "Name",
      orderingProps: {
        isActive: ordering?.state?.order_by === "admin",
        onChange: () => ordering?.onChange("admin"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "LogTable.date",
      header: "Datum",
      orderingProps: {
        isActive: ordering?.state?.order_by === "date",
        onChange: () => ordering?.onChange("date"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "LogTable.event",
      header: "Event",
      orderingProps: {
        isActive: ordering?.state?.order_by === "firm",
        onChange: () => ordering?.onChange("firm"),
        direction: ordering?.state?.direction
      }
    }
  ];
}

export default LogTab;
