const SECOND_IN_MS = 1000;
const MINUTE_IN_S = 60;
const MINUTE_IN_MS = MINUTE_IN_S * SECOND_IN_MS;
const HOUR_IN_MINUTES = 60;
const DAY_IN_HOURS = 24;
const WEEK_IN_DAYS = 7;

const DATE_FORMAT = {DEFAULT: "dd/MM/yyyy", API: "yyyy-MM-dd"};

const MONTH_NAMES = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember"
];

export {
  SECOND_IN_MS,
  MINUTE_IN_MS,
  HOUR_IN_MINUTES,
  DAY_IN_HOURS,
  WEEK_IN_DAYS,
  DATE_FORMAT,
  MONTH_NAMES
};
