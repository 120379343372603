/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import {ReactComponent as PlusButtonIcon} from "../../../core/ui/icon/plus-button.svg";
import {ReactComponent as TrashIcon} from "../../../core/ui/icon/24px/trash_24x24.svg";

import "./_edit-tour-form.scss";

import {SyntheticEvent, useEffect, useState} from "react";
import {
  FormField,
  Textarea,
  DropdownOption,
  TypeaheadSelect,
  Button
} from "@hipo/react-ui-toolkit";
import {format} from "date-fns";
import axios from "axios";

import Form from "../../../component/form/Form";
import DateInput from "../../../component/input/date/DateInput";
import employeeApi from "../../../employee/api/employeeApi";
import truckApi from "../../../truck/api/truckApi";
import {useAppContext} from "../../../core/app/AppContext";
import {DATE_FORMAT} from "../../../core/util/time/timeConstants";
import LabelToggle from "../../../component/label/toggle/LabelToggle";
import {BASE_URL} from "../../../core/network/util/networkConstants";
import Modal from "../../../component/modal/Modal";
import DeleteModal from "../../../component/delete-modal/DeleteModal";
import {getDatesInRange} from "../../../core/util/time/timeUtils";

interface EditTourFormProps {
  roller_nr: string;
  defaultDriver: null | {id: string; name: string};
  defaultTruck: null | {id: string; name: string};
  selectionRange: {
    id: string;
    date: Date;
    status: string;
    driverNames: string[];
    driverIds: string[];
    daily_note: string;
    vehicle: string;
  }[];
  onCancel: VoidFunction;
}

function EditTourForm({
  roller_nr,
  selectionRange,
  onCancel,
  defaultDriver,
  defaultTruck
}: EditTourFormProps) {
  const [driverOptions, setDriverOptions] = useState<DropdownOption[]>();
  const [truckOptions, setTruckOptions] = useState<DropdownOption[]>();
  const [isDeleteModalOpen, setDeleteModalVisibility] = useState(false);
  const [isSingleDay, setSingleDay] = useState(selectionRange.length === 1);
  const [formState, setFormState] = useState<{
    start: Date;
    end: Date | null;
    label: string;
    driverList: DropdownOption[];
    truck: DropdownOption[];
    note: string;
  }>({
    start: selectionRange[0].date,
    end:
      selectionRange.length > 1 ? selectionRange[selectionRange.length - 1].date : null,
    label: selectionRange.every((item) => item.status === selectionRange[0].status)
      ? selectionRange[0].status
      : "",
    driverList:
      selectionRange[0] &&
      selectionRange[0].driverIds[0] &&
      selectionRange[0].driverIds[0].length > 0
        ? selectionRange[0].driverIds.map((item, index) => ({
            id: item,
            title: selectionRange[0].driverNames[index]
          }))
        : defaultDriver?.id
        ? [{id: defaultDriver?.id, title: defaultDriver?.name}]
        : [],
    truck:
      selectionRange[0].vehicle.length > 0
        ? [{id: selectionRange[0].vehicle, title: selectionRange[0].vehicle}]
        : defaultTruck?.id
        ? [{id: defaultTruck?.id, title: defaultTruck?.name}]
        : [],
    note: isSingleDay ? selectionRange[0].daily_note : ""
  });
  const {
    state: {ownFirm, globalStatus, user}
  } = useAppContext();

  useEffect(() => {
    employeeApi.getDrivers({own_firm: ownFirm}).then((response) => {
      setDriverOptions(
        response.map((option) => ({
          id: String(option.id),
          title: `${option.name}`
        }))
      );
    });

    truckApi
      .getTrucks({
        own_firm: ownFirm
      })
      .then((response) => {
        setTruckOptions(
          response.map((option) => ({
            id: String(option.plate),
            title: option.plate
          }))
        );
      });
  }, [ownFirm]);

  return (
    <Form customClassName={"edit-tour-form"}>
      {selectionRange.every((item) => item.status) && (
        <>
          <Button
            onClick={() => setDeleteModalVisibility(true)}
            style={{marginBottom: "8px", paddingLeft: "0"}}>
            <TrashIcon />
          </Button>

          <Modal
            isOpen={isDeleteModalOpen}
            onClose={() => setDeleteModalVisibility(false)}>
            <DeleteModal
              text={roller_nr}
              onSubmit={handleDelete}
              onClose={() => setDeleteModalVisibility(false)}
            />
          </Modal>
        </>
      )}

      <FormField
        label={"Datum:"}
        labelFor={"start-date"}
        customClassName={"edit-tour-form__date-range"}>
        <DateInput
          name={"start-date"}
          selected={formState.start}
          onChange={handleStartDateChange}
          isIconed={false}
          focusSelectedMonth={false}
          customClassName={"edit-tour-form__date"}
        />

        {"-"}

        {isSingleDay ? (
          <Button
            customClassName={"edit-tour-form__plus-button"}
            onClick={handleMultipleDayClick}>
            <PlusButtonIcon />
          </Button>
        ) : (
          <DateInput
            name={"end-date"}
            selected={formState.end}
            onChange={handleEndDateChange}
            isIconed={false}
            customClassName={"edit-tour-form__date"}
          />
        )}
      </FormField>

      {globalStatus && (
        <FormField
          label={"Etikett:"}
          labelFor={"label"}
          customClassName={"edit-tour-form__form-field--label"}>
          <LabelToggle
            onToggle={handleLabelToggle}
            items={globalStatus.tour_tags}
            selectedItem={formState.label}
          />
        </FormField>
      )}

      <FormField
        label={"Fahrer:"}
        labelFor={"driver-list"}
        customClassName={"edit-tour-form__form-field--label"}>
        <TypeaheadSelect
          dropdownOptions={driverOptions || []}
          selectedOptions={formState.driverList}
          onSelect={handleSelectDriver}
          customClassName={"edit-tour-form__driver-typeahead"}
          typeaheadProps={{
            placeholder: formState.driverList.length === 0 ? "Suche" : "",
            name: "driver-list"
          }}
          onTagRemove={handleRemoveDriver}
        />
      </FormField>

      <FormField
        label={"LKW:"}
        labelFor={"truck"}
        customClassName={"edit-tour-form__form-field--label"}>
        <TypeaheadSelect
          dropdownOptions={truckOptions || []}
          selectedOptions={formState.truck}
          onSelect={handleSelectTruck}
          selectedOptionLimit={1}
          typeaheadProps={{
            placeholder: "Suche",
            name: "truck-list"
          }}
          onTagRemove={handleRemoveTruck}
        />
      </FormField>

      {isSingleDay && (
        <FormField
          label={"Notizen:"}
          labelFor={"day-note"}
          customClassName={
            "edit-tour-form__form-field--label edit-tour-form__form-field--label-note"
          }>
          <Textarea
            name={"day-note"}
            onChange={handleNoteChange}
            value={formState.note}
            customClassNames={{textarea: "create-tour-form__note-textarea"}}
          />
        </FormField>
      )}

      <div className={"create-tour-form__button-group"}>
        <Button
          onClick={handleCancelButton}
          customClassName={"button--transparent button--large"}>
          {"Abbrechen"}
        </Button>

        <Button
          onClick={handleSubmit}
          customClassName={"button--primary button--large"}
          isDisabled={
            !(
              formState.label?.length &&
              formState.driverList.length &&
              formState.truck.length
            )
          }
          type={"submit"}>
          {selectionRange[0].status ? "Speichern" : "Hinzufügen"}
        </Button>
      </div>
    </Form>
  );

  async function handleDelete() {
    const formData = new FormData();

    formData.append("tour_days", String(selectionRange.map((item) => item.id)));
    formData.append("admin", user!.hash);

    await axios({
      method: "POST",
      url: `${BASE_URL}delete-tour-days`,
      data: formData
    });

    location.reload();
  }

  function handleStartDateChange(date: Date) {
    setFormState({
      ...formState,
      start: date
    });
  }

  function handleMultipleDayClick() {
    setSingleDay(false);
  }

  function handleEndDateChange(date: Date) {
    setFormState({
      ...formState,
      end: date
    });
  }

  function handleSelectDriver(option: DropdownOption) {
    setFormState({
      ...formState,
      driverList: [...formState.driverList, option]
    });

    const typeahead = document.getElementsByClassName(
      "edit-tour-form__driver-typeahead"
    )[0] as HTMLElement;
    const dropdown = typeahead.getElementsByClassName("dropdown-list")[0];

    dropdown.classList.remove("dropdown-list--is-visible");
  }

  function handleRemoveDriver(option: DropdownOption) {
    setFormState({
      ...formState,
      driverList: formState.driverList.filter((driver) => driver !== option)
    });
  }

  function handleLabelToggle(selectedLabel: string) {
    setFormState({
      ...formState,
      label: selectedLabel
    });
  }

  function handleNoteChange(event: SyntheticEvent<HTMLTextAreaElement>) {
    setFormState({
      ...formState,
      note: event.currentTarget.value
    });
  }

  function handleSelectTruck(option: DropdownOption) {
    setFormState({
      ...formState,
      truck: [option]
    });
  }

  function handleRemoveTruck() {
    setFormState({
      ...formState,
      truck: []
    });
  }

  function handleCancelButton() {
    onCancel();
  }

  async function handleSubmit() {
    const formData = new FormData();

    formData.append("own_firm", ownFirm);
    formData.append("roller_nr", roller_nr);
    formData.append("status", formState.label);
    formData.append("admin", user!.hash);
    formData.append(
      "dates",
      formState.end
        ? String(
            getDatesInRange(formState.start, formState.end).map((item) =>
              format(new Date(item), DATE_FORMAT.API)
            )
          )
        : String(format(formState.start, DATE_FORMAT.API))
    );
    formData.append(
      "driver_list",
      String(formState.driverList.map((item) => item.id).join(", "))
    );
    formData.append("vehicle", formState.truck[0].title);
    if (formState.note) formData.append("note", formState.note);

    await axios({
      method: "POST",
      url: `${BASE_URL}create-tour-days`,
      data: formData
    });

    location.reload();
  }
}

export default EditTourForm;
