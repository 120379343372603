import classNames from "classnames";

interface ScheduleProps {
  children: React.ReactNode;
  customClassName?: string;
}

function Schedule({children, customClassName}: ScheduleProps) {
  return <div className={classNames("schedule", customClassName)}>{children}</div>;
}

export default Schedule;
