import elbcargoApi from "../../core/network/elbcargo/elbcargoApi";
import {SingleTankPaymentFuelCard, TankPaymentFuelCard} from "./tankPaymentApiModel";

const tankPaymentApi = {
  getFuelCards(params: {
    own_firm: string;
    firm?: string;
    driver?: string;
    active?: boolean;
    order_by?: string;
    direction?: string;
  }) {
    return elbcargoApi.run<TankPaymentFuelCard[]>(
      {
        method: "GET",
        params
      },
      "get-fuelcards"
    );
  },
  getFuelCard(params: {card_id: string; order_by?: string; direction?: string}) {
    return elbcargoApi.run<SingleTankPaymentFuelCard>(
      {
        method: "GET",
        params
      },
      "get-fuelcard"
    );
  }
};

export default tankPaymentApi;
