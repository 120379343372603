import classNames from "classnames";
import {Link, useLocation, useNavigate} from "react-router-dom";

import "./_page-navigation-item.scss";

interface PageNavigationItemProps {
  route: string;
  name: string;
}

function PageNavigationItem({route, name}: PageNavigationItemProps) {
  const location = useLocation();
  const rootPath = location.pathname.split("/");
  const isActive = `/${rootPath[1]}`.includes(route.split("/")[1] || "/");
  const navigate = useNavigate();

  return (
    <Link
      to={route}
      onClick={handleClick}
      className={classNames("typography--h4 page-navigation-item", {
        "typography--h6 page-navigation-item--is-active": isActive
      })}>
      {name}
    </Link>
  );

  function handleClick() {
    if (route === location.pathname) {
      window.location.reload();
    } else {
      navigate(route);
    }
  }
}

export default PageNavigationItem;
