/* eslint-disable no-negated-condition */
/* eslint-disable no-nested-ternary */
import "./_create-employee-document-form.scss";

import {Button, FormField, Input} from "@hipo/react-ui-toolkit";
import {useState} from "react";
import {FileUploader} from "react-drag-drop-files";

import Form from "../../../../../component/form/Form";
import DateInput from "../../../../../component/input/date/DateInput";

interface CreateEmployeeDocumentFormProps {
  onSubmit: (data: {
    name: string;
    date: null | Date;
    file: null | File;
    done?: boolean;
    delete_file?: boolean;
  }) => void;
  data: {
    id: string;
    name: string;
    expiry_date: null | Date;
    done: boolean;
    file_url: null | string | File;
  };
  onClose: VoidFunction;
}

function CreateEmployeeDocumentForm({
  onSubmit,
  data,
  onClose
}: CreateEmployeeDocumentFormProps) {
  const [formState, setFormState] = useState(
    data || {name: "", expiry_date: null, file_url: null}
  );
  const [isPending, setPending] = useState(false);
  const [isDeletedFile, setDeletedFile] = useState(false);

  return (
    <div>
      <h3 className={"typography--h3"} style={{marginBottom: "24px"}}>
        {"Neues Dokument"}
      </h3>

      <Form customClassName={"create-employee-document-form"}>
        <FormField label={"Dokumentname:"} labelFor={"name"}>
          <Input
            id={"name"}
            name={"name"}
            value={formState?.name}
            onChange={(event) =>
              setFormState({...formState, name: event.currentTarget.value})
            }
          />
        </FormField>

        <FormField label={"Verfallsdatum"} labelFor={"date"}>
          <DateInput
            name={"date"}
            onChange={(selectedDate) =>
              setFormState({...formState, expiry_date: selectedDate || new Date()})
            }
            selected={formState.expiry_date || null}
          />
        </FormField>

        <div className={"edit-truck-payment-form__radio-button-group"}>
          <label
            className={"edit-truck-payment-form__radio-button-container"}
            htmlFor={"done"}>
            <input
              id={"done"}
              name={"payment-method"}
              type={"radio"}
              value={"done"}
              checked={formState.done}
              className={"edit-truck-payment-form__radio-button__input"}
              onChange={() => setFormState({...formState, done: true})}
            />

            <p>{"Erledigt"}</p>
          </label>

          <label
            className={"edit-truck-payment-form__radio-button-container"}
            htmlFor={"note-done"}>
            <input
              id={"note-done"}
              name={"payment-method"}
              type={"radio"}
              checked={!formState.done}
              value={"installment-payment"}
              className={"edit-truck-payment-form__radio-button__input"}
              onChange={() => setFormState({...formState, done: false})}
            />

            <p>{"nicht Erledigt"}</p>
          </label>
        </div>

        <FormField label={"Dokument hochladen"} labelFor={"document"}>
          {typeof formState.file_url === "string" ? (
            <div style={{display: "flex", alignItems: "center"}}>
              <a
                className={"typography--b3"}
                href={formState.file_url}
                target={"_blank"}
                rel={"noopener noreferrer"}>
                {"Dokument"}
              </a>

              <Button
                onClick={() => {
                  setDeletedFile(true);
                  setFormState({...formState, file_url: null});
                }}>
                {"x"}
              </Button>
            </div>
          ) : !isDeletedFile ? (
            <>
              <FileUploader
                name={"document"}
                multiple={false}
                file={formState.file_url}
                handleChange={(file: File) =>
                  setFormState({...formState, file_url: file})
                }
                classes={"typography--h2 create-truck-document-form__drag-drop"}
              />

              <p className={"typography--h8 create-truck-document-form__drag-drop-files"}>
                {formState?.file_url
                  ? `File name: ${(formState.file_url as File).name}`
                  : "No files uploaded yet"}
              </p>
            </>
          ) : (
            <div>{"Will be deleted after submission"}</div>
          )}
        </FormField>

        <div className={"form__button-group"}>
          <Button customClassName={"button--transparent button--large"} onClick={onClose}>
            {"Abbrechen"}
          </Button>

          <Button
            isDisabled={
              !(formState.name && (formState.file_url || formState.expiry_date))
            }
            customClassName={"button--primary button--large"}
            shouldDisplaySpinner={isPending}
            type={"submit"}
            onClick={handleSubmit}>
            {"Hinzufügen"}
          </Button>
        </div>
      </Form>
    </div>
  );

  function handleSubmit() {
    setPending(true);
    onSubmit({
      name: formState.name,
      date: formState.expiry_date,
      file: formState.file_url as File,
      done: formState.done,
      delete_file: isDeletedFile
    });
  }
}

export default CreateEmployeeDocumentForm;
