import {ReactComponent as CalendarIcon} from "../../../core/ui/icon/calendar.svg";

import "./_date-input.scss";

import classNames from "classnames";
import DatePicker, {ReactDatePickerProps, registerLocale} from "react-datepicker";
import {Button} from "@hipo/react-ui-toolkit";
import de from "date-fns/locale/de";

registerLocale("de", de);
interface DateInputProps extends ReactDatePickerProps {
  customClassName?: string;
  isIconed?: boolean;
}

function DateInput({isIconed = true, customClassName, ...rest}: DateInputProps) {
  // eslint-disable-next-line no-magic-numbers
  const id = String(Math.floor(Math.random() * 100000000));

  return (
    <div id={id} className={"date-input-container"}>
      <DatePicker
        {...rest}
        locale={"de"}
        dateFormat={["dd/MM/yyyy", "dd.MM.yyyy"]}
        className={classNames("date-input", customClassName)}
        autoComplete={"off"}
      />

      {isIconed && (
        <Button customClassName={"date-input-button"} onClick={handleClick}>
          <CalendarIcon />
        </Button>
      )}
    </div>
  );

  function handleClick() {
    const pickerContainer = document.getElementById(id) as HTMLElement;
    const picker = pickerContainer.querySelector(".date-input") as HTMLElement;

    picker.focus();
  }
}

export default DateInput;
