import {ReactComponent as PlusIcon} from "../../../core/ui/icon/plus.svg";
import {ReactComponent as ArrowLeftIcon} from "../../../core/ui/icon/arrow-left.svg";
import {ReactComponent as ArrowRightIcon} from "../../../core/ui/icon/arrow-right.svg";
import {ReactComponent as SettingsIcon} from "../../../core/ui/icon/settings.svg";

import "./_tour-page-header.scss";

import {Button, DropdownOption} from "@hipo/react-ui-toolkit";
import {useState} from "react";

import Modal from "../../../component/modal/Modal";
import CreateTourForm from "../../form/create/CreateTourForm";
import {MONTH_NAMES} from "../../../core/util/time/timeConstants";
import TourFilterForm from "../../form/filter/TourFilterForm";

interface TourPageHeaderProps {
  currentDate: Date;
  onNextMonthClick: VoidFunction;
  onPrevMonthClick: VoidFunction;
  onFilterSubmit: ({
    firmList,
    driverList,
    label
  }: {
    firmList: DropdownOption[];
    driverList: DropdownOption[];
    label: string;
  }) => void;
}

function TourPageHeader({
  currentDate,
  onNextMonthClick,
  onPrevMonthClick,
  onFilterSubmit
}: TourPageHeaderProps) {
  const [isModalOpen, setModalVisibility] = useState({
    create: false,
    filter: false
  });

  return (
    <div className={"tour-page-header"}>
      <Button customClassName={"button--blue"} onClick={openCreateModalForm}>
        {"Neue Tour hinzufügen"}

        <PlusIcon />
      </Button>

      <Modal
        isOpen={isModalOpen.create}
        onClose={handleModalClose}
        customClassName={"schedule-row__form-modal"}>
        <CreateTourForm onCancel={handleModalClose} />
      </Modal>

      <div className={"tour-page-header__month-container"}>
        <Button
          customClassName={"tour-page-header__month-button"}
          onClick={handlePrevClick}>
          <ArrowLeftIcon />
        </Button>

        <p>{`${MONTH_NAMES[currentDate.getMonth()]}, ${currentDate.getFullYear()}`}</p>

        <Button
          customClassName={"tour-page-header__month-button"}
          onClick={handleNextClick}>
          <ArrowRightIcon />
        </Button>
      </div>

      <div className={"tour-page-header__filter"}>
        <Button
          customClassName={"button--primary text-color--light typography--h8"}
          onClick={openFilterForm}>
          {"Filter"}

          <SettingsIcon />
        </Button>

        <Modal isOpen={isModalOpen.filter} onClose={closeFilterForm}>
          <TourFilterForm onClose={closeFilterForm} onSubmit={onFilterSubmit} />
        </Modal>
      </div>
    </div>
  );

  function handleNextClick() {
    onNextMonthClick();
  }

  function handlePrevClick() {
    onPrevMonthClick();
  }

  function openFilterForm() {
    setModalVisibility({
      ...isModalOpen,
      filter: true
    });
  }

  function closeFilterForm() {
    setModalVisibility({
      ...isModalOpen,
      filter: false
    });
  }

  function openCreateModalForm() {
    setModalVisibility({
      ...isModalOpen,
      create: true
    });
  }

  function handleModalClose() {
    setModalVisibility({
      ...isModalOpen,
      create: false
    });
  }
}

export default TourPageHeader;
