import "./_bill-filter-form.scss";

import {useState} from "react";
import {
  Button,
  DropdownOption,
  FormField,
  Input,
  TypeaheadSelect
} from "@hipo/react-ui-toolkit";

import {useAppContext} from "../../../core/app/AppContext";
import DateInput from "../../../component/input/date/DateInput";

interface BillFilterFormProps {
  onSubmit: ({
    firm,
    nettoStart,
    nettoEnd,
    brutto_start,
    brutto_end,
    created_date_start,
    created_date_end
  }: {
    firm: string;
    nettoStart: string;
    nettoEnd: string;
    brutto_start: string;
    brutto_end: string;
    created_date_start: null | Date;
    created_date_end: null | Date;
  }) => void;
  onClose: VoidFunction;
}

function BillFilterForm({onSubmit, onClose}: BillFilterFormProps) {
  const [formState, setFormState] = useState<{
    firmList: DropdownOption[];
    nettoStart: string;
    nettoEnd: string;
    brutto_start: string;
    brutto_end: string;
    created_date_start: null | Date;
    created_date_end: null | Date;
  }>({
    firmList: [],
    nettoStart: "",
    nettoEnd: "",
    brutto_start: "",
    brutto_end: "",
    created_date_start: null,
    created_date_end: null
  });
  const {
    state: {globalFirms}
  } = useAppContext();
  const firmDropdownOptions: DropdownOption[] = globalFirms
    ? globalFirms?.tour_firms.map(
        (firm) => ({id: firm.name, title: firm.name} as DropdownOption)
      )
    : [];

  return (
    <form className={"bill-filter-form"}>
      <FormField label={"Firmenname"} labelFor={"firmName"}>
        <TypeaheadSelect
          dropdownOptions={firmDropdownOptions}
          selectedOptions={formState.firmList}
          selectedOptionLimit={1}
          customClassName={""}
          onSelect={handleSelectFirm}
          typeaheadProps={{
            placeholder: "Suche",
            name: "firm-list"
          }}
          onTagRemove={handleRemoveFirm}
        />
      </FormField>

      <FormField label={"Netto"} labelFor={"net"}>
        <div className={"bill-filter-form__range-container"}>
          <Input
            name={"net-start"}
            leftIcon={"€"}
            onChange={(event) =>
              setFormState({...formState, nettoStart: event.currentTarget.value})
            }
            value={formState.nettoStart}
          />

          <span className={"typography--h1"}>{"-"}</span>

          <Input
            name={"net-end"}
            onChange={(event) =>
              setFormState({...formState, nettoEnd: event.currentTarget.value})
            }
            value={formState.nettoEnd}
          />
        </div>
      </FormField>

      <FormField label={"Brutto"} labelFor={"gross"}>
        <div className={"bill-filter-form__range-container"}>
          <Input
            name={"gross-start"}
            leftIcon={"€"}
            onChange={(event) =>
              setFormState({...formState, brutto_start: event.currentTarget.value})
            }
            value={formState.brutto_start}
          />

          <span className={"typography--h1"}>{"-"}</span>

          <Input
            name={"gross-end"}
            onChange={(event) =>
              setFormState({...formState, brutto_end: event.currentTarget.value})
            }
            value={formState.brutto_end}
          />
        </div>
      </FormField>

      <FormField label={"Erstellungsdatum"}>
        <DateInput
          selected={formState.created_date_start}
          placeholderText={"von"}
          onChange={(date) => setFormState({...formState, created_date_start: date})}
        />

        <DateInput
          selected={formState.created_date_end}
          placeholderText={"bis"}
          onChange={(date) => setFormState({...formState, created_date_end: date})}
        />
      </FormField>

      <div className={"bill-filter-form__button-group"}>
        <Button onClick={onClose} customClassName={"button--transparent"}>
          {"Abbrechen"}
        </Button>

        <Button
          onClick={handleSubmit}
          customClassName={"button--primary"}
          type={"submit"}>
          {"Filtern"}
        </Button>
      </div>
    </form>
  );

  function handleSubmit() {
    onSubmit({
      firm: formState.firmList[0] ? formState.firmList[0].id : "",
      nettoStart: formState.nettoStart,
      nettoEnd: formState.nettoEnd,
      brutto_start: formState.brutto_start,
      brutto_end: formState.brutto_end,
      created_date_start: formState.created_date_start,
      created_date_end: formState.created_date_end
    });
  }

  function handleSelectFirm(option: DropdownOption) {
    setFormState({
      ...formState,
      firmList: [...formState.firmList, option]
    });
  }

  function handleRemoveFirm(option: DropdownOption) {
    setFormState({
      ...formState,
      firmList: formState.firmList.filter((item) => item.id !== option.id)
    });
  }
}

export default BillFilterForm;
