import "./_label.scss";

interface LabelProps {
  name: string;
  color: string;
}

function Label({name, color}: LabelProps) {
  return (
    <div className={"label"} style={{backgroundColor: color}}>
      {name}
    </div>
  );
}

export default Label;
