import "./_credit-form.scss";

import {Button, FormField, Input} from "@hipo/react-ui-toolkit";
import {useState} from "react";

import DateInput from "../../../../component/input/date/DateInput";

interface CreditFormProps {
  onClose: VoidFunction;
  onSubmit: (data: {amount: string; date: null | Date}) => void;
}

function CreditForm({onClose, onSubmit}: CreditFormProps) {
  const [formState, setFormState] = useState<{amount: string; date: null | Date}>({
    amount: "",
    date: null
  });

  return (
    <div>
      <h3 className={"typography--h3"}>{"Neue Zahlung"}</h3>

      <form className={"credit-form"}>
        <FormField label={"Betrag"} labelFor={"amount"}>
          <Input
            name={"amount"}
            leftIcon={"€"}
            onChange={(event) =>
              setFormState({...formState, amount: event.currentTarget.value})
            }
            value={formState?.amount}
          />
        </FormField>

        <FormField label={"Datum"} labelFor={"date"}>
          <DateInput
            name={"date"}
            onChange={(date) => setFormState({...formState, date})}
            selected={formState?.date}
          />
        </FormField>

        <div className={"form__button-group"}>
          <Button customClassName={"button--transparent button--large"} onClick={onClose}>
            {"Abbrechen"}
          </Button>

          <Button
            isDisabled={!(formState.amount && formState.date)}
            onClick={handleSubmit}
            customClassName={"button--primary button--large"}
            type={"submit"}>
            {"Hinzufügen"}
          </Button>
        </div>
      </form>
    </div>
  );

  function handleSubmit() {
    onSubmit(formState);
  }
}

export default CreditForm;
