import elbcargoApi from "../../core/network/elbcargo/elbcargoApi";
import {Bill} from "./billApiModel";

const billApi = {
  getBills(params: {
    own_firm: string;
    firm?: string;
    netto_start?: string;
    netto_end?: string;
    brutto_start?: string;
    brutto_end?: string;
    created_date_start?: string;
    created_date_end?: string;
    order_by?: string;
    direction?: string;
  }) {
    return elbcargoApi.run<Bill[]>(
      {
        method: "GET",
        params
      },
      "get-bills"
    );
  }
};

export default billApi;
