import {ReactComponent as OrderingIcon} from "../../../../core/ui/icon/ordering.svg";

import "./_table-header-item.scss";

import {Button} from "@hipo/react-ui-toolkit";
import classNames from "classnames";
import {Link} from "react-router-dom";

import {TableColumn} from "../../Table";

interface TableHeaderItemProps<TableData extends {id: string}> {
  item: TableColumn<TableData>;
}

function TableHeaderItem<TableData extends {id: string}>({
  item
}: TableHeaderItemProps<TableData>) {
  const tableHeaderItemClassNames = classNames(
    "text-color--secondary",
    "typography--h4",
    "table-header-item",
    `table-header-item--${item.id}`,
    {
      "typography--overline": !item.orderingProps?.isActive,
      "typogprahy--button": item.orderingProps?.isActive,
      "is-aligned-right": item.alignment === "right",
      "table-header-item--is-active": item.orderingProps?.isActive,
      [`table-header-item--direction--${item.orderingProps?.direction}`]:
        item.orderingProps?.direction
    }
  );

  return item.link ? (
    <Link
      key={`table-header-item--${item.id}`}
      to={item.link}
      className={tableHeaderItemClassNames}>
      {item.header}
    </Link>
  ) : (
    <div key={`table-header-item--${item.id}`} className={tableHeaderItemClassNames}>
      {item.orderingProps?.onChange ? (
        <Button
          key={`table-header-item--${item.id}`}
          onClick={item.orderingProps?.onChange}
          customClassName={classNames(
            tableHeaderItemClassNames,
            "table-header-item__button"
          )}>
          {item.header}
          <OrderingIcon />
        </Button>
      ) : (
        item.header
      )}
    </div>
  );
}

export default TableHeaderItem;
