import "./_credit-filter-form.scss";

import {Button, FormField, Input} from "@hipo/react-ui-toolkit";
import {useState} from "react";

interface CreditFilterFormProps {
  onClose: VoidFunction;
  onSubmit: (data: {
    firmName?: string;
    avis?: string;
    docNr?: string;
    paidStart?: string;
    paidEnd?: string;
    debtStart?: string;
    debtEnd?: string;
  }) => void;
}

function CreditFilterForm({onSubmit, onClose}: CreditFilterFormProps) {
  const [formState, setFormState] = useState({
    firmName: "",
    avis: "",
    docNr: "",
    paidStart: "",
    paidEnd: "",
    debtStart: "",
    debtEnd: ""
  });

  return (
    <form className={"credit-filter-form"}>
      <FormField label={"Firmenname"} labelFor={"firm-name"}>
        <Input
          name={"firm-name"}
          onChange={(event) =>
            setFormState({...formState, firmName: event.currentTarget.value})
          }
          value={formState.firmName}
        />
      </FormField>

      <FormField label={"Avis Nummer"} labelFor={"avis"}>
        <Input
          name={"avis"}
          onChange={(event) =>
            setFormState({...formState, avis: event.currentTarget.value})
          }
          value={formState.avis}
        />
      </FormField>

      <FormField label={"Dokumentnummer"} labelFor={"doc"}>
        <Input
          name={"doc"}
          onChange={(event) =>
            setFormState({...formState, docNr: event.currentTarget.value})
          }
          value={formState.docNr}
        />
      </FormField>

      <FormField label={"Ausgezahlt"} labelFor={"doc"}>
        <div className={"credit-filter-form__double-input"}>
          <Input
            leftIcon={"€"}
            name={"doc"}
            onChange={(event) =>
              setFormState({...formState, paidStart: event.currentTarget.value})
            }
            value={formState.paidStart}
          />

          {"-"}

          <Input
            name={"doc"}
            onChange={(event) =>
              setFormState({...formState, paidEnd: event.currentTarget.value})
            }
            value={formState.paidEnd}
          />
        </div>
      </FormField>

      <FormField label={"Offen"} labelFor={"doc"}>
        <div className={"credit-filter-form__double-input"}>
          <Input
            name={"doc"}
            leftIcon={"€"}
            onChange={(event) =>
              setFormState({...formState, debtStart: event.currentTarget.value})
            }
            value={formState.debtStart}
          />

          {"-"}

          <Input
            name={"doc"}
            onChange={(event) =>
              setFormState({...formState, debtEnd: event.currentTarget.value})
            }
            value={formState.debtEnd}
          />
        </div>
      </FormField>

      <div className={"form__button-group"}>
        <Button customClassName={"button--transparent button--large"} onClick={onClose}>
          {"Abbrechen"}
        </Button>

        <Button
          onClick={handleSubmit}
          customClassName={"button--primary button--large"}
          type={"submit"}>
          {"Filtern"}
        </Button>
      </div>
    </form>
  );

  function handleSubmit() {
    onSubmit(formState);
  }
}

export default CreditFilterForm;
