import "./_settings-page.scss";

import {Link} from "react-router-dom";
import React from "react";
import classNames from "classnames";

import PageContent from "../../component/page/content/PageContent";
import Page from "../../component/page/Page";
import ROUTES from "../../core/route/routes";

const tabItems = [
  {id: "tag", content: "Etikett", to: ROUTES.SETTINGS_TAG},
  {id: "firm", content: "Kunden", to: ROUTES.SETTINGS_FIRM},
  {id: "admin", content: "Admin", to: ROUTES.SETTINGS_ADMIN},
  {id: "log", content: "Logs", to: ROUTES.SETTINGS_LOG}
];

function SettingsPage({children}: {children: React.ReactNode}) {
  return (
    <Page title={"Einstellungen"}>
      <PageContent>
        <h2 className={"typography--h2 settings-page__title"}>{"Einstellungen"}</h2>

        <div className={"settings-page__navigation"}>
          {tabItems.map((item) => (
            <Link
              key={item.id}
              to={item.to}
              className={classNames("typography--h8 text-color--primary", {
                "typography--h9": location.pathname.includes(item.id)
              })}>
              {item.content}
            </Link>
          ))}
        </div>

        {children}
      </PageContent>
    </Page>
  );
}

export default SettingsPage;
