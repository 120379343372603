import elbcargoApi from "../../core/network/elbcargo/elbcargoApi";
import {OwnFirm} from "./companyApiModel";

const companyApi = {
  getOwnFirms(params?: {admin: string}) {
    return elbcargoApi.run<OwnFirm[]>(
      {
        method: "GET",
        params
      },
      "get-own-firms"
    );
  }
};

export default companyApi;
