import {FormField, Button, TypeaheadSelect, DropdownOption} from "@hipo/react-ui-toolkit";
import {useEffect, useState} from "react";

import DateInput from "../../../component/input/date/DateInput";
import useAsyncProcess from "../../../core/network/async-process/useAsyncProcess";
import settingsApi from "../../api/settingsApi";

function LogFilterForm({
  onClose,
  onSubmit
}: {
  onClose: VoidFunction;
  onSubmit: (filter: {name?: string; start: Date | null; end: Date | null}) => void;
}) {
  const [formState, setFormState] = useState({
    name: null as null | DropdownOption,
    start: null as null | Date,
    end: null as null | Date
  });
  const {runAsyncProcess, state} =
    useAsyncProcess<{id: string; name: string; username: string}[]>();

  useEffect(() => {
    runAsyncProcess(settingsApi.getAdmins());
  }, [runAsyncProcess]);

  return (
    <form className={"create-admin-form"}>
      <FormField label={"Admin"} labelFor={"name"}>
        <TypeaheadSelect
          dropdownOptions={
            state.data?.map((item) => ({id: item.id, title: item.name})) || []
          }
          selectedOptions={formState.name ? [formState.name] : []}
          onSelect={(option) => setFormState({...formState, name: option})}
          selectedOptionLimit={1}
          typeaheadProps={{
            placeholder: "Suche",
            name: "driver-list"
          }}
          onTagRemove={() => setFormState({...formState, name: null})}
        />
      </FormField>

      <FormField label={"Datum"} labelFor={"from"}>
        <DateInput
          name={"from"}
          selected={formState.start}
          onChange={(date) => setFormState({...formState, start: date})}
          placeholderText={"von"}
        />
      </FormField>

      <FormField label={""} labelFor={"to"}>
        <DateInput
          name={"to"}
          selected={formState.end}
          onChange={(date) => setFormState({...formState, end: date})}
          placeholderText={"bis"}
        />
      </FormField>

      <div className={"form__button-group"} style={{justifyContent: "space-between"}}>
        <Button customClassName={"button--transparent button--large"} onClick={onClose}>
          {"Abbrechen"}
        </Button>

        <Button
          customClassName={"button--primary button--large"}
          type={"submit"}
          onClick={handleSubmit}>
          {"Filtern"}
        </Button>
      </div>
    </form>
  );

  function handleSubmit() {
    onSubmit({...formState, name: formState.name?.id});
  }
}

export default LogFilterForm;
