/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-bind */
import {ReactComponent as TrashIcon} from "../../../core/ui/icon/24px/trash_24x24.svg";

import "./_create-tour-form.scss";
import "./_create-edit-tour-form.scss";

import {Fragment, useEffect, useState} from "react";
import {
  Button,
  DropdownOption,
  FormField,
  Input,
  Textarea,
  TypeaheadSelect,
  useToaster
} from "@hipo/react-ui-toolkit";
import axios, {AxiosError} from "axios";

import Form from "../../../component/form/Form";
import employeeApi from "../../../employee/api/employeeApi";
import truckApi from "../../../truck/api/truckApi";
import useAsyncProcess from "../../../core/network/async-process/useAsyncProcess";
import tourApi from "../../api/tourApi";
import {useAppContext} from "../../../core/app/AppContext";
import {SingleTourResponse} from "../../api/tourApiModel";
import {BASE_URL} from "../../../core/network/util/networkConstants";
import AsyncContent, {
  AsyncContentError,
  AsyncContentStatus
} from "../../../component/async-content/AsyncContent";
import Loading from "../../../component/loading/Loading";
import Modal from "../../../component/modal/Modal";
import DeleteModal from "../../../component/delete-modal/DeleteModal";
import ErrorToast from "../../../component/toast/error/ErrorToast";

interface CreateTourFormProps {
  onCancel: VoidFunction;
  id: string;
}

function CreateEditTourForm({onCancel, id}: CreateTourFormProps) {
  const [formState, setFormState] = useState<{
    id: string;
    firmList: DropdownOption[];
    driverList: DropdownOption[];
    truckList: DropdownOption[];
    note: string;
  }>({
    id: "",
    firmList: [],
    driverList: [],
    truckList: [],
    note: ""
  });
  const [visibilityState, setVisibility] = useState({
    id: true,
    firm: true,
    driver: true,
    truck: true
  });
  const [driverOptions, setDriverOptions] = useState<DropdownOption[]>();
  const [truckOptions, setTruckOptions] = useState<DropdownOption[]>();
  const [isDeleteModalOpen, setDeleteModalVisibility] = useState(false);
  const {runAsyncProcess: getTourProcess, state: getTourState} =
    useAsyncProcess<SingleTourResponse>();
  const {
    state: {ownFirm, globalFirms, user}
  } = useAppContext();
  const firmDropdownOptions: DropdownOption[] = globalFirms
    ? globalFirms?.tour_firms.map(
        (firm) => ({id: firm.name, title: firm.name} as DropdownOption)
      )
    : [];
  const toaster = useToaster();

  useEffect(() => {
    getTourProcess(
      tourApi.getTour({
        own_firm: ownFirm,
        roller_nr: id
      })
    );

    employeeApi.getDrivers({own_firm: ownFirm}).then((response) => {
      setDriverOptions(
        response.map((option) => ({
          id: String(option.id),
          title: option.name
        }))
      );
    });

    truckApi
      .getTrucks({
        own_firm: ownFirm
      })
      .then((response) => {
        setTruckOptions(
          response.map((option) => ({
            id: String(option.plate),
            title: option.plate
          }))
        );
      });
  }, [ownFirm, id, getTourProcess]);

  return (
    <div className={"create-tour-form-container"}>
      <Form customClassName={"create-tour-form-container"}>
        <AsyncContent requestStates={[getTourState]} content={renderAsyncContent} />
      </Form>
    </div>
  );

  function renderAsyncContent(status: AsyncContentStatus, error: AsyncContentError) {
    let node = <Fragment />;

    switch (status) {
      case "pending":
        node = (
          <div
            className={"table__loading-view-container"}
            style={{width: "750px", height: "400px"}}>
            <Loading />
          </div>
        );
        break;

      case "error":
        node = (
          <Fragment>
            <div className={"is-centered table__error-view-container"}>
              <div className={"is-centered"}>{error?.message}</div>
            </div>
          </Fragment>
        );
        break;

      case "success":
        node = (
          <div>
            <div className={"create-tour-form"}>
              <div className={"create-edit-tour-form"}>
                <div
                  className={"create-edit-tour-form__initial-view"}
                  style={{marginBottom: "28px"}}>
                  <p className={"typography--h3 text-color--secondary"}>
                    {"Tourenbesetzung"}
                  </p>
                  <p
                    className={
                      "typography--h3 text-color--primary"
                    }>{`/ ${getTourState.data?.roller_nr}`}</p>
                </div>

                <div>
                  {visibilityState.id ? (
                    <div className={"create-edit-tour-form__initial-view"}>
                      <p className={"typography--h7 text-color--primary"}>
                        {"Roller Nummer: "}
                      </p>
                      <p className={"typography--h3 text-color--primary"}>
                        {getTourState.data?.roller_nr}
                      </p>
                      <Button
                        onClick={() => setVisibility({...visibilityState, id: false})}>
                        {"x"}
                      </Button>
                    </div>
                  ) : (
                    <FormField label={"Roller Nummer:"} labelFor={"tour-id"}>
                      <Input
                        id={"tour-id"}
                        name={"tour-id"}
                        value={formState?.id}
                        onChange={handleTourNameChange}
                      />
                    </FormField>
                  )}

                  {visibilityState.firm ? (
                    <div className={"create-edit-tour-form__initial-view"}>
                      <p className={"typography--h7 text-color--primary"}>
                        {"Firmenname: "}
                      </p>
                      <p className={"typography--h3 text-color--primary"}>
                        {getTourState.data?.firm}
                      </p>
                      <Button
                        onClick={() => setVisibility({...visibilityState, firm: false})}>
                        {"x"}
                      </Button>
                    </div>
                  ) : (
                    <FormField label={"Firmenname:"} labelFor={"firm-name"}>
                      <TypeaheadSelect
                        dropdownOptions={firmDropdownOptions}
                        selectedOptions={formState.firmList}
                        customClassName={""}
                        selectedOptionLimit={1}
                        onSelect={handleSelectFirm}
                        typeaheadProps={{
                          placeholder: "Suche",
                          name: "firm-list"
                        }}
                        onTagRemove={handleRemoveFirm}
                      />
                    </FormField>
                  )}

                  {visibilityState.driver && getTourState.data?.default_driver ? (
                    <div className={"create-edit-tour-form__initial-view"}>
                      <p className={"typography--h7 text-color--primary"}>{"Fahrer: "}</p>
                      <p className={"typography--h3 text-color--primary"}>
                        {getTourState.data?.default_driver}
                      </p>
                      <Button
                        onClick={() =>
                          setVisibility({...visibilityState, driver: false})
                        }>
                        {"x"}
                      </Button>
                    </div>
                  ) : (
                    <FormField label={"Fahrer:"} labelFor={"driver-list"}>
                      <TypeaheadSelect
                        dropdownOptions={driverOptions || []}
                        selectedOptions={formState.driverList}
                        onSelect={handleSelectDriver}
                        selectedOptionLimit={1}
                        typeaheadProps={{
                          placeholder: "Suche",
                          name: "driver-list"
                        }}
                        onTagRemove={handleRemoveDriver}
                      />
                    </FormField>
                  )}

                  {visibilityState.truck && getTourState.data?.default_truck ? (
                    <div className={"create-edit-tour-form__initial-view"}>
                      <p className={"typography--h7 text-color--primary"}>
                        {"LKW-Kennzeichen: "}
                      </p>
                      <p className={"typography--h3 text-color--primary"}>
                        {getTourState.data?.default_truck}
                      </p>
                      <Button
                        onClick={() => setVisibility({...visibilityState, truck: false})}>
                        {"x"}
                      </Button>
                    </div>
                  ) : (
                    <FormField label={"LKW-Kennzeichen:"} labelFor={"truck"}>
                      <TypeaheadSelect
                        dropdownOptions={truckOptions || []}
                        selectedOptions={formState.truckList}
                        onSelect={handleSelectTruck}
                        selectedOptionLimit={1}
                        typeaheadProps={{
                          placeholder: "Suche",
                          name: "truck-list"
                        }}
                        onTagRemove={handleRemoveTruck}
                      />
                    </FormField>
                  )}
                </div>
              </div>

              <div>
                <FormField
                  label={"Tournotizen:"}
                  labelFor={"tour-note"}
                  customClassName={"create-tour-form__form-field--note"}>
                  <Textarea
                    name={"tour-note"}
                    onChange={handleNoteChange}
                    defaultValue={getTourState.data?.general_notes}
                    customClassNames={{textarea: "create-tour-form__note-textarea"}}
                  />
                </FormField>
              </div>
            </div>

            <div className={"create-tour-form__button-group"}>
              <Button
                onClick={() => setDeleteModalVisibility(true)}
                style={{marginRight: "auto"}}>
                <TrashIcon />
              </Button>

              <Modal
                isOpen={isDeleteModalOpen}
                onClose={() => setDeleteModalVisibility(false)}>
                <DeleteModal
                  text={id}
                  onClose={() => setDeleteModalVisibility(false)}
                  onSubmit={handleDelete}
                />
              </Modal>

              <Button
                onClick={handleCancelButton}
                customClassName={"button--transparent button--large"}>
                {"Abbrechen"}
              </Button>

              <Button
                onClick={handleSubmit}
                customClassName={"button--primary button--large"}
                type={"submit"}>
                {"Speichern"}
              </Button>
            </div>
          </div>
        );
        break;

      default:
        break;
    }

    return node;
  }

  async function handleDelete() {
    const formData = new FormData();

    formData.append("own_firm", ownFirm);
    formData.append("roller_nr", id);
    formData.append("admin", user!.hash);
    await axios({
      method: "POST",
      url: `${BASE_URL}delete-tour`,
      data: formData
    });

    location.reload();
  }

  function handleTourNameChange(event: React.SyntheticEvent<HTMLInputElement>) {
    setFormState({
      ...formState,
      id: event.currentTarget.value
    });
  }

  function handleSelectFirm(option: DropdownOption) {
    setFormState({
      ...formState,
      firmList: [...formState.firmList, option]
    });
  }

  function handleRemoveFirm(option: DropdownOption) {
    setFormState({
      ...formState,
      firmList: formState.firmList.filter((firm) => firm !== option)
    });
  }

  function handleSelectDriver(option: DropdownOption) {
    setFormState({
      ...formState,
      driverList: [...formState.driverList, option]
    });
  }

  function handleRemoveDriver(option: DropdownOption) {
    setFormState({
      ...formState,
      driverList: formState.driverList.filter((driver) => driver !== option)
    });
  }

  function handleSelectTruck(option: DropdownOption) {
    setFormState({
      ...formState,
      truckList: [...formState.truckList, option]
    });
  }

  function handleRemoveTruck(option: DropdownOption) {
    setFormState({
      ...formState,
      truckList: formState.truckList.filter((truck) => truck !== option)
    });
  }

  function handleNoteChange(event: React.SyntheticEvent<HTMLTextAreaElement>) {
    setFormState({
      ...formState,
      note: event.currentTarget.value
    });
  }

  function handleCancelButton() {
    onCancel();
  }

  async function handleSubmit() {
    const formData = new FormData();

    formData.append("own_firm", ownFirm);
    formData.append("query_roller_nr", id);
    formData.append("admin", user!.hash);
    if (formState.id) formData.append("roller_nr", formState.id);
    if (formState.firmList[0]) formData.append("firm_name", formState.firmList[0].title);
    if (formState.note) formData.append("general_note", formState.note);
    if (formState.driverList[0])
      formData.append("default_driver", formState.driverList[0].id);
    if (formState.truckList[0])
      formData.append("default_truck", formState.truckList[0].id);

    try {
      await axios({
        method: "POST",
        url: `${BASE_URL}update-tour`,
        data: formData
      });
    } catch (e) {
      const err = e as AxiosError;

      toaster.display({
        render() {
          return <ErrorToast message={err.response?.data as string} />;
        }
      });
    }

    handleCancelButton();
  }
}

export default CreateEditTourForm;
