import {Button} from "@hipo/react-ui-toolkit";

interface DeleteModalProps {
  onSubmit: VoidFunction;
  onClose: VoidFunction;
  text?: string;
  fullText?: string;
}

function DeleteModal({text, onSubmit, onClose, fullText}: DeleteModalProps) {
  return (
    <div>
      <p style={{textAlign: "center"}}>
        {fullText || `Möchten Sie ${text ? `die ${text}` : ""} wirklich löschen?`}
      </p>

      <div className={"form__button-group"} style={{justifyContent: "space-between"}}>
        <Button customClassName={"button--transparent button--large"} onClick={onClose}>
          {"Abbrechen"}
        </Button>

        <Button
          customClassName={"button--primary button--large"}
          type={"submit"}
          onClick={onSubmit}>
          {"Löschen"}
        </Button>
      </div>
    </div>
  );
}

export default DeleteModal;
