import {ReactComponent as PlusIcon} from "../../../../core/ui/icon/plus.svg";
import {ReactComponent as DocumentIcon} from "../../../../core/ui/icon/36px/document_36x36.svg";

import "./_employee-detail-page-document.scss";

import {useState} from "react";
import {Button, ListItem} from "@hipo/react-ui-toolkit";
import {format} from "date-fns";
import axios from "axios";

import Table from "../../../../component/table/Table";
import {generateEmployeeDocumentTableColumns} from "./util/employeeDetailPageDocumentUtils";
import Modal from "../../../../component/modal/Modal";
import CreateEmployeeDocumentForm from "./create/CreateEmployeeDocumentForm";
import {BASE_URL} from "../../../../core/network/util/networkConstants";
import {DATE_FORMAT} from "../../../../core/util/time/timeConstants";
import {EmployeeDocument} from "../../../api/employeeApiModel";
import {useAppContext} from "../../../../core/app/AppContext";

function EmployeeDetailPageDocument({
  workerId,
  documents
}: {
  workerId: string;
  documents?: EmployeeDocument[];
}) {
  const [modalState, setModalState] = useState<{
    isOpen: boolean;
    id: string;
    name: string;
    expiry_date: null | Date;
    done: boolean;
    file_url: null | string;
  }>({
    isOpen: false,
    id: "",
    name: "",
    expiry_date: null,
    done: false,
    file_url: null
  });
  const {
    state: {user}
  } = useAppContext();

  return (
    <div className={"employee-detail-page-document"}>
      <div className={"has-space-between employee-detail-page-document__header"}>
        <h2 className={"typography--h1 text-color--blue"}>{"Dokumente"}</h2>

        <Button
          customClassName={"button--blue"}
          onClick={() => setModalState({...modalState, isOpen: true})}>
          {"Neue Dokument hinzufügen"}

          <PlusIcon />
        </Button>

        <Modal
          isOpen={modalState.isOpen}
          onClose={() =>
            setModalState({
              isOpen: false,
              id: "",
              name: "",
              expiry_date: null,
              done: false,
              file_url: null
            })
          }>
          <CreateEmployeeDocumentForm
            onSubmit={handleSubmit}
            onClose={() =>
              setModalState({
                isOpen: false,
                id: "",
                name: "",
                expiry_date: null,
                done: false,
                file_url: null
              })
            }
            data={{
              id: modalState.id,
              name: modalState.name,
              expiry_date: modalState.expiry_date
                ? new Date(modalState.expiry_date)
                : null,
              done: modalState.done,
              file_url: modalState.file_url
            }}
          />
        </Modal>
      </div>

      <Table
        data={documents || []}
        columns={generateEmployeeDocumentTableColumns()}
        customRow={renderRow}
        customClassName={"employee-detail-page-document__table"}
      />
    </div>
  );

  function renderRow(rowData: EmployeeDocument) {
    return (
      <ListItem
        customClassName={"employee-detail-page-document__table__row"}
        clickableListItemProps={{
          onClick: () =>
            setModalState({
              id: rowData.id,
              name: rowData.name,
              file_url: (rowData.file_url as string) || null,
              expiry_date: rowData.expiry_date ? new Date(rowData.expiry_date) : null,
              done: rowData.done,
              isOpen: true
            })
        }}>
        <p className={"typography--h5"}>{rowData.name}</p>

        <p className={"typography--h5"}>
          {rowData.expiry_date
            ? format(new Date(rowData.expiry_date), DATE_FORMAT.DEFAULT)
            : "-"}
        </p>

        {rowData.file_url && (
          <a
            href={rowData.file_url as string}
            target={"_blank"}
            rel={"noopener noreferrer"}>
            <DocumentIcon />
          </a>
        )}
      </ListItem>
    );
  }

  async function handleSubmit(data: {
    name: string;
    date: null | Date;
    file: null | File;
    delete_file?: boolean;
    done?: boolean;
    id?: string;
  }) {
    const formData = new FormData();

    formData.append("worker_id", workerId);
    formData.append("name", data.name);
    formData.append("admin", user!.hash);
    if (data.date) {
      formData.append("expiry_date", format(new Date(data.date), DATE_FORMAT.API));
    }

    if (modalState?.id) {
      const editData = new FormData();

      editData.append("document_id", modalState.id);
      editData.append("admin", user!.hash);
      if (data.name !== modalState.name) editData.append("name", data.name);
      if (data.date !== modalState.expiry_date && data.date !== null)
        editData.append("expiry_date", format(new Date(data.date), DATE_FORMAT.API));
      if (data.delete_file) editData.append("delete_file", String(data.delete_file));
      if (data.done !== modalState.done) editData.append("done", String(data.done));

      await axios({
        method: "POST",
        data: editData,
        url: `${BASE_URL}update-worker-document`
      });

      if (data.file) {
        const fileFormData = new FormData();

        fileFormData.append("document_id", modalState.id);
        fileFormData.append("file", data.file);

        await axios({
          method: "POST",
          url: `${BASE_URL}add-worker-document-file`,
          data: fileFormData
        });
      }
    } else {
      const documentId = await axios({
        method: "POST",
        url: `${BASE_URL}add-worker-document`,
        data: formData
      });

      if (data.file) {
        const fileFormData = new FormData();

        fileFormData.append("document_id", documentId.data);
        fileFormData.append("file", data.file);

        await axios({
          method: "POST",
          url: `${BASE_URL}add-worker-document-file`,
          data: fileFormData
        });
      }
    }

    location.reload();
  }
}

export default EmployeeDetailPageDocument;
