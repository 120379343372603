/* eslint-disable no-magic-numbers */
import {ReactComponent as TrashIcon} from "../../../../core/ui/icon/24px/trash_24x24.svg";

import "./_employee-form.scss";

import {Button, FormField, Input, Textarea, useToaster} from "@hipo/react-ui-toolkit";
import {useState} from "react";
import axios, {AxiosError} from "axios";
import {format, getDayOfYear} from "date-fns";
import {useNavigate} from "react-router-dom";

import DateInput from "../../../../component/input/date/DateInput";
import {BASE_URL} from "../../../../core/network/util/networkConstants";
import {useAppContext} from "../../../../core/app/AppContext";
import {DATE_FORMAT} from "../../../../core/util/time/timeConstants";
import ROUTES from "../../../../core/route/routes";
import ErrorToast from "../../../../component/toast/error/ErrorToast";
import DeleteModal from "../../../../component/delete-modal/DeleteModal";
import Modal from "../../../../component/modal/Modal";

interface EmployeeFormProps {
  data?: {
    name?: string;
    apiWorkerId?: string;
    workerId?: string;
    jobDescription?: string;
    startDate?: null | Date;
    endDate?: null | Date;
    salary?: string;
    expense?: string;
    offDays?: string;
    note?: string;
    remaining_holidays?: string;
  };
  onClose: VoidFunction;
}

function EmployeeForm({data, onClose}: EmployeeFormProps) {
  const [formState, setFormState] = useState(data);
  const {
    state: {ownFirm, user}
  } = useAppContext();
  const navigate = useNavigate();
  const [hasEndDate, setHasEndDate] = useState(false);
  const toaster = useToaster();
  const [isDeleteModalOpen, setDeleteModalState] = useState(false);

  return (
    <div className={"employee-form-container"}>
      <h3 className={"typography--h3"}>{"Arbeiternehmer hinzufügen"}</h3>

      <form>
        <div className={"employee-form"}>
          <div>
            <FormField label={"Name"} labelFor={"name"}>
              <Input
                name={"name"}
                onChange={(event) =>
                  setFormState({...formState, name: event.currentTarget.value})
                }
                value={formState?.name}
              />
            </FormField>

            <FormField label={"Personalnummer"} labelFor={"worker-id"}>
              <Input
                name={"worker-id"}
                onChange={(event) =>
                  setFormState({...formState, workerId: event.currentTarget.value})
                }
                value={formState?.workerId}
              />
            </FormField>

            <FormField label={"Jobbeschreibung"} labelFor={"job-description"}>
              <Input
                name={"job-description"}
                onChange={(event) =>
                  setFormState({...formState, jobDescription: event.currentTarget.value})
                }
                value={formState?.jobDescription}
              />
            </FormField>

            <FormField label={"Arbeitsbeginn"} labelFor={"start"}>
              <DateInput
                name={"start"}
                onChange={(date) => setFormState({...formState, startDate: date})}
                selected={formState?.startDate}
              />
            </FormField>

            <FormField
              label={"Kündigungsdatum hinzufügen"}
              labelFor={"has-end-date"}
              customClassName={"create-credit-form__checkbox-label"}>
              <Input
                name={"has-end-date"}
                type={"checkbox"}
                onChange={() => setHasEndDate(!hasEndDate)}
                checked={hasEndDate || !!data?.endDate}
                customClassName={"create-credit-form__checkbox"}
              />
            </FormField>

            {(hasEndDate || !!data?.endDate) && (
              <FormField label={"Kündigungsdatum"} labelFor={"end"}>
                <DateInput
                  name={"end"}
                  onChange={(date) => setFormState({...formState, endDate: date})}
                  selected={formState?.endDate}
                />
              </FormField>
            )}
          </div>

          <div>
            <FormField label={"Lohn"} labelFor={"salary"}>
              <Input
                name={"salary"}
                leftIcon={"€"}
                onChange={(event) =>
                  setFormState({...formState, salary: event.currentTarget.value})
                }
                value={formState?.salary}
              />
            </FormField>

            <FormField label={"Spesen"} labelFor={"daily"}>
              <Input
                name={"daily"}
                leftIcon={"€"}
                onChange={(event) =>
                  setFormState({...formState, expense: event.currentTarget.value})
                }
                value={formState?.expense}
              />
            </FormField>

            <div style={{display: "flex", gap: "16px"}}>
              <FormField label={"Jährliche Urlaubstage"} labelFor={"off-days"}>
                <Input
                  style={{width: "150px"}}
                  name={"off-days"}
                  onChange={(event) =>
                    setFormState({...formState, offDays: event.currentTarget.value})
                  }
                  value={formState?.offDays}
                />
              </FormField>

              <FormField
                label={`Restliche Tage für ${new Date().getFullYear()}`}
                labelFor={"remaining-off-days"}>
                <Input
                  style={{width: "150px"}}
                  name={"remaining-off-days"}
                  placeholder={getCalculatedRemainingHolidays()}
                  onChange={(event) =>
                    setFormState({
                      ...formState,
                      remaining_holidays: event.currentTarget.value
                    })
                  }
                  value={formState?.remaining_holidays}
                />
              </FormField>
            </div>

            <FormField label={"Notizen"} labelFor={"note"}>
              <Textarea
                name={"note"}
                onChange={(event) =>
                  setFormState({...formState, note: event.currentTarget.value})
                }
                value={formState?.note}
              />
            </FormField>
          </div>
        </div>

        <div className={"employee-form__button-group"}>
          <Button onClick={() => setDeleteModalState(true)}>
            <TrashIcon />
          </Button>

          <Modal isOpen={isDeleteModalOpen} onClose={() => setDeleteModalState(false)}>
            <DeleteModal
              onSubmit={handleDelete}
              onClose={() => setDeleteModalState(false)}
            />
          </Modal>

          <div className={"form__button-group"}>
            <Button
              customClassName={"button--transparent button--large"}
              onClick={onClose}>
              {"Abbrechen"}
            </Button>

            <Button
              isDisabled={
                !(
                  formState?.name &&
                  formState.workerId &&
                  formState.jobDescription &&
                  formState.startDate &&
                  formState.offDays &&
                  formState.salary
                )
              }
              onClick={handleSubmit}
              customClassName={"button--primary button--large"}
              type={"submit"}>
              {data ? "Speichern" : "Hinzufügen"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );

  async function handleDelete() {
    const formData = new FormData();

    formData.append("worker_id", formState!.apiWorkerId!);
    formData.append("admin", user!.hash);
    await axios({
      method: "POST",
      url: `${BASE_URL}delete-worker`,
      data: formData
    });

    navigate(ROUTES.EMPLOYEE);
  }

  function getCalculatedRemainingHolidays() {
    const dayOfYear = getDayOfYear(new Date());
    const remainingDay = 365 - dayOfYear;
    const dayYearRatio = remainingDay / 365;
    const remainingHoliday = dayYearRatio * 24;

    return String(Math.floor(remainingHoliday));
  }

  async function handleSubmit() {
    const formData = new FormData();

    formData.append("own_firm", ownFirm);
    formData.append("admin", user!.hash);
    if (data?.name !== formState?.name) formData.append("name", formState!.name!);
    if (data?.jobDescription !== formState?.jobDescription)
      formData.append("position", formState!.jobDescription!);
    if (data?.startDate !== formState?.startDate)
      formData.append(
        "start_date",
        format(new Date(formState!.startDate!), DATE_FORMAT.API)
      );
    if (data?.endDate !== formState?.endDate)
      formData.append("end_date", format(new Date(formState!.endDate!), DATE_FORMAT.API));
    if (data?.salary !== formState?.salary) formData.append("salary", formState!.salary!);
    if (data?.offDays !== formState?.offDays)
      formData.append("holidays", formState!.offDays!);
    if (data?.expense !== formState?.expense && formState?.expense)
      formData.append("daily_expenses", formState.expense);
    if (data?.note !== formState?.note && formState?.note)
      formData.append("note", formState.note);
    if (
      data?.remaining_holidays !== formState?.remaining_holidays &&
      formState?.remaining_holidays
    )
      formData.append("remaining_holidays", formState.remaining_holidays);

    if (formState?.endDate)
      formData.append("quit_date", format(formState.endDate, DATE_FORMAT.API));

    if (data) {
      formData.append("worker_id", formState!.apiWorkerId!);
      if (data.workerId !== formState?.workerId && formState?.workerId)
        formData.append("new_worker_id", formState.workerId);

      try {
        await axios({
          method: "POST",
          url: `${BASE_URL}update-worker`,
          data: formData
        });

        location.reload();
      } catch (e) {
        const err = e as AxiosError;

        toaster.display({
          render() {
            return <ErrorToast message={err.response?.data as string} />;
          }
        });
      }
    } else {
      formData.append("worker_id", formState!.workerId!);

      try {
        await axios({
          method: "POST",
          url: `${BASE_URL}create-worker`,
          data: formData
        });

        location.reload();
      } catch (e) {
        const err = e as AxiosError;

        toaster.display({
          render() {
            return <ErrorToast message={err.response?.data as string} />;
          }
        });
      }
    }
  }
}

export default EmployeeForm;
