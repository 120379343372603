import {ReactComponent as PlusIcon} from "../../../../core/ui/icon/plus.svg";

import "./_company-tab.scss";

import {Fragment, useCallback, useEffect, useState} from "react";
import {Button, ListItem, useToaster} from "@hipo/react-ui-toolkit";
import axios, {AxiosError} from "axios";

import Table from "../../../../component/table/Table";
import Modal from "../../../../component/modal/Modal";
import CreateCompanyForm from "../../../form/create-company/CreateCompanyForm";
import useAsyncProcess from "../../../../core/network/async-process/useAsyncProcess";
import {SettingsCustomer} from "../../../api/settingsApiModel";
import settingsApi from "../../../api/settingsApi";
import {useAppContext} from "../../../../core/app/AppContext";
import {BASE_URL} from "../../../../core/network/util/networkConstants";
import AsyncContent, {
  AsyncContentStatus
} from "../../../../component/async-content/AsyncContent";
import Loading from "../../../../component/loading/Loading";
import {Ordering} from "../../../../core/util/ordering/orderingTypes";
import {filterTruthyObjectValues} from "../../../../core/util/object/objectUtils";
import ErrorToast from "../../../../component/toast/error/ErrorToast";

type TableOrdering = "name" | "vat" | "address";

export type CompanyTableOrdering = Ordering<TableOrdering>;

function CompanyTab() {
  const [modalState, setModalState] = useState<{
    visibility: boolean;
    firmName?: string;
    firmId?: string;
    vat?: null | string;
    address?: null | string;
  }>({visibility: false});
  const {runAsyncProcess, state, setState} = useAsyncProcess<SettingsCustomer[]>();
  const {
    state: {ownFirm}
  } = useAppContext();
  const [ordering, setOrdering] = useState<CompanyTableOrdering["state"]>();
  const [tableColumns, setColumns] = useState(generateCompanyTableColumns());
  const toaster = useToaster();

  const handleOrdering = useCallback(
    (order_by: TableOrdering) => {
      setOrdering({order_by, direction: ordering?.direction === "asc" ? "des" : "asc"});
    },
    [ordering?.direction]
  );

  useEffect(() => {
    setColumns(generateCompanyTableColumns({state: ordering, onChange: handleOrdering}));
  }, [ordering, handleOrdering]);

  useEffect(() => {
    runAsyncProcess(
      settingsApi.getCustomers(
        filterTruthyObjectValues({
          own_firm: ownFirm,
          order_by: ordering?.order_by,
          direction: ordering?.direction
        })
      )
    );
  }, [runAsyncProcess, ownFirm, ordering?.direction, ordering?.order_by]);

  return <AsyncContent requestStates={[state]} content={renderAsyncContent} />;

  function renderAsyncContent(status: AsyncContentStatus) {
    let node = <Fragment />;

    switch (status) {
      case "pending":
        node = <Loading />;
        break;

      case "error":
        node = <p>{"An error ocurred on server."}</p>;
        break;

      case "success":
        node = (
          <div className={"company-tab"}>
            <Button
              customClassName={"button--blue company-tab__cta"}
              onClick={handleClick}>
              {"Neuer Kunde"} <PlusIcon />
            </Button>

            <Modal isOpen={modalState.visibility} onClose={closeModal}>
              <CreateCompanyForm
                onSubmit={handleSubmit}
                firmName={modalState?.firmName}
                vat={modalState?.vat}
                address={modalState?.address}
                firmId={modalState.firmId}
                onClose={closeModal}
              />
            </Modal>

            <Table
              data={state.data!}
              columns={tableColumns}
              customRow={renderRow}
              customClassName={"company-tab__table"}
            />
          </div>
        );
        break;

      default:
        break;
    }

    return node;
  }

  async function handleSubmit(data: {
    firmId?: string;
    firmName: string;
    vat?: null | string;
    address?: null | string;
  }) {
    const formData = new FormData();

    formData.append("own_firm", ownFirm);
    formData.append("firm_name", data.firmName);
    if (data.vat) formData.append("vat", data.vat);
    if (data.address) formData.append("address", data.address);

    if (data.firmId) {
      formData.append("firm_id", data.firmId);
      try {
        await axios({
          method: "POST",
          url: `${BASE_URL}update-customer`,
          data: formData
        });
      } catch (e) {
        const err = e as AxiosError;

        toaster.display({
          render() {
            return <ErrorToast message={err.response?.data as string} />;
          }
        });
      }

      const excludedList = state.data!.filter((item) => item.id !== data.firmId);

      setState({
        ...state,
        data: [
          ...excludedList,
          {
            id: data.firmId,
            firm_name: data.firmName,
            vat: data.vat,
            address: data.address
          }
        ]
      });
    } else {
      formData.append("own_firm", ownFirm);

      try {
        await axios({
          method: "POST",
          url: `${BASE_URL}create-customer`,
          data: formData
        });

        setState({
          ...state,
          data: [
            ...state.data!,
            {
              id: "0",
              firm_name: data.firmName,
              vat: data.vat || null,
              address: data.address || null
            }
          ]
        });
      } catch (e) {
        const err = e as AxiosError;

        toaster.display({
          render() {
            return <ErrorToast message={err.response?.data as string} />;
          }
        });
      }
    }

    closeModal();

    location.reload();
  }

  function closeModal() {
    setModalState({visibility: false});
  }

  function handleClick() {
    setModalState({...modalState, visibility: true});
  }

  function renderRow(rowData: SettingsCustomer) {
    return (
      <ListItem
        customClassName={"table-row company-tab__table__row"}
        clickableListItemProps={{
          onClick: () =>
            setModalState({
              firmId: rowData.id,
              firmName: rowData.firm_name,
              vat: rowData.vat,
              address: rowData.address,
              visibility: true
            })
        }}>
        <p className={"typography--h5"}>{rowData.firm_name}</p>

        <p className={"typography--h5"}>{rowData.vat}</p>

        <p className={"typography--h5"}>{rowData.address}</p>
      </ListItem>
    );
  }
}

function generateCompanyTableColumns(ordering?: CompanyTableOrdering) {
  return [
    {
      id: "CompanyTable.firm",
      header: "Firmenname",
      orderingProps: {
        isActive: ordering?.state?.order_by === "name",
        onChange: () => ordering?.onChange("name"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "CompanyTable.vax",
      header: "VAX",
      orderingProps: {
        isActive: ordering?.state?.order_by === "vat",
        onChange: () => ordering?.onChange("vat"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "CompanyTable.address",
      header: "Adresse",
      orderingProps: {
        isActive: ordering?.state?.order_by === "address",
        onChange: () => ordering?.onChange("address"),
        direction: ordering?.state?.direction
      }
    }
  ];
}

export default CompanyTab;
