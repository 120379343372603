/* eslint-disable no-magic-numbers */
import {BLANK_PDF, Template} from "@pdfme/ui";

import {TableColumn} from "../../../component/table/Table";
import {EmployeeListItem} from "../../api/employeeApiModel";
import {EmployeeTableOrdering} from "../EmployeePage";

function generateEmployeeTableColumns(
  ordering?: EmployeeTableOrdering
): TableColumn<any>[] {
  return [
    {
      id: "EmployeeTable.name",
      header: "Name",
      orderingProps: {
        isActive: ordering?.state?.order_by === "name",
        onChange: () => ordering?.onChange("name"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "EmployeeTable.holidays",
      header: "Insgesamt",
      orderingProps: {
        isActive: ordering?.state?.order_by === "holidays",
        onChange: () => ordering?.onChange("holidays"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "EmployeeTable.remaning-holidays",
      header: "Verbleibend",
      orderingProps: {
        isActive: ordering?.state?.order_by === "remaining_holidays",
        onChange: () => ordering?.onChange("remaining_holidays"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "EmployeeTable.salary",
      header: "Lohn",
      orderingProps: {
        isActive: ordering?.state?.order_by === "salary",
        onChange: () => ordering?.onChange("salary"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "EmployeeTable.expense",
      header: "Spesen",
      orderingProps: {
        isActive: ordering?.state?.order_by === "daily_expense",
        onChange: () => ordering?.onChange("daily_expense"),
        direction: ordering?.state?.direction
      }
    }
  ];
}

function generateEmployeePdf(employeeList: EmployeeListItem[]) {
  const employeeRows = generateEmployeeRows(employeeList);
  const template: Template = {
    basePdf: BLANK_PDF,
    schemas: [
      {
        title: {
          type: "text",
          position: {x: 20, y: 10},
          width: 100,
          height: 20,
          fontSize: 14,
          backgroundColor: "#ffffff"
        },
        name: {
          type: "text",
          position: {x: 20, y: 30},
          fontSize: 10,
          width: 50,
          height: 10,
          backgroundColor: "#ffffff",
          fontColor: "#9B9B9B"
        },
        holiday: {
          type: "text",
          position: {x: 80, y: 30},
          fontSize: 10,
          width: 20,
          height: 10,
          backgroundColor: "#ffffff",
          fontColor: "#9B9B9B"
        },
        remaining: {
          type: "text",
          position: {x: 115, y: 30},
          fontSize: 10,
          width: 20,
          height: 10,
          backgroundColor: "#ffffff",
          fontColor: "#9B9B9B"
        },
        salary: {
          type: "text",
          position: {x: 150, y: 30},
          fontSize: 10,
          width: 20,
          height: 10,
          backgroundColor: "#ffffff",
          fontColor: "#9B9B9B"
        },
        expense: {
          type: "text",
          position: {x: 185, y: 30},
          fontSize: 10,
          width: 20,
          height: 10,
          backgroundColor: "#ffffff",
          fontColor: "#9B9B9B"
        },
        ...employeeRows.schemas
      }
    ]
  };
  const inputs = [
    {
      title: "Mitarbeiter",
      name: "Name",
      holiday: "Insgesamt",
      remaining: "Verbleind",
      salary: "Lohn",
      expense: "Spesen",
      ...employeeRows.inputs
    }
  ];

  return {template, inputs};
}

const EMPLOYEE_Y_POSITION = 40;

function generateEmployeeRows(employeeList: EmployeeListItem[]) {
  const schemas = employeeList.reduce(
    (prev, current, index) => ({
      ...prev,
      [`${current.name}-name${index}`]: {
        type: "text",
        position: {x: 20, y: EMPLOYEE_Y_POSITION + index * 10},
        width: 90,
        height: 5,
        backgroundColor: "#ffffff",
        fontSize: 11
      },
      [`${current.name}-holiday${index}`]: {
        type: "text",
        position: {x: 80, y: EMPLOYEE_Y_POSITION + index * 10},
        width: 20,
        height: 5,
        backgroundColor: "#ffffff",
        fontSize: 11
      },
      [`${current.name}-remaining${index}`]: {
        type: "text",
        position: {x: 115, y: EMPLOYEE_Y_POSITION + index * 10},
        width: 20,
        height: 5,
        backgroundColor: "#ffffff",
        fontSize: 11
      },
      [`${current.name}-salary${index}`]: {
        type: "text",
        position: {x: 150, y: EMPLOYEE_Y_POSITION + index * 10},
        width: 20,
        height: 5,
        backgroundColor: "#ffffff",
        fontSize: 11
      },
      [`${current.name}-expense${index}`]: {
        type: "text",
        position: {x: 185, y: EMPLOYEE_Y_POSITION + index * 10},
        width: 20,
        height: 5,
        backgroundColor: "#ffffff",
        fontSize: 11
      }
    }),
    {}
  );
  const inputs = employeeList.reduce(
    (prev, current, index) => ({
      ...prev,
      [`${current.name}-name${index}`]: String(current.name),
      [`${current.name}-holiday${index}`]: String(current.holidays),
      [`${current.name}-remaining${index}`]: String(current.remaining_holidays),
      [`${current.name}-salary${index}`]: String(current.salary),
      [`${current.name}-expense${index}`]: String(current.daily_expense)
    }),
    {}
  );

  return {inputs, schemas};
}

export {generateEmployeeTableColumns, generateEmployeePdf};
