/* eslint-disable max-lines */
import {ReactComponent as PlusIcon} from "../../../../core/ui/icon/plus.svg";

import "./_label-tab.scss";

import {Fragment, useEffect, useState} from "react";
import {Button, ListItem} from "@hipo/react-ui-toolkit";
import axios from "axios";

import Table from "../../../../component/table/Table";
import {generateLabelColumns} from "./util/labelUtils";
import Modal from "../../../../component/modal/Modal";
import CreateLabelForm from "../../../form/create-label/CreateLabelForm";
import useAsyncProcess from "../../../../core/network/async-process/useAsyncProcess";
import {SettingsTag, Tag} from "../../../api/settingsApiModel";
import settingsApi from "../../../api/settingsApi";
import {BASE_URL} from "../../../../core/network/util/networkConstants";
import AsyncContent, {
  AsyncContentStatus
} from "../../../../component/async-content/AsyncContent";
import Loading from "../../../../component/loading/Loading";

function LabelTab() {
  const [modalState, setModalVisibility] = useState({
    isContactOpen: false,
    isTourOpen: false,
    isVacationOpen: false,
    editForm: {
      id: "",
      name: "",
      color: ""
    }
  });
  const {runAsyncProcess, state, setState} = useAsyncProcess<SettingsTag>();

  useEffect(() => {
    runAsyncProcess(settingsApi.getTags());
  }, [runAsyncProcess]);

  return (
    <div className={"label-tab"}>
      <AsyncContent requestStates={[state]} content={renderAsyncContent} />
    </div>
  );

  function renderAsyncContent(status: AsyncContentStatus) {
    let node = <Fragment />;

    switch (status) {
      case "pending":
        node = <Loading />;
        break;

      case "error":
        node = <p>{"An error ocurred on server."}</p>;
        break;

      case "success":
        node = (
          <>
            <div className={"label-tab__table-container"}>
              <h4 className={"typography--h8 label-tab__table__title"}>
                {"Tourenbesetzung"}
              </h4>

              <Button
                onClick={openTourModal}
                customClassName={"button--blue button--medium label-tab__table__button"}>
                {"Neue"}

                <PlusIcon />
              </Button>

              <Modal isOpen={modalState.isTourOpen} onClose={closeModal}>
                <CreateLabelForm
                  title={"Tourenbesetzung Etikett"}
                  onSubmit={handleSubmit}
                  onClose={closeModal}
                  section={"tour"}
                  id={modalState.editForm.id}
                  name={modalState.editForm.name}
                  color={modalState.editForm.color}
                  isEditForm={!!(modalState.editForm.name && modalState.editForm.color)}
                />
              </Modal>

              <Table
                data={state.data!.tour_tags}
                columns={generateLabelColumns()}
                customRow={renderTourRow}
                customClassName={"label-tab__table"}
              />
            </div>

            <div className={"label-tab__table-container"}>
              <h4 className={"typography--h8 label-tab__table__title"}>
                {"Kontaktendatenbank"}
              </h4>

              <Button
                onClick={openContactModal}
                customClassName={"button--blue button--medium label-tab__table__button"}>
                {"Neue"}

                <PlusIcon />
              </Button>

              <Modal isOpen={modalState.isContactOpen} onClose={closeModal}>
                <CreateLabelForm
                  title={"Kontaktendatenbank"}
                  onClose={closeModal}
                  section={"contacts"}
                  onSubmit={handleSubmit}
                  id={modalState.editForm.id}
                  name={modalState.editForm.name}
                  color={modalState.editForm.color}
                  isEditForm={!!(modalState.editForm.name && modalState.editForm.color)}
                />
              </Modal>

              <Table
                data={state.data!.contacts_tags}
                columns={generateLabelColumns()}
                customRow={renderContactRow}
                customClassName={"label-tab__table"}
              />
            </div>

            <div className={"label-tab__table-container"}>
              <h4 className={"typography--h8 label-tab__table__title"}>
                {"Urlaubsplanung"}
              </h4>

              <Button
                onClick={openVacationModal}
                customClassName={"button--blue button--medium label-tab__table__button"}>
                {"Neue"}

                <PlusIcon />
              </Button>

              <Modal isOpen={modalState.isVacationOpen} onClose={closeModal}>
                <CreateLabelForm
                  title={"Urlaubsplanung"}
                  onClose={closeModal}
                  section={"offday"}
                  id={modalState.editForm.id}
                  name={modalState.editForm.name}
                  color={modalState.editForm.color}
                  onSubmit={handleSubmit}
                  isEditForm={!!(modalState.editForm.name && modalState.editForm.color)}
                />
              </Modal>

              <Table
                data={state.data!.offday_tags}
                columns={generateLabelColumns()}
                customRow={renderOffdayRow}
                customClassName={"label-tab__table"}
              />
            </div>
          </>
        );
        break;

      default:
        break;
    }

    return node;
  }

  async function handleSubmit(data: {
    id: string;
    section: string;
    name: string;
    color: string;
  }) {
    const formData = new FormData();
    const dataColor = `#${data.color}`;

    formData.append("section", data.section);
    formData.append("name", data.name);
    formData.append("colour", dataColor);

    if (modalState.editForm.name && modalState.editForm.color) {
      formData.append("id", data.id);

      await axios({
        method: "POST",
        url: `${BASE_URL}update-tag`,
        data: formData
      });

      if (data.section === "contacts") {
        const filteredList = state.data!.contacts_tags.filter(
          (item) => item.id !== data.id
        );

        setState({
          ...state,
          data: {
            ...state.data!,
            contacts_tags: [
              ...filteredList,
              {id: data.id, name: data.name, colour: dataColor}
            ]
          }
        });
      } else if (data.section === "offdays") {
        const filteredList = state.data!.offday_tags.filter(
          (item) => item.id !== data.id
        );

        setState({
          ...state,
          data: {
            ...state.data!,
            offday_tags: [
              ...filteredList,
              {id: data.id, name: data.name, colour: dataColor}
            ]
          }
        });
      } else {
        const filteredList = state.data!.tour_tags.filter((item) => item.id !== data.id);

        setState({
          ...state,
          data: {
            ...state.data!,
            tour_tags: [
              ...filteredList,
              {id: data.id, name: data.name, colour: dataColor}
            ]
          }
        });
      }
    } else {
      await axios({
        method: "POST",
        url: `${BASE_URL}create-tag`,
        data: formData
      });

      if (data.section === "contacts") {
        setState({
          ...state,
          data: {
            ...state.data!,
            contacts_tags: [
              ...state.data!.contacts_tags,
              {id: "0", name: data.name, colour: dataColor}
            ]
          }
        });
      } else if (data.section === "offday") {
        setState({
          ...state,
          data: {
            ...state.data!,
            offday_tags: [
              ...state.data!.offday_tags,
              {id: "0", name: data.name, colour: dataColor}
            ]
          }
        });
      } else {
        setState({
          ...state,
          data: {
            ...state.data!,
            tour_tags: [
              ...state.data!.tour_tags,
              {id: "0", name: data.name, colour: dataColor}
            ]
          }
        });
      }
    }

    closeModal();

    location.reload();
  }

  function renderContactRow(rowData: Tag) {
    return (
      <ListItem
        customClassName={"table-row label-tab__table__row"}
        clickableListItemProps={{
          onClick: () => {
            setModalVisibility({
              ...modalState,
              isContactOpen: true,
              editForm: {
                id: rowData.id,
                name: rowData.name,
                color: rowData.colour
              }
            });
          }
        }}>
        <p className={"typography--h5 label-tab__name"}>{rowData.name}</p>

        <div
          className={"typography--h5 label-tab__color"}
          style={{backgroundColor: rowData.colour}}
        />
      </ListItem>
    );
  }

  function renderOffdayRow(rowData: Tag) {
    return (
      <ListItem
        customClassName={"table-row label-tab__table__row"}
        clickableListItemProps={{
          onClick: () => {
            setModalVisibility({
              ...modalState,
              isVacationOpen: true,
              editForm: {
                id: rowData.id,
                name: rowData.name,
                color: rowData.colour
              }
            });
          }
        }}>
        <p className={"typography--h5 label-tab__name"}>{rowData.name}</p>

        <div
          className={"typography--h5 label-tab__color"}
          style={{backgroundColor: rowData.colour}}
        />
      </ListItem>
    );
  }

  function renderTourRow(rowData: Tag) {
    return (
      <ListItem
        customClassName={"table-row label-tab__table__row"}
        clickableListItemProps={{
          onClick: () => {
            setModalVisibility({
              ...modalState,
              isTourOpen: true,
              editForm: {
                id: rowData.id,
                name: rowData.name,
                color: rowData.colour
              }
            });
          }
        }}>
        <p className={"typography--h5 label-tab__name"}>{rowData.name}</p>

        <div
          className={"typography--h5 label-tab__color"}
          style={{backgroundColor: rowData.colour}}
        />
      </ListItem>
    );
  }

  function openTourModal() {
    setModalVisibility({
      ...modalState,
      isTourOpen: true
    });
  }

  function openContactModal() {
    setModalVisibility({
      ...modalState,
      isContactOpen: true
    });
  }

  function openVacationModal() {
    setModalVisibility({
      ...modalState,
      isVacationOpen: true
    });
  }

  function closeModal() {
    setModalVisibility({
      isContactOpen: false,
      isTourOpen: false,
      isVacationOpen: false,
      editForm: {name: "", color: "", id: ""}
    });
  }
}

export default LabelTab;
