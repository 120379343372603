import "./_create-payment-form.scss";

import {
  Button,
  FormField,
  Input,
  Textarea,
  TypeaheadSelect,
  DropdownOption,
  useToaster
} from "@hipo/react-ui-toolkit";
import {useState} from "react";
import axios, {AxiosError} from "axios";

import LabelToggle from "../../../component/label/toggle/LabelToggle";
import {useAppContext} from "../../../core/app/AppContext";
import {BASE_URL} from "../../../core/network/util/networkConstants";
import ErrorToast from "../../../component/toast/error/ErrorToast";

interface CreatePaymentFormProps {
  onClose: VoidFunction;
  updateId?: string;
  id?: string;
  firm?: {id: string; name: string};
  label?: string;
  note?: string;
}

function CreatePaymentForm({
  id,
  updateId,
  firm,
  label,
  note,
  onClose
}: CreatePaymentFormProps) {
  const [formState, setFormState] = useState({
    id,
    firm: firm?.name ? [{id: firm.name, title: firm.name}] : [],
    label,
    note
  });
  const {
    state: {globalFirms, ownFirm, user}
  } = useAppContext();
  const firmDropdownOptions: DropdownOption[] = globalFirms
    ? globalFirms?.fuelcard_firms.map(
        (item) => ({id: item.id, title: item.name} as DropdownOption)
      )
    : [];
  const toaster = useToaster();

  return (
    <div>
      <h3 className={"typography--h3"}>
        {id ? "Karteninformationen" : "Neue Tank-Karte"}
      </h3>

      <form className={"form-field--vertical create-payment-form"}>
        <div>
          <FormField label={"Kartennummer"} labelFor={"card_number"}>
            <Input
              name={"card_number"}
              onChange={(event) =>
                setFormState({...formState, id: event.currentTarget.value})
              }
              value={formState.id}
            />
          </FormField>

          <FormField label={"Firmenname"} labelFor={"firm"}>
            <TypeaheadSelect
              dropdownOptions={firmDropdownOptions}
              selectedOptions={formState.firm ? formState.firm : []}
              selectedOptionLimit={1}
              onSelect={(option) => setFormState({...formState, firm: [option]})}
              typeaheadProps={{
                placeholder: "Suche",
                name: "firm"
              }}
              onTagRemove={handleRemoveFirm}
            />
          </FormField>

          <FormField
            label={"Etikett"}
            labelFor={"label"}
            customClassName={"edit-tour-form__form-field--label"}>
            <LabelToggle
              onToggle={handleLabelToggle}
              items={[
                {name: "Aktiv", colour: "var(--tag-green-2)"},
                {name: "Inaktiv", colour: "var(--tag-pink-3)"}
              ]}
              selectedItem={formState.label || ""}
            />
          </FormField>
        </div>

        <div>
          <FormField label={"Notizen"} labelFor={"note"}>
            <Textarea
              name={"note"}
              onChange={(event) =>
                setFormState({...formState, note: event.currentTarget.value})
              }
              value={formState.note}
            />
          </FormField>

          <div className={"form__button-group"}>
            <Button
              customClassName={"button--transparent button--large"}
              onClick={onClose}>
              {"Abbrechen"}
            </Button>

            <Button
              isDisabled={!(formState.id && formState.label)}
              onClick={handleSubmit}
              customClassName={"button--primary button--large"}
              type={"submit"}>
              {id ? "Speichern" : "Hinzufügen"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );

  async function handleSubmit() {
    const formData = new FormData();

    formData.append("own_firm", ownFirm);
    formData.append("admin", user!.hash);
    formData.append("card_nr", formState.id!);
    formData.append("status", formState.label!);
    if (formState.note) formData.append("notes", formState.note);

    if (formState.firm[0]) {
      formData.append("firm", formState.firm[0].title);
    } else {
      const newFirm = document.querySelector(
        ".typeahead-input > .input"
      ) as HTMLInputElement;

      formData.append("firm", newFirm.value);
    }

    if (id) {
      const updateData = new FormData();

      updateData.append("admin", user!.hash);
      updateData.append("card_id", updateId!);
      updateData.append("status", formState.label!);
      if (formState.note) updateData.append("notes", formState.note);

      if (formState.firm[0]) {
        updateData.append("firm", formState.firm[0].title);
      } else {
        const newFirm = document.querySelector(
          ".typeahead-input > .input"
        ) as HTMLInputElement;

        updateData.append("firm", newFirm.value);
      }

      if (id !== formState.id) {
        updateData.append("card_nr", formState.id!);
      }

      try {
        await axios({
          method: "POST",
          url: `${BASE_URL}update-fuelcard`,
          data: updateData
        });
      } catch (e) {
        const err = e as AxiosError;

        toaster.display({
          render() {
            return <ErrorToast message={err.response?.data as string} />;
          }
        });
      }
    } else {
      try {
        await axios({
          method: "POST",
          url: `${BASE_URL}create-fuelcard`,
          data: formData
        });
      } catch (e) {
        const err = e as AxiosError;

        toaster.display({
          render() {
            return <ErrorToast message={err.response?.data as string} />;
          }
        });
      }
    }

    location.reload();
  }

  function handleLabelToggle(selected: string) {
    setFormState({...formState, label: selected});
  }

  function handleRemoveFirm() {
    setFormState({
      ...formState,
      firm: []
    });
  }
}

export default CreatePaymentForm;
