/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import {ReactComponent as PlusIcon} from "../../../../core/ui/icon/plus.svg";

import "./_admin-tab.scss";

import {Button, ListItem, useToaster} from "@hipo/react-ui-toolkit";
import {useCallback, useEffect, useState} from "react";
import axios, {AxiosError} from "axios";

import Modal from "../../../../component/modal/Modal";
import Table from "../../../../component/table/Table";
import CreateAdminForm from "../../../form/create-admin/CreateAdminForm";
import useAsyncProcess from "../../../../core/network/async-process/useAsyncProcess";
import settingsApi from "../../../api/settingsApi";
import Loading from "../../../../component/loading/Loading";
import {BASE_URL} from "../../../../core/network/util/networkConstants";
import {SettingsAdminDetail} from "../../../api/settingsApiModel";
import {Ordering} from "../../../../core/util/ordering/orderingTypes";
import {filterTruthyObjectValues} from "../../../../core/util/object/objectUtils";
import ErrorToast from "../../../../component/toast/error/ErrorToast";

type TableOrdering = "name" | "username";

export type AdminTableOrdering = Ordering<TableOrdering>;

function AdminTab() {
  const [modalState, setModalState] = useState({
    isOpen: false,
    data: {id: "", name: ""}
  });
  const {runAsyncProcess, state} =
    useAsyncProcess<{id: string; name: string; username: string}[]>();
  const toaster = useToaster();
  const [ordering, setOrdering] = useState<AdminTableOrdering["state"]>();
  const [tableColumns, setColumns] = useState(generateAdminTableColumns());

  const handleOrdering = useCallback(
    (order_by: TableOrdering) => {
      setOrdering({order_by, direction: ordering?.direction === "asc" ? "des" : "asc"});
    },
    [ordering?.direction]
  );

  useEffect(() => {
    setColumns(generateAdminTableColumns({state: ordering, onChange: handleOrdering}));
  }, [ordering, handleOrdering]);

  useEffect(() => {
    runAsyncProcess(
      settingsApi.getAdmins(
        filterTruthyObjectValues({
          order_by: ordering?.order_by,
          direction: ordering?.direction
        })
      )
    );
  }, [runAsyncProcess, ordering?.direction, ordering?.order_by]);

  return (
    <div className={"admin-tab"}>
      {state.isRequestFetched ? (
        <>
          <Button customClassName={"button--blue admin-tab__cta"} onClick={handleClick}>
            <PlusIcon />
          </Button>

          <Modal isOpen={modalState.isOpen} onClose={closeModal}>
            <CreateAdminForm
              onSubmit={handleSubmit}
              onClose={closeModal}
              data={modalState.data}
              onDelete={handleDelete}
            />
          </Modal>

          <Table
            data={state.data!}
            columns={tableColumns}
            customRow={renderRow}
            customClassName={"admin-tab__table"}
          />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );

  async function handleSubmit(data: SettingsAdminDetail) {
    const formData = new FormData();

    formData.append(
      "own_firms",
      data.access_to_own_firms
        .filter((item) => item.name.length > 0)
        .map((item) => item.name)
        .join(",")
    );
    formData.append("name", data.name);
    formData.append("username", data.username);
    formData.append("password", data.password);

    try {
      await axios({
        method: "POST",
        url: `${BASE_URL}create-admin`,
        data: formData
      });

      location.reload();
    } catch (e) {
      const err = e as AxiosError;

      toaster.display({
        render() {
          return <ErrorToast message={err.response?.data as string} />;
        }
      });
    }
  }

  async function handleDelete() {
    const formData = new FormData();

    formData.append("admin_id", modalState.data.id);
    await axios({
      method: "POST",
      url: `${BASE_URL}delete-admin`,
      data: formData
    });

    location.reload();
  }

  function renderRow(rowData: {id: string; name: string; username: string}) {
    return (
      <ListItem
        customClassName={"table-row admin-tab__table__row"}
        clickableListItemProps={{
          onClick: () =>
            setModalState({
              isOpen: true,
              data: {
                id: rowData.id,
                name: rowData.name
              }
            })
        }}>
        <p className={"typography--h5"}>{rowData.name}</p>

        <p className={"typography--h5"}>{rowData.username}</p>
      </ListItem>
    );
  }

  function closeModal() {
    setModalState({
      isOpen: false,
      data: {id: "", name: ""}
    });
  }

  function handleClick() {
    setModalState({...modalState, isOpen: true});
  }
}

function generateAdminTableColumns(ordering?: AdminTableOrdering) {
  return [
    {
      id: "AdminTable.name",
      header: "Name",
      orderingProps: {
        isActive: ordering?.state?.order_by === "name",
        onChange: () => ordering?.onChange("name"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "AdminTable.username",
      header: "Username",
      orderingProps: {
        isActive: ordering?.state?.order_by === "username",
        onChange: () => ordering?.onChange("username"),
        direction: ordering?.state?.direction
      }
    }
  ];
}

export default AdminTab;
