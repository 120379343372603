import {PaymentDetailTableOrdering} from "../TankPaymentDetailPage";

function generateTankPaymentDetailTableColumns(ordering?: PaymentDetailTableOrdering) {
  return [
    {
      id: "TankPaymentDetail.driver",
      header: "Fahrer",
      orderingProps: {
        isActive: ordering?.state?.order_by === "driver",
        onChange: () => ordering?.onChange("driver"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "TankPaymentDetail.taken-date",
      header: "Ausgabedatum",
      orderingProps: {
        isActive: ordering?.state?.order_by === "got_date",
        onChange: () => ordering?.onChange("got_date"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "TankPaymentDetail.issue-date",
      header: "Rückgabedatum",
      orderingProps: {
        isActive: ordering?.state?.order_by === "gave_back_date",
        onChange: () => ordering?.onChange("gave_back_date"),
        direction: ordering?.state?.direction
      }
    }
  ];
}

export {generateTankPaymentDetailTableColumns};
