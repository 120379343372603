import {TruckTableOrdering} from "../TruckPage";

function generateTruckTableColumns(ordering?: TruckTableOrdering) {
  return [
    {
      id: "Truck.plate",
      header: "Nummernschild",
      orderingProps: {
        isActive: ordering?.state?.order_by === "plate",
        onChange: () => ordering?.onChange("plate"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "Truck.creationDate",
      header: "Marke/Modell",
      orderingProps: {
        isActive: ordering?.state?.order_by === "manufacturer",
        onChange: () => ordering?.onChange("manufacturer"),
        direction: ordering?.state?.direction
      }
    }
  ];
}

export {generateTruckTableColumns};
