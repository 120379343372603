import {MeetingTableOrdering} from "../ContactDetailPage";

function generateContactDetailTableColumns(ordering?: MeetingTableOrdering) {
  return [
    {
      id: "ContactDetail.date",
      header: "Date",
      orderingProps: {
        isActive: ordering?.state?.order_by === "date",
        onChange: () => ordering?.onChange("date"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "ContactDetail.note",
      header: "Notizen"
    }
  ];
}

export {generateContactDetailTableColumns};
