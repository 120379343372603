import {ReactComponent as PlusIcon} from "../../core/ui/icon/plus.svg";
import {ReactComponent as EditIcon} from "../../core/ui/icon/24px/edit.svg";

import "./_company-select.scss";

import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Button, ListItem} from "@hipo/react-ui-toolkit";
import classNames from "classnames";

import ROUTES from "../../core/route/routes";
import useAsyncProcess from "../../core/network/async-process/useAsyncProcess";
import companyApi from "../api/companyApi";
import {OwnFirm} from "../api/companyApiModel";
import Loading from "../../component/loading/Loading";
import {useAppContext} from "../../core/app/AppContext";
import webStorage, {STORED_KEYS} from "../../core/util/storage/web/webStorage";

function CompanySelect() {
  const {runAsyncProcess, state} = useAsyncProcess<OwnFirm[]>();
  const [selectedCompany, setSelectedCompany] = useState<OwnFirm>();
  const {
    state: {user},
    dispatch
  } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      runAsyncProcess(
        companyApi.getOwnFirms(
          user.role === "superuser" ? undefined : {admin: user?.hash}
        )
      );
    }
  }, [runAsyncProcess, user]);

  if (!state.isRequestFetched) return <Loading />;

  return (
    <div className={"company-select"}>
      <ul className={"company-select__list"}>
        {state.data!.map((company) => (
          <ListItem
            key={company.id}
            customClassName={classNames("company-select__list-item", {
              "company-select__list-item--is-active": selectedCompany?.id === company.id
            })}
            clickableListItemProps={{onClick: () => setSelectedCompany(company)}}>
            {company.logo ? (
              <img
                src={company.logo as string}
                alt={"company"}
                className={"company-select__list-item__image"}
              />
            ) : (
              <div className={"company-select__list-item__image"} />
            )}

            <p className={"typography--h1 company-select__list-item__name"}>
              {company.name}
            </p>
          </ListItem>
        ))}

        {user?.role === "superuser" && (
          <Link to={ROUTES.COMPANY_CREATE} className={"company-select__new-company"}>
            <PlusIcon width={90} height={90} />

            <p className={"typography--h8 text-color--secondary"}>
              {"Neues Unternehmen"}
            </p>
          </Link>
        )}
      </ul>

      <Button
        customClassName={"company-select__go-back"}
        onClick={handleSelect}
        isDisabled={!selectedCompany}>
        {"Fertig"}
      </Button>

      {selectedCompany && user?.role === "superuser" ? (
        <Link
          to={ROUTES.COMPANY_CREATE}
          state={{company: selectedCompany, isNavigated: true}}
          className={"typography--h8 text-color--secondary company-select__edit-cta"}>
          <EditIcon />

          {"Unternehmen verwalten"}
        </Link>
      ) : (
        <div style={{height: "44px"}} />
      )}
    </div>
  );

  function handleSelect() {
    dispatch({type: "SET_OWN_FIRM", name: selectedCompany!.name});

    webStorage.local.setItem(STORED_KEYS.SELECTED_COMPANY, selectedCompany!.name);

    navigate(ROUTES.HOME);
  }
}

export default CompanySelect;
