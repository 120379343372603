import "./_tour-filter-form.scss";

import {Button, DropdownOption, FormField, TypeaheadSelect} from "@hipo/react-ui-toolkit";
import {useEffect, useState} from "react";

import LabelToggle from "../../../component/label/toggle/LabelToggle";
import {useAppContext} from "../../../core/app/AppContext";
import employeeApi from "../../../employee/api/employeeApi";

interface TourFilterFormProps {
  onClose: VoidFunction;
  onSubmit: ({
    firmList,
    driverList,
    label
  }: {
    firmList: DropdownOption[];
    driverList: DropdownOption[];
    label: string;
  }) => void;
}

function TourFilterForm({onClose, onSubmit}: TourFilterFormProps) {
  const [formState, setFormState] = useState<{
    firmList: DropdownOption[];
    driverList: DropdownOption[];
    label: string;
  }>({
    firmList: [],
    driverList: [],
    label: ""
  });
  const [driverOptions, setDriverOptions] = useState<DropdownOption[]>();
  const {
    state: {globalStatus, globalFirms}
  } = useAppContext();
  const firmDropdownOptions: DropdownOption[] = globalFirms
    ? globalFirms?.tour_firms.map(
        (firm) => ({id: firm.name, title: firm.name} as DropdownOption)
      )
    : [];
  const {
    state: {ownFirm}
  } = useAppContext();

  useEffect(() => {
    employeeApi.getDrivers({own_firm: ownFirm}).then((response) => {
      setDriverOptions(
        response.map((option) => ({
          id: String(option.id),
          title: option.name
        }))
      );
    });
  }, [ownFirm]);

  return (
    <form className={"tour-filter-form"}>
      <FormField label={"Firmenname"} labelFor={"firmName"}>
        <TypeaheadSelect
          dropdownOptions={firmDropdownOptions}
          selectedOptions={formState.firmList}
          selectedOptionLimit={1}
          customClassName={""}
          onSelect={handleSelectFirm}
          typeaheadProps={{
            placeholder: "Suche",
            name: "firm-list"
          }}
          onTagRemove={handleRemoveFirm}
        />
      </FormField>

      <FormField label={"Fahrer"} labelFor={"driver"}>
        <TypeaheadSelect
          dropdownOptions={driverOptions || []}
          selectedOptions={formState.driverList}
          onSelect={handleSelectDriver}
          selectedOptionLimit={1}
          typeaheadProps={{
            placeholder: "Suche",
            name: "driver-list"
          }}
          onTagRemove={handleRemoveDriver}
        />
      </FormField>

      {globalStatus && (
        <FormField
          label={"Etikett:"}
          labelFor={"label"}
          customClassName={"edit-tour-form__form-field--label"}>
          <LabelToggle
            onToggle={handleLabelToggle}
            items={globalStatus.tour_tags}
            selectedItem={formState.label}
          />
        </FormField>
      )}

      <div className={"create-tour-form__button-group"}>
        <Button onClick={onClose} customClassName={"button--large button--transparent"}>
          {"Abbrechen"}
        </Button>

        <Button
          onClick={handleSubmit}
          customClassName={"button--primary button--large"}
          type={"submit"}>
          {"Filtern"}
        </Button>
      </div>
    </form>
  );

  function handleSelectFirm(option: DropdownOption) {
    setFormState({
      ...formState,
      firmList: [...formState.firmList, option]
    });
  }

  function handleRemoveFirm(option: DropdownOption) {
    setFormState({
      ...formState,
      firmList: formState.firmList.filter((item) => item.id !== option.id)
    });
  }

  function handleSelectDriver(option: DropdownOption) {
    setFormState({
      ...formState,
      driverList: [...formState.driverList, option]
    });
  }

  function handleRemoveDriver(option: DropdownOption) {
    setFormState({
      ...formState,
      driverList: formState.driverList.filter((driver) => driver !== option)
    });
  }

  function handleLabelToggle(selectedLabel: string) {
    setFormState({
      ...formState,
      label: selectedLabel
    });
  }

  function handleSubmit() {
    onSubmit(formState);
  }
}

export default TourFilterForm;
