import {DriverOption} from "../../employee/api/employeeApiModel";
import {GlobalFirms, SettingsTag} from "../../settings/api/settingsApiModel";
import {Truck} from "../../truck/api/truckApiModel";
import webStorage, {STORED_KEYS} from "../util/storage/web/webStorage";

export type User = {
  hash: string;
  name: string;
  role: "superuser";
};

const initialAppState = {
  ownFirm: webStorage.local.getItem(STORED_KEYS.SELECTED_COMPANY) as string,
  ownFirmLogo: null as null | string,
  globalFirms: null as null | GlobalFirms,
  globalStatus: null as null | SettingsTag,
  vehicleList: null as null | Truck[],
  driverList: null as null | DriverOption[],
  isNavigationOpen: Boolean(webStorage.local.getItem(STORED_KEYS.IS_NAVIGATION_OPEN)),
  user: webStorage.local.getItem(STORED_KEYS.USER) as null | User
};

export type AppStateReducerAction =
  | {
      type: "SET_LOGGED_IN_ACCOUNT";
      account: null | Record<string, any>;
    }
  | {
      type: "SET_VEHICLE_LIST";
      vehicleList: Truck[];
    }
  | {
      type: "SET_GLOBAL_STATUS";
      status: SettingsTag;
    }
  | {
      type: "SET_GLOBAL_FIRMS";
      firms: GlobalFirms;
    }
  | {
      type: "SET_DRIVER_LIST";
      driverList: DriverOption[];
    }
  | {
      type: "TOGGLE_NAV_VISIBILITY";
    }
  | {
      type: "SET_OWN_FIRM";
      name: string;
    }
  | {
      type: "SET_OWN_FIRM_LOGO";
      logo: string;
    }
  | {
      type: "LOGIN";
      user: User;
    }
  | {
      type: "LOGOUT";
    };

function appStateReducer(state: typeof initialAppState, action: AppStateReducerAction) {
  let newState = state;

  switch (action.type) {
    case "LOGIN": {
      newState = {
        ...state,
        user: action.user
      };
      break;
    }

    case "LOGOUT": {
      newState = {
        ...state,
        user: null,
        ownFirm: ""
      };
      break;
    }

    case "SET_OWN_FIRM": {
      newState = {
        ...state,
        ownFirm: action.name
      };
      break;
    }

    case "SET_OWN_FIRM_LOGO": {
      newState = {
        ...state,
        ownFirmLogo: action.logo
      };
      break;
    }

    case "SET_GLOBAL_FIRMS": {
      newState = {
        ...state,
        globalFirms: action.firms
      };
      break;
    }

    case "SET_GLOBAL_STATUS": {
      newState = {
        ...state,
        globalStatus: action.status
      };
      break;
    }

    case "SET_DRIVER_LIST": {
      newState = {
        ...state,
        driverList: action.driverList
      };
      break;
    }

    case "SET_VEHICLE_LIST":
      newState = {
        ...state,
        vehicleList: action.vehicleList
      };
      break;

    case "TOGGLE_NAV_VISIBILITY":
      if (state.isNavigationOpen) {
        document.documentElement.style.setProperty("--page-navigation-width", "96px");

        webStorage.local.setItem(STORED_KEYS.IS_NAVIGATION_OPEN, false);
      } else {
        document.documentElement.style.setProperty("--page-navigation-width", "272px");

        webStorage.local.setItem(STORED_KEYS.IS_NAVIGATION_OPEN, true);
      }

      newState = {
        ...state,
        isNavigationOpen: !state.isNavigationOpen
      };

      break;

    default:
      break;
  }

  return newState;
}

export {appStateReducer, initialAppState};
