/* eslint-disable complexity */
/* eslint-disable no-magic-numbers */
import "./_tour-schedule.scss";

import {SelectionEvent} from "@viselect/react";
import classNames from "classnames";
import {useState} from "react";
import {format, isSaturday, isSunday, isWeekend} from "date-fns";

import Modal from "../../component/modal/Modal";
import ScheduleSelectionArea from "../../component/schedule/selection-area/ScheduleSelectionArea";
import {Tour} from "../api/tourApiModel";
import EditTourForm from "../form/edit/EditTourForm";
import {DATE_FORMAT} from "../../core/util/time/timeConstants";
import {useAppContext} from "../../core/app/AppContext";

interface TourScheduleProps {
  tour: Tour;
  scheduleDays: Date[];
}

function TourSchedule({tour, scheduleDays}: TourScheduleProps) {
  const [selectionState, setSelectionState] = useState<{
    self: Set<number>;
    range: {
      id: string;
      date: Date;
      status: string;
      driverNames: string[];
      driverIds: string[];
      daily_note: string;
      vehicle: string;
    }[];
  }>(() => ({
    self: new Set(),
    range: []
  }));
  const [isModalOpen, setModalVisibility] = useState(false);
  const extractIds = (els: Element[]): number[] =>
    els
      .map((v) => v.getAttribute("data-key"))
      .filter(Boolean)
      .map(Number);
  const {
    state: {globalStatus}
  } = useAppContext();

  return (
    <div>
      <ScheduleSelectionArea
        onStart={handleStart}
        onMove={handleMove}
        onStop={handleStop}
        selectableClassName={"selection-area__cell"}>
        {scheduleDays.map((day, dayIndex) => {
          const currentDay = tour.tour_days.find(
            (tourDay) =>
              format(new Date(tourDay.date), DATE_FORMAT.DEFAULT) ===
              format(day, DATE_FORMAT.DEFAULT)
          );
          const prevDay = new Date(day);
          const nextDay = new Date(day);

          prevDay.setDate(day.getDate() - 1);
          nextDay.setDate(day.getDate() + 1);

          const nextDayData = tour.tour_days.find(
            (tourDay) =>
              format(new Date(tourDay.date), DATE_FORMAT.DEFAULT) ===
              format(nextDay, DATE_FORMAT.DEFAULT)
          );
          const nextDayStatus = nextDayData?.status;
          const prevDayData = tour.tour_days.find(
            (tourDay) =>
              format(new Date(tourDay.date), DATE_FORMAT.DEFAULT) ===
              format(prevDay, DATE_FORMAT.DEFAULT)
          );
          const prevDayStatus = prevDayData?.status;
          const hasNextDaySameStatus =
            currentDay?.status === nextDayStatus &&
            currentDay &&
            currentDay?.drivers.length > 0 &&
            nextDayData &&
            nextDayData?.drivers.length > 0 &&
            JSON.stringify(currentDay?.drivers) ===
              JSON.stringify(nextDayData?.drivers) &&
            currentDay.truck === nextDayData.truck;
          const hasPrevDaySameStatus =
            currentDay?.status === prevDayStatus &&
            currentDay &&
            currentDay?.drivers.length > 0 &&
            prevDayData &&
            prevDayData?.drivers.length > 0 &&
            JSON.stringify(currentDay?.drivers) ===
              JSON.stringify(prevDayData?.drivers) &&
            currentDay.truck === prevDayData.truck;

          const next2Day = new Date(day);
          const next3Day = new Date(day);
          const next4Day = new Date(day);

          next2Day.setDate(day.getDate() + 2);
          next3Day.setDate(day.getDate() + 3);
          next4Day.setDate(day.getDate() + 4);

          const nextDay2Data = tour.tour_days.find(
            (tourDay) =>
              format(new Date(tourDay.date), DATE_FORMAT.DEFAULT) ===
              format(next2Day, DATE_FORMAT.DEFAULT)
          );

          const nextDay3Data = tour.tour_days.find(
            (tourDay) =>
              format(new Date(tourDay.date), DATE_FORMAT.DEFAULT) ===
              format(next3Day, DATE_FORMAT.DEFAULT)
          );

          const nextDay4Data = tour.tour_days.find(
            (tourDay) =>
              format(new Date(tourDay.date), DATE_FORMAT.DEFAULT) ===
              format(next4Day, DATE_FORMAT.DEFAULT)
          );

          const nextDay2Status = nextDay2Data?.status;
          const nextDay3Status = nextDay3Data?.status;
          const nextDay4Status = nextDay4Data?.status;

          const hasNext2DaySameStatus =
            currentDay?.status === nextDay2Status &&
            currentDay &&
            currentDay?.drivers.length > 0 &&
            nextDay2Data &&
            nextDay2Data?.drivers.length > 0 &&
            JSON.stringify(currentDay?.drivers) ===
              JSON.stringify(nextDay2Data?.drivers) &&
            currentDay.truck === nextDay2Data.truck;
          const hasNext3DaySameStatus =
            currentDay?.status === nextDay3Status &&
            currentDay &&
            currentDay?.drivers.length > 0 &&
            nextDay3Data &&
            nextDay3Data?.drivers.length > 0 &&
            JSON.stringify(currentDay?.drivers) ===
              JSON.stringify(nextDay3Data?.drivers) &&
            currentDay.truck === nextDay3Data.truck;
          const hasNext4DaySameStatus =
            currentDay?.status === nextDay4Status &&
            currentDay &&
            currentDay?.drivers.length > 0 &&
            nextDay4Data &&
            nextDay4Data?.drivers.length > 0 &&
            JSON.stringify(currentDay?.drivers) ===
              JSON.stringify(nextDay4Data?.drivers) &&
            currentDay.truck === nextDay4Data.truck;

          let dayCount = hasNextDaySameStatus ? 2 : 1;

          if (hasNext2DaySameStatus) dayCount = 3;
          if (hasNext3DaySameStatus) dayCount = 4;
          if (hasNext4DaySameStatus) dayCount = 5;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={dayIndex} className={"tour-schedule"}>
              <div
                className={classNames("selection-area__cell", {
                  "selection-area__cell__has-status": currentDay?.status,
                  "selection-area__cell__selected": selectionState.self.has(dayIndex),
                  "selection-area__cell__weekend-day": isWeekend(day),
                  "selection-area__cell__weekend-day--is-saturday": isSaturday(
                    new Date(day)
                  ),
                  "selection-area__cell__weekend-day--is-sunday": isSunday(day)
                })}
                style={{
                  backgroundColor: globalStatus?.tour_tags.find(
                    (item) => item.name === currentDay?.status
                  )?.colour,
                  paddingLeft: hasPrevDaySameStatus ? undefined : "4px",
                  paddingRight: hasNextDaySameStatus ? undefined : "4px",
                  borderRight: hasNextDaySameStatus
                    ? undefined
                    : "1px solid var(--stroke-grey)"
                }}
                data-tour-id={currentDay?.id}
                data-tour-date={day}
                data-tour-status={currentDay?.status}
                data-tour-driver-names={currentDay?.drivers.map((item) => item.name)}
                data-tour-driver-ids={currentDay?.drivers.map((item) => item.id)}
                data-tour-daily-note={currentDay?.daily_note}
                data-tour-vehicle={currentDay?.truck}
                data-key={dayIndex}
              />
              {currentDay?.drivers[0] && !hasPrevDaySameStatus && (
                <div>
                  <p
                    className={"typography--b2 text-color--primary tour-schedule__plate"}
                    style={{maxWidth: `${dayCount * 105}px`}}>
                    {currentDay.truck}
                  </p>

                  <p
                    className={"typography--b2 text-color--primary tour-schedule__driver"}
                    style={{maxWidth: `${dayCount * 105}px`}}>
                    {currentDay?.drivers.map((item) => item.name).join(", ")}
                  </p>
                </div>
              )}

              {currentDay?.daily_note && currentDay?.daily_note.length > 0 && (
                <div className={"dot"} />
              )}
            </div>
          );
        })}
      </ScheduleSelectionArea>

      {Boolean(selectionState.range.length) && (
        <Modal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          customClassName={"filter-modal"}>
          <EditTourForm
            roller_nr={tour.roller_nr}
            selectionRange={selectionState.range}
            onCancel={handleModalClose}
            defaultDriver={tour.default_driver}
            defaultTruck={tour.default_truck}
          />
        </Modal>
      )}
    </div>
  );

  function handleStart({event, selection}: SelectionEvent) {
    if (!event?.ctrlKey && !event?.metaKey) {
      selection.clearSelection();
      setSelectionState(() => ({
        range: [],
        self: new Set()
      }));
    }
  }

  function handleMove({
    store: {
      changed: {added, removed}
    }
  }: SelectionEvent) {
    setSelectionState((prev) => {
      const next = new Set(prev.self);

      extractIds(added).forEach((id) => next.add(id));
      extractIds(removed).forEach((id) => next.delete(id));

      return {
        ...selectionState,
        self: next
      };
    });
  }

  function handleStop({store}: SelectionEvent) {
    const selectedList: Element[] = Array.from(store.selected);
    const newSelectionRange = selectedList.map((day) => ({
      id: day.getAttribute("data-tour-id") || "",
      date: new Date(String(day.getAttribute("data-tour-date"))),
      status: day.getAttribute("data-tour-status") || "",
      driverNames: day.getAttribute("data-tour-driver-names")?.split(",") || [],
      driverIds: day.getAttribute("data-tour-driver-ids")?.split(",") || [],
      daily_note: day.getAttribute("data-tour-daily-note") || "",
      vehicle: day.getAttribute("data-tour-vehicle") || ""
    }));

    setSelectionState({
      self: new Set(selectedList.map((item) => Number(item.getAttribute("data-key")))),
      range: newSelectionRange
    });
    setModalVisibility(true);
  }

  function handleModalClose() {
    setSelectionState(() => ({
      range: [],
      self: new Set()
    }));
    setModalVisibility(false);
  }
}

export default TourSchedule;
