import Fetcher from "../Fetcher";
import {BASE_URL} from "../util/networkConstants";

const elbcargoApi = new Fetcher({
  baseUrl: BASE_URL,
  initOptions: {headers: {"Content-Type": "application/json"}},
  bodyParser: JSON.stringify
});

export default elbcargoApi;
