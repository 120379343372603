import "./_table.scss";

import React, {Fragment} from "react";
import classNames from "classnames";

import TableHeader from "./header/TableHeader";
import TableRow from "./row/TableRow";
import AsyncContent, {
  AsyncContentError,
  AsyncContentStatus
} from "../async-content/AsyncContent";
import Loading from "../loading/Loading";

export interface TableColumn<TableData extends {id: string}> {
  id: string;
  header: React.ReactNode;
  cellFieldName?: string;
  cellContent?: (props: {data: TableData; testid: string}) => React.ReactNode;
  alignment?: "right" | "left";
  link?: string;
  orderingProps?: {
    onChange: VoidFunction;
    direction?: "asc" | "des";
    isActive: boolean;
  };
}

interface TableProps<TableData extends {id: string}> {
  data: TableData[];
  columns: TableColumn<TableData>[];
  customRow?: (data: TableData, index: number) => React.ReactNode;
  customClassName?: string;
  requestState?: AsyncProcessState;
}

function Table<TableData extends {id: string}>({
  data,
  columns,
  customRow,
  customClassName,
  requestState
}: TableProps<TableData>) {
  return (
    <div
      className={classNames("table", customClassName, "typography--secondary-body", {
        "table--is-pending": requestState?.isRequestPending,
        "table--has-error": requestState?.error,
        "table--is-empty": requestState?.isRequestFetched && !data.length
      })}>
      <TableHeader items={columns} />

      <div className={"table-body"}>
        {requestState ? (
          <AsyncContent requestStates={[requestState]} content={renderAsyncContent} />
        ) : (
          renderData()
        )}
      </div>
    </div>
  );

  function renderAsyncContent(status: AsyncContentStatus, error: AsyncContentError) {
    let node = <Fragment />;

    switch (status) {
      case "pending":
        node = (
          <div className={"table__loading-view-container"}>
            <Loading />
          </div>
        );
        break;

      case "error":
        node = (
          <Fragment>
            <div className={"is-centered table__error-view-container"}>
              <div className={"is-centered"}>{error?.message}</div>
            </div>
          </Fragment>
        );
        break;

      case "success":
        node = <Fragment>{renderData()}</Fragment>;
        break;

      default:
        break;
    }

    return node;
  }

  function renderData() {
    return data.length ? (
      data.map((item, index) => (
        <Fragment key={item.id}>
          {customRow ? (
            customRow(item, index)
          ) : (
            <TableRow rowData={item} columns={columns} />
          )}
        </Fragment>
      ))
    ) : (
      <div className={"is-centered table__empty-view-container"}>{"-"}</div>
    );
  }
}

export default Table;
