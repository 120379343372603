import {TableColumn} from "../Table";
import TableHeaderItem from "./item/TableHeaderItem";

interface TableHeaderProps<TableData extends {id: string}> {
  items: Pick<TableColumn<TableData>, "id" | "header" | "alignment" | "orderingProps">[];
}

function TableHeader<TableData extends {id: string}>({
  items
}: TableHeaderProps<TableData>) {
  return (
    <div className={"table-header"}>
      {items.map((item) => (
        <TableHeaderItem key={item.id} item={item} />
      ))}
    </div>
  );
}

export default TableHeader;
