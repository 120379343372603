import "./_schedule-selection-area.scss";

import SelectionArea, {SelectionEvents} from "@viselect/react";

interface ScheduleSelectionAreaProps {
  onStart: SelectionEvents["start"];
  onMove: SelectionEvents["move"];
  onStop: SelectionEvents["stop"];
  selectableClassName: string;
  children: React.ReactNode;
}

function ScheduleSelectionArea({
  onStart,
  onMove,
  onStop,
  selectableClassName,
  children
}: ScheduleSelectionAreaProps) {
  return (
    <SelectionArea
      className={"schedule-selection-area"}
      onStart={onStart}
      onMove={onMove}
      onStop={onStop}
      selectables={`.${selectableClassName}`}>
      {children}
    </SelectionArea>
  );
}

export default ScheduleSelectionArea;
