import {ReactComponent as PlusIcon} from "../../../core/ui/icon/plus.svg";
import {ReactComponent as DocumentIcon} from "../../../core/ui/icon/document.svg";

import "./_employee-page-header.scss";

import {Button} from "@hipo/react-ui-toolkit";
import {useState} from "react";
import {Link} from "react-router-dom";
import axios from "axios";

import Modal from "../../../component/modal/Modal";
import EmployeeForm from "../detail/form/EmployeeForm";
import ROUTES from "../../../core/route/routes";
import {BASE_URL} from "../../../core/network/util/networkConstants";
import {useAppContext} from "../../../core/app/AppContext";

function EmployeePageHeader() {
  const [isOpen, setOpenModal] = useState(false);
  const {
    state: {ownFirm}
  } = useAppContext();

  return (
    <div>
      <div id={"pdf-viewer"} />
      <div className={"has-space-between employee-page-header"}>
        <Button customClassName={"button--blue"} onClick={openAddEmployeeFormModal}>
          {"Arbeiternehmer hinzufügen"}

          <PlusIcon />
        </Button>

        <h2 className={"typography--h2"}>{"Mitarbeiter"}</h2>

        <div className={"has-space-between employee-page-header__button-group"}>
          <Button customClassName={"button--primary"} onClick={handlePdfClick}>
            <DocumentIcon />

            {"Excel"}
          </Button>

          <Link to={ROUTES.EMPLOYEE_EXPENSES} className={"button button--primary"}>
            {"Spesenliste"}
          </Link>
        </div>

        <Modal isOpen={isOpen} onClose={() => setOpenModal(false)}>
          <EmployeeForm onClose={() => setOpenModal(false)} />
        </Modal>
      </div>
    </div>
  );

  async function handlePdfClick() {
    await axios({
      method: "GET",
      url: `${BASE_URL}get-workers-list-excel?own_firm=${ownFirm}`
    }).then((response) => {
      const link = document.createElement("a");

      link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data.file}`;
      link.setAttribute("download", response.data.name);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    });
  }

  function openAddEmployeeFormModal() {
    setOpenModal(true);
  }
}

export default EmployeePageHeader;
