import {ReactComponent as TrashIcon} from "../../../core/ui/icon/24px/trash_24x24.svg";

import "./_create-contact-form.scss";

import {SyntheticEvent, useState} from "react";
import {
  FormField,
  Input,
  Button,
  Textarea,
  TypeaheadSelect,
  DropdownOption
} from "@hipo/react-ui-toolkit";
import {useNavigate} from "react-router-dom";
import axios from "axios";

import {useAppContext} from "../../../core/app/AppContext";
import {BASE_URL} from "../../../core/network/util/networkConstants";
import LabelToggle from "../../../component/label/toggle/LabelToggle";
import ROUTES from "../../../core/route/routes";
import Modal from "../../../component/modal/Modal";
import DeleteModal from "../../../component/delete-modal/DeleteModal";

interface CreateContactFromProps {
  onClose: VoidFunction;
  name?: string;
  firm?: string;
  label?: string;
  phone?: string;
  mail?: string;
  fax?: string;
  address?: string;
  note?: string;
  contactId?: string;
}

function CreateContactFrom({
  onClose,
  name,
  firm,
  contactId,
  label,
  phone,
  mail,
  fax,
  address,
  note
}: CreateContactFromProps) {
  const {
    state: {ownFirm, globalStatus, user, globalFirms}
  } = useAppContext();
  const navigate = useNavigate();
  const [isDeleteModalOpen, setDeleteModalState] = useState(false);
  const [formState, setFormState] = useState({
    name,
    firm,
    label,
    phone,
    mail,
    fax,
    address,
    note
  });

  return (
    <form onSubmit={handleSubmit}>
      <h3 className={"typography--h3"}>{name ? "Informationen" : "Neue Kontakt"}</h3>

      <div className={"form-field--vertical create-contact-form"}>
        <div>
          <FormField label={"Name"} labelFor={"name"}>
            <Input
              name={"name"}
              onChange={(event) =>
                setFormState({...formState, name: event.currentTarget.value})
              }
              value={formState.name}
            />
          </FormField>

          <FormField label={"Firmenname"} labelFor={"firm"}>
            <TypeaheadSelect
              dropdownOptions={
                globalFirms?.contacts_firms.map((item) => ({
                  id: item,
                  title: item
                })) as DropdownOption[]
              }
              selectedOptions={
                formState.firm ? [{id: formState.firm, title: formState.firm}] : []
              }
              selectedOptionLimit={1}
              onSelect={(option) => setFormState({...formState, firm: option.title})}
              typeaheadProps={{
                placeholder: "Suche",
                name: "firm"
              }}
              onTagRemove={() => setFormState({...formState, firm: ""})}
            />
          </FormField>

          {globalStatus?.contacts_tags && (
            <FormField
              label={"Etikett:"}
              labelFor={"label"}
              customClassName={"edit-tour-form__form-field--label"}>
              <LabelToggle
                onToggle={handleLabelToggle}
                items={globalStatus.contacts_tags}
                selectedItem={formState.label || ""}
              />
            </FormField>
          )}

          <FormField label={"Telefonnummer"} labelFor={"phone"}>
            <Input
              name={"phone"}
              onChange={(event) =>
                setFormState({...formState, phone: event.currentTarget.value})
              }
              value={formState.phone}
            />
          </FormField>

          <FormField label={"Mail"} labelFor={"mail"}>
            <Input
              name={"mail"}
              onChange={(event) =>
                setFormState({...formState, mail: event.currentTarget.value})
              }
              value={formState.mail}
            />
          </FormField>
        </div>

        <div>
          <FormField label={"Fax"} labelFor={"fax"}>
            <Input
              name={"fax"}
              onChange={(event) =>
                setFormState({...formState, fax: event.currentTarget.value})
              }
              value={formState.fax}
            />
          </FormField>

          <FormField label={"Address"} labelFor={"address"}>
            <Textarea
              name={"note"}
              onChange={(event) =>
                setFormState({...formState, address: event.currentTarget.value})
              }
              value={formState.address}
            />
          </FormField>

          <FormField label={"Notizen"} labelFor={"note"}>
            <Textarea
              name={"note"}
              onChange={(event) =>
                setFormState({...formState, note: event.currentTarget.value})
              }
              value={formState.note}
            />
          </FormField>
        </div>
      </div>

      <div className={"form__button-group"} style={{marginTop: "60px"}}>
        {name && (
          <>
            <Button
              onClick={() => setDeleteModalState(true)}
              customClassName={"button--transparent"}
              style={{marginTop: "auto", marginRight: "auto"}}>
              <TrashIcon />
            </Button>

            <Modal isOpen={isDeleteModalOpen} onClose={() => setDeleteModalState(false)}>
              <DeleteModal
                onSubmit={handleDelete}
                onClose={() => setDeleteModalState(false)}
              />
            </Modal>
          </>
        )}

        <Button customClassName={"button--transparent button--large"} onClick={onClose}>
          {"Abbrechen"}
        </Button>

        <Button
          customClassName={"button--primary button--large"}
          type={"submit"}
          isDisabled={!(formState.name && formState.label)}>
          {name ? "Speichern" : "Hinzufügen"}
        </Button>
      </div>
    </form>
  );

  async function handleDelete() {
    const formData = new FormData();

    formData.append("admin", user!.hash);
    if (contactId) formData.append("contact_id", contactId);

    await axios({
      method: "POST",
      url: `${BASE_URL}delete-contact`,
      data: formData
    });

    navigate(ROUTES.CONTACTS);
  }

  function handleLabelToggle(selected: string) {
    setFormState({...formState, label: selected});
  }

  async function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    formData.append("own_firm", ownFirm);
    formData.append("admin", user!.hash);
    formData.append("tag", formState.label || "");
    formData.append("name", formState.name || "");
    if (formState.firm) formData.append("firm", formState.firm || "");
    if (formState.mail) formData.append("mail", formState.mail || "");
    if (formState.fax) formData.append("fax", formState.fax || "");
    if (formState.address) formData.append("address", formState.address || "");
    if (formState.note) formData.append("note", formState.note || "");

    if (contactId) {
      formData.append("contact_id", contactId);

      await axios({
        method: "POST",
        url: `${BASE_URL}update-contact`,
        data: formData
      });
    } else {
      await axios({
        method: "POST",
        url: `${BASE_URL}create-contact`,
        data: formData
      });
    }

    location.reload();
  }
}

export default CreateContactFrom;
