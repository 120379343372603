const BASE_ROOT = "/" as const;

const ROUTES = {
  HOME: `${BASE_ROOT}home`,
  TOUR: `${BASE_ROOT}tour`,
  EMPLOYEE: `${BASE_ROOT}employee`,
  EMPLOYEE_EXPENSES: `${BASE_ROOT}employee/expenses`,
  EMPLOYEE_DETAIL: `${BASE_ROOT}employee/detail/:employeeId`,
  VACATION_PLANNING: `${BASE_ROOT}vacation-planning`,
  VACATION_USAGE_MANAGEMENT: `${BASE_ROOT}vacation-usage-management`,
  BILLS: `${BASE_ROOT}bills`,
  BILL_CREATE: `${BASE_ROOT}bills/create`,
  CREDITS: `${BASE_ROOT}credits`,
  CREDIT_DETAIL: `${BASE_ROOT}credits/:creditId`,
  TRUCK: `${BASE_ROOT}truck`,
  TRUCK_DETAIL: `${BASE_ROOT}truck/:truckId`,
  TANK_PAYMENT: `${BASE_ROOT}tank-payment`,
  TANK_PAYMENT_DETAIL: `${BASE_ROOT}tank-payment/:tankPaymentId`,
  CONTACTS: `${BASE_ROOT}contacts`,
  CONTACT_DETAIL: `${BASE_ROOT}contacts/:contactId`,
  SETTINGS: `${BASE_ROOT}settings`,
  SETTINGS_TAG: `${BASE_ROOT}settings/tag`,
  SETTINGS_FIRM: `${BASE_ROOT}settings/firm`,
  SETTINGS_ADMIN: `${BASE_ROOT}settings/admin`,
  SETTINGS_LOG: `${BASE_ROOT}settings/log`,
  LOGIN: `${BASE_ROOT}login`,
  COMPANY_SELECT: `${BASE_ROOT}company`,
  COMPANY_CREATE: `${BASE_ROOT}company/create`
} as const;

export default ROUTES;
