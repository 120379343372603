function generateEmployeeWorkingColumns() {
  return [
    {
      id: "EmployeeWorking.expenses",
      header: "Spesen"
    },
    {
      id: "EmployeeWorking.start-time",
      header: "Beginnzeit"
    },
    {
      id: "EmployeeWorking.pause",
      header: "Pause"
    },
    {
      id: "EmployeeWorking.end",
      header: "Endzeit"
    },
    {
      id: "EmployeeWorking.duration",
      header: "Dauer Summe"
    },
    {
      id: "EmployeeWorking.date",
      header: "Datum"
    }
  ];
}

export {generateEmployeeWorkingColumns};
