import elbcargoApi from "../../../core/network/elbcargo/elbcargoApi";
import {VacationPlanningWorker} from "./vacationPlanningApiModel";

const vacationPlanningApi = {
  getOffdays(params: {
    own_firm: string;
    month: string;
    year: string;
    worker?: string;
    holidays_remaining_start?: string;
    holidays_remaining_end?: string;
    tag?: string;
  }) {
    return elbcargoApi.run<VacationPlanningWorker[]>(
      {
        method: "GET",
        params
      },
      "get-offdays"
    );
  }
};

export default vacationPlanningApi;
