import {ReactComponent as PlusIcon} from "../../core/ui/icon/plus.svg";
import {ReactComponent as SettingsIcon} from "../../core/ui/icon/settings.svg";
import {ReactComponent as DocumentIcon} from "../../core/ui/icon/36px/document_36x36.svg";

import "./_credit-page.scss";

import {Button} from "@hipo/react-ui-toolkit";
import {Fragment, useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";

import PageContent from "../../component/page/content/PageContent";
import Page from "../../component/page/Page";
import Table from "../../component/table/Table";
import {generateCreditTableColumns} from "./util/creditPageUtils";
import useAsyncProcess from "../../core/network/async-process/useAsyncProcess";
import {CreditResponse} from "../api/creditApiModel";
import creditApi from "../api/creditApi";
import {useAppContext} from "../../core/app/AppContext";
import AsyncContent, {
  AsyncContentStatus
} from "../../component/async-content/AsyncContent";
import Loading from "../../component/loading/Loading";
import {filterTruthyObjectValues} from "../../core/util/object/objectUtils";
import Modal from "../../component/modal/Modal";
import CreateCreditForm from "../form/create-credit/CreateCreditForm";
import CreditFilterForm from "./detail/form/filter/CreditFilterForm";
import {formatNumber} from "../../core/util/number/numberUtils";
import {Ordering} from "../../core/util/ordering/orderingTypes";

type TableOrdering = "avis" | "document_nr" | "firm" | "open_amount";

export type CreditTableOrdering = Ordering<TableOrdering>;

function CreditPage() {
  const {state, runAsyncProcess} = useAsyncProcess<CreditResponse[]>();
  const {
    state: {ownFirm}
  } = useAppContext();
  const [isCreateModalOpen, setCreateModalVisibility] = useState(false);
  const [filterState, setFilterState] = useState<{
    firmName?: string;
    avis?: string;
    docNr?: string;
    paidStart?: string;
    paidEnd?: string;
    debtStart?: string;
    debtEnd?: string;
    visibility: boolean;
  }>();
  const [ordering, setOrdering] = useState<CreditTableOrdering["state"]>();
  const [tableColumns, setColumns] = useState(generateCreditTableColumns());

  const handleOrdering = useCallback(
    (order_by: TableOrdering) => {
      setOrdering({order_by, direction: ordering?.direction === "asc" ? "des" : "asc"});
    },
    [ordering?.direction]
  );

  useEffect(() => {
    setColumns(generateCreditTableColumns({state: ordering, onChange: handleOrdering}));
  }, [ordering, handleOrdering]);

  useEffect(() => {
    runAsyncProcess(
      creditApi.getCredits(
        filterTruthyObjectValues({
          own_firm: ownFirm,
          firm: filterState?.firmName,
          avis_nr: filterState?.avis,
          document_nr: filterState?.docNr,
          paid_start: filterState?.paidStart,
          paid_end: filterState?.paidEnd,
          open_start: filterState?.debtStart,
          open_end: filterState?.debtEnd,
          order_by: ordering?.order_by,
          direction: ordering?.direction
        })
      )
    );
  }, [runAsyncProcess, ownFirm, filterState, ordering?.direction, ordering?.order_by]);

  return (
    <Page title={"Gutschriften"}>
      <PageContent>
        <AsyncContent requestStates={[state]} content={renderAsyncContent} />
      </PageContent>
    </Page>
  );

  function renderAsyncContent(status: AsyncContentStatus) {
    let node = <Fragment />;

    switch (status) {
      case "pending":
        node = <Loading />;
        break;

      case "error":
        node = <p>{"An error ocurred on server."}</p>;
        break;

      case "success":
        node = (
          <>
            <div className={"has-space-between credit-page__header"}>
              <Button
                customClassName={"button--blue"}
                onClick={() => setCreateModalVisibility(true)}>
                {"Gutschrift erstellen"}

                <PlusIcon />
              </Button>

              <Modal
                isOpen={isCreateModalOpen}
                onClose={() => setCreateModalVisibility(false)}>
                <CreateCreditForm onClose={() => setCreateModalVisibility(false)} />
              </Modal>

              <h2 className={"typography--h2"}>{"Gutschriften"}</h2>

              <Button
                customClassName={"button--primary"}
                onClick={() => setFilterState({...filterState, visibility: true})}>
                {"Filter"}

                <SettingsIcon />
              </Button>

              <Modal
                isOpen={filterState?.visibility || false}
                onClose={() => setFilterState({...filterState, visibility: false})}
                customClassName={"filter-modal"}>
                <CreditFilterForm
                  onClose={() => setFilterState({...filterState, visibility: false})}
                  onSubmit={handleFilterSubmit}
                />
              </Modal>
            </div>

            <div className={"credit-page__table-container"}>
              <Table
                customClassName={"credit-page__table"}
                data={state.data || []}
                columns={tableColumns}
                customRow={renderRow}
              />
            </div>
          </>
        );
        break;

      default:
        break;
    }

    return node;
  }

  function handleFilterSubmit(data: {
    firmName?: string;
    avis?: string;
    docNr?: string;
    paidStart?: string;
    paidEnd?: string;
    debtStart?: string;
    debtEnd?: string;
  }) {
    setFilterState({...data, visibility: false});
  }

  function renderRow(rowData: CreditResponse) {
    return (
      <div className={"credit-page__table__row-container"}>
        <Link className={"credit-page__table__row"} to={`/credits/${rowData.id}`}>
          <p className={"typography--h5"}>{rowData.avis_nr || "-"}</p>

          <p className={"typography--h5"}>{rowData.document_nr || "-"}</p>

          <p className={"typography--h5"}>{rowData.firm_name || "-"}</p>

          <p className={"typography--h5"}>
            {rowData.paid_amount ? `${rowData.paid_amount}€` : "-"}
          </p>

          <p className={"typography--h5"}>
            {rowData.open_amount ? `${rowData.open_amount}€` : "-"}
          </p>

          <p className={"typography--h5"}>
            {`${formatNumber({maximumFractionDigits: 2})(
              parseFloat(rowData.paid_amount) + parseFloat(rowData.open_amount)
            )}€`}
          </p>
        </Link>

        {rowData.file_url ? (
          <a href={rowData.file_url} target={"_blank"} rel={"noreferrer"}>
            <DocumentIcon />
          </a>
        ) : (
          "-"
        )}
      </div>
    );
  }
}

export default CreditPage;
