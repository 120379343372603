import {ReactComponent as DocumentIcon} from "../../../core/ui/icon/36px/document_36x36.svg";

import "./_file-input.scss";

import {Button, Input, InputProps} from "@hipo/react-ui-toolkit";
import {useRef} from "react";
import classNames from "classnames";

type FileInputProps = Omit<InputProps, "type"> & {
  buttonContent?: React.ReactNode;
  customClassName?: string;
};

function FileInput(props: FileInputProps) {
  const fileInput = useRef<HTMLInputElement>(null);

  return (
    <>
      <Button
        onClick={handleClick}
        customClassName={classNames("file-input__button", props.customClassName)}>
        {props.buttonContent || <DocumentIcon />}
      </Button>

      <Input
        ref={fileInput}
        type={"file"}
        {...props}
        customClassName={"file-input__hidden"}
        onChange={handleChange}
      />
    </>
  );

  function handleClick() {
    fileInput.current?.click();
  }

  function handleChange(event: React.SyntheticEvent<HTMLInputElement>) {
    props.onChange(event);
  }
}

export default FileInput;
