import "./_employee-page.scss";

import {Link} from "react-router-dom";
import {Fragment, useCallback, useEffect, useState} from "react";
import {FormField, Input} from "@hipo/react-ui-toolkit";

import PageContent from "../../component/page/content/PageContent";
import Page from "../../component/page/Page";
import Table from "../../component/table/Table";
import EmployeePageHeader from "./header/EmployeePageHeader";
import {generateEmployeeTableColumns} from "./util/employeePageUtils";
import useAsyncProcess from "../../core/network/async-process/useAsyncProcess";
import {EmployeeListItem} from "../api/employeeApiModel";
import employeeApi from "../api/employeeApi";
import {useAppContext} from "../../core/app/AppContext";
import Loading from "../../component/loading/Loading";
import AsyncContent, {
  AsyncContentStatus
} from "../../component/async-content/AsyncContent";
import {Ordering} from "../../core/util/ordering/orderingTypes";
import {filterTruthyObjectValues} from "../../core/util/object/objectUtils";
import {formatNumber} from "../../core/util/number/numberUtils";

type TableOrdering =
  | "name"
  | "holidays"
  | "remaining_holidays"
  | "salary"
  | "daily_expense";

export type EmployeeTableOrdering = Ordering<TableOrdering>;

function EmployeePage() {
  const {runAsyncProcess, state} = useAsyncProcess<EmployeeListItem[]>();
  const {
    state: {ownFirm}
  } = useAppContext();
  const [ordering, setOrdering] = useState<EmployeeTableOrdering["state"]>();
  const [tableColumns, setColumns] = useState(generateEmployeeTableColumns());
  const [employeeFilter, setEmployeeFilter] = useState(false);

  const handleOrdering = useCallback(
    (order_by: TableOrdering) => {
      setOrdering({order_by, direction: ordering?.direction === "asc" ? "des" : "asc"});
    },
    [ordering?.direction]
  );

  useEffect(() => {
    setColumns(generateEmployeeTableColumns({state: ordering, onChange: handleOrdering}));
  }, [ordering, handleOrdering]);

  useEffect(() => {
    runAsyncProcess(
      employeeApi.getWorkers(
        filterTruthyObjectValues({
          own_firm: ownFirm,
          order_by: ordering?.order_by,
          direction: ordering?.direction,
          show_quit_workers: employeeFilter
        })
      )
    );
  }, [runAsyncProcess, ownFirm, ordering?.direction, employeeFilter, ordering?.order_by]);

  return (
    <Page title={"Mitarbeiter"}>
      <PageContent>
        <AsyncContent requestStates={[state]} content={renderAsyncContent} />
      </PageContent>
    </Page>
  );

  function renderRow(rowData: EmployeeListItem) {
    return (
      <Link className={"employee-page__table__row"} to={`/employee/detail/${rowData.id}`}>
        <p className={"typography--h5"}>{rowData.name}</p>

        <p className={"typography--h5"}>{rowData.holidays}</p>

        <p className={"typography--h5"}>{rowData.remaining_holidays}</p>

        <p className={"typography--h5"}>
          {rowData.salary
            ? `${formatNumber({maximumFractionDigits: 0})(Number(rowData.salary))}€`
            : "-"}
        </p>

        <p className={"typography--h5"}>
          {rowData.daily_expense
            ? `${formatNumber({maximumFractionDigits: 0})(
                Number(rowData.daily_expense)
              )}€`
            : "-"}
        </p>
      </Link>
    );
  }

  function renderAsyncContent(status: AsyncContentStatus) {
    let node = <Fragment />;

    switch (status) {
      case "pending":
        node = <Loading />;
        break;

      case "error":
        node = <p>{"An error ocurred on server."}</p>;
        break;

      case "success":
        node = (
          <>
            <EmployeePageHeader />

            <FormField
              label={"Gekündigte Mitarbeiter zeigen."}
              labelFor={"show-fired"}
              customClassName={
                "create-credit-form__checkbox-label employee-filter-checkbox"
              }>
              <Input
                name={"show-fired"}
                type={"checkbox"}
                onChange={() => setEmployeeFilter(!employeeFilter)}
                checked={employeeFilter}
                customClassName={"create-credit-form__checkbox"}
              />
            </FormField>

            <Table
              customClassName={"employee-page__table"}
              data={state.data!}
              columns={tableColumns}
              customRow={renderRow}
            />
          </>
        );
        break;

      default:
        break;
    }

    return node;
  }
}

export default EmployeePage;
