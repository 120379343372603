import {PaymentTableOrdering} from "../TankPaymentPage";

function generateTankPaymentTableColumns(ordering?: PaymentTableOrdering) {
  return [
    {
      id: "TankPaymentTable.cardNumber",
      header: "Kartennummer",
      orderingProps: {
        isActive: ordering?.state?.order_by === "card_nr",
        onChange: () => ordering?.onChange("card_nr"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "TankPaymentTable.firmName",
      header: "Firmenname",
      orderingProps: {
        isActive: ordering?.state?.order_by === "firm",
        onChange: () => ordering?.onChange("firm"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "TankPaymentTable.driver",
      header: "Fahrer",
      orderingProps: {
        isActive: ordering?.state?.order_by === "driver",
        onChange: () => ordering?.onChange("driver"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "TankPaymentTable.label",
      header: "Etikett"
    }
  ];
}

export {generateTankPaymentTableColumns};
