import elbcargoApi from "../../core/network/elbcargo/elbcargoApi";
import {ContactResponse, SingleContactResponse} from "./contactApiModels";

const contactApi = {
  getContacts(params: {
    own_firm: string;
    name?: string;
    firm?: string;
    tag?: string;
    order_by?: string;
    direction?: string;
  }) {
    return elbcargoApi.run<ContactResponse[]>(
      {
        method: "GET",
        params
      },
      "get-contacts"
    );
  },
  getContact(params: {contact_id: string; order_by?: string; direction?: string}) {
    return elbcargoApi.run<SingleContactResponse>(
      {
        method: "GET",
        params
      },
      "get-contact"
    );
  },
  deleteContact(params: {contact_id: string}) {
    return elbcargoApi.run(
      {
        method: "POST",
        params
      },
      "delete-contact"
    );
  }
};

export default contactApi;
