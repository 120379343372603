/* eslint-disable no-nested-ternary */
import {ReactComponent as TrashIcon} from "../../../core/ui/icon/24px/trash_24x24.svg";

import "./_create-admin-form.scss";

import {useEffect, useState} from "react";
import {FormField, Input, Button, useToaster} from "@hipo/react-ui-toolkit";
import axios, {AxiosError} from "axios";

import useAsyncProcess from "../../../core/network/async-process/useAsyncProcess";
import companyApi from "../../../company/api/companyApi";
import {OwnFirm} from "../../../company/api/companyApiModel";
import {SettingsAdminDetail} from "../../api/settingsApiModel";
import settingsApi from "../../api/settingsApi";
import {BASE_URL} from "../../../core/network/util/networkConstants";
import ErrorToast from "../../../component/toast/error/ErrorToast";
import Modal from "../../../component/modal/Modal";
import DeleteModal from "../../../component/delete-modal/DeleteModal";

function CreateAdminForm({
  onSubmit,
  onDelete,
  onClose,
  data
}: {
  onSubmit: (data: SettingsAdminDetail) => void;
  onDelete: (adminId: string) => void;
  onClose: VoidFunction;
  data?: {id: string; name: string};
}) {
  const {state, runAsyncProcess} = useAsyncProcess<OwnFirm[]>();
  const {runAsyncProcess: runAdminDetails, state: detailState} =
    useAsyncProcess<SettingsAdminDetail>();
  const [formState, setFormState] = useState({
    id: data?.id || "",
    name: data?.name || "",
    username: "",
    password: "",
    all_own_firms: [{id: "", name: ""}],
    access_to_own_firms: [{id: "", name: ""}]
  });
  const toaster = useToaster();
  const [isDeleteModalOpen, setDeleteModalState] = useState(false);

  useEffect(() => {
    (async () => {
      if (data?.id) {
        const adminDetail = await runAdminDetails(
          settingsApi.getAdminDetails({admin_id: data.id})
        );

        setFormState({...adminDetail, id: data.id});
      }
    })();
  }, [runAdminDetails, data?.id]);

  useEffect(() => {
    runAsyncProcess(companyApi.getOwnFirms());
  }, [runAsyncProcess]);

  return (
    <form className={"create-admin-form"}>
      <h3 className={"typography--h3"} style={{marginBottom: "16px"}}>
        {data ? "Admin" : "Neuer Admin"}
      </h3>

      <FormField label={"Name"} labelFor={"name"}>
        <Input
          name={"name"}
          onChange={(event) =>
            setFormState({...formState, name: event.currentTarget.value})
          }
          value={formState?.name}
        />
      </FormField>

      <FormField label={"Username"} labelFor={"username"}>
        <Input
          name={"username"}
          onChange={(event) =>
            setFormState({...formState, username: event.currentTarget.value})
          }
          value={formState.username}
        />
      </FormField>

      <FormField label={"Passwort"} labelFor={"password"}>
        <Input
          name={"password"}
          onChange={(event) =>
            setFormState({...formState, password: event.currentTarget.value})
          }
          value={formState.password}
        />
      </FormField>

      <p>{"Zugriff auf:"}</p>

      <div className={"create-admin-form__company-container"}>
        {state.data?.map((firm, index) => (
          <FormField
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            label={firm.name}
            labelFor={"own_firms"}
            customClassName={"create-admin-form__company"}>
            <Input
              name={"own_firms"}
              type={"checkbox"}
              checked={
                !!formState.access_to_own_firms.find((item) => item.name === firm.name)
              }
              onChange={() =>
                setFormState({
                  ...formState,
                  access_to_own_firms: formState.access_to_own_firms.find(
                    (item) => item.name === firm.name
                  )
                    ? formState.access_to_own_firms.filter(
                        (item) => item.name !== firm.name
                      )
                    : formState.access_to_own_firms[0].name.length > 0
                    ? [...formState.access_to_own_firms, {id: firm.id, name: firm.name}]
                    : [{id: firm.id, name: firm.name}]
                })
              }
              customClassName={"create-credit-form__checkbox"}
            />
          </FormField>
        ))}
      </div>

      <div className={"form__button-group"} style={{justifyContent: "space-between"}}>
        {data?.id && (
          <>
            <Button
              customClassName={"button--large"}
              onClick={() => setDeleteModalState(true)}
              style={{padding: "12px", marginRight: "16px"}}>
              <TrashIcon />
            </Button>

            <Modal isOpen={isDeleteModalOpen} onClose={() => setDeleteModalState(false)}>
              <DeleteModal
                onSubmit={handleDelete}
                onClose={() => setDeleteModalState(false)}
              />
            </Modal>
          </>
        )}

        <Button customClassName={"button--transparent button--large"} onClick={onClose}>
          {"Abbrechen"}
        </Button>

        <Button
          customClassName={"button--primary button--large"}
          type={"submit"}
          onClick={handleSubmit}
          isDisabled={
            !(
              formState.name &&
              formState.username &&
              formState.password &&
              formState.access_to_own_firms[0].name.length > 0
            )
          }>
          {data ? "Speichern" : "Hinzufügen"}
        </Button>
      </div>
    </form>
  );

  function handleDelete() {
    if (data?.id) onDelete(data.id);
  }

  async function handleSubmit() {
    if (data?.id) {
      const formData = new FormData();

      formData.append("id", data.id);
      if (formState.access_to_own_firms[0].name.length > 0) {
        formData.append(
          "own_firms",
          formState.access_to_own_firms
            .filter((item) => item.name.length > 0)
            .map((item) => item.name)
            .join(",")
        );
      }
      if (detailState.data?.name !== formState.name)
        formData.append("name", formState.name);
      if (detailState.data?.username !== formState.username)
        formData.append("username", formState.username);
      formData.append("password", formState.password);

      try {
        await axios({
          method: "POST",
          url: `${BASE_URL}update-admin`,
          data: formData
        });
      } catch (e) {
        const err = e as AxiosError;

        toaster.display({
          render() {
            return <ErrorToast message={(err.response?.data as string) || ""} />;
          }
        });
      }

      location.reload();
    } else {
      onSubmit(formState);
    }
  }
}

export default CreateAdminForm;
