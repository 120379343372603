import elbcargoApi from "../../core/network/elbcargo/elbcargoApi";
import {SingleTourResponse, Tours} from "./tourApiModel";

const tourApi = {
  getTour(params: {own_firm: string; roller_nr: string}) {
    return elbcargoApi.run<SingleTourResponse>(
      {
        method: "GET",
        params
      },
      "get-tour"
    );
  },
  getTourList(params: {
    own_firm: string;
    firm?: string;
    status?: string;
    drivers?: string[];
    year?: string;
    month?: string;
  }) {
    return elbcargoApi.run<Tours>(
      {
        method: "GET",
        params
      },
      "get-tours"
    );
  }
};

export default tourApi;
