/* eslint-disable no-magic-numbers */
import {differenceInMinutes} from "date-fns";

function getDatesInRange(startDate: Date, endDate: Date) {
  const date = new Date(startDate.getTime());

  const dates = [];

  // eslint-disable-next-line no-unmodified-loop-condition
  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

function generateFullDateForToday(hour: string) {
  const today = new Date();

  if (hour) {
    today.setHours(Number(hour.split(":")[0]));
    today.setMinutes(Number(hour.split(":")[1]));
  }

  return today;
}

function customDifferenceInHours(
  start: Date,
  pause: {hour: string; min: string},
  end: Date
) {
  const minDiff = differenceInMinutes(end, start);
  const pauseMinDiff = Number(pause.hour) * 60 + Number(pause.min);

  const diffMin = minDiff - pauseMinDiff;

  const hours = Math.floor(diffMin / 60);
  const minutes = diffMin % 60;

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
}

export {getDatesInRange, generateFullDateForToday, customDifferenceInHours};
