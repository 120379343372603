function generateCreditDetailTableColumns() {
  return [
    {
      id: "CreditDetailTable.amount",
      header: "Betrag"
    },
    {
      id: "CreditDetailTable.date",
      header: "Bezahlt am"
    }
  ];
}

export {generateCreditDetailTableColumns};
