function generateTruckDetailTableColumns() {
  return [
    {
      id: "TruckDetailTable.documentType",
      header: "Art des Dokument"
    },
    {
      id: "TruckDetailTable.expireDate",
      header: "Verfallsdatum"
    },
    {
      id: "TruckDetailTable.document",
      header: "Dokument"
    }
  ];
}

export {generateTruckDetailTableColumns};
