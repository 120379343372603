import {ReactComponent as TrashIcon} from "../../../core/ui/icon/24px/trash_24x24.svg";

import "./_create-label-form.scss";

import React, {useEffect, useState} from "react";
import {Button, FormField, Input, List, ListItem} from "@hipo/react-ui-toolkit";
import axios from "axios";
import classNames from "classnames";

import useAsyncProcess from "../../../core/network/async-process/useAsyncProcess";
import settingsApi from "../../api/settingsApi";
import {BASE_URL} from "../../../core/network/util/networkConstants";
import Loading from "../../../component/loading/Loading";
import DeleteModal from "../../../component/delete-modal/DeleteModal";
import Modal from "../../../component/modal/Modal";

function CreateLabelForm({
  onClose,
  title,
  section,
  onSubmit,
  id = "",
  name = "",
  color = "",
  isEditForm
}: {
  onClose: VoidFunction;
  title: string;
  id: string;
  section: string;
  onSubmit: (data: {id: string; section: string; name: string; color: string}) => void;
  name?: string;
  color?: string;
  isEditForm?: boolean;
}) {
  const [formState, setFormState] = useState({
    id,
    name,
    color,
    section
  });
  const {runAsyncProcess, state} = useAsyncProcess<{id: string; hex_code: string}[]>();
  const [isDeleteModalOpen, setDeleteModalState] = useState(false);

  useEffect(() => {
    runAsyncProcess(settingsApi.getAvailableColors());
  }, [runAsyncProcess]);

  if (!state.isRequestFetched) return <Loading />;

  return (
    <div className={"create-label-form-container"}>
      <h3 className={"typography--h3"}>{title}</h3>

      <form onSubmit={handleSubmit} className={"form-field--vertical create-label-form"}>
        <FormField label={"Etikettname"} labelFor={"name"}>
          <Input
            name={"name"}
            value={formState.name}
            onChange={(event) =>
              setFormState({...formState, name: event.currentTarget.value})
            }
          />
        </FormField>

        <FormField
          label={"Farbe"}
          labelFor={"color"}
          customClassName={"create-label-form__color-list"}>
          <List items={state.data!}>
            {({hex_code}) => (
              <ListItem clickableListItemProps={{onClick: handleColorClick(hex_code)}}>
                <div
                  style={{backgroundColor: `#${hex_code}`}}
                  className={classNames("create-label-form__color", {
                    "create-label-form__color--is-active":
                      formState.color.length > 0 &&
                      (formState.color.includes(hex_code) ||
                        hex_code.includes(formState.color))
                  })}
                />
              </ListItem>
            )}
          </List>
        </FormField>

        <div className={"form__button-group"}>
          {isEditForm && (
            <>
              <Button
                customClassName={"create-label-form__delete-button"}
                onClick={() => setDeleteModalState(true)}
                style={{marginTop: "4px"}}>
                <TrashIcon />
              </Button>

              <Modal
                isOpen={isDeleteModalOpen}
                onClose={() => setDeleteModalState(false)}>
                <DeleteModal
                  onSubmit={handleDelete}
                  onClose={() => setDeleteModalState(false)}
                />
              </Modal>
            </>
          )}

          <Button customClassName={"button--transparent button--large"} onClick={onClose}>
            {"Abbrechen"}
          </Button>

          <Button
            customClassName={"button--primary button--large"}
            type={"submit"}
            isDisabled={!(formState.color.length && formState.name.length)}>
            {"Speichern"}
          </Button>
        </div>
      </form>
    </div>
  );

  async function handleDelete() {
    const formData = new FormData();

    formData.append("section", section);
    formData.append("id", id);
    await axios({
      method: "POST",
      url: `${BASE_URL}delete-tag`,
      data: formData
    });

    location.reload();
  }

  function handleSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    onSubmit(formState);
  }

  function handleColorClick(selectedColor: string) {
    return () => setFormState({...formState, color: selectedColor});
  }
}

export default CreateLabelForm;
