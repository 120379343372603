import {ContactTableOrdering} from "../ContactPage";

function generateContactTableColumns(ordering?: ContactTableOrdering) {
  return [
    {
      id: "ContactTable.name",
      header: "Name",
      orderingProps: {
        isActive: ordering?.state?.order_by === "name",
        onChange: () => ordering?.onChange("name"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "ContactTable.firmName",
      header: "Firmenname",
      orderingProps: {
        isActive: ordering?.state?.order_by === "firm",
        onChange: () => ordering?.onChange("firm"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "ContactTable.phoneNumber",
      header: "Telefonnummer",
      orderingProps: {
        isActive: ordering?.state?.order_by === "phone",
        onChange: () => ordering?.onChange("phone"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "ContactTable.mail",
      header: "Mail",
      orderingProps: {
        isActive: ordering?.state?.order_by === "mail",
        onChange: () => ordering?.onChange("mail"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "ContactTable.label",
      header: "Etikett"
    }
  ];
}

export {generateContactTableColumns};
