import {ReactComponent as PlusIcon} from "../../core/ui/icon/plus.svg";
import {ReactComponent as SettingsIcon} from "../../core/ui/icon/settings.svg";

import "./_truck-page.scss";

import {Fragment, useCallback, useEffect, useState} from "react";
import {Button} from "@hipo/react-ui-toolkit";
import {Link, useSearchParams} from "react-router-dom";

import PageContent from "../../component/page/content/PageContent";
import Page from "../../component/page/Page";
import Table from "../../component/table/Table";
import {generateTruckTableColumns} from "./util/truckPageUtils";
import Modal from "../../component/modal/Modal";
import CreateTruckFrom from "../form/create-truck/CreateTruckForm";
import useAsyncProcess from "../../core/network/async-process/useAsyncProcess";
import {Truck} from "../api/truckApiModel";
import truckApi from "../api/truckApi";
import {useAppContext} from "../../core/app/AppContext";
import AsyncContent, {
  AsyncContentStatus
} from "../../component/async-content/AsyncContent";
import Loading from "../../component/loading/Loading";
import TruckFilterForm from "../form/truck-filter/TruckFilterForm";
import {filterTruthyObjectValues} from "../../core/util/object/objectUtils";
import {Ordering} from "../../core/util/ordering/orderingTypes";

export type TruckTableOrdering = Ordering<"plate" | "manufacturer">;

function TruckPage() {
  const [_searchParams, setSearchParams] = useSearchParams();
  const [isFormOpen, setFormVisibility] = useState(false);
  const [isFilterOpen, setFilterVisibility] = useState(false);
  const {runAsyncProcess, state, setState} = useAsyncProcess<Truck[]>();
  const {
    state: {ownFirm}
  } = useAppContext();
  const [filterState, setFilterState] = useState<{
    plate?: string;
    manufacturer?: string;
    model?: string;
  }>();
  const [ordering, setOrdering] = useState<TruckTableOrdering["state"]>();
  const [tableColumns, setColumns] = useState(generateTruckTableColumns());

  const handleOrdering = useCallback(
    (order_by: "plate" | "manufacturer") => {
      setOrdering({order_by, direction: ordering?.direction === "asc" ? "des" : "asc"});
    },
    [ordering?.direction]
  );

  useEffect(() => {
    setColumns(generateTruckTableColumns({state: ordering, onChange: handleOrdering}));
  }, [ordering, handleOrdering]);

  useEffect(() => {
    runAsyncProcess(
      truckApi.getTrucks(
        filterTruthyObjectValues({
          own_firm: ownFirm,
          ...filterState,
          order_by: ordering?.order_by,
          direction: ordering?.direction
        })
      )
    );
  }, [ownFirm, runAsyncProcess, filterState, ordering?.order_by, ordering?.direction]);

  return (
    <Page title={"LKW-Datenbank"}>
      <PageContent>
        <AsyncContent requestStates={[state]} content={renderAsyncContent} />
      </PageContent>
    </Page>
  );

  function renderAsyncContent(status: AsyncContentStatus) {
    let node = <Fragment />;

    switch (status) {
      case "pending":
        node = <Loading />;
        break;

      case "error":
        node = <p>{"An error ocurred on server."}</p>;
        break;

      case "success":
        node = (
          <>
            <div className={"has-space-between"}>
              <Button customClassName={"button--blue"} onClick={handleCreateTruckClick}>
                {"LKW hinzufügen"}

                <PlusIcon />
              </Button>

              <Modal isOpen={isFormOpen} onClose={handleFormClose}>
                <CreateTruckFrom
                  onClose={handleFormClose}
                  onSubmit={handleCreateTruckSubmit}
                />
              </Modal>

              <h2 className={"typography--h2"}>{"LKW-Datenbank"}</h2>

              <Button
                onClick={() => setFilterVisibility(true)}
                customClassName={"button--primary text-color--light typography--h8"}>
                {"Filter"}

                <SettingsIcon />
              </Button>

              <Modal
                isOpen={isFilterOpen}
                onClose={() => setFilterVisibility(false)}
                customClassName={"filter-modal"}>
                <TruckFilterForm
                  onClose={() => setFilterVisibility(false)}
                  onSubmit={(filterProps) => {
                    setFilterState(filterProps);
                    setFilterVisibility(false);
                  }}
                />
              </Modal>
            </div>

            <div className={"truck-page__table-container"}>
              <Table
                customClassName={"truck-page__table"}
                data={state.data || []}
                columns={tableColumns}
                customRow={renderRow}
              />
            </div>
          </>
        );
        break;

      default:
        break;
    }

    return node;
  }

  function renderRow(rowData: Truck) {
    return (
      <Link
        className={"truck-page__table__row"}
        to={`/truck/${rowData.plate}`}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => handleRowClick(rowData.plate)}>
        <p className={"typography--h5"}>{rowData.plate}</p>

        <p className={"typography--h5"}>{`${rowData.manufacturer} / ${rowData.model}`}</p>
      </Link>
    );
  }

  function handleCreateTruckSubmit(truck: Truck) {
    setState({
      ...state,
      data: state.data ? [...state.data, truck] : []
    });
  }

  function handleRowClick(plate: string) {
    setSearchParams({plate});
  }

  function handleCreateTruckClick() {
    setFormVisibility(true);
  }

  function handleFormClose() {
    setFormVisibility(false);
  }
}

export default TruckPage;
