function checkObjects(arr: any[]) {
  let flag = true;

  arr.forEach((obj) => {
    if (
      Object.values(obj).some((item) => item) &&
      Object.values(obj).some((item) => !item)
    ) {
      flag = false;
    }
  });

  return flag;
}

export {checkObjects};
