import {Suspense} from "react";
import {Routes, Route} from "react-router-dom";

import RouteLoading from "../route/loading/RouteLoading";
import ROUTES from "../route/routes";
import {AppContextProvider} from "./AppContext";
import HomePage from "../../home/HomePage";
import TourPage from "../../tour/page/TourPage";
import EmployeePage from "../../employee/page/EmployeePage";
import LoginPage from "../../authentication/login/LoginPage";
import EmployeeDetailPage from "../../employee/page/detail/EmployeeDetailPage";
import BillPage from "../../bill/page/BillPage";
import CreditPage from "../../credit/page/CreditPage";
import CreditDetailPage from "../../credit/page/detail/CreditDetailPage";
import TruckPage from "../../truck/page/TruckPage";
import TruckDetailPage from "../../truck/page/detail/TruckDetailPage";
import TankPaymentPage from "../../tank-payment/page/TankPaymentPage";
import TankPaymentDetailPage from "../../tank-payment/page/detail/TankPaymentDetailPage";
import ContactPage from "../../contact/page/ContactPage";
import ContactDetailPage from "../../contact/page/detail/ContactDetailPage";
import SettingsPage from "../../settings/page/SettingsPage";
import VacationManagementPage from "../../vacation/management/page/VacationManagementPage";
import CreateBillForm from "../../bill/form/create-bill/CreateBillForm";
import VacationPlanningPage from "../../vacation/planning/page/VacationPlanningPage";
import CompanySelect from "../../company/select/CompanySelect";
import CreateCompany from "../../company/create/CreateCompany";
import RequireAccount from "../route/RequireAccount";
import EmployeeExpensesPage from "../../employee/page/expenses/EmployeeExpensesPage";
import LabelTab from "../../settings/page/tab/label/LabelTab";
import CompanyTab from "../../settings/page/tab/company/CompanyTab";
import AdminTab from "../../settings/page/tab/admin/AdminTab";
import LogTab from "../../settings/page/tab/log/LogTab";

function App() {
  return (
    <Suspense fallback={<RouteLoading />}>
      <AppContextProvider>
        <Routes>
          <Route
            path={ROUTES.HOME}
            element={
              <RequireAccount>
                <HomePage />
              </RequireAccount>
            }
          />

          <Route
            path={ROUTES.TOUR}
            element={
              <RequireAccount>
                <TourPage />
              </RequireAccount>
            }
          />

          <Route
            path={ROUTES.EMPLOYEE}
            element={
              <RequireAccount>
                <EmployeePage />
              </RequireAccount>
            }
          />

          <Route
            path={ROUTES.EMPLOYEE_DETAIL}
            element={
              <RequireAccount>
                <EmployeeDetailPage />
              </RequireAccount>
            }
          />

          <Route
            path={ROUTES.EMPLOYEE_EXPENSES}
            element={
              <RequireAccount>
                <EmployeeExpensesPage />
              </RequireAccount>
            }
          />

          <Route
            path={ROUTES.BILLS}
            element={
              <RequireAccount>
                <BillPage />
              </RequireAccount>
            }
          />

          <Route
            path={ROUTES.BILL_CREATE}
            element={
              <RequireAccount>
                <CreateBillForm />
              </RequireAccount>
            }
          />

          <Route path={ROUTES.LOGIN} element={<LoginPage />} />

          <Route
            path={ROUTES.CREDITS}
            element={
              <RequireAccount>
                <CreditPage />
              </RequireAccount>
            }
          />

          <Route
            path={ROUTES.CREDIT_DETAIL}
            element={
              <RequireAccount>
                <CreditDetailPage />
              </RequireAccount>
            }
          />

          <Route
            path={ROUTES.TRUCK}
            element={
              <RequireAccount>
                <TruckPage />
              </RequireAccount>
            }
          />

          <Route
            path={ROUTES.TRUCK_DETAIL}
            element={
              <RequireAccount>
                <TruckDetailPage />
              </RequireAccount>
            }
          />

          <Route
            path={ROUTES.TANK_PAYMENT}
            element={
              <RequireAccount>
                <TankPaymentPage />
              </RequireAccount>
            }
          />

          <Route
            path={ROUTES.TANK_PAYMENT_DETAIL}
            element={
              <RequireAccount>
                <TankPaymentDetailPage />
              </RequireAccount>
            }
          />

          <Route
            path={ROUTES.CONTACTS}
            element={
              <RequireAccount>
                <ContactPage />
              </RequireAccount>
            }
          />

          <Route
            path={ROUTES.CONTACT_DETAIL}
            element={
              <RequireAccount>
                <ContactDetailPage />
              </RequireAccount>
            }
          />

          <Route
            path={ROUTES.SETTINGS_TAG}
            element={
              <RequireAccount>
                <SettingsPage>
                  <LabelTab />
                </SettingsPage>
              </RequireAccount>
            }
          />

          <Route
            path={ROUTES.SETTINGS_FIRM}
            element={
              <RequireAccount>
                <SettingsPage>
                  <CompanyTab />
                </SettingsPage>
              </RequireAccount>
            }
          />
          <Route
            path={ROUTES.SETTINGS_ADMIN}
            element={
              <RequireAccount>
                <SettingsPage>
                  <AdminTab />
                </SettingsPage>
              </RequireAccount>
            }
          />
          <Route
            path={ROUTES.SETTINGS_LOG}
            element={
              <RequireAccount>
                <SettingsPage>
                  <LogTab />
                </SettingsPage>
              </RequireAccount>
            }
          />

          <Route
            path={ROUTES.VACATION_USAGE_MANAGEMENT}
            element={
              <RequireAccount>
                <VacationManagementPage />
              </RequireAccount>
            }
          />

          <Route
            path={ROUTES.VACATION_PLANNING}
            element={
              <RequireAccount>
                <VacationPlanningPage />
              </RequireAccount>
            }
          />

          <Route path={ROUTES.COMPANY_SELECT} element={<CompanySelect />} />

          <Route
            path={ROUTES.COMPANY_CREATE}
            element={
              <RequireAccount>
                <CreateCompany />
              </RequireAccount>
            }
          />

          <Route
            path={"*"}
            element={
              <RequireAccount>
                <HomePage />
              </RequireAccount>
            }
          />
        </Routes>
      </AppContextProvider>
    </Suspense>
  );
}

export default App;
