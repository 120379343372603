import {ReactComponent as ArrowRightIcon} from "../../../core/ui/icon/arrow-right.svg";
import {ReactComponent as TrashIcon} from "../../../core/ui/icon/24px/trash_24x24.svg";
import {ReactComponent as PlusIcon} from "../../../core/ui/icon/plus.svg";
import {ReactComponent as EditIcon} from "../../../core/ui/icon/24px/edit.svg";

import "./_tank-payment-detail-page.scss";

import {Button} from "@hipo/react-ui-toolkit";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {format} from "date-fns";
import {Fragment, useCallback, useEffect, useState} from "react";
import axios from "axios";

import PageContent from "../../../component/page/content/PageContent";
import Page from "../../../component/page/Page";
import ROUTES from "../../../core/route/routes";
import Table from "../../../component/table/Table";
import {generateTankPaymentDetailTableColumns} from "./util/tankPaymentDetailPageUtils";
import {DATE_FORMAT} from "../../../core/util/time/timeConstants";
import useAsyncProcess from "../../../core/network/async-process/useAsyncProcess";
import {
  SingleTankPaymentFuelCard,
  SingleTankPaymentFuelCardActivity
} from "../../api/tankPaymentApiModel";
import tankPaymentApi from "../../api/tankPaymentApi";
import Label from "../../../component/label/Label";
import AsyncContent, {
  AsyncContentStatus
} from "../../../component/async-content/AsyncContent";
import Loading from "../../../component/loading/Loading";
import Modal from "../../../component/modal/Modal";
import DeleteModal from "../../../component/delete-modal/DeleteModal";
import {BASE_URL} from "../../../core/network/util/networkConstants";
import CreateDriverForm from "../../form/create-driver/CreateDriverForm";
import CreatePaymentForm from "../../form/create-payment/CreatePaymentForm";
import {useAppContext} from "../../../core/app/AppContext";
import {Ordering} from "../../../core/util/ordering/orderingTypes";
import {filterTruthyObjectValues} from "../../../core/util/object/objectUtils";

type TableOrdering = "driver" | "got_date" | "gave_back_date";

export type PaymentDetailTableOrdering = Ordering<TableOrdering>;

function TankPaymentDetailPage() {
  const {tankPaymentId} = useParams<{tankPaymentId: string}>();
  const {state, runAsyncProcess} = useAsyncProcess<SingleTankPaymentFuelCard>();
  const [isDeleteModalOpen, setDeleteModalVisibility] = useState(false);
  const [isCreateDriverModalOpen, setCreateDriverModal] = useState(false);
  const [isEditModalOpen, setEditModalVisibility] = useState(false);
  const navigate = useNavigate();
  const {
    state: {user, globalFirms}
  } = useAppContext();
  const [rowDeleteModal, setRowDeleteModal] = useState({visibility: false, id: ""});
  const [ordering, setOrdering] = useState<PaymentDetailTableOrdering["state"]>();
  const [tableColumns, setColumns] = useState(generateTankPaymentDetailTableColumns());

  const handleOrdering = useCallback(
    (order_by: TableOrdering) => {
      setOrdering({order_by, direction: ordering?.direction === "asc" ? "des" : "asc"});
    },
    [ordering?.direction]
  );

  useEffect(() => {
    setColumns(
      generateTankPaymentDetailTableColumns({state: ordering, onChange: handleOrdering})
    );
  }, [ordering, handleOrdering]);

  useEffect(() => {
    if (tankPaymentId) {
      runAsyncProcess(
        tankPaymentApi.getFuelCard(
          filterTruthyObjectValues({
            card_id: tankPaymentId,
            order_by: ordering?.order_by,
            direction: ordering?.direction
          })
        )
      );
    }
  }, [tankPaymentId, runAsyncProcess, ordering?.direction, ordering?.order_by]);

  return (
    <Page title={"Tank-Zahlungsverfolgung"}>
      <PageContent>
        <div className={"has-space-between"}>
          <div className={"tank-payment-detail-page__header__breadcrumb"}>
            <NavLink
              to={ROUTES.TANK_PAYMENT}
              className={"typography--h5 text-color--secondary"}>
              {"Tank-Zahlungsverfolgung"}
            </NavLink>

            <ArrowRightIcon />

            <p className={"typography--h5 text-color--primary"}>
              {state.data?.details.card_nr}
            </p>
          </div>

          <Button
            onClick={() => setDeleteModalVisibility(true)}
            customClassName={
              "button--transparent tank-payment-detail-page__header__delete-button"
            }>
            <TrashIcon />

            {"Löschen"}
          </Button>

          <Modal
            isOpen={isDeleteModalOpen}
            onClose={() => setDeleteModalVisibility(false)}>
            <DeleteModal
              onSubmit={handleDelete}
              text={state.data?.details.card_nr}
              onClose={() => setDeleteModalVisibility(false)}
            />
          </Modal>
        </div>

        <AsyncContent requestStates={[state]} content={renderAsyncContent} />
      </PageContent>
    </Page>
  );

  function renderAsyncContent(status: AsyncContentStatus) {
    let node = <Fragment />;

    switch (status) {
      case "pending":
        node = <Loading />;
        break;

      case "error":
        node = <p>{"An error ocurred on server."}</p>;
        break;

      case "success":
        node = (
          <div>
            <div style={{marginTop: "50px", display: "flex"}}>
              <h2 className={"typography--h1 text-color--blue"}>
                {"Karteninformationen"}
              </h2>

              <Button
                onClick={() => setEditModalVisibility(true)}
                style={{backgroundColor: "transparent"}}>
                <EditIcon />
              </Button>
            </div>

            <Modal isOpen={isEditModalOpen} onClose={() => setEditModalVisibility(false)}>
              <CreatePaymentForm
                updateId={tankPaymentId}
                id={state.data?.details.card_nr}
                firm={globalFirms?.fuelcard_firms.find(
                  (item) => item.name === state.data?.details.firm_name
                )}
                label={state.data?.details.status}
                note={state.data?.details.notes}
                onClose={() => setEditModalVisibility(false)}
              />
            </Modal>

            <div className={"tank-payment-detail-page__info-container"}>
              <div>
                <div className={"tank-payment-detail-page__info__field"}>
                  <label
                    htmlFor={"card-number"}
                    className={"typography--h7 text-color--primary"}>
                    {"Kartennummer:"}
                  </label>
                  <p className={"typography--h5 text-color--primary"}>
                    {state.data?.details.card_nr || "-"}
                  </p>
                </div>

                <div className={"tank-payment-detail-page__info__field"}>
                  <label
                    htmlFor={"firm-name"}
                    className={"typography--h7 text-color--primary"}>
                    {"Firmenname:"}
                  </label>
                  <p className={"typography--h5 text-color--primary"}>
                    {state.data?.details.firm_name || "-"}
                  </p>
                </div>

                <div className={"tank-payment-detail-page__info__field"}>
                  <label
                    htmlFor={"label"}
                    className={"typography--h7 text-color--primary"}>
                    {"Etikett:"}
                  </label>
                  <Label
                    name={state.data?.details.status || "-"}
                    color={
                      state.data?.details.status === "Aktiv"
                        ? "var(--tag-green-2)"
                        : "var(--tag-pink-3)"
                    }
                  />
                </div>
              </div>

              <div>
                <label htmlFor={"note"} className={"typography--h7 text-color--primary"}>
                  {"Notizen:"}
                </label>
                <p
                  className={
                    "tank-payment-detail-page-info__field--note typography--b3 text-color--primary"
                  }>
                  {state.data?.details.notes || "-"}
                </p>
              </div>
            </div>

            <Modal
              isOpen={rowDeleteModal.visibility}
              onClose={() => setRowDeleteModal({...rowDeleteModal, visibility: false})}>
              <DeleteModal
                fullText={"Möchten Sie wirklich löschen?"}
                onClose={() => setRowDeleteModal({...rowDeleteModal, visibility: false})}
                onSubmit={handleActivityDelete}
              />
            </Modal>

            <div className={"tank-payment-detail-page__payment-history"}>
              <div className={"has-space-between"}>
                <h2 className={"typography--h1 text-color--blue"}>{"Kartenverlauf"}</h2>

                <Button
                  onClick={() => setCreateDriverModal(true)}
                  customClassName={"button--blue"}>
                  {"Neuer Besitzer"}

                  <PlusIcon />
                </Button>

                <Modal
                  isOpen={isCreateDriverModalOpen}
                  onClose={() => setCreateDriverModal(false)}>
                  <CreateDriverForm
                    cardId={tankPaymentId!}
                    onClose={() => setCreateDriverModal(false)}
                  />
                </Modal>
              </div>

              <Table
                customClassName={"tank-payment-detail-page__table"}
                data={state.data?.activities || []}
                columns={tableColumns}
                customRow={renderRow}
              />
            </div>
          </div>
        );
        break;

      default:
        break;
    }

    return node;
  }

  async function handleDelete() {
    const formData = new FormData();

    if (tankPaymentId) formData.append("card_id", tankPaymentId);
    formData.append("admin", user!.hash);

    await axios({
      method: "POST",
      url: `${BASE_URL}delete-fuelcard`,
      data: formData
    });

    navigate(ROUTES.TANK_PAYMENT);
  }

  function renderRow(rowData: SingleTankPaymentFuelCardActivity) {
    return (
      <div className={"table-row tank-payment-detail-page__table__row"}>
        <p className={"typography--h5"}>{rowData.driver}</p>

        <p className={"typography--h5"}>
          {format(new Date(rowData.got_date), DATE_FORMAT.DEFAULT)}
        </p>

        <p className={"typography--h5"}>
          {rowData.gave_back_date
            ? format(new Date(rowData.gave_back_date), DATE_FORMAT.DEFAULT)
            : "-"}
        </p>

        <Button
          customClassName={"tank-payment-detail-page__table__row__delete"}
          onClick={() => setRowDeleteModal({id: rowData.id, visibility: true})}>
          <TrashIcon />
        </Button>
      </div>
    );
  }

  async function handleActivityDelete() {
    const formData = new FormData();

    formData.append("activity_id", rowDeleteModal.id);

    await axios({
      method: "POST",
      url: `${BASE_URL}delete-fuelcard-activity`,
      data: formData
    });

    location.reload();
  }
}

export default TankPaymentDetailPage;
