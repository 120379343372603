/* eslint-disable complexity */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import {ReactComponent as ArrowRightIcon} from "../../../core/ui/icon/arrow-right.svg";
import {ReactComponent as PlusIcon} from "../../../core/ui/icon/plus.svg";

import "./_create-bill-form.scss";

import {NavLink, useNavigate} from "react-router-dom";
import {
  FormField,
  TypeaheadSelect,
  DropdownOption,
  Input,
  Button
} from "@hipo/react-ui-toolkit";
import {useState} from "react";
import axios from "axios";
import {format} from "date-fns";
// import {Viewer} from "@pdfme/ui";

import PageContent from "../../../component/page/content/PageContent";
import Page from "../../../component/page/Page";
import ROUTES from "../../../core/route/routes";
import {useAppContext} from "../../../core/app/AppContext";
import DateInput from "../../../component/input/date/DateInput";
import {BillProduct} from "../../api/billApiModel";
import {BASE_URL} from "../../../core/network/util/networkConstants";
import {DATE_FORMAT} from "../../../core/util/time/timeConstants";
import {checkObjects} from "../../../core/util/array/arrayUtils";

function CreateBillForm() {
  const [sendingState, setSendingState] = useState(false);
  const [formState, setFormState] = useState<{
    firmList: DropdownOption[];
    vat: string;
    address: string;
    creationDate: null | Date;
    expireDateStart: null | Date;
    expireDateEnd: null | Date;
    isTaxesIncluded: boolean;
    tax: string;
    products: BillProduct[];
    docNr: null | string;
  }>({
    firmList: [],
    vat: "",
    address: "",
    creationDate: null,
    expireDateStart: null,
    expireDateEnd: null,
    isTaxesIncluded: true,
    tax: "19",
    products: [{name: "", price: "", amount: "", unit: ""}],
    docNr: null
  });
  // const [logo, setLogo] = useState("");
  const {
    state: {ownFirm, user, globalFirms}
  } = useAppContext();
  const firmDropdownOptions: DropdownOption[] = globalFirms
    ? globalFirms?.tour_firms.map(
        (firm) => ({id: firm.name, title: firm.name} as DropdownOption)
      )
    : [];
  const navigate = useNavigate();
  // const {runAsyncProcess, state} = useAsyncProcess<OwnFirm[]>();
  // const billOwnFirm = state.data?.find((item) => item.name === ownFirm);

  // useEffect(() => {
  //   runAsyncProcess(companyApi.getOwnFirms({admin: user!.hash}));
  // }, [user, runAsyncProcess]);

  // useEffect(() => {
  //   getBase64Image(billOwnFirm?.logo as string, (base64) => {
  //     setLogo(base64 as string);
  //   });
  // }, [billOwnFirm?.logo]);

  // const {template, inputs} = pdfGenerator({
  //   products: generateProductRows(formState.products, formState.tax),
  //   ownFirm: billOwnFirm,
  //   billNr: formState.docNr,
  //   creationDate: formState.creationDate,
  //   expireDate: formState.expireDateEnd,
  //   firm: formState.firmList.length > 0 ? formState.firmList[0].title : "",
  //   firmAddress: formState.address,
  //   logo: logo || ""
  // });

  // useEffect(() => {
  //   // eslint-disable-next-line no-new
  //   new Viewer({domContainer: document.querySelector("#pdf-viewer")!, template, inputs});
  // }, [template, inputs]);

  return (
    <Page title={"Rechnung erstellen"}>
      <PageContent>
        <div className={"truck-detail-page__header__breadcrumb"}>
          <NavLink to={ROUTES.BILLS} className={"typography--h5 text-color--secondary"}>
            {"Rechnungen"}
          </NavLink>

          <ArrowRightIcon />

          <p className={"typography--h5 text-color--primary"}>{"Rechnung erstellen"}</p>
        </div>

        <div id={"pdf-viewer"} />

        <div className={"create-bill-form__section-container"}>
          <h1 className={"typography--h1 text-color--blue"}>
            {"Rechnung Informationen"}
          </h1>

          <div className={"create-bill-form__section"}>
            <div>
              <FormField label={"Firmenname"} labelFor={"firm-list"}>
                <TypeaheadSelect
                  dropdownOptions={firmDropdownOptions}
                  selectedOptions={formState.firmList}
                  customClassName={""}
                  selectedOptionLimit={1}
                  onSelect={(option) =>
                    setFormState({
                      ...formState,
                      firmList: [option],
                      vat:
                        globalFirms?.tour_firms &&
                        globalFirms.tour_firms.find((item) => item.name === option.title)
                          ?.vat
                          ? globalFirms.tour_firms.find(
                              (item) => item.name === option.title
                            )?.vat || ""
                          : "",
                      address:
                        globalFirms?.tour_firms &&
                        globalFirms.tour_firms.find((item) => item.name === option.title)
                          ?.address
                          ? globalFirms.tour_firms.find(
                              (item) => item.name === option.title
                            )?.address || ""
                          : ""
                    })
                  }
                  typeaheadProps={{
                    placeholder: "Suche",
                    name: "firm-list"
                  }}
                  onTagRemove={() => setFormState({...formState, firmList: []})}
                />
              </FormField>

              <FormField label={"VAT"} labelFor={"vat"}>
                <Input
                  name={"vat"}
                  onChange={(event) =>
                    setFormState({...formState, vat: event.currentTarget.value})
                  }
                  value={formState.vat}
                />
              </FormField>

              <FormField label={"Adresse"} labelFor={"address"}>
                <Input
                  name={"address"}
                  onChange={(event) =>
                    setFormState({...formState, address: event.currentTarget.value})
                  }
                  value={formState.address}
                />
              </FormField>
            </div>

            <div>
              <FormField label={"Erstellungsdatum"} labelFor={"creation-date"}>
                <DateInput
                  name={"creation-date"}
                  onChange={(selectedDate) =>
                    setFormState({...formState, creationDate: selectedDate || new Date()})
                  }
                  selected={formState.creationDate}
                />
              </FormField>

              <FormField
                label={"Leistungszeitraum (optional)"}
                labelFor={"expire-date-start"}>
                <div className={"create-bill-form__expire-date-container"}>
                  <DateInput
                    name={"expire-date-start"}
                    onChange={(selectedDate) =>
                      setFormState({
                        ...formState,
                        expireDateStart: selectedDate || new Date()
                      })
                    }
                    selected={formState.expireDateStart}
                  />

                  {"-"}

                  <DateInput
                    name={"expire-date-end"}
                    onChange={(selectedDate) =>
                      setFormState({
                        ...formState,
                        expireDateEnd: selectedDate || new Date()
                      })
                    }
                    selected={formState.expireDateEnd}
                  />
                </div>
              </FormField>

              <FormField
                label={"Inklusive Mwst"}
                labelFor={"tax-included"}
                customClassName={"create-bill-form__checkbox-label"}>
                <Input
                  name={"tax-included"}
                  type={"checkbox"}
                  onChange={() =>
                    setFormState({
                      ...formState,
                      isTaxesIncluded: !formState.isTaxesIncluded
                    })
                  }
                  checked={formState.isTaxesIncluded}
                  customClassName={"create-bill-form__checkbox"}
                />
              </FormField>

              {formState.isTaxesIncluded && (
                <FormField label={"Steuerprozent"} labelFor={"taxes"}>
                  <div className={"create-bill-form__tax-input"}>
                    <span className={"typography--h5"}>{"%"}</span>

                    <Input
                      name={"taxes"}
                      onChange={(event) =>
                        setFormState({...formState, tax: event.currentTarget.value})
                      }
                      value={formState.tax}
                    />
                  </div>
                </FormField>
              )}
            </div>
          </div>
        </div>

        <div className={"create-bill-form__section-container"}>
          <h1 className={"typography--h1 text-color--blue"}>{"Produkte"}</h1>

          <Button
            customClassName={"button--transparent"}
            style={{marginTop: "12px"}}
            onClick={() =>
              setFormState({
                ...formState,
                products: Object.values(formState.products[0]).every(
                  (item) => item.length > 0
                )
                  ? [...formState.products, {name: "", price: "", amount: "", unit: ""}]
                  : formState.products
              })
            }>
            <PlusIcon />

            {"Produkt hinzufügen"}
          </Button>

          {formState.products.map((product, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={"create-bill-form__section"}>
              <div>
                <FormField label={"Produktname"} labelFor={"product-name"}>
                  <Input
                    name={"product-name"}
                    onChange={(event) => {
                      const data = [...formState.products];

                      data[index].name = event.currentTarget.value;
                      setFormState({
                        ...formState,
                        products: data
                      });
                    }}
                    value={product.name}
                  />
                </FormField>

                <FormField label={"Preis je Menge"} labelFor={"price"}>
                  <Input
                    name={"price"}
                    leftIcon={"€"}
                    onChange={(event) => {
                      const data = [...formState.products];

                      data[index].price = event.currentTarget.value;
                      setFormState({
                        ...formState,
                        products: data
                      });
                    }}
                    value={product.price}
                  />
                </FormField>
              </div>

              <div>
                <FormField label={"Menge"} labelFor={"amount"}>
                  <Input
                    name={"amount"}
                    onChange={(event) => {
                      const data = [...formState.products];

                      data[index].amount = event.currentTarget.value;
                      setFormState({
                        ...formState,
                        products: data
                      });
                    }}
                    value={product.amount}
                  />
                </FormField>

                <FormField label={"Mengeneinheit:"} labelFor={"unit"}>
                  <Input
                    name={"unit"}
                    onChange={(event) => {
                      const data = [...formState.products];

                      data[index].unit = event.currentTarget.value;
                      setFormState({
                        ...formState,
                        products: data
                      });
                    }}
                    value={product.unit}
                  />
                </FormField>
              </div>
            </div>
          ))}
        </div>

        <div
          className={"form__button-group create-bill-form__button-group"}
          style={{justifyContent: "space-between"}}>
          <Button
            customClassName={"button--transparent button--large"}
            onClick={handleCancel}>
            {"Abbrechen"}
          </Button>

          <Button
            shouldDisplaySpinner={sendingState}
            isDisabled={
              !(formState.firmList[0] &&
              formState.vat &&
              formState.address &&
              formState.creationDate &&
              formState.tax &&
              // If you are trying to understand this, sorry man
              Object.values(formState.products[formState.products.length - 1]).every(
                (item) => item.length > 0
              ) &&
              checkObjects(formState.products) &&
              formState.expireDateStart === null
                ? formState.expireDateEnd === null
                : formState.expireDateEnd !== null)
            }
            customClassName={"button--primary button--large"}
            type={"submit"}
            onClick={handleSubmit}>
            {"Speichern"}
          </Button>
        </div>
      </PageContent>
    </Page>
  );

  async function handleSubmit() {
    setSendingState(true);
    const formData = new FormData();

    formData.append("own_firm", ownFirm);
    formData.append("admin", user!.hash);
    formData.append("firm", formState.firmList[0].id);
    formData.append("customer_tax_nr", formState.vat);
    formData.append("address", formState.address);
    formData.append(
      "creation_date",
      String(format(new Date(formState.creationDate!), DATE_FORMAT.API))
    );
    if (formState.expireDateStart)
      formData.append(
        "has_to_be_paid_date_start",
        String(format(new Date(formState.expireDateStart), DATE_FORMAT.API))
      );

    if (formState.expireDateEnd)
      formData.append(
        "has_to_be_paid_date_end",
        String(format(new Date(formState.expireDateEnd), DATE_FORMAT.API))
      );

    formData.append("taxes", formState.isTaxesIncluded ? formState.tax : "0");
    formData.append(
      "products",
      JSON.stringify(
        formState.products.map((product, index) => ({
          position: index + 1,
          description: product.name,
          amount: parseInt(product.amount),
          unit: product.unit,
          unit_price: parseFloat(product.price)
        }))
      )
    );

    try {
      await axios({
        method: "POST",
        url: `${BASE_URL}create-bill`,
        data: formData
      }).then((response) => {
        const link = document.createElement("a");

        link.href = `data:application/pdf;base64,${response.data.file}`;
        link.setAttribute("download", response.data.name);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);

        navigate(ROUTES.BILLS);
      });

      // generate({template, inputs}).then((pdf) => {
      //   (async () => {
      //     const blob = new File([pdf.buffer], `bill-${new Date()}.pdf`);
      //     const fileFormData = new FormData();

      //     fileFormData.append("bill_id", billId.data);
      //     fileFormData.append("file", blob);

      //     await axios({
      //       method: "POST",
      //       url: `${BASE_URL}add-bill-document`,
      //       data: fileFormData
      //     });

      //     window.open(
      //       URL.createObjectURL(new Blob([pdf.buffer], {type: "application/pdf"}))
      //     );

      //     navigate(ROUTES.BILLS);
      //   })();
      // });
    } catch (e) {
      console.error(e);

      setSendingState(false);
    }
  }

  function handleCancel() {
    navigate(ROUTES.BILLS);
  }
}

export default CreateBillForm;
