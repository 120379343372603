/* eslint-disable max-lines */
import {ReactComponent as ArrowRightIcon} from "../../../core/ui/icon/arrow-right.svg";
import {ReactComponent as TrashIcon} from "../../../core/ui/icon/24px/trash_24x24.svg";
import {ReactComponent as PlusIcon} from "../../../core/ui/icon/plus.svg";
import {ReactComponent as EditIcon} from "../../../core/ui/icon/24px/edit.svg";
import {ReactComponent as DocumentIcon} from "../../../core/ui/icon/document.svg";

import "./_credit-detail-page.scss";

import {NavLink, useNavigate, useParams} from "react-router-dom";
import {Button} from "@hipo/react-ui-toolkit";
import {format} from "date-fns";
import {Fragment, useEffect, useState} from "react";
import axios from "axios";

import PageContent from "../../../component/page/content/PageContent";
import Page from "../../../component/page/Page";
import ROUTES from "../../../core/route/routes";
import Table from "../../../component/table/Table";
import {generateCreditDetailTableColumns} from "./util/creditDetailPageUtils";
import {DATE_FORMAT} from "../../../core/util/time/timeConstants";
import useAsyncProcess from "../../../core/network/async-process/useAsyncProcess";
import {SingleCreditPayment, SingleCreditResponse} from "../../api/creditApiModel";
import creditApi from "../../api/creditApi";
import AsyncContent, {
  AsyncContentStatus
} from "../../../component/async-content/AsyncContent";
import Loading from "../../../component/loading/Loading";
import Modal from "../../../component/modal/Modal";
import CreditForm from "./form/CreditForm";
import {BASE_URL} from "../../../core/network/util/networkConstants";
import {useAppContext} from "../../../core/app/AppContext";
import CreateCreditForm from "../../form/create-credit/CreateCreditForm";
import {formatNumber} from "../../../core/util/number/numberUtils";
import DeleteModal from "../../../component/delete-modal/DeleteModal";

function CreditDetailPage() {
  const {creditId} = useParams<{creditId: string}>();
  const {state, runAsyncProcess} = useAsyncProcess<SingleCreditResponse>();
  const [isModalOpen, setModalVisibility] = useState(false);
  const {
    state: {user, globalFirms}
  } = useAppContext();
  const [editModalState, setEditModalState] = useState(false);
  const navigate = useNavigate();
  const selectedFirm = globalFirms?.tour_firms.find(
    (item) => item.name === state.data?.firm
  );
  const [isDocumentDeleteModal, setDocumentDeleteModal] = useState<undefined | string>();
  const [isDeleteModalOpen, setDeleteModalState] = useState(false);

  useEffect(() => {
    runAsyncProcess(creditApi.getCredit({gutschrift_id: creditId!}));
  }, [creditId, runAsyncProcess]);

  return (
    <Page title={"Gutschriften"}>
      <PageContent>
        <div className={"has-space-between"}>
          <div className={"credit-detail-page__header__breadcrumb"}>
            <NavLink
              to={ROUTES.CREDITS}
              className={"typography--h5 text-color--secondary"}>
              {"Gutschriften"}
            </NavLink>

            <ArrowRightIcon />

            <p className={"typography--h5 text-color--primary"}>{creditId}</p>
          </div>

          <div>
            {state.data?.file_url && (
              <a
                href={state.data?.file_url}
                target={"_blank"}
                rel={"noreferrer"}
                className={"button button--primary button--large"}>
                <DocumentIcon />

                {"Herunterladen"}
              </a>
            )}

            <Button
              customClassName={
                "button--transparent credit-detail-page__header__delete-button"
              }
              onClick={() => setDeleteModalState(true)}
              style={{marginLeft: "auto", marginTop: "8px"}}>
              <TrashIcon />

              {"Löschen"}
            </Button>

            <Modal isOpen={isDeleteModalOpen} onClose={() => setDeleteModalState(false)}>
              <DeleteModal
                onSubmit={handleDelete}
                onClose={() => setDeleteModalState(false)}
              />
            </Modal>
          </div>
        </div>

        <AsyncContent requestStates={[state]} content={renderAsyncContent} />
      </PageContent>
    </Page>
  );

  function renderAsyncContent(status: AsyncContentStatus) {
    let node = <Fragment />;

    switch (status) {
      case "pending":
        node = <Loading />;
        break;

      case "error":
        node = <p>{"An error ocurred on server."}</p>;
        break;

      case "success":
        node = (
          <div className={"credit-detail-page__info-wrapper"}>
            <div className={"credit-detail-page__info-header"}>
              <h2 className={"typography--h1 text-color--blue"}>
                {"Gutschrift Informationen"}
              </h2>

              <Button onClick={() => setEditModalState(true)}>
                <EditIcon />
              </Button>

              <Modal isOpen={editModalState} onClose={() => setEditModalState(false)}>
                <CreateCreditForm
                  onClose={() => setEditModalState(false)}
                  data={{
                    firmList: selectedFirm?.id
                      ? [{id: selectedFirm.id, title: selectedFirm.name}]
                      : [],
                    billDate: state.data?.creation_date
                      ? new Date(state.data.creation_date)
                      : null,
                    start: state.data?.start ? new Date(state.data.start) : null,
                    end: state.data?.end ? new Date(state.data.end) : null,
                    document_nr: state.data?.document_nr,
                    gross_amount: state.data?.open,
                    taxes: state.data?.taxes,
                    avis: state.data?.avis,
                    file_url: state.data?.file_url,
                    id: state.data?.id
                  }}
                />
              </Modal>
            </div>

            <div className={"credit-detail-page__info-container"}>
              <div>
                <div className={"credit-detail-page__info__field"}>
                  <label
                    htmlFor={"doc-no"}
                    className={"typography--h7 text-color--primary"}>
                    {"Dokumentnummer:"}
                  </label>
                  <p className={"typography--h5 text-color--primary"}>
                    {state.data?.document_nr}
                  </p>
                </div>

                <div className={"credit-detail-page__info__field"}>
                  <label
                    htmlFor={"firm-name"}
                    className={"typography--h7 text-color--primary"}>
                    {"Firmenname:"}
                  </label>
                  <p className={"typography--h5 text-color--primary"}>
                    {state.data?.firm}
                  </p>
                </div>

                <div className={"credit-detail-page__info__field"}>
                  <label
                    htmlFor={"receipt-date"}
                    className={"typography--h7 text-color--primary"}>
                    {"Eingangsdatum:"}
                  </label>
                  <p className={"typography--h5 text-color--primary"}>
                    {state.data?.creation_date}
                  </p>
                </div>
              </div>

              <div>
                <div className={"credit-detail-page__info__field"}>
                  <label
                    htmlFor={"time-period"}
                    className={"typography--h7 text-color--primary"}>
                    {"Zeitraum:"}
                  </label>
                  <p className={"typography--h5 text-color--primary"}>
                    {state.data?.start && state.data?.end
                      ? `${format(new Date(state.data.start), DATE_FORMAT.DEFAULT)} - 
                    ${format(new Date(state.data.end), DATE_FORMAT.DEFAULT)}`
                      : "-"}
                  </p>
                </div>

                <div className={"credit-detail-page__info__field"}>
                  <label
                    htmlFor={"paid"}
                    className={"typography--h7 text-color--primary"}>
                    {"Ausgezahlt:"}
                  </label>
                  <p className={"typography--h5 text-color--primary"}>
                    {`${formatNumber({maximumFractionDigits: 0})(
                      Number(state.data?.paid)
                    )}€`}
                  </p>
                </div>
              </div>

              <div>
                <div className={"credit-detail-page__info__field"}>
                  <label
                    htmlFor={"debt"}
                    className={"typography--h7 text-color--primary"}>
                    {"Offen:"}
                  </label>
                  <p className={"typography--h5 text-color--primary"}>
                    {`${formatNumber({maximumFractionDigits: 0})(
                      Number(state.data?.open)
                    )}€`}
                  </p>
                </div>

                <div className={"credit-detail-page__info__field"}>
                  <label
                    htmlFor={"gross"}
                    className={"typography--h7 text-color--primary"}>
                    {"Brutto:"}
                  </label>
                  <p className={"typography--h5 text-color--primary"}>
                    {state.data?.paid && state.data?.open
                      ? `${formatNumber({maximumFractionDigits: 2})(
                          parseFloat(state.data?.paid) + parseFloat(state.data?.open)
                        )}€`
                      : "-"}
                  </p>
                </div>
              </div>
            </div>

            <div className={"credit-detail-page__payment-history"}>
              <div className={"has-space-between"}>
                <h2 className={"typography--h1 text-color--blue"}>
                  {"Zahlungshistorie"}
                </h2>

                <Button
                  customClassName={"button--blue"}
                  onClick={() => setModalVisibility(true)}>
                  {"Neue hinzufügen"}

                  <PlusIcon />
                </Button>

                <Modal isOpen={isModalOpen} onClose={() => setModalVisibility(false)}>
                  <CreditForm
                    onClose={() => setModalVisibility(false)}
                    onSubmit={handleSubmit}
                  />
                </Modal>
              </div>

              <Table
                customClassName={"credit-detail-page__table"}
                data={state.data?.gutschrift_payments || []}
                columns={generateCreditDetailTableColumns()}
                customRow={renderRow}
              />
            </div>

            <Modal
              isOpen={!!isDocumentDeleteModal}
              onClose={() => setDocumentDeleteModal(undefined)}>
              <DeleteModal
                text={"Zahlung"}
                onSubmit={() => handleDocumentDelete(isDocumentDeleteModal!)}
                onClose={() => setDocumentDeleteModal(undefined)}
              />
            </Modal>
          </div>
        );
        break;

      default:
        break;
    }

    return node;
  }

  async function handleDelete() {
    const formData = new FormData();

    formData.append("gutschrift_id", creditId!);
    formData.append("admin", user!.hash);

    await axios({
      method: "POST",
      url: `${BASE_URL}delete-gutschrift`,
      data: formData
    });

    navigate(ROUTES.CREDITS);
  }

  async function handleSubmit(data: {amount: string; date: null | Date}) {
    const formData = new FormData();

    formData.append("gutschrift_id", creditId!);
    formData.append("admin", user!.hash);
    formData.append("amount", data.amount);
    if (data.date) formData.append("date", format(data.date, DATE_FORMAT.API));

    await axios({
      method: "POST",
      url: `${BASE_URL}add-gutschrift-payment`,
      data: formData
    });

    // setState({
    //   ...state,
    //   data: {
    //     ...state.data!,
    //     gutschrift_payments: [
    //       ...state.data!.gutschrift_payments,
    //       {id: String(data.date), amount: data.amount, date: String(data.date)}
    //     ]
    //   }
    // });

    setModalVisibility(false);
    location.reload();
  }

  function renderRow(rowData: SingleCreditPayment) {
    return (
      <div className={"credit-detail-page__table__row table-row"}>
        <p className={"typography--h5"}>{`${formatNumber({maximumFractionDigits: 0})(
          Number(rowData.amount)
        )}€`}</p>

        <p className={"typography--h5"}>
          {format(new Date(rowData.date), DATE_FORMAT.DEFAULT)}
        </p>

        <Button
          onClick={() => setDocumentDeleteModal(rowData.id)}
          customClassName={"button--transparent"}
          style={{marginTop: "auto"}}>
          <TrashIcon />
        </Button>
      </div>
    );
  }

  async function handleDocumentDelete(id: string) {
    const formData = new FormData();

    formData.append("gutschrift_payment_id", id);
    formData.append("admin", user!.hash);
    await axios({
      method: "POST",
      data: formData,
      url: `${BASE_URL}delete-gutschrift-payment`
    });

    location.reload();
  }
}

export default CreditDetailPage;
