import "./_employee-expenses-page.scss";

import {useCallback, useEffect, useState} from "react";

import PageContent from "../../../component/page/content/PageContent";
import Page from "../../../component/page/Page";
import Table from "../../../component/table/Table";
import useAsyncProcess from "../../../core/network/async-process/useAsyncProcess";
import EmployeeDetailPageHeader from "../detail/header/EmployeeDetailPageHeader";
import employeeApi from "../../api/employeeApi";
import {useAppContext} from "../../../core/app/AppContext";
import {DailyExpense} from "../../api/employeeApiModel";
import Loading from "../../../component/loading/Loading";
import {Ordering} from "../../../core/util/ordering/orderingTypes";
import {filterTruthyObjectValues} from "../../../core/util/object/objectUtils";

type TableOrdering = "name" | "worker_id" | "daily_expense";

export type ExpenseTableOrdering = Ordering<TableOrdering>;

function EmployeeExpensesPage() {
  const {runAsyncProcess, state} = useAsyncProcess<DailyExpense[]>();
  const {
    state: {ownFirm}
  } = useAppContext();
  const [ordering, setOrdering] = useState<ExpenseTableOrdering["state"]>();
  const [tableColumns, setColumns] = useState(generateColumns());

  const handleOrdering = useCallback(
    (order_by: TableOrdering) => {
      setOrdering({order_by, direction: ordering?.direction === "asc" ? "des" : "asc"});
    },
    [ordering?.direction]
  );

  useEffect(() => {
    setColumns(generateColumns({state: ordering, onChange: handleOrdering}));
  }, [ordering, handleOrdering]);

  useEffect(() => {
    runAsyncProcess(
      employeeApi.getDailyExpenses(
        filterTruthyObjectValues({
          own_firm: ownFirm,
          order_by: ordering?.order_by,
          direction: ordering?.direction
        })
      )
    );
  }, [runAsyncProcess, ownFirm, ordering?.direction, ordering?.order_by]);

  return (
    <Page title={"Spesenliste | Mitarbeiter"}>
      <PageContent>
        {state.isRequestFetched ? (
          <>
            <EmployeeDetailPageHeader employeeName={"Spesenliste"} />

            <div style={{marginTop: "32px"}}>
              <Table
                data={state.data!}
                columns={tableColumns}
                customRow={renderRow}
                customClassName={"employee-expenses-page__table"}
              />
            </div>
          </>
        ) : (
          <Loading />
        )}
      </PageContent>
    </Page>
  );

  function renderRow(data: DailyExpense) {
    return (
      <div className={"table-row"}>
        <p>{data.name}</p>
        <p>{data.worker_id}</p>
        <p>{data.daily_expense}</p>
      </div>
    );
  }
}

function generateColumns(ordering?: ExpenseTableOrdering) {
  return [
    {
      id: "EmployeeExpensesTable.name",
      header: "Name",
      orderingProps: {
        isActive: ordering?.state?.order_by === "name",
        onChange: () => ordering?.onChange("name"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "EmployeeExpensesTable.id",
      header: "Personalnummer",
      orderingProps: {
        isActive: ordering?.state?.order_by === "worker_id",
        onChange: () => ordering?.onChange("worker_id"),
        direction: ordering?.state?.direction
      }
    },
    {
      id: "EmployeeExpensesTable.expenses",
      header: "Spesen",
      orderingProps: {
        isActive: ordering?.state?.order_by === "daily_expense",
        onChange: () => ordering?.onChange("daily_expense"),
        direction: ordering?.state?.direction
      }
    }
  ];
}

export default EmployeeExpensesPage;
